import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectOrderMessagesExpedition,
  selectOrderMessagesProduction,
  selectOrderMessagesProductionAndExpedition,
} from 'app/containers/OrderDetails/selectors';
import MessageOrder from 'app/containers/OrderDetails/GeneralTab/MessageOrder';

const MessagesArea = () => {
  const messagesProduction = useSelector(selectOrderMessagesProduction);
  const messagesExpedition = useSelector(selectOrderMessagesExpedition);
  const messagesProductionAndExpedition = useSelector(
    selectOrderMessagesProductionAndExpedition,
  );

  if (
    (messagesProduction && messagesProduction.length > 0) ||
    (messagesExpedition && messagesExpedition.length > 0) ||
    (messagesProductionAndExpedition &&
      messagesProductionAndExpedition.length > 0)
  ) {
    return (
      <div className="flex flex-col">
        <div className="text-xl mb-2">
          Messages (
          {(messagesProduction?.length ?? 0) +
            (messagesExpedition?.length ?? 0) +
            (messagesProductionAndExpedition?.length ?? 0)}
          )
        </div>
        <div>
          {messagesProductionAndExpedition?.map((e: string) => (
            <MessageOrder key={e} messageContent={e} expedition={false} />
          ))}
          {messagesProduction?.map((e: string) => (
            <MessageOrder key={e} messageContent={e} expedition={false} />
          ))}
          {messagesExpedition?.map((e: string) => (
            <MessageOrder key={e} messageContent={e} expedition />
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default MessagesArea;
