/**
 *
 * OrderInfos
 *
 */

import React from 'react';
import { OrderInfo } from 'app/pages/ToursPage/types';
import { useDispatch } from 'react-redux';
import { GrFormClose } from 'react-icons/gr';
import { resetSelectionOrderAction } from 'app/containers/TourMap/slice';
import { PartOrder } from 'app/models/PartOrder';
import { setOrder } from 'app/containers/SiteWrapper/slice';
import { setCurrentPartOrderId } from 'store/slices/activeContentSlice';

interface Props {
  orderIdentification: string;
  partOrder: PartOrder;
  orderInfos: OrderInfo | undefined;
  addressOnly: boolean;
}

export function OrderDisplayInfos({
  orderInfos,
  orderIdentification,
  partOrder,
  addressOnly,
}: Props) {
  const dispatch = useDispatch();

  return (
    <div className="flex justify-around py-2 text-gray-700 bg-white">
      {!addressOnly && <div className="">Commande : {orderIdentification}</div>}
      <div>
        {orderInfos?.address1} {orderInfos?.postcode} {orderInfos?.city}
      </div>
      {!addressOnly && (
        <>
          <button
            className="border py-0 px-2 text-gray-700"
            onClick={() => {
              dispatch(setCurrentPartOrderId(partOrder.id));
            }}
          >
            Détails
          </button>

          <button
            onClick={() => {
              dispatch(resetSelectionOrderAction());
            }}
          >
            <GrFormClose className="h-5 w-5" />
          </button>
        </>
      )}
    </div>
  );
}
