import React from 'react';
import { PackageOrder } from 'app/models/PackageOrder';
import 'assets/css/print.css';
import styled from 'styled-components';
import { PartOrder } from 'app/models/PartOrder';

interface Props {
  order: PartOrder;
  packageOrder?: PackageOrder;
}

export function BoxWithPackages({ order, packageOrder }: Props) {
  const destination = order.destination;
  const numberOfPackages = order.packages ? order.packages.length : 0;

  const packagesInfo = packageOrder
    ? `${packageOrder.packageNumber} / ${numberOfPackages}`
    : `Tot. packages: ${numberOfPackages}`;

  let partOrderWeight = 0;
  order?.packages?.forEach(pkg => {
    if (pkg.weight !== undefined) partOrderWeight += pkg.weight;
  });

  return destination.direct ? (
    <ColorBoxDirect
      color={destination.color}
      textColor={destination.textColor}
      className="absolute bottom-box-destination"
    >
      <div className="relative text-center">
        <div className="bottom-box-destination-name-shop uppercase font-bold">
          {destination.name}
        </div>
        <div className="bottom-box-destination-pkg">{packagesInfo}</div>
      </div>
    </ColorBoxDirect>
  ) : destination.name === 'Köln' ? (
    <ColorBox
      color={destination.color}
      textColor={destination.textColor}
      className="absolute bottom-box-destination"
    >
      <div className="relative text-center">
        <ColorBox
          color={'#fff'}
          textColor={'#000'}
          borderColor={
            order.destinationNotAggregated.name === 'Köln Lieferung'
              ? destination.color
              : undefined
          }
          className="bottom-box-destination-carrier"
        >
          {order.destinationNotAggregated.name === 'Köln Lieferung' ? (
            'Lieferung'
          ) : (
            <>&nbsp;</>
          )}
        </ColorBox>
        <div className="bottom-box-destination-name">{destination.name}</div>
        <div className="bottom-box-destination-pkg">{packagesInfo}</div>
      </div>
    </ColorBox>
  ) : destination.shop && order.carrier?.name === 'TNPS' ? (
    <ColorBox
      color={destination.color}
      textColor={destination.textColor}
      className="absolute bottom-box-destination"
    >
      <div className="relative text-center">
        <div className="bottom-box-destination-name-shop">
          {destination.name}
        </div>
        <div className="bottom-box-destination-pkg">{packagesInfo}</div>
      </div>
    </ColorBox>
  ) : (
    <ColorBox
      color={order.carrier?.color}
      textColor={order.carrier?.textColor}
      className="absolute bottom-box-destination"
    >
      <div className="relative text-center">
        <ColorBox
          color={destination.color}
          textColor={destination.textColor}
          className="bottom-box-destination-carrier"
        >
          {destination.name}
        </ColorBox>
        <div className="bottom-box-destination-name">
          {order.carrier?.name}
          {order.carrier?.name.toLocaleUpperCase() === 'BRENGER' &&
            partOrderWeight > 190000 && (
              <>
                <span className="bg-red p-2 ml-2">DUO</span>
              </>
            )}
        </div>
        <div className="bottom-box-destination-pkg">{packagesInfo}</div>
      </div>
    </ColorBox>
  );
}

const ColorBox = styled.div<{
  color?: string;
  textColor?: string;
  borderColor?: string;
}>`
  background-color: ${p => (p.color ? p.color : 'none')};
  color: ${p => (p.textColor ? p.textColor : 'default')};
  border: ${p => (p.borderColor ? `2px solid ${p.borderColor}` : 'none')};
`;

const ColorBoxDirect = styled.div<{
  color?: string;
  textColor?: string;
  borderColor?: string;
}>`
  background-color: white;
  color: black;
  border: 8px solid red;
`;
