import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  createPartOrder,
  PartOrder,
  PartOrderJson,
} from 'app/models/PartOrder';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import { setPartOrder, setPartOrders } from 'store/slices/partOrderSlice';
import { providesList } from 'services/utils';

export const partOrderEndpoints = (
  builder: EndpointBuilder<any, any, any>,
) => ({
  getPartOrder: builder.query<PartOrderJson[], void>({
    query: () => `/part-order/`,
    providesTags: result => providesList(result, 'PartOrder'),
    async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
      // We integrate the transformation logic within the RTK Query lifecycle methods to avoid using selectors directly in reducers.
      try {
        const { data } = await queryFulfilled;
        const state = getState();
        // @ts-ignore
        const destinations = selectAllDestinations(state);
        // @ts-ignore
        const carriers = selectAllCarriers(state);
        // @ts-ignore
        const colors = selectAllColors(state);
        const transformedPartOrders = data.map(e =>
          createPartOrder(e, destinations, carriers, colors),
        );
        dispatch(setPartOrders(transformedPartOrders));
      } catch (error) {
        console.error('Error fetching part orders:', error);
      }
    },
  }),
  getPartOrderById: builder.query<PartOrderJson, number>({
    query: id => `/part-order/${id}/`,
    providesTags: (result, error, id) => [{ type: 'PartOrder', id }],
    async onQueryStarted(id, { dispatch, getState, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled;
        // Transform the JSON data to the PartOrder model

        // @ts-ignore
        const destinations = selectAllDestinations(getState());
        // @ts-ignore
        const carriers = selectAllCarriers(getState());
        // @ts-ignore
        const colors = selectAllColors(getState());
        const transformedPartOrder = createPartOrder(
          data,
          destinations,
          carriers,
          colors,
        );

        dispatch(setPartOrder(transformedPartOrder));
      } catch (error) {
        console.error(`Error fetching part order with id ${id}:`, error);
      }
    },
  }),
  updatePartOrder: builder.mutation<PartOrderJson, Partial<PartOrder>>({
    query: ({ id, ...body }) => ({
      url: `part-order/${id}/`,
      method: 'PUT',
      body: body,
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'PartOrder', id },
      { type: 'Tour', id: 'LIST' },
    ],
    async onQueryStarted(
      { id, ...body },
      { dispatch, getState, queryFulfilled },
    ) {
      try {
        // Transform the JSON data to the PartOrder model
        const { data } = await queryFulfilled;
        // @ts-ignore
        const destinations = selectAllDestinations(getState());
        // @ts-ignore
        const carriers = selectAllCarriers(getState());
        // @ts-ignore
        const colors = selectAllColors(getState());
        const transformedPartOrder = createPartOrder(
          data,
          destinations,
          carriers,
          colors,
        );
        dispatch(setPartOrder(transformedPartOrder));
      } catch (error) {
        console.error(`Error updating part order with id ${id}:`, error);
      }
    },
  }),
  resetPartOrder: builder.mutation<void, number>({
    query: id => ({
      url: `part-order/${id}/reset/`,
      method: 'GET',
    }),
    invalidatesTags: (result, error, id) => [{ type: 'PartOrder', id }],
  }),
  subdividePartOrder: builder.mutation<unknown, { id: number; data: any }>({
    query: ({ id, data }) => ({
      url: `/part-order/${id}/subdivide/`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'PartOrder', id },
      { type: 'PartOrder', id: 'LIST' },
    ],
  }),
  mergePackages: builder.mutation<
    unknown,
    { pkgId: number; partOrderId: number; data: { [key: string]: number[] } }
  >({
    query: ({ pkgId, data }) => ({
      url: `/package/${pkgId}/merge/`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result, error, { partOrderId }) => [
      { type: 'PartOrder', partOrderId },
    ],
  }),
  splitPackages: builder.mutation<
    unknown,
    { pkgId: number; partOrderId: number; data: any }
  >({
    query: ({ pkgId, partOrderId, data }) => ({
      url: `/package/${pkgId}/split/`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result, error, { partOrderId }) => [
      { type: 'PartOrder', partOrderId },
    ],
  }),
  addPackages: builder.mutation<unknown, { partOrderId: number; data: any }>({
    query: ({ data }) => ({
      url: `/package/`,
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result, error, { partOrderId }) => [
      { type: 'PartOrder', partOrderId },
    ],
  }),
});
