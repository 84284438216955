import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { removeToken } from 'app/containers/AuthProvider/slice';

const Menu = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside(ref, () => {
    hide();
  });

  const hide = () => {
    setOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="flex"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <FiMenu className="h-8 w-8" />
      </button>
      {isOpen && (
        <div
          ref={ref}
          className="absolute z-50 w-64 bg-white border-gray-200 -ml-32 shadow-xl text-gray-600"
        >
          <Link to="/">
            <div className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer">
              Accueil
            </div>
          </Link>
          <Link to="/history">
            <div className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer">
              Historique
            </div>
          </Link>
          <Link to="/waypoint">
            <div className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer">
              Points de passages
            </div>
          </Link>
          <Link to="/driver">
            <div className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer">
              Livreurs
            </div>
          </Link>
          <Link to="/carrier-configuration">
            <div className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer">
              Kuehne-Nagel
            </div>
          </Link>
          <div
            className="menu-item hover:bg-gray-300 px-3 py-2 cursor-pointer"
            onClick={() => {
              dispatch(removeToken());
            }}
          >
            Se déconnecter
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
