import React, { useState } from 'react';
import { FiPrinter } from 'react-icons/fi';
import { Tour } from 'app/models/Tour';
import { TourPrinter } from 'app/containers/TourPrinter/index';
import { requestGet } from 'utils/requests';
import { API_BASE_URL } from 'app/constants';
import { toast } from 'react-toastify';
import { CircleSpinner } from 'react-spinners-kit';
import { PartOrder } from 'app/models/PartOrder';
import { useSelector } from 'react-redux';
import { selectPartOrderFromTour } from 'store/slices/toursSlice';

interface Props {
  tour: Tour;
}

export interface TourFurnitures {
  id: number;
  name: string;
  image: string;
  prestashopId: number;
  partOrder: number;
  partOrderObject?: PartOrder;
}

interface TourFurnituresResponse {
  furnitures: TourFurnitures[];
}

const TourPrinterButton = ({ tour }: Props) => {
  const [tourData, setTourData] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const partOrders = useSelector(selectPartOrderFromTour(tour.id));

  const getTourData = (tourId: number) => {
    if (loading) {
      return;
    }

    let hasAssembly = false;
    if (partOrders) {
      for (const partOrder of partOrders) {
        if (partOrder?.orderInfo.assembly) {
          hasAssembly = true;
          break;
        }
      }
    }

    if (!hasAssembly) {
      setTourData([]);
      return;
    }

    setLoading(true);
    requestGet(`${API_BASE_URL}/tour/${tourId}/furnitures/`)
      .then((data: TourFurnituresResponse) => {
        const partOrderFurnitures = new Map<number, TourFurnitures[]>();
        for (const entry of data.furnitures) {
          if (partOrderFurnitures.has(entry.partOrder)) {
            partOrderFurnitures.get(entry.partOrder)?.push(entry);
          } else {
            partOrderFurnitures.set(entry.partOrder, [entry]);
          }
        }
        const results = [];
        if (partOrders) {
          for (const partOrder of partOrders) {
            if (
              partOrder &&
              partOrderFurnitures.has(partOrder.id) &&
              partOrder?.orderInfo.assembly
            ) {
              const partOrderFurnituresList = partOrderFurnitures.get(
                partOrder.id,
              );
              if (partOrderFurnituresList) {
                partOrderFurnituresList.forEach(
                  pO => (pO.partOrderObject = partOrder),
                );
              }
              // @ts-ignore
              results.push(partOrderFurnituresList);
            }
          }
        }
        setTourData(results);
        setLoading(false);
      })
      .catch((error: any) => {
        toast.error(`Une erreur est survenue: ${error.statusText}`);
        setTourData(undefined);
        setLoading(false);
      });
  };

  const onAfterPrint = () => {
    setTourData(undefined);
  };

  return (
    <button
      data-tip="Imprimer la feuille de route"
      data-for="infos"
      onClick={() => {
        getTourData(tour.id);
      }}
    >
      {loading ? (
        <CircleSpinner size={12} color="#fff" />
      ) : (
        <FiPrinter className="h-5 w-5" />
      )}
      {tourData && (
        <TourPrinter
          tour={tour}
          furnitureTourData={tourData}
          onAfterPrint={onAfterPrint}
        />
      )}
    </button>
  );
};

export default TourPrinterButton;
