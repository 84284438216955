import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from 'app/containers/TourMap/slice';
import { PartOrder } from 'app/models/PartOrder';

const selectDomain = (state: RootState) => state.tourMap || initialState;

export const selectTourMap = createSelector(
  [selectDomain],
  tourMapState => tourMapState,
);

export const selectOrderSelected = createSelector(
  [selectDomain],
  (substate): PartOrder | null => substate.selectedOrder,
);
