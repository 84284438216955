import React from 'react';
import { OrderInfo } from 'app/pages/ToursPage/types';
import { FaHammer } from 'react-icons/fa';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface Props {
  infos: OrderInfo;
  index: number;
}

const OrderTourStopInfos = ({ infos, index }: Props) => (
  <div key={`${infos.idOrder}`} className="page-section">
    <div className="flex items-baseline justify-between w-full">
      <div>
        <span className="page-section-order-n">{index + 1}. </span>
        <span className="page-section-order-id">
          {infos.company !== undefined &&
            infos.company !== '' &&
            `${infos.company.toUpperCase()} / `}{' '}
          {infos.lastname && infos.lastname.toUpperCase()} {infos.firstname}
        </span>
      </div>
      <div className="page-section-order-id">
        <div className="flex items-center">
          {infos.assembly && <FaHammer className="mr-2 h-6 w-6" />}
          {infos.idOrder}{' '}
        </div>
      </div>
    </div>
    {infos.gAddress !== '' && <div>Adresse Gmap : {infos.gAddress}</div>}
    <div>
      Adresse client :{' '}
      {infos.address2 && infos.address2 !== '' && `${infos.address2}, `}{' '}
      {infos.address1}, {infos.postcode} {infos.city}
    </div>
    {infos.addrInfos && infos.addrInfos !== '' && (
      <div>Infos client : {infos.addrInfos}</div>
    )}
    <div>
      Tél. :{' '}
      {infos.phoneMobile &&
        infos.phoneMobile !== '' &&
        `${parsePhoneNumberFromString(
          infos.phoneMobile,
        )?.formatInternational()}`}
      {infos.phoneMobile &&
        infos.phoneMobile !== '' &&
        infos.phone &&
        infos.phone !== '' &&
        ' - '}
      {infos.phone &&
        infos.phone !== '' &&
        `${parsePhoneNumberFromString(infos.phone)?.formatInternational()}`}
    </div>
    {infos.messagesDelivery && (
      <div>
        Messages Kewnet :{' '}
        {infos.messagesDelivery
          .split('////')
          .reverse()
          .map(message => (
            <div>- {message.split('%%%')[0]}</div>
          ))}
      </div>
    )}
  </div>
);

export default OrderTourStopInfos;
