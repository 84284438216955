import { Progression } from 'app/models/Order';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';
import { KewnetStatus } from 'app/constants';
import {
  ProductionStatus,
  ProductOrderType,
  Workstation,
} from 'app/containers/OrderDetails/types';
import { PartOrder } from 'app/models/PartOrder';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';
import {
  selectAllPartOrders,
  selectPartOrderEntities,
} from 'store/slices/partOrderSlice';

const selectDomain = (state: RootState) => state.siteWrapper || initialState;

export const selectDestinations = createSelector(
  [selectDomain],
  subdomain => subdomain.destinations,
);

export const selectOrders = createSelector(
  [selectDomain],
  (subdomain): PartOrder[] | undefined => subdomain.orders,
);

export const selectOrdersProduction = createSelector(
  [selectDomain],
  subdomain =>
    subdomain.orders?.filter(o => o.statusId === KewnetStatus.inProgress),
);

export const selectNumberOfCuttings = createSelector(
  [selectDomain],
  subdomain => subdomain.numberOfCuttings,
);

export const selectOrdersProductionWorkstation = (
  workstation: Workstation,
  productionStatus?: ProductionStatus,
) =>
  createSelector(selectOrdersProduction, (orders: PartOrder[] | undefined):
    | PartOrder[]
    | undefined => {
    if (orders) {
      const orderStation: PartOrder[] = [];
      orders
        .filter(o => o.productionDate)
        .forEach(order => {
          let progression: Progression | undefined;
          switch (workstation) {
            case Workstation.Cabinet:
              progression = order.progressionCabinet;
              break;
            case Workstation.Corner:
              progression = order.progressionCorners;
              break;
            case Workstation.Accessory:
              progression = order.progressionAccessories;
              break;
            case Workstation.Drawer:
              progression = order.progressionDrawers;
              break;
          }

          if (progression) {
            if (productionStatus !== undefined) {
              switch (productionStatus) {
                case ProductionStatus.NotStarted:
                  if (progression.progress === 0) orderStation.push(order);
                  break;
                case ProductionStatus.Started:
                  if (progression.progress > 0 && progression.progress < 100)
                    orderStation.push(order);
                  break;
                case ProductionStatus.Standby:
                  if (progression.standby) orderStation.push(order);
                  break;
                case ProductionStatus.Done:
                  if (progression.progress === 100) orderStation.push(order);
                  break;
              }
            } else {
              orderStation.push(order);
            }
          }
        });

      return orderStation.sort(
        (a, b) => a.diffBusinessDays - b.diffBusinessDays,
      );
    }
    return undefined;
  });

export const selectOrdersMap = createSelector(
  selectAllPartOrders,
  partOrders => {
    const map = new Map<number, PartOrder>();
    partOrders?.forEach(partOrder => map.set(partOrder.id, partOrder));
    return map;
  },
);

export const selectOrderProducts = createSelector(
  [selectCurrentPartOrder()],
  partOrder => partOrder?.products,
);

export const selectPartRealOrderProducts = createSelector(
  [selectOrderProducts],
  products =>
    products
      ?.filter(product => !product.drawer)
      .filter(product => product.type !== ProductOrderType.Drawer),
);

export const selectOrderPackages = createSelector(
  [selectCurrentPartOrder()],
  partOrder => partOrder?.packages,
);

export const selectOrderLoading = createSelector(
  [selectDomain],
  subdomain => subdomain.loadingOrder,
);

export const selectCuttingPieces = createSelector(
  [selectDomain],
  cuttingPanePageState => cuttingPanePageState.cuttingPieces,
);

export const selectCuttingPiecesSorted = createSelector(
  [selectCuttingPieces, selectPartOrderEntities],
  (cuttingPieces, partOrders) =>
    cuttingPieces
      .slice()
      .sort(
        (a, b) =>
          (partOrders[a.order]?.productionDate as any) -
          (partOrders[b.order]?.productionDate as any),
      ),
);

export const selectBasePartOrder = createSelector(
  [selectCurrentPartOrder(), selectAllPartOrders],
  (partOrder, partOrders): PartOrder | undefined => {
    if (partOrder) {
      if (partOrder.index > 1) {
        if (partOrders) {
          return partOrders.find(
            o => o.order.id === partOrder.order.id && o.index === 1,
          );
        }
      } else return partOrder;
    }
    return undefined;
  },
);

export const selectSiblingPartOrders = createSelector(
  [selectCurrentPartOrder(), selectAllPartOrders],
  (partOrder, partOrders): PartOrder[] | undefined => {
    if (partOrder) {
      if (partOrder.total > 1) {
        if (partOrders) {
          return partOrders.filter(
            o => o.order.id === partOrder.order.id && o.index !== 1,
          );
        }
      }
    }
    return undefined;
  },
);
