import { createSelector } from '@reduxjs/toolkit';
import {
  selectOrderProducts,
  selectPartRealOrderProducts,
} from 'app/containers/SiteWrapper/selectors';
import { ProductOrder } from 'app/models/ProductOrder';
import { Workstation } from 'app/containers/OrderDetails/types';

export const selectDirectPartOrderProductsFiltered = (filtered: boolean) =>
  createSelector([selectPartRealOrderProducts], products => {
    if (products) {
      if (filtered) {
        const productList = new Set<ProductOrder>();

        products.forEach(product => {
          product.production.forEach(piece => {
            if (
              piece.cutting ||
              piece.isFragile ||
              piece.station === Workstation.Drawer
            ) {
              productList.add(product);
            }
          });
        });
        return Array.from(productList);
      }
    }
    return products;
  });

export const selectHasCuttingPiece = createSelector(
  [selectOrderProducts],
  products => {
    let cutting = false;
    products?.forEach(product =>
      product.production.forEach(p => {
        if (p.cutting) cutting = true;
      }),
    );
    return cutting;
  },
);
