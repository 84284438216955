import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Driver } from 'app/pages/DriverPage/types';
import { providesList } from 'services/utils';
export const driverEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getAllDrivers: builder.query<Driver[], void>({
    query: () => `/driver/`,
    providesTags: results => providesList(results, 'Driver'),
  }),
  getDriver: builder.query<Driver, number>({
    query: id => `/driver/${id}/`,
    providesTags: ['Driver'],
  }),
  addDriver: builder.mutation<Driver, Partial<Driver>>({
    query: newDriver => ({
      url: `/driver/`,
      method: 'POST',
      body: newDriver,
    }),
    invalidatesTags: ['Driver'],
  }),
  updateDriver: builder.mutation<Driver, Partial<Driver>>({
    query: ({ id, ...body }) => ({
      url: `driver/${id}/`,
      method: 'PATCH',
      body: body,
    }),
    invalidatesTags: ['Driver'],
  }),
  deleteDriver: builder.mutation<Driver, number>({
    query: id => ({
      url: `driver/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Driver'],
  }),
});
