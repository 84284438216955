import React from 'react';
import 'assets/css/print.css';
import { PartOrder } from 'app/models/PartOrder';
import CarrierLabel from '../../../../components/Kewlox/CarrierLabel';
import Img from '../../../../components/Img';
import Logo from 'assets/images/Kewlox_logo.svg';
import { useSelector } from 'react-redux';
import { AppRootState } from 'index';
import { selectPartOrderById } from 'store/slices/partOrderSlice';

interface Props {
  partOrderId: number;
  firstPage?: boolean;
}

export function OrderPageForExternalCarrier({
  partOrderId,
  firstPage = false,
}: Props) {
  const nbPackagesToDisplay = 20;

  const partOrder = useSelector((state: AppRootState) =>
    selectPartOrderById(state, partOrderId),
  );

  if (!partOrder) return null;

  return (
    <div className={`${!firstPage && 'page-break'} page relative flex`}>
      <div className="w-full">
        <div className="flex items-center label-header justify-between w-full">
          <Img src={Logo} alt="Logo" className="label-kewlox-logo" />{' '}
          {partOrder.carrier && <CarrierLabel carrier={partOrder.carrier} />}
        </div>
        <div className="flex flex-col justify-start items-start order-page-content">
          <div className="flex justify-between w-full">
            <div>{partOrder.identification}</div>
            {false && !partOrder.orderInfo.paid && (
              <div className="text-2xl">NP</div>
            )}
          </div>
          <div>
            {partOrder.orderInfo.firstname}{' '}
            <b>{partOrder.orderInfo.lastname.toUpperCase()}</b>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start order-page-address">
          {partOrder.orderInfo.address2}
          <br />
          {partOrder.orderInfo.address1}
          <br />
          {partOrder.orderInfo.postcode} {partOrder.orderInfo.city}
          <br />
          {partOrder.orderInfo.isoCode}
          <br />
          {partOrder.orderInfo.phoneMobile}
          <br />
          {partOrder.orderInfo.phone !== partOrder.orderInfo.phoneMobile &&
            partOrder.orderInfo.phone}
        </div>
      </div>
    </div>
  );
}
