import { ALUMINIUM_COLOR, GLASS_COLOR, MIRROR_COLOR } from 'app/constants';

export interface ColorJSON {
  active: boolean;
  id: number;
  name: string;
  nameEn: string;
  order: number;
  reference: string;
  hexa: string;
  opacity: number;
  texture?: string;
}

export interface Color extends ColorJSON {
  hexaNum: number;
  hexaHtml: string;
  material: string | null;
}

export const createColor = (data: ColorJSON): Color => ({
  ...data,
  hexaNum: parseInt(`0x${data.hexa}`),
  hexaHtml: `#${data.hexa}`,
  material: getMaterial(data),
});

const getMaterial = (data: ColorJSON): string | null => {
  if (0 <= data.order && data.order < 100) return 'MDF';
  if (100 <= data.order && data.order < 200) return 'Acrylique';
  if (200 <= data.order && data.order < 300) return 'Acrylique';
  if ([GLASS_COLOR, MIRROR_COLOR, ALUMINIUM_COLOR].includes(data.reference))
    return '';
  return '';
};

export const findColor = (
  colorRef: string,
  colors: Color[],
): Color | undefined => {
  return colors.find(c => c.reference === colorRef);
};

export const extractColorReference = (
  pieceReference: string,
): string | undefined => {
  const results = pieceReference.match(/\[(.)+\]/);
  if (results && results.length > 0) {
    return results[0].replace('[', '').replace(']', '');
  }
};

export const extractColor = (
  pieceReference: string,
  colors: Color[],
): Color | undefined => {
  const colorRef = extractColorReference(pieceReference);
  if (colorRef) {
    const color = findColor(colorRef, colors);
    if (color) return color;
    throw new Error(`Color not found for reference: ${colorRef}`);
  }
};

// export class Color {
//   readonly id: number;
//   readonly name: string;
//   readonly nameEn: string;
//   readonly reference: string;
//   readonly hexa: string;
//   readonly order: number;
//   readonly active: boolean;
//   readonly opacity: number;
//   readonly texture?: string;
//
//   static colors = Color.setupColors(colorsData as ColorJSON[]);
//
//   constructor(data: ColorJSON) {
//     this.id = data.id;
//     this.name = data.name;
//     this.nameEn = data.nameEn;
//     this.reference = data.reference;
//     this.hexa = data.hexa;
//     this.order = data.order;
//     this.active = data.active;
//     this.opacity = data.opacity;
//     if (data.texture) this.texture = data.texture;
//   }
//
//   get hexaNum(): number {
//     return parseInt(`0x${this.hexa}`);
//   }
//
//   get hexaHtml(): string {
//     return `#${this.hexa}`;
//   }
//
//   get material(): string | null {
//     if (0 <= this.order && this.order < 100) return 'MDF';
//     if (100 <= this.order && this.order < 200) return 'Acrylique';
//     if (200 <= this.order && this.order < 300) return 'Acrylique';
//     if (this.reference === GLASS_COLOR) return '';
//     if (this.reference === MIRROR_COLOR) return '';
//     if (this.reference === ALUMINIUM_COLOR) return '';
//
//     return '';
//   }
//
//   static setupColors(colorsData: ColorJSON[]): Color[] {
//     return colorsData.map(c => new Color(c));
//   }
//
//   static findColor(colorRef: string): Color | undefined {
//     return Color.colors.find(c => c.reference === colorRef);
//   }
//
//   static extractColor(pieceReference: string): Color | undefined {
//     // Get a color from piece or product reference, the color reference must be between brackets
//     const results = pieceReference.match(/\[(.)+\]/);
//     if (results && results.length > 0) {
//       const colorRef = results[0].replace('[', '').replace(']', '');
//       return Color.findColor(colorRef);
//     }
//   }
// }
