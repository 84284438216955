import React, { ReactElement, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import { ProductionPiece } from 'app/models/ProductionPiece';
import _ from 'lodash';
import CuttingPage from 'app/containers/Cutting/CuttingPage';
import { Workstation } from 'app/containers/OrderDetails/types';
import { getMapColorBandsPieces } from 'app/pages/ProductionPage/CuttingPanePage/utils';
import { useSelector } from 'react-redux';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { PartOrder } from 'app/models/PartOrder';
import { Dictionary } from '@reduxjs/toolkit';

const getCuttingSections = (cuttingMap, partOrders): any[] => {
  const sections: ReactElement[] = [];

  let colors: any[] = Array.from(cuttingMap.keys());
  colors = _.orderBy(colors, ['material', 'name']);

  const mapSummary = new Map<string, any[]>();

  for (const color of Array.from(colors)) {
    const map = cuttingMap.get(color);
    map.forEach((allPieces, colorBandSize) => {
      if (allPieces.length > 0) {
        const piecesByOrder = _.groupBy(allPieces, piece => piece.order);

        _.forEach(piecesByOrder, (orderPieces: ProductionPiece[]) => {
          const orderPiecesByTrolley = _.groupBy(orderPieces, orderPiece =>
            orderPiece.trolleyName.startsWith('ND')
              ? `ND${orderPiece.order}`
              : orderPiece.trolleyName,
          );

          _.forEach(orderPiecesByTrolley, pieces => {
            const partOrder: PartOrder = partOrders[pieces[0].order];
            if (pieces.length > 0) {
              const group = pieces[0].trolleyName.startsWith('ND')
                ? `ND${partOrder.id}`
                : pieces[0].trolleyName;
              const key = `${partOrder.orderInfo.lastname}:::${partOrder.orderInfo.firstname}:::${partOrder.id}:::${group}`;
              if (!mapSummary.has(key)) mapSummary.set(key, []);
              mapSummary.get(key)?.push({
                color: color,
                colorBandSize: colorBandSize,
                trolleyName: group,
                order: pieces[0].order,
              });
              sections.push(
                <CuttingPage
                  order={partOrder}
                  trolleyName={group}
                  color={color}
                  colorBandSize={colorBandSize}
                  pieces={pieces}
                />,
              );
            }
          });
        });
      }
    });
  }
  return [mapSummary, sections];
};

class ComponentToPrint extends React.Component<{
  pieces: ProductionPiece[];
  partOrders: Dictionary<PartOrder>;
}> {
  render() {
    const { pieces, partOrders } = this.props;

    const sections: ReactElement[] = [];

    for (const workstation of Object.values(Workstation)) {
      const map = getMapColorBandsPieces(
        pieces.filter(p => p.station === workstation),
      );

      const data = getCuttingSections(map, partOrders);
      const newSections = data[1];

      if (newSections && newSections.length > 0) {
        const mapSummary = new Map(
          [...data[0]].sort((a, b) => String(a[0]).localeCompare(b[0])),
        );
        /*
        sections.push(
          <CuttingSummary
            mapSummary={mapSummary as Map<string, any[]>}
            workstation={workstation as Workstation}
          />,
        );
*/
        sections.push(...newSections);
      }
    }

    if (!sections || sections.length === 0) return null;

    return <div>{sections}</div>;
  }
}

interface Props {
  pieces: ProductionPiece[];
  onAfterPrint?: any;
}

export function CuttingToPrint({ pieces, onAfterPrint }: Props) {
  const componentRef = useRef<any>(null);
  const triggerRef = useRef<any>(null);

  const partOrders: Dictionary<PartOrder> = useSelector(
    selectPartOrderEntities,
  );

  useEffect(() => {
    triggerRef.current.click();
  }, []);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button ref={triggerRef} className="hidden">
            <FiPrinter className="h-5 w-5" />
          </button>
        )}
        content={() => componentRef.current}
        bodyClass="toprint"
        onAfterPrint={onAfterPrint}
      />
      <div className="will-print hidden">
        <ComponentToPrint
          ref={componentRef}
          pieces={pieces}
          partOrders={partOrders}
        />
      </div>
    </>
  );
}
