import React from 'react';
import DestinationsSelector from 'app/components/Filters/DestinationsSelector';
import { useDispatch, useSelector } from 'react-redux';
import { selectDestinationFilter } from 'app/containers/ToursList/slice';
import { selectDestinationSelected } from 'app/containers/ToursList/selectors';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { selectAllDestinations } from 'store/slices/destinationsSlice';

const DestinationTableHeader = () => {
  const dispatch = useDispatch();
  const destinations = useSelector(selectAllDestinations);
  const destinationFiltered = useSelector(selectDestinationSelected);

  const handleDestinationFilter = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    let targetId = event.target.value as number;
    if (targetId === 51) targetId = 54;
    const dest = destinations.find(e => e.id === targetId);
    if (dest) {
      dispatch(selectDestinationFilter({ destination: dest, list: true }));
    } else {
      dispatch(selectDestinationFilter({ destination: null, list: false }));
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="Order picking">
        <TableHead>
          <TableRow>
            <TableCell>
              <span className="mr-40">ID</span>
            </TableCell>
            <TableCell align="right">Facture</TableCell>
            <TableCell align="right">
              <div className="-mt-4">
                <DestinationsSelector
                  handleOnChange={handleDestinationFilter}
                  selectedDestination={destinationFiltered}
                />
              </div>
            </TableCell>
            <TableCell align="right">Msg</TableCell>
            <TableCell align="right">Infos</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default DestinationTableHeader;
