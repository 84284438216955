import React, { useMemo, useState } from 'react';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { useSelector } from 'react-redux';
import {
  selectMapCabinetsColor,
  selectProductionPiecesFactory,
} from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/selectors';
import { Workstation } from 'app/containers/OrderDetails/types';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { FaChevronCircleDown } from 'react-icons/fa';
import { BiChevronDownCircle } from 'react-icons/bi';
import { ListType } from '../../constants';
import SwipeableCell from './SwipeableCell';

interface Props {
  workstation: Workstation;
}

const PickingList = ({ workstation }: Props) => {
  const [sortByPiece, setSortByPiece] = useState<boolean>(false);

  const production: ProductionPiece[] | undefined = useSelector(
    selectProductionPiecesFactory(workstation, ListType.picking),
  );
  const cabinetsBgColors = useSelector(selectMapCabinetsColor(workstation));

  useMemo(() => {
    if (sortByPiece) {
      production?.sort((a: ProductionPiece, b: ProductionPiece) =>
        a.reference.localeCompare(b.reference),
      );
    } else {
      production?.sort((a: ProductionPiece, b: ProductionPiece) =>
        a.cabinetName.localeCompare(b.cabinetName),
      );
    }
  }, [sortByPiece, production]);

  if (!production) return null;

  return (
    <div>
      <div className="flex items-center justify-between font-thin border-black border-b-2 h-12">
        <button
          className="flex items-center"
          onClick={() => setSortByPiece(true)}
        >
          Pièce
          {sortByPiece ? (
            <FaChevronCircleDown className="h-4 w-4 ml-2" />
          ) : (
            <BiChevronDownCircle className="h-5 w-5 ml-2" />
          )}
        </button>
        <button
          className="flex items-center"
          onClick={() => setSortByPiece(false)}
        >
          Meuble
          {sortByPiece ? (
            <BiChevronDownCircle className="h-5 w-5 ml-2" />
          ) : (
            <FaChevronCircleDown className="h-4 w-4 ml-2" />
          )}
        </button>
      </div>
      <SwipeableList>
        {production.map(piece =>
          piece.cutting && piece.quantityCutted !== piece.quantity ? (
            <SwipeableCell
              key={piece.id}
              quantity={piece.quantity}
              piece={piece}
              cabinetName={piece.cabinetName}
              backgroundColor={cabinetsBgColors.get(piece.cabinetName)}
              listType={ListType.standbyCutting}
            />
          ) : (
            <>
              <SwipeableCell
                key={piece.id}
                quantity={piece.quantityStandby}
                piece={piece}
                cabinetName={piece.cabinetName}
                backgroundColor={cabinetsBgColors.get(piece.cabinetName)}
                listType={ListType.standby}
              />

              <SwipeableCell
                key={piece.id}
                quantity={
                  piece.quantity - piece.quantityStandby - piece.quantityPicked
                }
                piece={piece}
                cabinetName={piece.cabinetName}
                backgroundColor={cabinetsBgColors.get(piece.cabinetName)}
                listType={ListType.picking}
              />
            </>
          ),
        )}
      </SwipeableList>
    </div>
  );
};

export default PickingList;
