import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/redux-injectors';
import { actions, sliceKey } from './slice';
import { watchPollingSaga } from 'app/containers/PollingAgent/saga';

export function PollingAgent() {
  const dispatch = useDispatch();
  useInjectSaga({ key: sliceKey, saga: watchPollingSaga });

  useEffect(() => {
    // Start the polling
    dispatch(actions.startPollingAgent());

    return () => {
      // Stop the polling when unmounting
      dispatch(actions.stopPollingAgent());
    };
  }, []);

  return <></>;
}

export default PollingAgent;
