import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { OrderPriority, PartOrder } from 'app/models/PartOrder';

interface Props {
  partOrder: PartOrder;
}

const OrderIdentification = ({ partOrder }: Props) => (
  <div className="text-3xl whitespace-no-wrap">
    {partOrder.orderInfo.priority === OrderPriority.urgent && (
      <span className="bg-red-dark text-white p-1">
        {partOrder.identification}
      </span>
    )}
    {partOrder.orderInfo.priority === OrderPriority.fast && (
      <span className="text-red-dark">{partOrder.identification}</span>
    )}
    {partOrder.orderInfo.priority === OrderPriority.normal && (
      <span>{partOrder.identification}</span>
    )}
    {partOrder.orderInfo.priority === OrderPriority.lowest && (
      <span className="bg-gray-500 text-white p-1">
        {partOrder.identification}
      </span>
    )}
    {partOrder.orderInfo.customerDispute && (
      <span className="italic text-red-dark ml-4">SAV</span>
    )}
  </div>
);

export default OrderIdentification;
