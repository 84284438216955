import React, { ReactElement } from 'react';
import CuttingGroup from 'app/pages/ProductionPage/CuttingPanePage/CuttingGroup';
import { getProductionPiecesByDate } from './utils';
import { CuttingMap } from 'app/pages/ProductionPage/constants';
import { useSelector } from 'react-redux';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { Dictionary } from '@reduxjs/toolkit';
import { PartOrder } from 'app/models/PartOrder';

interface Props {
  cuttingMap: CuttingMap;
  groupDay: number;
  lowerOrEquals: boolean;
  startAt?: number;
  title?: string;
  partOrders: Dictionary<PartOrder>;
}

const getCuttingSections = (
  cuttingMap,
  groupDay,
  lowerOrEquals,
  startAt,
  partOrders,
) => {
  const sections: ReactElement[] = [];
  cuttingMap.forEach((map, color) => {
    map.forEach((pieces, colorBandSize) => {
      const cuttingList = getProductionPiecesByDate(
        pieces,
        groupDay,
        lowerOrEquals,
        startAt,
        partOrders,
      );

      const cuttingCompleted = lowerOrEquals
        ? getProductionPiecesByDate(pieces, groupDay, false, startAt)
        : undefined;
      if (cuttingList.length > 0) {
        sections.push(
          <CuttingGroup
            key={`${color.name}---${colorBandSize}`}
            title={`${color.material} ${color.name}${
              colorBandSize !== undefined &&
              ` – ${
                typeof colorBandSize === 'number'
                  ? `${colorBandSize} mm`
                  : colorBandSize
              }`
            }`.trim()}
            color={color.hexaHtml}
            cuttingList={cuttingList}
            cuttingCompleted={cuttingCompleted}
          />,
        );
      }
    });
  });
  return sections;
};

const CuttingSection = ({
  cuttingMap,
  groupDay,
  lowerOrEquals,
  startAt,
  title,
}: Props) => {
  const partOrders = useSelector(selectPartOrderEntities);
  const sections = getCuttingSections(
    cuttingMap,
    groupDay,
    lowerOrEquals,
    startAt,
    partOrders,
  );

  if (!sections || sections.length === 0) return null;

  let titleSection = title;
  if (!titleSection) {
    titleSection = `${lowerOrEquals ? '≤' : '≥'} ${groupDay} jours`;
  }

  return (
    <div className="mb-8">
      <div className="flex justify-end border-b-2 border-gray-600">
        <div className="text-white bg-gray-600 px-2 py-1 text-2xl">
          {titleSection}
        </div>
      </div>
      {sections}
    </div>
  );
};
export default CuttingSection;
