import {
  ColorBandSize,
  colorBandSizesValues,
  CuttingMap,
} from './../constants';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { Workstation } from 'app/containers/OrderDetails/types';
import { Dictionary } from '@reduxjs/toolkit';
import { PartOrder } from 'app/models/PartOrder';

interface ComputeData {
  colorBandSize?: ColorBandSize;
  difference?: number;
}

function computeDataForPieceDimension(dimension: number): ComputeData {
  const data: ComputeData = {
    colorBandSize: undefined,
    difference: undefined,
  };

  colorBandSizesValues.forEach(colorBandSize => {
    if (
      dimension < colorBandSize &&
      (data.difference === undefined ||
        colorBandSize - dimension < data.difference)
    ) {
      data.difference = colorBandSize - dimension;
      data.colorBandSize = colorBandSize;
    }
  });

  return data;
}

export function getBandSizeForPiece(
  piece: ProductionPiece,
): ColorBandSize | string | undefined {
  if (piece.calibrated) return piece.basePieceRef;

  const dimensions = [piece.width, piece.height, piece.depth].sort(
    (a, b) => b - a,
  );
  const height = dimensions[0];
  const width = dimensions[1];

  if (piece.station === Workstation.Woodwork || dimensions[2] !== 3)
    return undefined;

  if (colorBandSizesValues.includes(height as ColorBandSize))
    return height as ColorBandSize;
  if (colorBandSizesValues.includes(width as ColorBandSize))
    return width as ColorBandSize;

  const heightComputeData: ComputeData = computeDataForPieceDimension(height);
  const widthComputeData: ComputeData = computeDataForPieceDimension(width);

  if (
    heightComputeData.colorBandSize !== undefined &&
    widthComputeData.colorBandSize === undefined
  )
    return heightComputeData.colorBandSize;

  if (
    heightComputeData.colorBandSize === undefined &&
    widthComputeData.colorBandSize !== undefined
  )
    return widthComputeData.colorBandSize;

  if (
    heightComputeData.difference !== undefined &&
    widthComputeData.difference !== undefined
  ) {
    if (widthComputeData.difference < heightComputeData.difference)
      return widthComputeData.colorBandSize;
    else return heightComputeData.colorBandSize;
  }

  return undefined;
}

export function getMapColorBandsPieces(
  pieces: ProductionPiece[],
  cutted = false,
): CuttingMap {
  const map = new CuttingMap();
  pieces.forEach(piece => {
    if (
      piece.color &&
      piece.cutting &&
      (cutted || piece.quantityCutted !== piece.quantity) &&
      (!cutted || piece.quantityCutted === piece.quantity)
    ) {
      let colorMap = map.get(piece.color);
      if (!colorMap) {
        colorMap = new Map<number | undefined, ProductionPiece[]>();
        map.set(piece.color, colorMap);
      }

      const colorBandSize = getBandSizeForPiece(piece);
      let piecesArray = colorMap.get(colorBandSize);
      if (!piecesArray) {
        piecesArray = [];
        colorMap.set(colorBandSize, piecesArray);
      }

      piecesArray.push(piece);
    }
  });

  return map;
}

export function getProductionPiecesByDate(
  pieces: ProductionPiece[],
  groupDay: number,
  lowerOrEquals: boolean,
  startAt?: number,
  partOrders?: Dictionary<PartOrder>,
): ProductionPiece[] {
  const result: ProductionPiece[] = [];

  pieces.forEach(piece => {
    const partOrder = partOrders![piece.order];
    const diffDay = partOrder?.diffBusinessDays;

    if (
      diffDay !== undefined &&
      (!lowerOrEquals || diffDay <= groupDay) &&
      (lowerOrEquals || diffDay > groupDay) &&
      (startAt === undefined || diffDay >= startAt)
    ) {
      result.push(piece);
    }
  });

  return result;
}
