import { FiMap } from 'react-icons/fi';
import React from 'react';
import { Tour } from 'app/models/Tour';
import { AppRootState } from 'index';
import { selectAllWaypoints } from 'store/slices/waypointsSlice';
import { useSelector } from 'react-redux';
import { Delivery } from 'app/models/Delivery';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { PartOrder } from 'app/models/PartOrder';

interface Props {
  tour: Tour;
  deliveries?: Delivery[];
}

function TourMapLink({ deliveries }: Props) {
  const extraWaypoints = useSelector((state: AppRootState) =>
    selectAllWaypoints(state),
  );
  const partOrdersEntities = useSelector(selectPartOrderEntities);

  if (deliveries && deliveries.length > 0) {
    const home = 'Rue Sainte-Anne 7, 5310 Éghezée, Belgique';
    const waypoints: string[] = [];
    for (const delivery of deliveries) {
      if (!delivery) continue;
      let partOrder: PartOrder | undefined = undefined;
      if (delivery.partOrder) {
        partOrder = partOrdersEntities[delivery.partOrder];
      }
      if (delivery.waypoint !== undefined) {
        const wp = extraWaypoints.find(e => e.id === delivery.waypoint);
        if (wp) waypoints.push(encodeURI(wp.address));
      }
      if (partOrder && partOrder.order.infos.gAddress)
        waypoints.push(encodeURI(partOrder.order.infos.gAddress));
    }
    const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURI(
      home,
    )}&destination=${encodeURI(home)}&waypoints=${waypoints.join('|')}`;

    return (
      <div className="flex justify-center items-center">
        <a href={url} target="_blank">
          <FiMap className="h-5 w-5" />
        </a>
      </div>
    );
  }
  return null;
}

export default TourMapLink;
