import React from 'react';
import ProductEntry from 'app/containers/OrderDetails/GeneralTab/ProductEntry';
import { ProductAggregated } from 'app/containers/OrderDetails/types';
import { TourMap } from 'app/containers/TourMap';
import InformationsOrder from 'app/containers/OrderDetails/GeneralTab/InformationsOrder';
import { useSelector } from 'react-redux';
import { aggregatedOrderProducts } from 'app/containers/OrderDetails/selectors';
import MessagesArea from 'app/containers/OrderDetails/GeneralTab/MessagesArea';
import { PartOrder } from 'app/models/PartOrder';
import LoadingSpinner from 'app/containers/OrderDetails/LoadingSpinner';

interface Props {
  partOrder: PartOrder;
  loading: boolean;
}

const GeneralTab = ({ partOrder, loading }: Props) => {
  const productAggregated: ProductAggregated[] | undefined = useSelector(
    aggregatedOrderProducts,
  );

  return (
    <div className="overflow-y-auto" style={{ height: 'calc(100% - 170px)' }}>
      <MessagesArea />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {!partOrder.packageDelivery &&
            productAggregated?.map(e => (
              <ProductEntry
                key={e.product.prestashopId}
                product={e.product}
                quantity={e.quantity}
              />
            ))}
        </div>
      )}

      {partOrder.packageDelivery ? (
        <div className="my-8 text-lg flex justify-center">
          Cette commande est une expédition partielle des colis de la commande{' '}
          <span className="font-semibold mx-2">{partOrder.order.id}</span>
        </div>
      ) : (
        <div className="my-4">
          <div className="my-3">
            <InformationsOrder partOrder={partOrder} />
          </div>
          {!partOrder.destination.shop && !partOrder.direct && (
            <>
              {import.meta.env.VITE_DISABLE_MAP !== 'true' && (
                <TourMap viewPartOrder={partOrder} showDepot={false} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GeneralTab;
