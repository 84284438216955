import React from 'react';
import { DestinationLabelStyled } from 'app/components/Kewlox/DestinationLabel';
import { Carrier } from 'app/pages/ToursPage/types';

// import styled from 'styled-components';

interface Props {
  carrier: Carrier;
  short?: boolean;
}

const CarrierLabel = ({ carrier, short }: Props) => {
  let name = carrier.name;
  if (short) {
    name = carrier.name.charAt(0);
  }

  return (
    <DestinationLabelStyled
      color={carrier.color}
      light={false}
      short={short}
      className="border border-gray-400 flex justify-center"
    >
      {name}
    </DestinationLabelStyled>
  );
};

export default CarrierLabel;
