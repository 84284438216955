/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { Action, combineReducers, ThunkAction } from '@reduxjs/toolkit';
import { InjectedReducersType } from 'utils/types/injector-typings';
import { RootState } from 'types';
import wayPointReducer from 'store/slices/waypointsSlice';
import carrierReducer from 'store/slices/carriersSlice';
import destinationReducer from 'store/slices/destinationsSlice';
import colorReducer from 'store/slices/colorSlice';
import { kewloxApi } from 'services/kewloxApi';
import toursReducer from 'store/slices/toursSlice';
import partOrder from 'store/slices/partOrderSlice';
import delivery from 'store/slices/deliverySlice';
import activeContent from 'store/slices/activeContentSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    carrier: carrierReducer,
    color: colorReducer,
    destination: destinationReducer,
    tour: toursReducer,
    delivery: delivery,
    partOrder: partOrder,
    waypoint: wayPointReducer,
    activeContent: activeContent,
    [kewloxApi.reducerPath]: kewloxApi.reducer,
  });
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
