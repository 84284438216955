import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WaypointForm from 'app/pages/WaypointsPage/WaypointForm';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useGetWaypointQuery } from 'services/kewloxApi';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import { useTheme } from '@mui/styles';
import LoadingSpinner from 'app/containers/OrderDetails/LoadingSpinner';
import { Button } from '@mui/material';

const AddWayPoint = () => {
  const { waypointId } = useParams<{ waypointId: string }>();
  const navigate = useNavigate();
  const [waypoint, setWaypoint] = useState<Partial<Waypoint>>({});
  const theme = useTheme();

  const { data: waypointData, isLoading } = useGetWaypointQuery(
    waypointId ? parseInt(waypointId) : 0,
    {
      skip: waypointId === undefined,
    },
  );

  useEffect(() => {
    if (waypointData) {
      setWaypoint(waypointData);
    }
  }, [waypointData]);

  const onAfterSave = () => {
    navigate(-1);
  };

  return (
    <SiteWrapper title="Points de passage">
      <div className="mx-auto bg-white p-8">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <WaypointForm onAfterSave={onAfterSave} waypoint={waypoint} />
        )}

        <div className="flex justify-end">
          <Button
            variant="inline"
            onClick={() => {
              navigate(-1);
            }}
          >
            Retour
          </Button>
        </div>
      </div>
    </SiteWrapper>
  );
};

export default AddWayPoint;
