import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ProductOrder } from 'app/models/ProductOrder';
import { ProductionPiece } from 'app/models/ProductionPiece';
import _ from 'lodash';
import Img from 'app/components/Img';
import LoadingSpinner from 'app/containers/OrderDetails/LoadingSpinner';
import CreatePartOrderModal from 'app/containers/OrderDetails/PiecesTab/CreatePartOrderModal';
import { RiAlarmWarningFill } from 'react-icons/all';
import { pieceRef } from 'app/pages/ToursPage/tourHelpers';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';

interface Props {
  loading: boolean;
}

const PiecesTab = ({ loading }: Props) => {
  const partOrder = useSelector(selectCurrentPartOrder());
  const [isCreatePartOrderModalOpen, setCreatePartOrderModalOpen] =
    useState<boolean>(false);

  if (loading) return <LoadingSpinner />;

  if (!partOrder || partOrder.products === undefined) return null;

  return (
    <>
      <TableContainer
        className="overflow-y-auto"
        style={{ height: 'calc(100% - 170px)' }}
      >
        <Table
          stickyHeader
          size="small"
          aria-label="Pieces production informations"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" className="whitespace-no-wrap">
                Nb
              </TableCell>
              <TableCell align="left" className="whitespace-no-wrap">
                Référence
              </TableCell>
              <TableCell align="left">
                <div className="flex justify-between">
                  <div>Description</div>
                  <button
                    onClick={() => {
                      setCreatePartOrderModalOpen(true);
                    }}
                    className="py-1 px-2 border border-gray-600"
                  >
                    Créer une sous-commande
                  </button>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partOrder.products.map((product: ProductOrder) => (
              <>
                <TableRow key={`T-${product.id}`} className="bg-gray-200">
                  <TableCell align="left" colSpan={3}>
                    <div className="flex items-center justify-between font-bold text-lg">
                      <div>
                        {!product.uniqueProduct &&
                        product.name &&
                        product.name !== ''
                          ? product.name
                          : '-'}
                      </div>
                      {product.image && (
                        <div className="mr-20 w-12 h-12">
                          <Img src={product.image} alt={`preview `} />
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                {_.orderBy(product.production, ['sorting', 'reference']).map(
                  (e: ProductionPiece) => (
                    <TableRow key={`P-${e.id}`}>
                      <TableCell align="left">{e.quantity}</TableCell>
                      <TableCell align="left">
                        <div className="flex">
                          {e.isOutOfStock && (
                            <RiAlarmWarningFill className="h-5 w-5 mx-1 bg-red text-white" />
                          )}
                          <div className="ml-1">
                            {product.uniqueProduct
                              ? 'SPECIAL'
                              : pieceRef(e.reference)}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: product.uniqueProduct
                              ? product.name
                              : e.descriptionHTML ?? '',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreatePartOrderModal
        partOrder={partOrder}
        isCreatePartOrderModalOpen={isCreatePartOrderModalOpen}
        setCreatePartOrderModalOpen={setCreatePartOrderModalOpen}
      />
    </>
  );
};

export default PiecesTab;
