import React from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import { parseISO } from 'date-fns';
import { FiAlertTriangle } from 'react-icons/fi';
import { OrderPriority } from '../../models/PartOrder';

// import styled from 'styled-components';

interface Props {
  date: string;
  priority: OrderPriority;
  dateShipping?: string;
}

const DateDelay = ({ date, dateShipping, priority }: Props) => {
  let d = new Date();
  if (dateShipping !== undefined) {
    d = parseISO(dateShipping);
  }
  const diff_day = differenceInDays(d, parseISO(date));

  if (priority === OrderPriority.lowest)
    return <div className="text-black">{diff_day}j</div>;

  if (priority === OrderPriority.urgent || diff_day >= 34)
    return (
      <div className="flex justify-center items-center text-white bg-red-dark font-semibold p-1">
        {diff_day}j <FiAlertTriangle className="text-white h-4 w-4 ml-1" />
      </div>
    );

  if (diff_day < 12 && priority !== OrderPriority.fast)
    return <div className="text-green-dark font-semibold">{diff_day}j</div>;
  else if (diff_day < 20 && priority !== OrderPriority.fast)
    return <div className="text-orange font-semibold">{diff_day}j</div>;
  else if (diff_day < 27)
    return <div className="text-red-dark font-semibold">{diff_day}j</div>;

  return (
    <div className="flex justify-center items-center text-red-dark font-semibold p-1">
      {diff_day}j <FiAlertTriangle className="text-red-dark h-4 w-4 ml-1" />
    </div>
  );
};

export default DateDelay;
