import { PackageOrder } from 'app/models/PackageOrder';
import { Workstation } from 'app/containers/OrderDetails/types';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { workstationPackage } from 'app/containers/OrderDetails/helpers';

export interface Trolley {
  id: number;
  name: string;
  packages: PackageOrder[];
  production: ProductionPiece[];
  workstation: Workstation;
  drawer?: number;
  averagePackageWeight: number;
}

export const createTrolley = (
  id: number,
  packages: PackageOrder[],
): Trolley => {
  const production: ProductionPiece[] = [];
  packages.forEach(pkg => {
    if (pkg.composition) {
      production.push(...pkg.composition);
    }
  });

  const name = packages.length > 0 ? packages[0].trolleyName : `${id}`;
  const workstation =
    packages.length > 0
      ? workstationPackage(packages[0].type)
      : Workstation.Accessory;

  let drawer: number | undefined;
  if (workstation === Workstation.Drawer) {
    for (const piece of production) {
      if (piece.drawer) {
        drawer = piece.drawer;
        break;
      }
    }
  }

  const averagePackageWeight = getAveragePackageWeight(packages);

  packages.forEach(pkg => {
    pkg.averageWeight = averagePackageWeight;
  });

  return {
    id,
    name,
    packages,
    production,
    workstation,
    drawer,
    averagePackageWeight,
  };
};

const getAveragePackageWeight = (packages: PackageOrder[]): number => {
  let totalWeight = 0;
  packages.forEach(pkg => {
    if (pkg.weight) {
      totalWeight += pkg.weight;
    }
  });
  return packages.length > 0 ? totalWeight / packages.length : totalWeight;
};

// export class Trolley {
//   id: number;
//   name: string;
//   packages: PackageOrder[];
//   production: ProductionPiece[];
//   workstation: Workstation;
//   drawer?: number;
//
//   constructor(id: number, packages: PackageOrder[]) {
//     this.id = id;
//     this.packages = packages;
//     this.production = [];
//
//     for (const pkg of this.packages) {
//       if (pkg.composition)
//         this.production = this.production.concat(pkg.composition);
//     }
//
//     if (this.packages.length > 0) {
//       this.name = this.packages[0].trolleyName;
//       this.workstation = workstationPackage(this.packages[0].type);
//     } else {
//       this.name = `${id}`;
//       this.workstation = Workstation.Accessory;
//     }
//
//     if (this.workstation === Workstation.Drawer) this.findDrawer();
//
//     for (const pkg of this.packages) {
//       pkg.averageWeight = this.averagePackageWeight;
//     }
//   }
//
//   findDrawer() {
//     for (const piece of this.production) {
//       if (piece.drawer) {
//         this.drawer = piece.drawer;
//       }
//     }
//   }
//
//   get averagePackageWeight(): number {
//     let totalWeight = 0;
//     for (const pkg of this.packages) {
//       if (pkg.weight) totalWeight += pkg.weight;
//     }
//     if (this.packages && this.packages.length > 0)
//       return totalWeight / this.packages.length;
//     return totalWeight;
//   }
// }
