import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the SearchField container
export const initialState: ContainerState = {
  value: '',
  searching: false,
};

const searchFieldSlice = createSlice({
  name: 'searchField',
  initialState,
  reducers: {
    searchingOrder(state, action: PayloadAction<boolean>) {
      state.searching = action.payload;
    },
    updateSearchField(state, action: PayloadAction<string>) {
      state.value = action.payload;
    },
  },
});

export const { searchingOrder } = searchFieldSlice.actions;

export const { actions, reducer, name: sliceKey } = searchFieldSlice;
