import React from 'react';
import { IoIosPin } from 'react-icons/io';
import { AiFillHome } from 'react-icons/ai';

// import styled from 'styled-components';

interface Props {
  lat?: number;
  lng?: number;
  isKewloxHome?: boolean;
  isCircle?: boolean;
}

const Pin = ({ lat, lng, isKewloxHome = false, isCircle = false }: Props) => {
  if (isCircle) {
    return (
      <div className="-mt-2 -ml-2">
        <div className="w-2 h-2 rounded-full bg-white border-black border-2"></div>
      </div>
    );
  } else if (isKewloxHome) {
    return (
      <div className="-mt-2 -ml-2">
        <AiFillHome className="w-5 h-5 text-white bg-gray-800" />
      </div>
    );
  } else {
    return (
      <div className="-mt-8 -ml-4">
        <IoIosPin className="w-8 h-8 text-red-light" />
      </div>
    );
  }
};

export default Pin;
