import React, { useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import Modal from 'react-responsive-modal';
import { useUpdateTourMutation } from 'services/kewloxApi';

interface Props {
  note?: string;
  tourId: number;
  isTourNoteModalOpen: boolean;
  setIsTourNoteModalOpen: (boolean) => void;
}

export function TourNote({
  note = '',
  tourId,
  setIsTourNoteModalOpen,
  isTourNoteModalOpen,
}: Props) {
  const [noteText, setNoteText] = useState(note); // Controlled input for the note

  const [updateTour, { isLoading }] = useUpdateTourMutation();

  const handleSave = async () => {
    try {
      await updateTour({
        id: tourId,
        note: noteText,
      });
      setIsTourNoteModalOpen(false);
    } catch (error) {
      console.error('Failed to update the tour note', error);
    }
  };

  const deleteNote = async () => {
    try {
      await updateTour({
        id: tourId,
        note: '',
      });
      setIsTourNoteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete the tour note', error);
    }
  };

  // Function to format the note by bolding the order number
  const formatNote = (text: string) => {
    const orderNumberPattern = /\b(1[0-9]{5}|[2-9][0-9]{5})\b/g;
    return text.replace(
      orderNumberPattern,
      match => `<strong>${match}</strong>`,
    );
  };

  // Function to truncate the note to the first 50 characters
  const truncateNote = (text: string, limit: number) => {
    if (text.length > limit) {
      return `${text.substring(0, limit)}...`;
    }
    return text;
  };

  const customModalStyles = {
    modal: {
      maxWidth: '800px', // Increase the width of the modal
      width: '90%',
      padding: '20px',
    },
  };

  return (
    <>
      {note && (
        <div
          className="px-2 py-1 text-lg flex items-center bg-orange-lighter cursor-pointer"
          onClick={() => setIsTourNoteModalOpen(true)}
        >
          <span className="mx-2">
            <FiAlertTriangle className="h-8 w-8" />
          </span>
          {/* Displaying the truncated note 100 char. */}
          <span
            dangerouslySetInnerHTML={{
              __html: formatNote(truncateNote(note, 100)),
            }}
          />
        </div>
      )}

      <Modal
        onClose={() => setIsTourNoteModalOpen(false)}
        open={isTourNoteModalOpen}
        center
        styles={customModalStyles}
      >
        <div className="p-8">
          <div className="text-lg font-bold mb-4">
            Modifier la note de la tournée <strong>{tourId}</strong>
          </div>
          <textarea
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
            className="w-full p-2 border rounded"
            rows={8}
            style={{ fontSize: '16px', height: '200px' }}
          />
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSave}
              className="bg-main text-white px-3 py-2 rounded rounded mr-2"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Sauver'}
            </button>
            <button
              onClick={() => {
                setNoteText('');
                deleteNote();
              }}
              className="bg-red text-white px-3 py-2 rounded mr-2"
            >
              Supprimer
            </button>
            <button
              onClick={() => setIsTourNoteModalOpen(false)}
              className="border border-black text-black px-4 py-2 rounded"
            >
              Annuler
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
