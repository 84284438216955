/**
 *
 * TourMap
 *
 */

import React, { useEffect } from 'react';

import { useInjectReducer } from 'utils/redux-injectors';
import { reducer, sliceKey } from 'app/containers/TourMap/slice';
import { OrderDisplayInfos } from 'app/containers/TourMap/OrderDisplayInfos';

import GoogleMapReact from 'google-map-react';
import Pin from 'app/containers/TourMap/Pin';
import { PartOrder } from 'app/models/PartOrder';
import { useSelector } from 'react-redux';
import { selectedTour } from 'app/containers/ToursList/selectors';
import { selectOrderSelected } from 'app/containers/TourMap/selectors';
import { selectAllWaypoints } from 'store/slices/waypointsSlice';
import { AppRootState } from 'index';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import { selectCurrentTour } from 'store/slices/activeContentSlice';

interface Props {
  showDepot?: boolean;
  viewPartOrder?: PartOrder;
}

let map: any;
let maps: any;
let directionsService: any;
let directionsRenderer: any;

export function TourMap({ viewPartOrder, showDepot = true }: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const viewTour = useSelector(selectCurrentTour);
  const order = viewPartOrder ?? useSelector(selectOrderSelected);
  const waypoints = useSelector((state: AppRootState) =>
    selectAllWaypoints(state),
  );

  const tour = viewTour && viewTour.isKewlox ? viewTour : undefined;

  const hideMap =
    (!tour || !tour?.isHomeTour) &&
    (!order || order.direct || order?.destination.shop);

  const defaultProps = {
    center: {
      lat: showDepot ? 50.56828 : order?.orderInfo?.lat,
      lng: showDepot ? 4.903442 : order?.orderInfo?.lng,
    },
    zoom: showDepot ? 8 : 15,
  };

  useEffect(() => {
    if (map && maps && directionsService && directionsRenderer) {
      if (tour && tour.isKewlox && tour.orders && tour.orders.length > 0) {
        directionsRenderer.setOptions({
          polylineOptions: {
            strokeColor: tour.color ?? '000000',
          },
        });
        const waypts: any[] = [];

        for (const delivery of tour.orders) {
          if (delivery.waypoint) {
            const waypoint: Waypoint | undefined = waypoints.find(
              wp => wp.id === delivery.waypoint,
            );
            if (waypoint) {
              waypts.push({
                location: {
                  lat: waypoint.latitude,
                  lng: waypoint.longitude,
                },
                stopover: true,
              });
            }
          } else if (
            delivery.order?.orderInfo.lat &&
            delivery.order?.orderInfo.lng
          ) {
            waypts.push({
              location: {
                lat: delivery.order.orderInfo.lat,
                lng: delivery.order.orderInfo.lng,
              },
              stopover: true,
            });
          }
        }

        directionsService.route(
          {
            origin: { lat: 50.56828, lng: 4.903442 },
            destination: { lat: 50.56828, lng: 4.903442 },
            waypoints: waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING',
          },
          function (response, status) {
            if (status === 'OK') {
              directionsRenderer.setMap(map);
              directionsRenderer.setDirections(response);
              console.log('route loaded');
            } else {
              window.alert(`Directions request failed due to ${status}`);
            }
          },
        );
      } else {
        directionsRenderer.setMap(null);
        /*
        const markers = [
          { lat: 50.56828, lng: 4.903442 },
          { lat: order.lat, lng: order.lng },
        ];
        const geodesicPolyline = new maps.Polyline({
          path: markers,
          geodesic: true,
          strokeColor: '#00a1e1',
          strokeOpacity: 1.0,
          strokeWeight: 4,
          zIndex: 10,
        });
        //geodesicPolyline.setMap(map);
  
        */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tour?.id,
    tour?.orders,
    map,
    maps,
    directionsService,
    directionsRenderer,
    hideMap,
  ]);

  useEffect(() => {
    if (map && maps) {
      const bounds = new maps.LatLngBounds();
      let loc = new maps.LatLng(
        defaultProps.center.lat,
        defaultProps.center.lng,
      );
      bounds.extend(loc);
      if (order && order.orderInfo.lat && order.orderInfo.lng) {
        loc = new maps.LatLng(order.orderInfo.lat, order.orderInfo.lng);
        bounds.extend(loc);
      }

      if (tour && tour.isKewlox && tour.orders) {
        for (const delivery of tour.orders) {
          if (delivery.waypoint) {
            const waypoint: Waypoint | undefined = waypoints.find(
              wp => wp.id === delivery.waypoint,
            );
            if (waypoint) {
              loc = new maps.LatLng(waypoint.latitude, waypoint.longitude);
            }
          } else if (
            delivery.order?.orderInfo.lat &&
            delivery.order.orderInfo.lng
          ) {
            loc = new maps.LatLng(
              delivery.order.orderInfo.lat,
              delivery.order.orderInfo.lng,
            );
            bounds.extend(loc);
          }
        }
      }

      map.setOptions({
        maxZoom: 8,
      });

      map.fitBounds(bounds);
      map.panToBounds(bounds);

      map.setOptions({
        maxZoom: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, tour?.id, tour?.orders, map, maps, hideMap]);

  const handleApiLoaded = (gmap, gmaps) => {
    // use map and maps objects
    map = gmap;
    maps = gmaps;

    directionsService = new maps.DirectionsService();
    directionsRenderer = new maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true,
    });
  };

  return (
    <div className={`mb-2 border ${hideMap && 'hidden'}`}>
      <div className="h-64 w-full">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${import.meta.env.VITE_GOOGLE_API_KEY}`,
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          debounced={false}
        >
          <>
            {map &&
              maps &&
              tour &&
              tour.orders &&
              tour.orders.length > 0 &&
              tour.orders.map(delivery => {
                if (delivery.waypoint) {
                  const waypoint: Waypoint | undefined = waypoints.find(
                    wp => wp.id === delivery.waypoint,
                  );
                  if (waypoint) {
                    return (
                      <Pin
                        lat={waypoint.latitude}
                        lng={waypoint.longitude}
                        isCircle
                      />
                    );
                  } else {
                    return null;
                  }
                } else
                  return (
                    <Pin
                      lat={delivery.order?.orderInfo.lat}
                      lng={delivery.order?.orderInfo.lng}
                      isCircle
                    />
                  );
              })}
          </>

          {showDepot && (
            <Pin
              lat={defaultProps.center.lat}
              lng={defaultProps.center.lng}
              isKewloxHome
            />
          )}
          {order && order.orderInfo.lat && order.orderInfo.lng && (
            <Pin lat={order.orderInfo.lat} lng={order.orderInfo.lng} />
          )}
        </GoogleMapReact>
      </div>
      {order && order.orderInfo.lat && order.orderInfo.lng && (
        <OrderDisplayInfos
          orderIdentification={order.identification}
          partOrder={order}
          orderInfos={order.orderInfo}
          addressOnly={!showDepot}
        />
      )}
      {/*<TourInfos tour={tour} />*/}
    </div>
  );
}
