import React, { useState } from 'react';
import { PartOrder } from 'app/models/PartOrder';
import { FiPrinter } from 'react-icons/fi';
import { PrintDirectPartOrderModal } from 'app/containers/OrderDetails/PartOrderSelection/PrintDirectPartOrderModal';

// import styled from 'styled-components';

interface Props {
  partOrder: PartOrder;
}

const PrintDirectPartOrder = ({ partOrder }: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className="mt-2 ml-2"
        data-tip="Imprimer la commande direct"
        data-for="infos"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <FiPrinter className="h-6 w-6 ml-2" />
      </button>
      <PrintDirectPartOrderModal
        partOrder={partOrder}
        isPrintDirectPartOrderModalOpen={isModalOpen}
        setIsPrintDirectPartOrderModal={setModalOpen}
      />
    </>
  );
};

export default PrintDirectPartOrder;
