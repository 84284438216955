import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { actions } from 'app/pages/ProductionPage/slice';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCuttingPieces,
  selectOrdersMap,
} from 'app/containers/SiteWrapper/selectors';
import { Workstation } from 'app/containers/OrderDetails/types';
import {
  getProductionPiece,
  setCuttingPieces,
} from 'app/containers/SiteWrapper/slice';
import { CircleSpinner } from 'react-spinners-kit';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import {
  CuttingMap,
  cuttingSectionsDays,
  cuttingSectionsDaysText,
} from 'app/pages/ProductionPage/constants';
import CuttingSection from 'app/pages/ProductionPage/CuttingPanePage/CuttingSection';
import { getMapColorBandsPieces } from 'app/pages/ProductionPage/CuttingPanePage/utils';
import { IconButton, InputBase } from '@mui/material';
// import styled from 'styled-components';

const DrawersCuttingPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cuttingPieces = useSelector(selectCuttingPieces);
  const ordersMap = useSelector(selectOrdersMap);

  const cuttingMap = useMemo(() => getMapColorBandsPieces(cuttingPieces), [
    cuttingPieces,
  ]);

  useEffect(() => {
    if (ordersMap) dispatch(getProductionPiece(Workstation.Drawer, ordersMap));
    return () => {
      dispatch(setCuttingPieces([]));
    };
  }, [ordersMap]);

  return (
    <>
      <Helmet>
        <title>Production</title>
        <meta name="description" content="Cutting" />
      </Helmet>
      {!cuttingMap ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircleSpinner size={60} color="#4a5568" />
        </div>
      ) : (
        <SiteWrapper
          title={'Découpe - tiroirs'}
          leftTopBarButton={
            <Link to="/production">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(actions.setProductionMode(undefined))}
              >
                <MenuIcon />
              </IconButton>
            </Link>
          }
          rightTopBarButton={
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          }
        >
          <ProductionContainer>
            {cuttingSectionsDays.map((day, index) => (
              <CuttingSection
                key={day}
                cuttingMap={cuttingMap}
                title={cuttingSectionsDaysText[index]}
                startAt={
                  index > 0 ? cuttingSectionsDays[index - 1] + 1 : undefined
                }
                groupDay={day}
                lowerOrEquals
              />
            ))}
            <CuttingSection
              cuttingMap={cuttingMap}
              groupDay={cuttingSectionsDays[cuttingSectionsDays.length - 1] + 1}
              lowerOrEquals={false}
            />
          </ProductionContainer>
        </SiteWrapper>
      )}
    </>
  );
};

export default DrawersCuttingPage;
