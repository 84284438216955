import { createSelector, createSlice } from '@reduxjs/toolkit';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { AppRootState } from 'index';
import { PartOrder } from 'app/models/PartOrder';
import { selectAllDeliveries } from 'store/slices/deliverySlice';
import { selectTourEntities } from 'store/slices/toursSlice';

export interface ActiveContentState {
  tourId?: number;
  partOrderId?: number;
  deliveryId?: number;
  activeSearch?: {
    tourIds: undefined | number[];
    partOrderIds: undefined | number[];
  };
}
const initialState: ActiveContentState = {
  tourId: undefined,
  partOrderId: undefined,
  deliveryId: undefined,
  activeSearch: undefined,
};

const activeContentSlice = createSlice({
  name: 'activeContent',
  initialState,
  reducers: {
    setCurrentTourId(state, action) {
      state.tourId = action.payload;
    },
    removeCurrentTourId(state) {
      state.tourId = undefined;
    },
    setCurrentPartOrderId(state, action) {
      if (action.payload === undefined) {
        if (state.activeSearch !== undefined) {
          state.activeSearch = undefined;
        }
      }
      state.partOrderId = action.payload;
    },
    setCurrentDeliveryId(state, action) {
      state.deliveryId = action.payload;
    },
    setActiveSearch(
      state,
      action: { payload: ActiveContentState['activeSearch'] },
    ) {
      state.activeSearch = action.payload;
    },
  },
});

export const {
  setCurrentTourId,
  removeCurrentTourId,
  setCurrentPartOrderId,
  setCurrentDeliveryId,
  setActiveSearch,
} = activeContentSlice.actions;

const selectDomain = (state: AppRootState) => state.activeContent;

export const selectCurrentTourId = () =>
  createSelector([selectDomain], substate => substate.tourId!);

export const selectCurrentTour = createSelector(
  [(state: AppRootState) => selectTourEntities(state), selectCurrentTourId()],
  (tours, tourId) => {
    if (tourId === undefined) return undefined;
    return tours[tourId];
  },
);

export const selectCurrentPartOrderId = () =>
  createSelector([selectDomain], substate => substate.partOrderId);
export const selectCurrentDeliveryId = () =>
  createSelector([selectDomain], substate => substate.deliveryId!);

export const selectCurrentPartOrder = () =>
  createSelector(
    [selectPartOrderEntities, selectCurrentPartOrderId()],
    (partOrders, partOrderId): PartOrder | undefined => {
      if (partOrderId) return partOrders[partOrderId];
      return undefined;
    },
  );

export const selectActiveSearch = createSelector(
  [selectDomain],
  substate => substate.activeSearch,
);

export const selectCurrentDelivery = createSelector(
  [selectCurrentPartOrderId(), selectAllDeliveries],
  (partOrderId, deliveries) => {
    return deliveries.find(e => e.partOrder === partOrderId);
  },
);

export default activeContentSlice.reducer;
