import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { TextField, Theme } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Driver } from 'app/pages/DriverPage/types';
import {
  useAddDriverMutation,
  useUpdateDriverMutation,
} from 'services/kewloxApi';
import FormInputErrorStyled from 'app/components/styles/FormInputErrorStyled';
import Button from 'app/components/Button';

interface Props {
  editedDriver?: Driver;
  onAfterSave?: any;
}

const DriverForm = ({ editedDriver, onAfterSave }: Props) => {
  const [addDriver, { isLoading: isLoadingAddDriver }] = useAddDriverMutation();
  const [
    updateDriver,
    { isLoading: isLoadingUpdateDriver },
  ] = useUpdateDriverMutation();

  const initialValues = {
    name: editedDriver ? editedDriver.name : '',
    shortName: editedDriver ? editedDriver.shortName : '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Champ requis'),
      shortName: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (editedDriver === undefined) {
        addDriver({
          name: values.name,
          shortName: values.shortName,
        })
          .unwrap()
          .then(() => {
            toast.success('Le livreur a été rajouté');
            // @ts-ignore
            resetForm(initialValues);
          })
          .catch(() => {
            toast.error(`Le livreur n'a pas pu être rajouté`);
          });
      } else {
        updateDriver({
          id: editedDriver.id,
          name: values.name,
          shortName: values.shortName,
        })
          .unwrap()
          .then(() => {
            toast.success('Le livreur a été mis à jours');
          })
          .catch(() => {
            toast.error(`Le livreur n'a pas pu être mis à jours`);
          });
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      name: editedDriver ? editedDriver.name : '',
      shortName: editedDriver ? editedDriver.shortName : '',
    });
  }, [editedDriver]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: 300,
        },
      },
    }),
  );
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit} className={classes.root}>
      <div className="flex items-center ">
        <div className="my-3">
          <TextField
            id="name"
            label="Nom"
            variant="outlined"
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name !== undefined &&
              formik.errors.name !== undefined
            }
          />
          {formik.touched.name && formik.errors.name ? (
            <FormInputErrorStyled>{formik.errors.name}</FormInputErrorStyled>
          ) : null}
        </div>

        <div className="my-3">
          <TextField
            id="shortName"
            label="Initiales"
            variant="outlined"
            {...formik.getFieldProps('shortName')}
            error={
              formik.touched.shortName !== undefined &&
              formik.errors.shortName !== undefined
            }
          />
          {formik.touched.shortName && formik.errors.shortName ? (
            <FormInputErrorStyled>
              {formik.errors.shortName}
            </FormInputErrorStyled>
          ) : null}
        </div>

        <div>
          <button
            type="submit"
            className={`border bg-main text-white px-3 py-2 rounded`}
          >{`${editedDriver ? 'Sauver' : 'Ajouter'}`}</button>
        </div>
      </div>
    </form>
  );
};

export default DriverForm;
