import React, { useEffect, useMemo, useState } from 'react';
import { ProductOrder } from 'app/models/ProductOrder';
import { ListItem } from '@mui/material';
import { FiChevronRight } from 'react-icons/fi';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import DiffBusinessDay from 'app/components/DiffBusinessDay';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import { useSelector } from 'react-redux';
import { selectOrdersMap } from 'app/containers/SiteWrapper/selectors';
import { Color, extractColor } from 'app/models/Color';
import styled from 'styled-components';
import { PartOrder } from 'app/models/PartOrder';
import { selectAllColors } from 'store/slices/colorSlice';
import { parseISO } from 'date-fns';

interface Props {
  product: ProductOrder;
  to: string;
}

const ProductOrderListItem = ({ product, to }: Props) => {
  const [order, setOrder] = useState<PartOrder | undefined>(undefined);
  const [color, setColor] = useState<Color | undefined>(undefined);
  const ordersMap = useSelector(selectOrdersMap);
  const allColors = useSelector(selectAllColors);

  useEffect(() => {
    const o = ordersMap.get(product.order);
    if (o) {
      setOrder(o);
      const c = extractColor(product.reference, allColors);
      setColor(c);
    }
  }, [product.order]);

  const days = useMemo(() => {
    if (product.productionDate) {
      return differenceInBusinessDays(
        parseISO(product.productionDate),
        new Date(),
      );
    } else return undefined;
  }, [product.productionDate]);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li className="w-full border-b border-gray-300">
      <ListItem button component={renderLink}>
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <div className="mx-2">{product.reference}</div>
            {color && (
              <ColorPreviewStyled
                color={color.hexaHtml}
                className="w-5 h-5 mx-2"
              />
            )}
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="font-bold text-sm">{product.order}</div>
            {order && (
              <div className="text-sm italic">{order.customerName}</div>
            )}
          </div>
          <div className="flex items-center">
            {days && (
              <div className="mx-2">
                <DiffBusinessDay days={days} alert={true} />
              </div>
            )}
            <FiChevronRight className="h-5 w-5" />
          </div>
        </div>
      </ListItem>
    </li>
  );
};

export default ProductOrderListItem;

const ColorPreviewStyled = styled.div<{ color: string }>`
  background-color: ${p => p.color};
`;
