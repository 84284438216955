import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'assets/images/logo.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useLoginMutation } from 'services/kewloxApi';
import { selectIsAuthenticated } from 'app/containers/AuthProvider/selectors';
import { setToken } from 'app/containers/AuthProvider/slice';

export function LoginPage() {
  const [displayHelp, showHelp] = useState(false);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [login, { data, error, isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const [errorsList, setErrors] = useState(null);

  const schema = Yup.object({
    username: Yup.string().required('Champ requis'),
    password: Yup.string().required('Champ requis'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async values => {
    setErrors(null);
    try {
      await login(values).unwrap();
    } catch (err) {
      setErrors(
        'Impossible de se connecter, vérifier votre login/mot de passe',
      );
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setToken(data.token));
    }
  }, [data]);

  const textFieldStyle = {
    width: 300,
    margin: 1,
  };

  return (
    <>
      <Helmet>
        <title>Connexion</title>
        <meta name="description" content="Connexion" />
      </Helmet>

      {isAuthenticated && <Navigate to="/" />}

      <div className="flex justify-center items-center h-screen bg-gray-200">
        <div className="flex flex-col bg-white border shadow p-4 px-20">
          <div className="flex justify-center py-2">
            <div>
              <img src={logo} alt="logo" className="h-8 py-1" />
            </div>
          </div>
          <div className="flex justify-center text-2xl font-light py-6">
            Expedinet
          </div>
          <div className="flex justify-center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="my-3">
                <TextField
                  id="username"
                  label="Username"
                  placeholder="your@email.com"
                  variant="outlined"
                  {...register('username')}
                  error={!!errors.username}
                  sx={textFieldStyle}
                />
                {errors.username && (
                  <div className="text-red-600">{errors.username.message}</div>
                )}
              </div>
              <div className="my-3">
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  {...register('password')}
                  error={!!errors.password}
                  sx={textFieldStyle}
                />
                {errors.password && (
                  <div className="text-red-600">{errors.password.message}</div>
                )}
              </div>
              <div className="text-red-600">{errorsList}</div>
              <div className="mt-4 flex justify-center">
                <button
                  type="submit"
                  className="border bg-main text-white px-3 py-2 rounded w-24"
                  disabled={isLoading}
                >
                  OK
                </button>
              </div>
            </form>
          </div>
          <button
            onClick={() => showHelp(!displayHelp)}
            className="flex justify-center text-gray-600 text-xs mt-4 underline"
          >
            Vous avez oublié votre mot de passe?
          </button>
          {displayHelp && (
            <div className="mt-2 text-gray-700 flex justify-center">
              Contacter votre administrateur
            </div>
          )}
        </div>
      </div>
    </>
  );
}
