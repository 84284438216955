import React from 'react';
// import styled from 'styled-components';

interface Props {
  title: string;
}

const TitleModal = ({ title }: Props) => (
  <div className="flex justify-center text-2xl py-2 z-20">{title}</div>
);

export default TitleModal;
