import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PartOrder } from 'app/models/PartOrder';
import Modal from 'react-responsive-modal';
import { Destination } from 'app/models/Destination';
import TitleModal from 'app/components/Modal/TitleModal';
import DestinationsSelector from 'app/components/Filters/DestinationsSelector';
import { Checkbox } from '@mui/material';
import { ProductOrder } from 'app/models/ProductOrder';
import Img from 'app/components/Img';
import _ from 'lodash';
import { ProductionPiece } from 'app/models/ProductionPiece';
import Button from 'app/components/Button';
import { requestPost } from 'utils/requests';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import {
  getAllPartOrders,
  getPartOrder,
} from 'app/containers/SiteWrapper/slice';
import { Workstation } from 'app/containers/OrderDetails/types';
import { GiCircularSawblade } from 'react-icons/gi';
import {
  FaWineGlass,
  RiAlarmWarningFill,
  RiArchiveDrawerLine,
} from 'react-icons/all';
import { ModalContentStyled } from 'app/containers/OrderDetails/PartOrderSelection/PrintDirectPartOrderModal';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { useSubdividePartOrderMutation } from 'services/kewloxApi';

interface Props {
  partOrder: PartOrder;
  isCreatePartOrderModalOpen: boolean;
  setCreatePartOrderModalOpen: any;
}

const CreatePartOrderModal = ({
  partOrder,
  isCreatePartOrderModalOpen,
  setCreatePartOrderModalOpen,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedPieces, setSelectedPieces] = useState<number[]>([]);
  const [selectedPieceQuantity, setSelectedPieceQuantity] = useState<
    Map<number, number>
  >(new Map());
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [destination, setDestination] = useState<Destination | undefined>(
    partOrder.destination,
  );

  const destinations = useSelector(selectAllDestinations);

  const [subdivide] = useSubdividePartOrderMutation();

  useEffect(() => {
    setSelectedPieces([]);
    setSelectedPieceQuantity(new Map());
  }, [partOrder]);

  const handleDestination = (event: React.ChangeEvent<{ value: unknown }>) => {
    let targetId = event.target.value as number;
    if (targetId === 51) targetId = 54;
    const destination = destinations.find(e => e.id === targetId);
    if (destination) {
      setDestination(destination);
    }
  };

  const handleSelectedProduct = (productId: number) => {
    const selected = new Set<number>(selectedProducts);
    if (selected.has(productId)) selected.delete(productId);
    else selected.add(productId);
    setSelectedProducts(Array.from(selected));
  };

  const handleSelectedPieces = (pieceId: number) => {
    const selected = new Set<number>(selectedPieces);
    if (selected.has(pieceId)) selected.delete(pieceId);
    else selected.add(pieceId);
    setSelectedPieces(Array.from(selected));
  };

  const handleCreateNewPartOrder = () => {
    if (destination) {
      const data = {};
      const dataPieces = selectedPieces.map(pieceId => {
        const quantity = selectedPieceQuantity.get(pieceId);
        const changeList = {
          id: pieceId,
        };
        if (quantity) changeList['quantity'] = quantity;
        return changeList;
      });
      if (dataPieces && dataPieces.length > 0) {
        data['pieces'] = dataPieces;
      }

      if (selectedProducts.length > 0) {
        data['products'] = selectedProducts;
      }

      data['destination'] = destination.id;

      subdivide({ id: partOrder.id, data })
        .unwrap()
        .then(() => {
          setCreatePartOrderModalOpen(false);
        })
        .catch(error => {
          console.error('Error fetching part orders:', error);
        });

      // const url = `${PARTORDER_URL}${partOrder.id}/subdivide/`;
      //
      // requestPost(url, data).then(() => {
      //   setCreatePartOrderModalOpen(false);
      //   dispatch(getPartOrder(partOrder.id));
      //   dispatch(getAllPartOrders());
      // });
    } else {
      alert(
        'Vous devez sélectionner une destination pour la nouvelle commande',
      );
    }
  };

  function productName(product: ProductOrder): string {
    if (product.name && product.name) return product.name;
    if (product.production.length === 1 && product.production[0].description) {
      return `${product.production[0].quantity} x ${product.production[0].description}`;
    }
    return '-';
  }

  return (
    <Modal
      open={isCreatePartOrderModalOpen}
      onClose={() => {
        setCreatePartOrderModalOpen(false);
      }}
      center
    >
      <ModalContentStyled>
        <TitleModal
          title={`Création d'une sous-commande depuis ${partOrder.identification}`}
        />
      </ModalContentStyled>
      <div className="flex item-center py-4 border-b border-t border-gray-200">
        <div className="">
          Sélectionner la destination pour la nouvelle sous-commande
        </div>
        <div className="ml-10 -mt-5">
          <DestinationsSelector
            handleOnChange={handleDestination}
            selectedDestination={destination}
          />
        </div>
      </div>

      <div className="overflow-y-auto">
        <div>
          {partOrder.products
            ?.filter(p => !p.drawer)
            .map((product: ProductOrder) => (
              <>
                <div
                  key={`T-${product.id}`}
                  className="w-full flex items-center border cursor-pointer hover:bg-gray-100 mt-2"
                  onClick={() => {
                    handleSelectedProduct(product.id);
                  }}
                >
                  <div className="w-1/12">
                    <Checkbox
                      checked={selectedProducts.includes(product.id)}
                      color="primary"
                      name={`${product.id}`}
                    />
                  </div>
                  <div className="w-11/12">
                    <div className="flex items-center justify-between text-xl ">
                      <div className="flex items-center">
                        {product.outOfStock && (
                          <RiAlarmWarningFill className="h-5 w-5 mx-1" />
                        )}
                        {productName(product)}
                      </div>
                      {product.image && (
                        <div className="mr-20 w-12 h-12">
                          <Img src={product.image} alt={`preview `} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {product.production.length > 1 &&
                  _.orderBy(product.production, ['sorting', 'reference']).map(
                    (piece: ProductionPiece) => (
                      <div
                        key={`P-${piece.id}`}
                        className={`flex border-b border-l border-r items-center h-8 justify-start text-base cursor-pointer hover:bg-gray-100 ${
                          selectedPieces.includes(piece.id) ? 'bg-gray-200' : ''
                        }`}
                        onClick={() => {
                          handleSelectedPieces(piece.id);
                        }}
                      >
                        <div className="w-1/6">
                          <div className="flex items-center">
                            <Checkbox
                              checked={selectedPieces.includes(piece.id)}
                              color="primary"
                              name={`${piece.id}`}
                            />
                            {piece.quantity} x
                          </div>
                        </div>
                        <div className="w-3/12 flex justify-start items-center">
                          {piece.cutting && (
                            <GiCircularSawblade className="h-5 w-5 mr-2 text-gray-700" />
                          )}
                          {piece.station === Workstation.Drawer && (
                            <RiArchiveDrawerLine className="h-5 w-5 mr-2 text-gray-700" />
                          )}
                          {piece.isFragile && (
                            <FaWineGlass className="h-5 w-5 mr-2 text-gray-700" />
                          )}
                          {piece.isOutOfStock && (
                            <RiAlarmWarningFill className="h-5 w-5 mx-1 bg-red text-white" />
                          )}
                          {piece.reference}
                        </div>
                        <div className="w-8/12 flex justify-start">
                          {piece.description}
                        </div>
                      </div>
                    ),
                  )}
              </>
            ))}
        </div>
      </div>

      {(selectedPieces.length > 0 || selectedProducts.length > 0) && (
        <div className="w-full">
          <div className="my-2  flex flex-end">
            <Button
              onClick={handleCreateNewPartOrder}
              contentText="Créer une nouvelle sous-commande avec la sélection"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CreatePartOrderModal;
