import React, { useMemo } from 'react';
import PickingPageHeader from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingPageHeader';
import { PickingSection } from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingSection';
import { Workstation } from 'app/containers/OrderDetails/types';
import { Trolley } from 'app/models/Trolley';
import { PartOrder } from 'app/models/PartOrder';

interface Props {
  order: PartOrder;
  trolleys: Trolley[];
  workstation: Workstation;
}

const PickingCornerPage = ({ order, trolleys, workstation }: Props) => {
  const pickingSubPages = useMemo(() => {
    const newPckingSubPages: any[] = [];
    let counter = 0;
    let nbSubPages = 0;
    newPckingSubPages.push([]);

    for (let index = 0; index < trolleys.length; index++) {
      newPckingSubPages[nbSubPages].push(trolleys[index]);
      counter++;
      for (let j = 0; j < trolleys[index].production.length; j++) {
        counter++;
        if (
          (counter % 17 === 0 || (j === 0 && (counter - 1) % 17 === 0)) &&
          index < trolleys.length - 1
        ) {
          nbSubPages++;
          newPckingSubPages.push([]);
        }
      }
    }
    return newPckingSubPages;
  }, [order.id]);

  return (
    <>
      {pickingSubPages.map((subpage, indexPage) => (
        <div key={`${indexPage}`} className="page page-break">
          <PickingPageHeader
            order={order}
            workstation={workstation}
            indexPage={indexPage + 1}
            nbPages={pickingSubPages.length}
          />
          {indexPage === 0 && (
            <div className="flex items-start justify-start label-signatures">
              <div className="flex w-1/3">Preparé par</div>
              <div className="flex w-1/3">Vérifié par</div>
              {order.isHomeTour && !order.isKewlox ? (
                <div className="flex w-1/3">Emballé par</div>
              ) : (
                <div style={{ border: 0 }}></div>
              )}
            </div>
          )}
          <div className="flex flex-col">
            {subpage.map((trolley: Trolley) => (
              <div key={trolley.id}>
                <PickingSection
                  pieces={trolley.production}
                  productId={trolley.production[0].product?.id}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default PickingCornerPage;
