import React from 'react';
import { Event } from 'app/models/Feed';
// import styled from 'styled-components';

interface Props {
  event: Event;
  children: any;
}

const EventEntry = ({ event, children }: Props) => (
  <div className="flex items-center">
    {children}
    {event.user && (
      <div className="ml-1">
        par <span className="font-semibold">{event.user}</span>
      </div>
    )}
  </div>
);

export default EventEntry;
