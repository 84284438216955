import React, { useEffect } from 'react';
import { PartOrder } from 'app/models/PartOrder';
import OrderIdentification from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingPageHeader/OrderIdentification';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNextPartOrder,
  selectPreviousPartOrder,
} from 'app/containers/OrderDetails/HeaderOrderDetails/OrdersNavigator/selectors';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { setCurrentPartOrderId } from 'store/slices/activeContentSlice';

interface Props {
  partOrder: PartOrder;
  cycling?: boolean;
}

const OrdersNavigator = ({ partOrder, cycling = false }: Props) => {
  const dispatch = useDispatch();

  const previousPartOrder = useSelector(selectPreviousPartOrder);
  const nextPartOrder = useSelector(selectNextPartOrder);

  return (
    <div className="text-2xl  text-gray-800 mx-5 flex items-center justify-between">
      {previousPartOrder && (
        <button
          onClick={() => {
            dispatch(setCurrentPartOrderId(previousPartOrder.id));
          }}
        >
          <FiChevronLeft className="h-5 w-5 mr-3" />
        </button>
      )}
      <OrderIdentification partOrder={partOrder} />
      {nextPartOrder && (
        <button
          onClick={() => {
            dispatch(setCurrentPartOrderId(nextPartOrder.id));
          }}
        >
          <FiChevronRight className="h-5 w-5 ml-3" />
        </button>
      )}
    </div>
  );
};

export default OrdersNavigator;
