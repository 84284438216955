import React from 'react';
import { RiUser2Fill } from 'react-icons/ri';
import { HiShoppingCart } from 'react-icons/hi';
import { OrderInfo } from 'app/pages/ToursPage/types';

interface Props {
  order: OrderInfo;
}

const EmployeOrder = ({ order }: Props) => (
  <div className=" text-base text-gray-800 ml-4">
    {order.idEmployee && order.idEmployee !== 0 ? (
      <div className="flex items-center">
        <RiUser2Fill className="h-4 w-4" />
        <div className="ml-2">
          {order.employeeFirstname.charAt(0).toUpperCase()}.{' '}
          {order.employeeLastname}
        </div>
      </div>
    ) : (
      <div className="flex items-center">
        <HiShoppingCart className="h-5 w-5" />
        <div className="ml-2">
          {order.employeeFirstname &&
          order.employeeLastname &&
          order.employeeLastname !== '' ? (
            <div className="flex">
              {order.employeeFirstname.charAt(0).toUpperCase()}.
              <div className="capitalize ml-1">{order.employeeLastname}</div>
            </div>
          ) : (
            <div>Webshop</div>
          )}
        </div>
      </div>
    )}
  </div>
);

export default EmployeOrder;
