import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { Tour } from 'app/models/Tour';
import { GrFormClose } from 'react-icons/gr';
import WaypointModal from 'app/containers/WaypointModal';
import { removeCurrentTourId } from 'store/slices/activeContentSlice';

interface Props {
  tour?: Tour;
}

const TourInfos = ({ tour }: Props) => {
  const dispatch = useDispatch();

  if (!tour) return null;

  return (
    <>
      <div className="py-0 pb-1 bg-white">
        <div className="flex justify-evenly text-lg font-normal text-gray-700">
          <TourColorStyles color={tour.color} className="border w-6 h-6" />
          <div>
            Tournée {tour.id}{' '}
            <span className="text-sm">
              ({format(parseISO(tour.date), 'dd/MM/yyyy')})
            </span>
          </div>
          {/*<div>{format(parseISO(tour.date), 'dd/MM/yyyy')}</div>
        <div>
          {tour.distance !== undefined ? (tour.distance / 1000).toFixed(0) : 0}{' '}
          Km
        </div>
        <div>{tour.orders.length} Livraisons</div>*/}

          {/*<WorkforceSelector tour={tour} />*/}

          {tour.isKewlox && tour.drivers && (
            <>
              {tour.drivers.length} livreur
              {tour.drivers.length > 1 ? 's' : ''}
            </>
          )}

          <button
            onClick={() => {
              dispatch(removeCurrentTourId());
            }}
          >
            <GrFormClose className="text-gray-700 h-5 w-5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default TourInfos;

const TourColorStyles = styled.div<{ color: string }>`
  background-color: ${p => p.color};
`;
