import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Img from 'app/components/Img';
import logo from 'assets/images/logo.png';
import { Helmet } from 'react-helmet-async';
import { useGetOrderPickupInfoQuery } from 'services/kewloxApi';
import { FiCheck } from 'react-icons/all';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PickupPage = () => {
  const { pickupCode } = useParams<{ pickupCode: string }>();
  const { orderId } = useParams<{ orderId: string }>();
  const query = useQuery();
  const { t, i18n } = useTranslation();

  const { data: orderInfos } = useGetOrderPickupInfoQuery(
    {
      orderId,
      pickupCode,
    },
    {
      pollingInterval: 5000,
    },
  );

  useEffect(() => {
    const lg = query.get('lg');
    if (lg) {
      i18n.changeLanguage(lg);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('delivery.Pickup')}</title>
        <meta name="description" content="Pickup of your Kewlox order" />
      </Helmet>
      <div className="container mx-auto px-3 md:px-0 bg-white">
        <div className="flex flex-col justify-center items-center">
          <div className="my-6">
            <Img src={logo} alt="logo" className="h-10" />
          </div>
          <div>
            {t('delivery.Order')}: <span className="font-bold">{orderId}</span>
          </div>
          {orderInfos && [35, 6].includes(orderInfos.status) ? (
            <div>
              <div className="my-5">
                {t('delivery.Your order has been picked up')}
              </div>
              <div className="flex justify-center align-center">
                <FiCheck className="h-24 w-24 text-green" />
              </div>
            </div>
          ) : (
            <div>
              <div className="my-5">
                {t(
                  'delivery.To collect your package, present your QR code to the seller',
                )}
              </div>
              <div className="flex justify-center align-center">
                <Img
                  src={`${
                    import.meta.env.VITE_KEWNET_URL
                  }/shop/kewapp/phpqrcode/?data=PKU${pickupCode}`}
                  alt="PickupCode"
                  className="h-72"
                />
              </div>
            </div>
          )}
          <div>
            <div className="my-4 text-2xl flex justify-center">
              <a href="https://www.kewlox.com" className="font-semibold">
                www.kewlox.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupPage;
