import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PartOrder } from 'app/models/PartOrder';
import { AppRootState } from 'index';

const partOrderAdapter = createEntityAdapter<PartOrder>({
  selectId: partOrder => partOrder.id,
});

const partOrderSlice = createSlice({
  name: 'partOrder',
  initialState: partOrderAdapter.getInitialState(),
  reducers: {
    setPartOrders(state, { payload }) {
      partOrderAdapter.setAll(state, payload);
    },
    setPartOrder(state, { payload }) {
      partOrderAdapter.setOne(state, payload);
    },
    addPartOrders(state, { payload }) {
      partOrderAdapter.addMany(state, payload);
    },
  },
});

export const {
  setPartOrders,
  setPartOrder,
  addPartOrders,
} = partOrderSlice.actions;

export const {
  selectById: selectPartOrderById,
  selectIds: selectPartOrderIds,
  selectEntities: selectPartOrderEntities,
  selectAll: selectAllPartOrders,
} = partOrderAdapter.getSelectors((state: AppRootState) => state.partOrder);

export default partOrderSlice.reducer;
