import React from 'react';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import SwipeableCell from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/SwipeableCell';
import { ListType } from '../../constants';
import { ProductionPiece } from 'app/models/ProductionPiece';

interface Props {
  production: ProductionPiece[];
}

const PickedList = ({ production }: Props) => (
  <div className="bg-gray-200 mt-6 p-3">
    <div className="flex w-full justify-center ml-2 text-xl italic font-hairline ">
      Pièces sur le chariot
    </div>
    <SwipeableList>
      {production.map(piece => (
        <SwipeableCell
          key={piece.id}
          quantity={piece.quantityPicked}
          piece={piece}
          cabinetName={piece.cabinetName}
          listType={ListType.picked}
        />
      ))}
    </SwipeableList>
  </div>
);

export default PickedList;
