/**
 *
 * SearchField
 *
 */

import React from 'react';
import { useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { searchOrderSaga } from './saga';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputAdornment, InputBase, TextField } from '@mui/material';

export function SearchField() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: searchOrderSaga });

  const dispatch = useDispatch();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      searchText: '',
    },
    validationSchema: Yup.object({
      searchText: Yup.number('Numéro de commande'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      dispatch(actions.searchingOrder(true));
      dispatch(actions.updateSearchField(values.searchText));
    },
  });

  return (
    <>
      <div className={classes.search}>
        {/*<div className={classes.searchIcon}>*/}
        {/*  <SearchIcon />*/}
        {/*</div>*/}
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="search-field"
            placeholder="Rechercher…"
            variant="standard"
            color="secondary"
            type="number"
            sx={{
              mx: 2,
              width: '25ch',
              height: '2ch',
              input: { color: 'white', fontWeight: '600' },
            }}
            classes={{
              root: classes.inputRoot,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
            {...formik.getFieldProps('searchText')}
          />
        </form>
      </div>
    </>
  );
}
