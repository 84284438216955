import React, { useEffect, useMemo, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import Img from 'app/components/Img';
import Logo from 'assets/images/logo.png';
import { format, parseISO } from 'date-fns';
import { Tour } from 'app/models/Tour';
import { Delivery } from 'app/models/Delivery';
import { TourFurnitures } from 'app/containers/TourPrinter/TourPrinterButton';
import { FurniturePage } from 'app/containers/TourPrinter/FurniturePage';
import OrderTourStopInfos from 'app/containers/TourPrinter/OrderTourStopInfos';
import WaypointTourStopInfos from 'app/containers/TourPrinter/WaypointTourStopInfos';
import { useSelector } from 'react-redux';
import { selectPartOrderFromTour } from 'store/slices/toursSlice';
import { PartOrder } from 'app/models/PartOrder';

class ComponentToPrint extends React.Component<{
  t: any;
  tour: Tour;
  partOrders?: PartOrder[];
  assembly: boolean;
  furnitureTourData: TourFurnitures[][];
}> {
  render() {
    const { tour, assembly, furnitureTourData, partOrders } = this.props;

    return (
      <div className="bg-white">
        <div className="page">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center">
              <Img src={Logo} alt="Logo" className="header-page-logo" />
            </div>
          </div>
          <div className="page-section">
            <div className="page-title text-center">
              Tournée {tour.id} du {format(parseISO(tour.date), 'dd/MM/yyyy')}
              <br />
              {tour.orders?.length} livraisons -{' '}
              {tour.distance && Math.round(tour.distance / 1000)} Km
              <br />
              {tour.drivers.length > 0 && (
                <div className="text-base font-normal text-left">Livreurs:</div>
              )}
              {tour.drivers.map(driver => (
                <div className="text-lg text-left">{driver.name}</div>
              ))}
              {assembly && (
                <div className="text-lg font-semibold">
                  Cette tournée comporte des montages
                </div>
              )}
            </div>
          </div>
          {tour.orders.map((delivery: Delivery, index) => {
            if (delivery.waypoint) {
              return (
                <WaypointTourStopInfos
                  waypointId={delivery.waypoint}
                  index={index}
                  waypointNote={delivery.waypointNote}
                />
              );
            } else {
              if (!partOrders) return null;
              return (
                <OrderTourStopInfos
                  infos={
                    partOrders
                      .filter(e => e !== undefined)
                      .find(e => e.id === delivery.partOrder)!.orderInfo
                  }
                  index={index}
                />
              );
            }
          })}
        </div>
        {furnitureTourData.map((tourFurnitures: TourFurnitures[]) =>
          tourFurnitures.map((furnitureInfo: TourFurnitures) => (
            <FurniturePage furnitureInfo={furnitureInfo} />
          )),
        )}
      </div>
    );
  }
}

interface Props {
  tour: Tour;
  furnitureTourData: TourFurnitures[][];
  onAfterPrint: () => void;
}

export function TourPrinter({ tour, furnitureTourData, onAfterPrint }: Props) {
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);
  const triggerBtnRef = useRef<any>(null);

  const partOrders = useSelector(selectPartOrderFromTour(tour.id));

  const assembly = useMemo(() => {
    const assemblyDeliveries = tour.orders.find(delivery => delivery.assembly);
    return !!assemblyDeliveries;
  }, [tour]);

  useEffect(() => {
    triggerBtnRef.current.click();
  }, []);

  if (tour.shouldPrintTourList)
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <button
              ref={triggerBtnRef}
              data-tip="Imprimer la feuille de route"
              data-for="infos"
            />
          )}
          content={() => componentRef.current}
          bodyClass="toprint"
          onAfterPrint={onAfterPrint}
        />
        <div className="will-print" style={{ display: 'none' }}>
          <ComponentToPrint
            ref={componentRef}
            t={t}
            tour={tour}
            partOrders={partOrders}
            assembly={assembly}
            furnitureTourData={furnitureTourData}
          />
        </div>
      </>
    );

  return null;
}
