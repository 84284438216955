// More utils here: https://gist.github.com/Shrugsy/6b6af02aef1f783df9d636526c1e05fa
// More utils here: https://gist.github.com/Shrugsy/6b6af02aef1f783df9d636526c1e05fa
export function providesList<
  R extends { id: string | number }[],
  T extends string
>(resultsWithIds: R | undefined, tagType: T) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}
