import React from 'react';
import CarrierDestinationLabel from 'app/components/Kewlox/CarrierDestinationLabel';
import { format, parseISO } from 'date-fns';
import { Workstation } from 'app/containers/OrderDetails/types';
import { workstationDescription } from '../../../../helpers';
import { PartOrder } from 'app/models/PartOrder';
import OrderIdentification from './OrderIdentification';
import { FaHammer } from 'react-icons/fa';
interface Props {
  order: PartOrder;
  workstation: Workstation;
  indexPage?: number;
  nbPages?: number;
  trolley?: string;
}

const PickingPageHeader = ({
  order,
  workstation,
  indexPage = 1,
  nbPages = 1,
  trolley,
}: Props) => {
  const { orderInfo } = order;

  return (
    <div>
      <div className="flex justify-around">
        <div className="flex items-start justify-start">
          {order.assembly && (
            <div className="top-prod-assembly bg-yellow">
              <FaHammer className="bottom-hammer text-red" />
            </div>
          )}
          <CarrierDestinationLabel
            carrier={order.carrier}
            destination={order.destination}
          />
        </div>

        <div className="flex flex-col ml-4">
          <div>
            <div className="text-xl flex">
              {orderInfo.firstname}
              <span className="uppercase ml-1">{orderInfo.lastname}</span>
            </div>
            <OrderIdentification partOrder={order} />
          </div>
        </div>

        <div className="flex flex-col items-center">
          {order.productionDate && (
            <div className="ml-2 text-2xl">
              {format(parseISO(order.productionDate), 'dd/MM/yyyy')}
            </div>
          )}
          <div>{workstationDescription(workstation)}</div>
        </div>
      </div>
      {trolley && (
        <div className="flex mt-3 mb-3 justify-center items-center">
          <div className="text-xl px-2 border-gray-800 border-double border-2">
            Chariot {trolley}
          </div>
          {nbPages > 1 && (
            <div className="text-xl px-2 border-gray-800 border-double border-2 bg-black text-white font-bold">
              {indexPage} / {nbPages}
            </div>
          )}
        </div>
      )}
      {!trolley && nbPages > 1 && (
        <div className="flex mt-3 mb-3 justify-center items-center">
          <div className="text-xl px-2 border-gray-800 border-double border-2 bg-black text-white font-bold">
            {indexPage} / {nbPages}
          </div>
        </div>
      )}
    </div>
  );
};

export default PickingPageHeader;
