/**
 *
 * ConfirmationPage
 *
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import logo from 'assets/images/logo.png';
import Img from 'app/components/Img';
import { FaShippingFast } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { requestPost } from 'utils/requests';
import { DELIVERY_URL } from 'app/pages/ToursPage/constants';
import { useLocation, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { CircleSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';
import { Delivery } from 'app/models/Delivery';
import { useActionDeliveryMutation } from 'services/kewloxApi';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function ConfirmationPage() {
  const { token } = useParams<{ token: string }>();
  const [delivery, setDelivery] = useState<Delivery>();
  const [error, setError] = useState<string>();
  // const [loading, setLoading] = useState(false);
  const query = useQuery();
  const { t, i18n } = useTranslation();

  const [deliveryAction, { isLoading }] = useActionDeliveryMutation();

  useEffect(() => {
    const lg = query.get('lg');
    if (lg) {
      i18n.changeLanguage(lg);
    }

    if (query.get('confirm') === '1') {
      requestDelivery('confirmation');
    } else {
      requestDelivery('get_order');
    }
  }, []);

  function requestDelivery(action = 'confirmation') {
    deliveryAction({ id: 1, action, token: token! })
      .unwrap()
      .then(response => {
        setDelivery(response as Delivery);
      })
      .catch(errors => {
        setError('There is an error with the token');
      });

    // const url = `${DELIVERY_URL}${action}/`;
    // setLoading(true);
    // requestPost(url, { token: token })
    //   .then(response => {
    //     setLoading(false);
    //     setDelivery(response as Delivery);
    //   })
    //   .catch(errors => {
    //     setLoading(false);
    //     setError('There is an error with the token');
    //   });
  }

  if (error) {
    return (
      <>
        <Helmet>
          <title>ConfirmationPage</title>
          <meta
            name="description"
            content="Confirmation of your Kewlox order"
          />
        </Helmet>
        <div>{error}</div>
      </>
    );
  }

  if (token === 'undefined') {
    return (
      <>
        <Helmet>
          <title>ConfirmationPage</title>
          <meta
            name="description"
            content="Confirmation of your Kewlox order"
          />
        </Helmet>

        <div>
          <div>This page is invalid</div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('delivery.Confirmation')}</title>
        <meta name="description" content="Confirmation of your Kewlox order" />
      </Helmet>
      <div className="container mx-auto px-3 md:px-0">
        <div className="flex flex-col justify-center items-center">
          <div className="my-6">
            <Img src={logo} alt="logo" className="h-10" />
          </div>

          <div className="my-4 flex items-center">
            <FaShippingFast className="h-24 w-24 text-gray-800" />
            {delivery && delivery.confirmed && (
              <FiCheckCircle className="h-12 w-12 text-green-dark ml-6" />
            )}
          </div>

          <div className="my-4">
            {delivery &&
              delivery.order &&
              delivery.confirmed &&
              delivery.deliveryTime && (
                <div className="text-xl">
                  {t('delivery.dateConfirmed', {
                    orderId: delivery.order.identification,
                    deliveryDate: format(
                      parseISO(delivery.deliveryTime),
                      'dd/MM/yyyy',
                    ),
                  })}
                </div>
              )}

            {!isLoading && (
              <>
                {delivery &&
                  delivery.order &&
                  delivery.deliveryTime &&
                  !delivery.confirmed && (
                    <div className="flex flex-col justify-center items-center">
                      {t('delivery.dateProposition', {
                        orderId: delivery.order.identification,
                        deliveryDate: format(
                          parseISO(delivery.deliveryTime),
                          'dd/MM/yyyy',
                        ),
                      })}

                      <div className="my-4">
                        <button
                          onClick={() => {
                            requestDelivery();
                          }}
                          className="py-2 px-4 text-white rounded bg-main"
                          disabled={isLoading}
                        >
                          {t('delivery.iconfirm', {
                            deliveryDate: format(
                              parseISO(delivery.deliveryTime),
                              'dd/MM/yyyy',
                            ),
                          })}
                        </button>
                      </div>

                      {/*<div className="my-4 w-full md:w-128">*/}
                      {/*  {delivery.order && (*/}
                      {/*    <TourMap order={delivery.order} showDepot={false} />*/}
                      {/*  )}*/}
                      {/*</div>*/}
                    </div>
                  )}
              </>
            )}

            {isLoading && <CircleSpinner size={40} color="#4a5568" />}
          </div>

          <div className="text-sm my-3 italic">
            <>
              {t('delivery.In case of unforeseen events, contact us at')}{' '}
              <a href="mailto:expedition@kewlox.com" className="font-semibold">
                expedition@kewlox.com
              </a>{' '}
              {t('delivery.or phone number')}{' '}
              <a href="tel:+3281859260" className="font-semibold">
                +32 (0)81 85 92 60
              </a>
            </>
          </div>

          <div className="mt-4 text-2xl">
            <a href="https://www.kewlox.com" className="font-semibold">
              www.kewlox.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
