import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import { AppRootState } from 'index';
import { kewloxApi } from 'services/kewloxApi';

export const waypointAdapter = createEntityAdapter<Waypoint>();

const waypointsSlice = createSlice({
  name: 'waypointsSlice',
  initialState: waypointAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getAllWaypoints.matchFulfilled,
      (state, { payload }) => {
        waypointAdapter.setAll(state, payload);
      },
    );
  },
});

export const {} = waypointsSlice.actions;

export const {
  selectById: selectWaypointById,
  selectIds: selectWaypointIds,
  selectEntities: selectWaypointEntities,
  selectAll: selectAllWaypoints,
} = waypointAdapter.getSelectors((state: AppRootState) => state.waypoint);

export default waypointsSlice.reducer;
