import { sortingDestinations } from 'app/constants';

export interface DestinationJson {
  id: number;
  name: string;
  color: string;
  textColor: string;
  shop: boolean;
  active: boolean;
}

export interface Destination extends DestinationJson {
  home: boolean;
  sorting: number;
  direct: boolean;
  shopLg: string;
  aggregatedDestinationName: string | undefined;
}

export const createDestination = (data: DestinationJson): Destination => ({
  ...data,
  home: data.name.toLowerCase().includes('home'),
  sorting: sortingDestinations.indexOf(data.id),
  direct: data.name === 'Direct',
  shopLg: data.name === 'Antwerpen' ? 'nl' : 'fr',
  aggregatedDestinationName: getAggregatedDestinationName(data.name),
});

const getAggregatedDestinationName = (name: string): string | undefined => {
  if (name.includes('Home')) {
    return '@Home';
  }
  if (name.includes('Köln')) {
    return '@Köln';
  }
  return name;
};
