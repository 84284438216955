import React, { useEffect } from 'react';
import TourItem from 'app/containers/ToursList/TourItem';
import { useDispatch, useSelector } from 'react-redux';
import { compareAsc, parseISO } from 'date-fns';
import {
  selectDestinationSelected,
  selectError,
  selectIsPastToursList,
} from 'app/containers/ToursList/selectors';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  loadTours,
  reducer,
  selectDestinationFilter,
  sliceKey,
} from 'app/containers/ToursList/slice';
import ReactTooltip from 'react-tooltip';
import { Tour } from 'app/models/Tour';
import { FiChevronLeft } from 'react-icons/fi';

import { toast } from 'react-toastify';
import { selectToursFiltered } from 'store/slices/toursSlice';
import { TourItemPlaceholder } from 'app/components/Placeholders/TourItemPlaceholder';
import {
  selectActiveSearch,
  setCurrentPartOrderId,
} from 'store/slices/activeContentSlice';

const ToursList = ({ isLoading }: { isLoading: boolean }) => {
  const dispatch = useDispatch();
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const tours = useSelector(selectToursFiltered);
  // const isLoading = useSelector(selectIsToursLoading);
  const isPastTourList = useSelector(selectIsPastToursList);
  const error = useSelector(selectError);

  const isDestinationFiltered = useSelector(selectDestinationSelected);

  const activeSearch = useSelector(selectActiveSearch);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tours]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error);
    }
  }, [error]);

  const handleOnClickBackBtn = () => {
    dispatch(setCurrentPartOrderId(undefined));
    dispatch(selectDestinationFilter({ destination: null, list: false }));
    if (isPastTourList) {
      dispatch(loadTours());
    }
  };

  return (
    <div>
      {isDestinationFiltered && (
        <div className="sticky z-10" style={{ top: '64px' }}>
          <button
            onClick={handleOnClickBackBtn}
            className="flex items-center py-1 mb-2 ml-0 px-2 font-bold w-full bg-gray-400"
          >
            <FiChevronLeft className="h-4 w-4" />
            <div className="ml-2">Retour</div>
          </button>
        </div>
      )}
      <div className="tours-container flex flex-col relative">
        {tours
          .slice()
          .sort((a: Tour, b: Tour) => {
            // If direct, it should be first
            if (a.direct) return -1;
            // Otherwise, sort by date
            const dateComp = compareAsc(parseISO(a.date), parseISO(b.date));
            if (dateComp === 0) {
              return a.id - b.id;
            } else return dateComp;
          })
          .filter(tour => {
            if (activeSearch !== undefined) {
              if (activeSearch.tourIds !== undefined) {
                return activeSearch.tourIds.includes(tour.id);
              }
            } else {
              return !tour.delivered;
            }
          })
          .map(tour => (
            <TourItem
              droppableId={`${tour.id}`}
              key={tour.id}
              tour={tour}
              history={false}
            />
          ))}
        {isLoading && (
          <div>
            <TourItemPlaceholder />
            <TourItemPlaceholder />
            <TourItemPlaceholder />
            <TourItemPlaceholder />
            <TourItemPlaceholder />
            <TourItemPlaceholder />
          </div>
        )}
      </div>

      <ReactTooltip id="manual-mode" place="bottom" type="dark" />
      <ReactTooltip id="infos" place="bottom" type="dark" />
    </div>
  );
};

export default ToursList;
