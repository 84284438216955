import React from 'react';

const CrashMessage = () => (
  <>
    <div className="my-2 text-lg">Un problème est survenu</div>
    <div className="my-4 text-base">
      N'hésitez pas à informer les développeurs!
    </div>
  </>
);

export default CrashMessage;
