import React from 'react';
import Img from 'app/components/Img';
import { ProductOrder } from 'app/models/ProductOrder';
import { RiAlarmWarningFill } from 'react-icons/all';

// import styled from 'styled-components';

interface Props {
  product: ProductOrder;
  quantity: number;
}

const ProductEntry = ({ product, quantity }: Props) => {
  if (product.drawer) return null;

  let name = product.reference;
  if (product.name) {
    name = product.name;
  }
  if (product.cabinet) {
    name = product.cabinet.toString();
  }

  return (
    <div className="flex my-2 border-b">
      {product.image && (
        <div className="w-20 h-20 border border-gray-300 m-2">
          <Img
            src={product.image}
            alt={`preview of cabinet ${product.cabinet}`}
          />
        </div>
      )}
      <div className="flex-col ml-2">
        <div className="title text-xl ">
          {quantity > 1 && (
            <span>
              {quantity} <span className="text-base">x</span>
            </span>
          )}
          <span className={`ml-1 ${!product.cabinet && 'text-sm'}`}>
            {name}
          </span>
        </div>
        {product.cabinet && (
          <div className="my-2">
            <a
              href={`https://www.kewlox.com/shop/fr/nos-meubles/${product.prestashopId}-meuble-bas-55x80x42-cm.html#modif`}
              target="_blank"
            >
              <button className="border border-gray-300 py-1 px-3 text-gray-700">
                Configurateur
              </button>
            </a>
          </div>
        )}
      </div>
      {product.outOfStock && (
        <div className="flex items-center">
          <RiAlarmWarningFill className="h-5 w-5 mx-1 bg-red text-white mr-2" />{' '}
          <div className="italic">
            Ce produit comporte des pièces qui sont en rupture de stock
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductEntry;
