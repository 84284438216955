import { ProductionPiece } from 'app/models/ProductionPiece';
import { Color } from 'app/models/Color';
import { API_BASE_URL } from 'app/constants';

export const PRODUCTION_PIECE_URL = `${API_BASE_URL}/production-piece`;

export enum ProductionMode {
  basePicking = 'picking/0',
  corners = 'picking/1',
  accessoriesPicking = 'picking/2',
  drawerPicking = 'drawers',
  paneCutting = 'cutting',
  drawerCuttinh = 'drawers-cutting',
  woodworkCutting = 'woodwork-cutting',
}

export enum ListType {
  picking = 'picking',
  picked = 'picked',
  standbyCutting = 'standbyCutting',
  standby = 'standby',
  confirmation = 'confirmation',
  confirmed = 'confirmed',
}

export const pieceTypeText = {
  'batten rack': 'Tasseau',
  'pane rack': 'Côté/Dos',
  'cross piece rack': 'Traverse',
  'cross piece top cabinet': 'Traverse',
  'plate top cabinet': 'Plateau',
  'plate rack': 'Plateau',
  'glass plate rack': 'Plateau',
  'glass plate top cabinet': 'Plateau',
  'door handle': 'Poignée',
  'door shelf': 'Porte',
  door: 'Porte',
  'corner cabinet': 'Cornière',
  'wheel pane cabinet': 'Base roulettes',
  'wheel cabinet': 'Roulette',
  'intermediate shelf plank': 'Tabl. interm.',
  'support is intermediate shelf': 'Support TI',
  'feet extension cabinet': 'Pied',
  'feet cabinet': 'Pied',
  'rod shelf': 'Tringle',
};

export const cuttingSectionsDays = [1, 7];
export const cuttingSectionsDaysText = ["Aujourd'hui", 'Cette semaine'];

export const colorBandSizesValues = [280, 380, 480] as const;

export type ColorBandSize = typeof colorBandSizesValues[number];

export class CuttingMap extends Map<
  Color,
  Map<number | string | undefined, ProductionPiece[]>
> {}

export const backgroundColors = [
  '#D4E1F5',
  '#FFF4C3',
  '#D5E8D4',
  '#FFCE9F',
  '#F8CECC',
  '#A9C4EB',
  '#FFB570',
  '#9AC7BF',
  '#F19C99',
  '#FFD966',
  '#97D077',
  '#99FFFF',
  '#FFCCFF',
  '#CCFFCC',
  '#CC99FF',
];
