import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { differenceInMinutes, format, parseISO } from 'date-fns';
import { MAX_DIFFERENCE_DELIVERY_TIME_ALLOWED } from 'app/pages/ToursPage/constants';
import { Delivery } from 'app/models/Delivery';
import { AppRootState } from 'index';
import { selectWaypointById } from 'store/slices/waypointsSlice';
import { Menu, MenuItem } from '@mui/material';
import { FaTrash } from 'react-icons/all';
import AddNoteWaypointForm from 'app/containers/WaypointModal/AddNoteWaypointForm';
import { deleteDeliveryAction } from 'app/containers/ToursList/slice';

interface Props {
  delivery: Delivery;
  index: number;
  tourDate: string;
}

const initialState = {
  mouseX: null,
  mouseY: null,
};

const WaypointTourItem = ({ delivery, index, tourDate }: Props) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [displayEditWaypointModal, setDisplayEditWaypointModal] = useState<
    number | undefined
  >(undefined);
  const waypoint = useSelector((state: AppRootState) =>
    selectWaypointById(state, delivery.waypoint!!),
  );

  const [mousePos, setMousePos] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  if (!delivery.waypoint) {
    return null;
  }

  const handleClose = () => {
    setMousePos(initialState);
  };

  function isInDeliveryRange(deliveryTime: string, customerTime: string) {
    return (
      differenceInMinutes(parseISO(deliveryTime), parseISO(customerTime)) <=
      MAX_DIFFERENCE_DELIVERY_TIME_ALLOWED
    );
  }

  function getTimeDeliveryStatus(orderTour: Delivery) {
    if (orderTour.deliveryTime) {
      if (orderTour.customerTime) {
        if (isInDeliveryRange(orderTour.deliveryTime, orderTour.customerTime)) {
          return 'bg-green';
        } else return 'bg-red';
      } else {
        return 'bg-main';
      }
    }
  }

  const handleOnContextMenuClick = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    if (showMenu) {
      handleClose();
      setShowMenu(false);
    } else {
      setMousePos({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setShowMenu(true);
    }
  };

  return (
    <Draggable
      key={`${waypoint}${tourDate}`}
      draggableId={`${waypoint}${tourDate}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onMouseDownCapture={e => {
            if (e.button === 2) handleClose();
          }}
        >
          <div onContextMenu={handleOnContextMenuClick}>
            <WPCardStyle>
              <div className="flex flex-col text-xs mb-2 ml-1 mt-2">
                <div>Pt. de passage</div>
              </div>
              <div
                className={`text-sm font-semibold flex items-center justify-center text-gray-800`}
              >
                {waypoint?.name}
              </div>
              <Menu
                keepMounted={false}
                open={mousePos.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  mousePos.mouseY !== null && mousePos.mouseX !== null
                    ? { top: mousePos.mouseY, left: mousePos.mouseX }
                    : undefined
                }
              >
                <MenuItem
                  onClick={() => {
                    setDisplayEditWaypointModal(delivery.id);
                    handleClose();
                  }}
                >
                  Ajouter une note
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(deleteDeliveryAction(delivery));
                    handleClose();
                  }}
                >
                  <div className="flex items-center">
                    <FaTrash className="h-4 w-4 mr-2 text-red-dark" /> Retirer
                  </div>
                </MenuItem>
              </Menu>
            </WPCardStyle>
          </div>
          {delivery.deliveryTime && (
            <div className="w-full  flex justify-center items-center -mt-2">
              <div
                className={`${getTimeDeliveryStatus(
                  delivery,
                )} text-white border text-xs px-1`}
              >
                {format(parseISO(delivery.deliveryTime), 'HH:mm')}
              </div>
            </div>
          )}
          {displayEditWaypointModal !== undefined && (
            <AddNoteWaypointForm
              delivery={delivery}
              show={setDisplayEditWaypointModal}
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default WaypointTourItem;

const WPCardStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 0.25rem;
  border-width: 2px;
  border-radius: 0.25rem;
  line-height: 1.25;
  color: #4a5568;
  border-color: #9b3fc2;
  background-color: #ffffff;
  min-width: 4.2rem;
  user-select: none;
`;
