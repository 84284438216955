import React, { useState } from 'react';
import { ProductionPiece } from 'app/models/ProductionPiece';
import styled from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import SwipeableItem from 'app/components/SwipeableItem';
import { GiCircularSawblade } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import {
  ListType,
  pieceTypeText,
  PRODUCTION_PIECE_URL,
} from 'app/pages/ProductionPage/constants';
import { requestPut } from 'utils/requests';
import { updateProductionPiece } from 'app/containers/SiteWrapper/slice';
import Modal from 'react-responsive-modal';
import { useFormik } from 'formik';
import { CircleSpinner } from 'react-spinners-kit';
import useMedia from 'use-media';
import DiffBusinessDay from 'app/components/DiffBusinessDay';
import { FiCodepen } from 'react-icons/all';

interface Props {
  quantity: number;
  piece: ProductionPiece;
  cabinetName: string;
  backgroundColor?: string;
  listType: ListType;
  hideDescription?: boolean;
}

const SwipeableCell = ({
  quantity,
  piece,
  cabinetName,
  backgroundColor,
  listType,
  hideDescription,
}: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [errors, setError] = useState<string | null>(null);

  const isMobile = useMedia({ maxWidth: 1023 });

  const validate = values => {
    const errors: any = {};
    if (
      (listType === ListType.picking &&
        parseInt(values.quantityPicked) + parseInt(values.quantityStandby) >
          quantity) ||
      (listType === ListType.picked &&
        parseInt(values.quantityPicked) > quantity) ||
      (listType === ListType.standby &&
        parseInt(values.quantityStandby) > quantity)
    ) {
      errors.quantityPicked = 'Quantités incorrectes';
      errors.quantityStandby = 'Quantités incorrectes';
    }
    return errors;
  };
  const form = useFormik({
    initialValues: {
      quantityPicked: 0,
      quantityStandby: 0,
    },
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const url = `${PRODUCTION_PIECE_URL}/${piece.id}/`;
      setError(null);

      let data: any = undefined;
      if (listType === ListType.picked)
        data = {
          quantityPicked: `${
            Number(piece.quantityPicked) - Number(values.quantityPicked)
          }`,
        };
      else if (listType === ListType.standby)
        data = {
          quantityStandby: `${
            Number(piece.quantityStandby) - Number(values.quantityStandby)
          }`,
        };
      else if (listType === ListType.picking)
        data = {
          quantityPicked: `${
            Number(values.quantityPicked) + Number(piece.quantityPicked)
          }`,
          quantityStandby: `${
            Number(values.quantityStandby) + Number(piece.quantityStandby)
          }`,
        };

      requestPut(url, data)
        .then(data => {
          dispatch(updateProductionPiece(data));
          setOpen(false);
          setSubmitting(false);
        })
        .catch((error: string) => {
          setError(error);
          setSubmitting(false);
        });
    },
  });

  const onClose = () => {
    setOpen(false);
  };

  if (quantity === 0) return null;

  let description = '';
  if (piece.type in pieceTypeText) description = pieceTypeText[piece.type];

  return (
    <>
      <SwipeableItem
        piece={piece}
        leftAction={() => {
          console.log(`Swipe Left ${piece.id} (${piece.reference})`);

          const url = `${PRODUCTION_PIECE_URL}/${piece.id}/`;
          let data;
          if (listType === ListType.picked) {
            data = {
              quantityPicked: 0,
            };
          } else if (listType === ListType.picking) {
            data = {
              quantityPicked: piece.quantityPicked + quantity,
            };
          } else if (listType === ListType.standby) {
            data = {
              quantityStandby: piece.quantityStandby - quantity,
            };
          }
          requestPut(url, data)
            .then(data => {
              dispatch(updateProductionPiece(data));
            })
            .catch(error => {
              // TODO Set toast with error msg
            });
        }}
        rightAction={() => {
          console.log(`Swipe Right  ${piece.id} (${piece.reference})`);
          setOpen(true);
        }}
        listType={listType}
      >
        <div className="flex items-center ">
          <div className="flex items-baseline">
            {piece.cutting && (
              <span>
                <GiCircularSawblade className="h-4 w-4" />
              </span>
            )}
            <span className="font-bold mx-1">{quantity}</span>
            <span className="mx-1 text-xs font-thin">X</span>
          </div>
          <span className="mx-1 whitespace-no-wrap">
            {isMobile ? piece.shortName : piece.name}
          </span>
          {listType !== ListType.picked && (
            <>
              {piece.color && (
                <ColorPreviewStyled
                  color={piece.color.hexaHtml}
                  className="w-5 h-5 mx-2"
                />
              )}
              {!hideDescription && piece.description && (
                <span className="text-sm italic whitespace-no-wrap">
                  {piece.description}
                </span>
              )}
              {/*{piece.color && (*/}
              {/*  <span className="ml-1 text-sm italic">*/}
              {/*    {piece.color.material} {piece.color.name}*/}
              {/*  </span>*/}
              {/*)}*/}
            </>
          )}
        </div>
        <div
          className={`w-40 flex justify-end items-center ${
            listType === ListType.picked && 'line-through'
          }`}
        >
          {piece.isSparePartInsideCabinetPackage && (
            <FiCodepen className="h-5 w-5 mr-2" />
          )}
          <CabinetNameStyled
            backgroundColor={backgroundColor}
            className="px-2 py-1"
          >
            {piece.trolleyName}
          </CabinetNameStyled>
        </div>
        {piece.order && (
          <div className="flex items-center">
            <div className="flex flex-col items-center w-28">
              <div className="text-sm truncate">{piece.order.customerName}</div>
              <div className="text-sm">{piece.order.id}</div>
            </div>
            <div>
              <DiffBusinessDay
                days={piece.order.diffBusinessDays}
                alert={true}
              />
            </div>
          </div>
        )}
      </SwipeableItem>
      <Modal open={isOpen} onClose={onClose} center>
        <div className="mt-4">
          <div className="italic text-xl my-2">
            <span className="font-bold mx-1">{quantity}</span>
            <span className="mx-1 text-xs font-thin">X</span>
            <span className="mx-1">{piece.reference}</span>
          </div>
        </div>
        <form onSubmit={form.handleSubmit} className="flex flex-col">
          <div
            className={`flex justify-between items-center my-2 ${
              listType !== ListType.picked &&
              listType !== ListType.picking &&
              ' hidden'
            }`}
          >
            <div className="mr-4">{`Quantité ${
              listType === ListType.picked ? 'retirée du' : 'ajoutée sur le'
            } chariot`}</div>
            <input
              id="quantityPicked"
              name="quantityPicked"
              type="text"
              placeholder="0"
              onChange={form.handleChange}
              value={form.values.quantityPicked}
              className={`w-24 border py-2 my-1 px-3 text-lg ${
                errors ||
                (form.touched.quantityPicked && form.errors.quantityPicked)
                  ? 'border-red'
                  : 'border-gray-400'
              }`}
            />
          </div>
          <div
            className={`flex justify-between items-center my-2 ${
              listType === ListType.picked ? ' hidden' : ''
            }`}
          >
            <div className="mr-4">{`Quantité ${
              listType === ListType.standby ? 'prête' : 'à mettre en stand-by'
            }`}</div>
            <input
              id="quantityStandby"
              name="quantityStandby"
              type="text"
              placeholder="0"
              onChange={form.handleChange}
              value={form.values.quantityStandby}
              className={`w-24 border py-2 my-1 px-3 text-lg ${
                errors ||
                (form.touched.quantityStandby && form.errors.quantityStandby)
                  ? 'border-red'
                  : 'border-gray-400'
              }`}
            />
          </div>
          {form.touched.quantityPicked && form.errors.quantityPicked ? (
            <div className="text-red-dark">{form.errors.quantityPicked}</div>
          ) : null}
          {errors && <div className="text-red-dark">Erreur</div>}
          <button
            type="submit"
            className="px-2 py-2 my-3 bg-main text-white"
            disabled={form.isSubmitting}
          >
            {!form.isSubmitting ? (
              <>Valider</>
            ) : (
              <div className="flex justify-center items-center">
                <CircleSpinner size={30} color="#fff" />
              </div>
            )}
          </button>
        </form>
      </Modal>
    </>
  );
};

const CabinetNameStyled = styled.span<{ backgroundColor: string | undefined }>`
  ${p =>
    p.backgroundColor &&
    css`
      background-color: ${p.backgroundColor};
    `}
`;

const ColorPreviewStyled = styled.div<{ color: string }>`
  background-color: ${p => p.color};
`;

export default SwipeableCell;
