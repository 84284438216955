import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { providesList } from 'services/utils';
import { Driver } from 'app/pages/DriverPage/types';
import { createTour, Tour, TourJson } from 'app/models/Tour';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import { createPartOrder, PartOrderJson } from 'app/models/PartOrder';
import { addPartOrders } from 'store/slices/partOrderSlice';
import { addTours } from 'store/slices/toursSlice';
import { createDelivery } from 'app/models/Delivery';
import { addDeliveries } from 'store/slices/deliverySlice';
import { selectOrderAction } from 'app/containers/TourMap/slice';
import {
  setActiveSearch,
  setCurrentPartOrderId,
} from 'store/slices/activeContentSlice';
import { searchingOrder } from 'app/containers/SearchField/slice';
import { toast } from 'react-toastify';

export const tourEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getActiveTours: builder.query<Tour[], void>({
    query: () => `/tour/`,
    providesTags: result => providesList(result, 'Tour'),
  }),
  getTour: builder.query<Tour, number>({
    query: id => `/tour/${id}/`,
    providesTags: (result, error, id) => [{ type: 'Tour', id }],
  }),
  addTour: builder.mutation<Tour, Partial<Tour>>({
    query: body => ({
      url: `/tour/`,
      method: 'POST',
      body,
    }),
    invalidatesTags: [{ type: 'Tour', id: 'LIST' }],
  }),
  updateTour: builder.mutation<Partial<Tour>, Partial<Tour>>({
    query: ({ id, ...body }) => ({
      url: `/tour/${id}/`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'Tour', id },
      { type: 'Tour', id: 'LIST' },
      { type: 'Delivery', id: 'LIST' },
      { type: 'PartOrder', id: 'LIST' },
    ],
  }),
  deleteTour: builder.mutation<void, number>({
    query: id => ({
      url: `/tour/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'Tour', id },
      { type: 'Tour', id: 'LIST' },
    ],
  }),
  getTourHistory: builder.query<Tour[], { start: string; end: string }>({
    query: ({ start, end }) => `/tour/history/?start=${start}&end=${end}`,
    transformResponse: (response: any[]) => {
      return response.map(tourJson => createTour(tourJson));
    },
  }),
  notifyAllCustomers: builder.mutation<unknown, number>({
    query: tourId => ({
      url: `tour/${tourId}/notify`,
      method: 'GET',
    }),
    invalidatesTags: (result, error, tourId) => [{ type: 'Tour', id: tourId }],
  }),
  searchOrderTour: builder.query<void, number>({
    query: orderId => `tour/search?order=${orderId}`,
  }),
  setDriversTour: builder.mutation<void, { tour: Tour; drivers: Driver[] }>({
    query: ({ tour, drivers }) => ({
      url: `/tour/${tour.id}/set_drivers/`,
      method: 'POST',
      body: { drivers },
    }),
    invalidatesTags: (result, error, { tour }) => [
      { type: 'Tour', id: tour.id },
    ],
  }),
  searchOrder: builder.query<
    { tours: TourJson[]; orders: PartOrderJson[] },
    number
  >({
    query: orderId => `tour/search/?order=${orderId}`,
    async onQueryStarted(orderId, { dispatch, getState, queryFulfilled }) {
      try {
        console.log('Wait for searchOrder', orderId);
        const { data } = await queryFulfilled;

        console.log('data', data);

        if (data && data.tours.length > 0) {
          const tours = data.tours.map((item: TourJson) => createTour(item));
          dispatch(addTours(tours));

          // @ts-ignore
          const destinations = selectAllDestinations(getState());
          // @ts-ignore
          const carriers = selectAllCarriers(getState());
          // @ts-ignore
          const colors = selectAllColors(getState());

          const pastOrders = data.orders.map(entry =>
            createPartOrder(entry, destinations, carriers, colors),
          );

          dispatch(addPartOrders(pastOrders));

          const deliveries = tours
            .map(tours =>
              tours.orders.map(delivery => createDelivery(delivery)),
            )
            .flat();
          dispatch(addDeliveries(deliveries));

          const partSearchOrder = pastOrders.filter(
            item => item.order.id === orderId,
          );
          if (partSearchOrder) {
            dispatch(selectOrderAction(partSearchOrder[0]));
            dispatch(setCurrentPartOrderId(partSearchOrder[0].id));
            dispatch(
              setActiveSearch({
                tourIds: tours.map(e => e.id),
                partOrderIds: partSearchOrder.map(e => e.id),
              }),
            );
          }
        }
        dispatch(searchingOrder(false));
      } catch (error) {
        toast.error(`La commande ${orderId} n'a pas été trouvée`);
        dispatch(searchingOrder(false));
      }
    },
  }),
});
