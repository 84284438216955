import React from 'react';
import styled from 'styled-components';
import { FiAlertTriangle } from 'react-icons/fi';

interface Props {
  days: number;
  alert: boolean;
}

enum OrderPriorityColors {
  URGENT = '#c10606',
  VERY_HIGHT = '#dd5561',
  HIGHT = '#673005',
  NORMAL = '#232b23',
  LOW = '#1d211d',
  VERY_LOW = '#544d4d',
}

const getStyledColor = (value: number): OrderPriorityColors => {
  if (value <= 1) {
    return OrderPriorityColors.URGENT;
  } else if (value <= 2) {
    return OrderPriorityColors.URGENT;
  } else if (value < 5) {
    return OrderPriorityColors.HIGHT;
  }
  return OrderPriorityColors.LOW;
};

const DiffBusinessDay = ({ days, alert = false }: Props) => (
  <DiffDayStyled
    bg={getStyledColor(days)}
    className="text-xl mx-2 flex items-center justify-end w-16"
  >
    {alert && days <= 1 && (
      <div>
        <FiAlertTriangle className="h-4 w-4 mx-1" />
      </div>
    )}
    {days} j
  </DiffDayStyled>
);

export default DiffBusinessDay;

const DiffDayStyled = styled.div<{ bg: string }>`
  color: ${p => p.bg};
`;
