import React from 'react';
import { ProductionStatus } from 'app/containers/OrderDetails/types';
import { Tab, Tabs } from '@mui/material';

// import styled from 'styled-components';

interface Props {
  productionStatus: ProductionStatus;
  handleTabChange: any;
}

const TabsProductionStatus = ({ productionStatus, handleTabChange }: Props) => (
  <div className="bg-gray-100 flex items-center py-4 px-3 border-b-2 border-gray-400">
    <Tabs
      value={productionStatus}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="A faire" disableRipple />
      <Tab label="En cours" disableRipple />
      <Tab label="Standby" disableRipple />
      <Tab label="Terminé" disableRipple />
    </Tabs>
  </div>
);

export default TabsProductionStatus;
