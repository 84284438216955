import { createSelector } from '@reduxjs/toolkit';
import { ProductAggregated } from 'app/containers/OrderDetails/types';
import { selectPartRealOrderProducts } from 'app/containers/SiteWrapper/selectors';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';

export const aggregatedOrderProducts = createSelector(
  [selectPartRealOrderProducts],
  productsOrder => {
    if (productsOrder) {
      const productsMap = new Map<string, ProductAggregated>();

      for (const element of productsOrder) {
        if (productsMap.has(element.reference)) {
          // @ts-ignore
          productsMap.get(element.reference).quantity += 1;
        } else {
          productsMap.set(element.reference, {
            product: element,
            quantity: element.quantity,
          });
        }
      }

      return [...productsMap.values()];
    }
  },
);

export const selectOrderMessagesProductionAndExpedition = createSelector(
  [selectCurrentPartOrder()],
  (partOrder): string[] => {
    const commonMessages: string[] = [];
    if (
      partOrder &&
      partOrder?.orderInfo &&
      partOrder.orderInfo.messagesProduction &&
      partOrder.orderInfo.messagesExpedition
    ) {
      const productionMessages = partOrder.orderInfo.messagesProduction.split(
        '///',
      );
      const expeditionMessages = partOrder.orderInfo.messagesExpedition.split(
        '///',
      );

      for (const message of productionMessages) {
        for (const expMessage of expeditionMessages) {
          if (message === expMessage) {
            commonMessages.push(message);
            break;
          }
        }
      }
    }
    return commonMessages;
  },
);

export const selectOrderMessagesProduction = createSelector(
  [selectCurrentPartOrder(), selectOrderMessagesProductionAndExpedition],
  (partOrder, commonMessages) => {
    if (partOrder?.orderInfo.messagesProduction) {
      const messages = partOrder.orderInfo.messagesProduction.split('///');
      return messages.filter(
        message => !commonMessages.find(e => e === message),
      );
    }
  },
);

export const selectOrderMessagesExpedition = createSelector(
  [selectCurrentPartOrder(), selectOrderMessagesProductionAndExpedition],
  (partOrder, commonMessages) => {
    if (partOrder?.orderInfo.messagesExpedition) {
      const messages = partOrder.orderInfo.messagesExpedition.split('///');
      return messages.filter(
        message => !commonMessages.find(e => e === message),
      );
    }
  },
);
