import React from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import { ListItem } from '@mui/material';
import { FiChevronRight } from 'react-icons/fi';
import DiffBusinessDay from 'app/components/DiffBusinessDay';
import useMedia from 'use-media';
import CarrierLabel from 'app/components/Kewlox/CarrierLabel';
import { PartOrder } from 'app/models/PartOrder';

interface ListItemLinkProps {
  order: PartOrder;
  to: string;
}

export function ListItemLink({ order, to }: ListItemLinkProps) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  const isMobile = useMedia({ maxWidth: 1023 });

  return (
    <li className="w-full border-b border-gray-300 bg-white">
      <ListItem button component={renderLink}>
        <div className="flex justify-between items-center w-full">
          <div className="flex">
            <div className="font-bold mx-3 text-xs sm:text-sm md:text-lg whitespace-no-wrap">
              {order.id}
            </div>
            <div className="mx-2 text-xs sm:text-sm md:text-base whitespace-no-wrap">
              {order.customerName}
            </div>
          </div>
          <div className="flex items-center">
            {order.carrier && (
              <div className="mx-2">
                <CarrierLabel carrier={order.carrier} short={isMobile} />
              </div>
            )}
            <div className="mr-2">
              <DiffBusinessDay days={order.diffBusinessDays} alert={true} />
            </div>
            <FiChevronRight className="h-5 w-5" />
          </div>
        </div>
      </ListItem>
    </li>
  );
}
