import { Helmet } from 'react-helmet-async';
import React from 'react';
import SiteWrapper from 'app/containers/SiteWrapper';

import { CircleSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  useGetAllWaypointsQuery,
  useUpdateWaypointMutation,
} from 'services/kewloxApi';
import { FaPlus } from 'react-icons/fa';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import { FiPlus } from 'react-icons/fi';

function WaypointsPage() {
  const {
    data: waypoints,
    isLoading: isLoadingWaypoints,
  } = useGetAllWaypointsQuery();

  const [updateWaypoint] = useUpdateWaypointMutation();

  function handleDeleteWaypoint(id: number) {
    updateWaypoint({
      id: id,
      active: false,
    })
      .unwrap()
      .then(() => {
        toast.success('Le point de passage a été désactivé');
      })
      .catch(() => {
        toast.error(`Une erreur est survenue`);
      });
  }

  return (
    <>
      <Helmet>
        <title>Points de passage</title>
        <meta name="description" content="Liste des points de passage" />
      </Helmet>
      <SiteWrapper title="Points de passage">
        <div className="mx-auto bg-white px-2 lg:px-12 xl:px-24">
          <div>
            {isLoadingWaypoints && <CircleSpinner size={35} color="#4a5568" />}
          </div>

          <TableContainer className="overflow-y-auto w-128 mt-12">
            <Table
              stickyHeader
              size="small"
              aria-label="List des livreurss Kewlox"
            >
              <TableHead className="border-b-2 border-black">
                <TableRow>
                  <TableCell align="right" colSpan={6}>
                    Nom
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              {waypoints
                ?.filter(waypoint => waypoint.active)
                .map(waypoint => (
                  <TableRow key={waypoint.id}>
                    <TableCell align="left" colSpan={6}>
                      {waypoint.name}
                    </TableCell>
                    <TableCell align="right" colSpan={1}>
                      <Link to={`/waypoint/edit/${waypoint.id}/`}>
                        <button className="py-0 px-2 bg-white text-gray-700 border border-gray-300">
                          Modifier
                        </button>
                      </Link>
                    </TableCell>
                    <TableCell align="right" colSpan={2}>
                      <button
                        className="py-0 px-2 bg-white text-gray-700 border border-gray-300 mx2 text-red-dark"
                        onClick={() => {
                          handleDeleteWaypoint(waypoint.id);
                        }}
                      >
                        Retirer
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </TableContainer>
          <div className="mt-8">
            <Link to="/waypoint/add">
              <Button variant="contained">
                <FiPlus className="h-6 w-6 mr-4" />
                <span>Ajouter un nouveau point de passage</span>
              </Button>
            </Link>
          </div>

          <div className="flex justify-end">
            <div className="mt-24 mb-4">
              <a href="/" className="underline">
                <Button variant="inline">Retourner sur le dashboard</Button>
              </a>
            </div>
          </div>
        </div>
      </SiteWrapper>
    </>
  );
}

export default WaypointsPage;
