import React, { useEffect, useState } from 'react';
import PickingDetailHeader from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/PickingDetailHeader';
import PickingList from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/PickingList';
import { IconButton } from '@mui/material';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';

import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOrderLoading,
  selectOrdersProductionWorkstation,
} from 'app/containers/SiteWrapper/selectors';
import { getPartOrder } from 'app/containers/SiteWrapper/slice';

import { CircleSpinner } from 'react-spinners-kit';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import OrderDetailsContent from 'app/containers/OrderDetails/OrderDetailsContent';
import PickedList from './PickedList';
import { selectProductionPiecesFactory } from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/selectors';
import { ListType } from 'app/pages/ProductionPage/constants';
import SiteWrapper from 'app/containers/SiteWrapper';
import {
  ProductionStatus,
  Workstation,
} from 'app/containers/OrderDetails/types';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';

const BasePickingDetailsPage = props => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const orderId = props.match.params.orderId;
  const workstation: Workstation = parseInt(props.match.params.workstation);
  let suffix = '';
  if (workstation === Workstation.Cabinet) suffix = ' de base';
  else if (workstation === Workstation.Accessory) suffix = ' accessoires';

  const orders = useSelector(
    selectOrdersProductionWorkstation(workstation, ProductionStatus.NotStarted),
  );
  const partOrder = useSelector(selectCurrentPartOrder());
  const loading: boolean = useSelector(selectOrderLoading);
  const [displayDetails, setDisplayDetails] = useState<boolean>(false);

  const productionPicked = useSelector(
    selectProductionPiecesFactory(workstation, ListType.picked),
  );

  useEffect(() => {
    dispatch(getPartOrder(parseInt(orderId)));
  }, [orderId]);

  return (
    <SiteWrapper
      title={`Picking${suffix} - ${orderId}`}
      leftTopBarButton={
        <Link to={`/production/picking/${workstation}`}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <FiArrowLeft className="w-6 h-6" />
          </IconButton>
        </Link>
      }
    >
      {loading || orders === undefined ? (
        <div className="flex justify-center items-center w-full h-screen">
          <CircleSpinner size={60} color="#4a5568" />
        </div>
      ) : (
        <ProductionContainer>
          {partOrder ? (
            <>
              {displayDetails ? (
                <OrderDetailsContent
                  partOrder={partOrder}
                  loading={loading}
                  displayOnly={true}
                  close={() => {
                    setDisplayDetails(false);
                  }}
                />
              ) : (
                <div className="flex flex-col">
                  <PickingDetailHeader
                    orders={orders}
                    order={partOrder}
                    setDisplayDetails={setDisplayDetails}
                  />
                  <PickingList workstation={workstation} />
                  {productionPicked && productionPicked.length > 0 && (
                    <PickedList production={productionPicked} />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="text-xl mx-2">
              La commande {orderId} n'est pas disponible ou n'existe pas !
            </div>
          )}
        </ProductionContainer>
      )}
    </SiteWrapper>
  );
};

export default BasePickingDetailsPage;
