import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from 'app/constants';
import { requestGet } from 'utils/requests';
import { Event } from 'app/models/Feed';
import Timeline from 'app/components/Timeline';
import { selectCurrentPartOrderId } from 'store/slices/activeContentSlice';

interface Props {
  loading: boolean;
}

const HistoryTab = ({ loading }: Props) => {
  const partOrderId = useSelector(selectCurrentPartOrderId());
  const [eventsStream, setEventsStream] = useState<Event[] | undefined>(
    undefined,
  );

  useEffect(() => {
    refreshFeed();
  }, [partOrderId]);

  const refreshFeed = () => {
    const url = `${API_BASE_URL}/feed/part-order/${partOrderId}`;
    requestGet(url).then((eventsStream: Event[]) => {
      setEventsStream(eventsStream);
    });
  };

  return (
    <div className="overflow-y-auto h-full">
      <div className="flex justify-center">
        {eventsStream && (
          <div className="flex">
            <Timeline events={eventsStream} />
          </div>
        )}
      </div>
      <a
        href={`${API_BASE_URL}/feed/part-order/${partOrderId}`}
        className="italic text-sm underline mx-4"
      >
        Voir l'api
      </a>
    </div>
  );
};

export default HistoryTab;
