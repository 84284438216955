/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import * as React from 'react';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';
import './assets/css/tailwind.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

// Initialize languages
import './locales/i18n';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import * as Sentry from '@sentry/react';

// Create redux store with history
export const store = configureAppStore();

setupListeners(store.dispatch);

export type AppRootState = ReturnType<typeof store.getState>;
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

if (
  import.meta.env.NODE_ENV === 'production' &&
  import.meta.env.VITE_SENTRY_URL
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_URL,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <BrowserRouter>
          <HelmetProvider>
            <Component />
          </HelmetProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ErrorBoundary>
  </Provider>
);

const root = createRoot(MOUNT_NODE);

root.render(<ConnectedApp Component={App} />);
