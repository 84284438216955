import React from 'react';
import { ProductionPiece } from 'app/models/ProductionPiece';
import CuttingPageHeader from 'app/containers/Cutting/CuttingPageHeader';

import styled from 'styled-components';
import { Color } from 'app/models/Color';
import { CuttingLine } from 'app/containers/Cutting/CuttingLine';
import { PartOrder } from 'app/models/PartOrder';
import { workstationDescription } from 'app/containers/OrderDetails/helpers';

interface Props {
  order: PartOrder;
  trolleyName: string;
  color: Color;
  colorBandSize: number | string | undefined;
  pieces: ProductionPiece[];
}
const CuttingPage = ({
  order,
  trolleyName,
  color,
  colorBandSize,
  pieces,
}: Props) => {
  if (pieces.length === 0) return null;

  const cabinetPieces = pieces.filter(p => !p.isSparePartInsideCabinetPackage);
  const sparePieces = pieces.filter(p => p.isSparePartInsideCabinetPackage);
  const workstation = pieces[0].station;

  return (
    <div className="page page-break">
      <ColorHeaderStyled
        className="flex items-center justify-center w-full mb-6"
        color={color.hexaHtml}
      >
        <div
          className="bg-white px-8 py-0 leading-3 text-lg"
          style={{ margin: '-4px' }}
        >
          {`${color.material} ${color.name}`.trim()}
          {colorBandSize !== undefined && (
            <span className="font-bold">
              {` – ${
                typeof colorBandSize === 'number'
                  ? `${colorBandSize} mm`
                  : colorBandSize
              }`}
            </span>
          )}
        </div>
      </ColorHeaderStyled>
      <CuttingPageHeader order={order} workstation={workstation} />
      {workstationDescription(workstation) !== 'Tiroirs' && (
        <div className="flex mt-3 mb-1 justify-center items-center">
          <div className="text-xl px-2 border-gray-800 border-double border-2">
            Chariot {trolleyName}
          </div>
        </div>
      )}
      <div className="flex flex-col">
        {cabinetPieces.map(piece => (
          <CuttingLine piece={piece} />
        ))}
        {sparePieces.length > 0 && (
          <div className="flex mt-4 -mb-1">
            <div className="text-xl font-bold">
              Pièces supplémentaires au meuble du chariot
            </div>
          </div>
        )}
        {sparePieces.map(piece => (
          <CuttingLine piece={piece} />
        ))}
      </div>
    </div>
  );
};

const ColorHeaderStyled = styled.div<{
  color: string;
}>`
  background-color: ${p => p.color};
`;

export default CuttingPage;
