/**
 *
 * BasePickingPage
 *
 */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { IconButton, InputBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useDispatch } from 'react-redux';
import { actions } from './../slice';
import { Link } from 'react-router-dom';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import {
  ProductionStatus,
  Workstation,
} from 'app/containers/OrderDetails/types';
import ListOrders from 'app/pages/ProductionPage/BasePickingListPage/ListOrders';
import TabsProductionStatus from 'app/components/TabProductionStatus';

export const BasePickingListPage = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [productionStatusFilter, setProductionStatusFilter] = useState<
    ProductionStatus
  >(ProductionStatus.NotStarted);

  const workstation: Workstation = parseInt(props.match.params.workstation);
  let suffix = '';

  if (workstation === Workstation.Cabinet) suffix = ' de base';
  else if (workstation === Workstation.Accessory) suffix = ' accessoires';

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setProductionStatusFilter(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Picking</title>
        <meta name="description" content="Liste des commandes" />
      </Helmet>
      <div className={classes.root}>
        <SiteWrapper
          title={`Picking${suffix}`}
          leftTopBarButton={
            <Link to="/production">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(actions.setProductionMode(undefined))}
              >
                <MenuIcon />
              </IconButton>
            </Link>
          }
          rightTopBarButton={
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          }
        >
          <ProductionContainer>
            <TabsProductionStatus
              productionStatus={productionStatusFilter}
              handleTabChange={handleTabChange}
            />

            <ListOrders
              workstation={workstation}
              productionStatus={productionStatusFilter}
            />
          </ProductionContainer>
        </SiteWrapper>
      </div>
    </>
  );
};
