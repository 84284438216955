import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from 'app/containers/TourMap/types';
import { PartOrder } from 'app/models/PartOrder';

// The initial state of the TourMap container
export const initialState: ContainerState = {
  selectedOrder: null,
};

const tourMapSlice = createSlice({
  name: 'tourMap',
  initialState,
  reducers: {
    selectOrderAction: (state, action: PayloadAction<PartOrder>) => {
      state.selectedOrder = action.payload;
    },
    resetSelectionOrderAction: state => {
      state.selectedOrder = null;
    },
  },
});

export const {
  selectOrderAction,
  resetSelectionOrderAction,
} = tourMapSlice.actions;

export const { actions, reducer, name: sliceKey } = tourMapSlice;
