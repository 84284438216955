import React, { useEffect } from 'react';
import SiteWrapper from 'app/containers/SiteWrapper';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import FormInputErrorStyled from 'app/components/styles/FormInputErrorStyled';
import {
  useGetCarrierConfigurationQuery,
  useUpdateCarrierConfigurationMutation,
} from 'services/kewloxApi';
import { TextField } from '@mui/material';
import LoadingSpinner from 'app/containers/OrderDetails/LoadingSpinner';
import { FiSave } from 'react-icons/all';
import { LoadingButton } from '@mui/lab';

const CarrierConfigurationPage = () => {
  const {
    data: carrierConfiguration,
    isLoading: isLoadingCarrierConf,
  } = useGetCarrierConfigurationQuery(1);

  useEffect(() => {
    console.log('carrierConfiguration', carrierConfiguration);
    console.log('isLoadingCarrierConf', isLoadingCarrierConf);
  }, [carrierConfiguration, isLoadingCarrierConf]);

  const [
    updateCarrierConfiguration,
    { isLoading: isUpdating },
  ] = useUpdateCarrierConfigurationMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fuelFee: carrierConfiguration
        ? `${(carrierConfiguration.data.fuelFee * 100).toFixed(2)}`
        : '',
      kewloxFee: carrierConfiguration
        ? `${carrierConfiguration.data.kewloxFee}`
        : '',
      meetingFee: carrierConfiguration
        ? `${carrierConfiguration.data.meetingFee}`
        : '',
      seasonTax: carrierConfiguration
        ? `${(carrierConfiguration.data.seasonTax * 100).toFixed(2)}`
        : '',
      wrappingUpFee: carrierConfiguration
        ? `${carrierConfiguration.data.wrappingUpFee}`
        : '',
      urbanDeliveryFee: carrierConfiguration
        ? `${carrierConfiguration.data.urbanDeliveryFee}`
        : '',
      knAdministrativeFee: carrierConfiguration
        ? `${carrierConfiguration.data.knAdministrativeFee}`
        : '',
    },
    validationSchema: Yup.object({
      fuelFee: Yup.number(),
      kewloxFee: Yup.number(),
      meetingFee: Yup.number(),
      seasonTax: Yup.number(),
      wrappingUpFee: Yup.number(),
      urbanDeliveryFee: Yup.number(),
      knAdministrativeFee: Yup.number(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateCarrierConfiguration({
        id: 1,
        data: {
          fuelFee: parseFloat(values.fuelFee) / 100,
          kewloxFee: parseFloat(values.kewloxFee),
          meetingFee: parseFloat(values.meetingFee),
          seasonTax: parseFloat(values.seasonTax) / 100,
          wrappingUpFee: parseFloat(values.wrappingUpFee),
          urbanDeliveryFee: parseFloat(values.urbanDeliveryFee),
          knAdministrativeFee: parseFloat(values.knAdministrativeFee),
        },
      })
        .unwrap()
        .then(() => {
          toast.success('Les données ont été mises à jours');
        })
        .catch(() => {
          toast.error(`Les données n'ont pas pu être mises à jours`);
        });
    },
  });

  const textFieldStyle = {
    width: 300,
    margin: 1,
  };

  return (
    <>
      <Helmet>
        <title>Kuehne Nagel - Configuration</title>
        <meta name="description" content="Configuration du transporteur" />
      </Helmet>

      <SiteWrapper title="Configuration du transporteur">
        <div className="mx-auto bg-white p-8">
          <h1 className="text-4xl my-2">Kuehne Nagel</h1>
          <h4 className="text-xl">Configuration des prix</h4>
          {isUpdating && (
            <div className="italic my-8">
              Nous mettons à jours les nouveaux prix pour ce transporteur, cela
              peut prendre quelques minutes
            </div>
          )}
          {isLoadingCarrierConf ? (
            <div className="flex justify-center items-center my-8">
              <LoadingSpinner size={40} />
            </div>
          ) : (
            <div>
              {carrierConfiguration && (
                <form onSubmit={formik.handleSubmit}>
                  <div className="my-1">
                    <TextField
                      id="wrappingUpFee"
                      label="Frais d'embalage"
                      variant="outlined"
                      {...formik.getFieldProps('wrappingUpFee')}
                      error={
                        formik.touched.wrappingUpFee !== undefined &&
                        formik.errors.wrappingUpFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.wrappingUpFee &&
                    formik.errors.wrappingUpFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.wrappingUpFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="kewloxFee"
                      label="Marge Kewlox"
                      variant="outlined"
                      {...formik.getFieldProps('kewloxFee')}
                      error={
                        formik.touched.kewloxFee !== undefined &&
                        formik.errors.kewloxFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.kewloxFee && formik.errors.kewloxFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.kewloxFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="meetingFee"
                      label="Frais de rendez-vous"
                      variant="outlined"
                      {...formik.getFieldProps('meetingFee')}
                      error={
                        formik.touched.meetingFee !== undefined &&
                        formik.errors.meetingFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.meetingFee && formik.errors.meetingFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.meetingFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="urbanDeliveryFee"
                      label="Taxe urbaine"
                      variant="outlined"
                      {...formik.getFieldProps('urbanDeliveryFee')}
                      error={
                        formik.touched.urbanDeliveryFee !== undefined &&
                        formik.errors.urbanDeliveryFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.urbanDeliveryFee &&
                    formik.errors.urbanDeliveryFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.urbanDeliveryFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="knAdministrativeFee"
                      label="Frais administratif"
                      variant="outlined"
                      {...formik.getFieldProps('knAdministrativeFee')}
                      error={
                        formik.touched.knAdministrativeFee !== undefined &&
                        formik.errors.knAdministrativeFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.knAdministrativeFee &&
                    formik.errors.knAdministrativeFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.knAdministrativeFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="fuelFee"
                      label="Supplément essence (en %)"
                      variant="outlined"
                      {...formik.getFieldProps('fuelFee')}
                      error={
                        formik.touched.fuelFee !== undefined &&
                        formik.errors.fuelFee !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.fuelFee && formik.errors.fuelFee ? (
                      <FormInputErrorStyled>
                        {formik.errors.fuelFee}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <TextField
                      id="seasonTax"
                      label="Taxe saisonnière (du 1er mai au 31 août)  (en %)"
                      variant="outlined"
                      {...formik.getFieldProps('seasonTax')}
                      error={
                        formik.touched.seasonTax !== undefined &&
                        formik.errors.seasonTax !== undefined
                      }
                      sx={textFieldStyle}
                    />
                    {formik.touched.seasonTax && formik.errors.seasonTax ? (
                      <FormInputErrorStyled>
                        {formik.errors.seasonTax}
                      </FormInputErrorStyled>
                    ) : null}
                  </div>

                  <LoadingButton
                    variant="contained"
                    loading={isLoadingCarrierConf || isUpdating}
                    loadingPosition="start"
                    startIcon={<FiSave />}
                    type="submit"
                  >
                    Sauver
                  </LoadingButton>
                </form>
              )}
            </div>
          )}
        </div>
      </SiteWrapper>
    </>
  );
};

export default CarrierConfigurationPage;
