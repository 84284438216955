import { API_BASE_URL } from 'app/constants';

export const TOUR_URL = `${API_BASE_URL}/tour/`;
export const ALL_ORDER_URL = `${API_BASE_URL}/all-orders/`;
export const DELIVERY_URL = `${API_BASE_URL}/order-tour/`;

export const STATUS_SHIPPING_NOTIFY = 13;
export const STATUS_PARTIAL_PROCESSING = 52;
export const STATUS_FINAL_PROCESSING = 38;

export const MAX_DIFFERENCE_DELIVERY_TIME_ALLOWED = 60;
