import React, { useMemo, useRef } from 'react';
import { Order } from 'app/models/Order';
import DestinationLabel from 'app/components/Kewlox/DestinationLabel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import DiffBusinessDay from '../../../../components/DiffBusinessDay';
import { PartOrder } from 'app/models/PartOrder';

// import styled from 'styled-components';

interface Props {
  orders: PartOrder[];
  order: PartOrder;
  setDisplayDetails: any;
}

const PickingDetailHeader = ({ order, orders, setDisplayDetails }: Props) => {
  const currentIndexRef = useRef<number | undefined>(undefined);

  useMemo(() => {
    currentIndexRef.current = orders.findIndex(e => e.id === order.id);
  }, [orders, order.id]);

  let prev: number | undefined = undefined;
  let next: number | undefined = undefined;

  const currentIndex = currentIndexRef.current;
  if (currentIndex !== undefined) {
    if (currentIndex > 0) prev = currentIndex - 1;
    if (currentIndex < orders.length - 1) next = currentIndex + 1;
  }

  return (
    <div>
      <div className="flex justify-between items-center my-2 py-3 border border-gray-200 shadow">
        <div className="flex h-full justify-center items-center">
          <NextOrderStyled
            role="button"
            to={prev !== undefined ? `${orders[prev].id}` : '#'}
            className="px-3"
            disabled={prev === undefined}
          >
            <FiChevronLeft className="h-6 w-6" />
          </NextOrderStyled>
        </div>
        <DestinationLabel destination={order.destination} />
        <div className="flex flex-col justify-center items-center">
          <div className="-ml-6">
            <DiffBusinessDay days={order.diffBusinessDays} alert={true} />
          </div>
          <button
            onClick={() => {
              setDisplayDetails(true);
            }}
            className="flex flex-col justify-center items-center"
          >
            <div className="text-base md:text-xl font-semibold">
              {order.customerName}
            </div>
            <div className="border py-0 px-3">Voir</div>
          </button>
        </div>
        <div className="flex h-full justify-center items-center">
          <NextOrderStyled
            role="button"
            to={next ? `${orders[next].id}` : '#'}
            className="px-3"
            disabled={next === undefined}
          >
            <FiChevronRight className="h-6 w-6" />
          </NextOrderStyled>
        </div>
      </div>
    </div>
  );
};

export default PickingDetailHeader;

const NextOrderStyled = styled(Link)<{ disabled: boolean }>`
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
