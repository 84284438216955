import React from 'react';
import { FaRegHandPaper } from 'react-icons/fa';
import { Draggable, Droppable } from 'react-beautiful-dnd';

// import styled from 'styled-components';

interface Props {
  orderId: number;
}

const DragOrderDetails = ({ orderId }: Props) => (
  <Droppable
    droppableId="order-details"
    renderClone={(provided, snapshot, rubric) => (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        {
          <div className="bg-white text-gray-800 text-xl my-2 mx-3 border-2 border-blue rounded w-24 flex justify-center items-center">
            {`${orderId}`}
          </div>
        }
      </div>
    )}
  >
    {(provided, snapshot) => (
      <div ref={provided.innerRef}>
        <Draggable draggableId={`drag-${orderId}`} index={0}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <FaRegHandPaper className="h-6 w-6 cursor-move text-gray-800" />
            </div>
          )}
        </Draggable>
      </div>
    )}
  </Droppable>
);

export default DragOrderDetails;
