import React from 'react';
import { useSelector } from 'react-redux';
import { selectUnallocatedPartOrderByDestination } from 'app/containers/OrderList/selectors';
import DestinationTable from 'app/containers/OrderList/DestinationTable';
import { PartOrder } from 'app/models/PartOrder';

function OrderList() {
  const waitingPartOrdersByDestiations: PartOrder[][] = useSelector(
    selectUnallocatedPartOrderByDestination,
  );

  const totalWaitingPartOrders = waitingPartOrdersByDestiations.reduce(
    (acc, entry) => acc + entry.length,
    0,
  );

  const getTable = (entry: PartOrder[]) => {
    if (entry.length > 0 && entry[0].destination) {
      return (
        <DestinationTable
          key={entry[0].destination.id}
          destination={entry[0].destination}
          orders={entry}
        />
      );
    }
    return null;
  };
  return (
    <div>
      {totalWaitingPartOrders === 0 ? (
        <div className="text-lg mt-10">
          Il n'y a pas de commandes en attente.
        </div>
      ) : (
        <div className="flex justify-end -mb-10 mr-2">
          <div className="text-sm mt-4">
            <span className="font-semibold">{totalWaitingPartOrders}</span>{' '}
            commandes en attente.
          </div>
        </div>
      )}
      {waitingPartOrdersByDestiations
        .filter(a => a.length > 0)
        .map(entry => getTable(entry))}
    </div>
  );
}

export default OrderList;
