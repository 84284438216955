import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

declare module '@mui/system' {
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4a5568',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F3F2F1',
      contrastText: '#4a5568',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
