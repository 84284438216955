// Define endpoints related to deliveries
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Delivery } from 'app/models/Delivery';
import { providesList } from 'services/utils';
import { createPartOrder, PartOrder } from 'app/models/PartOrder';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import { addPartOrders, setPartOrders } from 'store/slices/partOrderSlice';

export const deliveryEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getDeliveries: builder.query<Delivery[], void>({
    query: () => `/order-tour/`,
    providesTags: result => providesList(result, 'Delivery'),
  }),
  getDelivery: builder.query<Delivery, number>({
    query: id => `/order-tour/${id}`,
    providesTags: (result, error, id) => [{ type: 'Delivery', id }],
  }),
  updateDelivery: builder.mutation<unknown, Partial<Delivery>>({
    query: ({ id, partOrder, ...body }) => ({
      url: `order-tour/${id}/`,
      method: 'PUT',
      body: body,
    }),
    invalidatesTags: (result, error, { id, partOrder }) => [
      { type: 'Delivery', id },
      { type: 'Tour', id: 'LIST' },
      { type: 'PartOrder', id: partOrder },
    ],
  }),
  deleteDelivery: builder.mutation<void, number>({
    query: id => ({
      url: `order-tour/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, id) => [
      { type: 'Delivery', id },
      { type: 'Delivery', id: 'LIST' },
      { type: 'Tour', id: 'LIST' },
      { type: 'PartOrder', id: 'LIST' },
    ],
  }),
  addDelivery: builder.mutation<Delivery, Partial<Delivery>>({
    query: body => ({
      url: `/order-tour/`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (result, error, body) => [
      { type: 'Delivery', id: 'LIST' },
      { type: 'Tour', id: 'LIST' },
      { type: 'PartOrder', id: 'LIST' },
    ],
  }),
  actionDelivery: builder.mutation<
    Delivery,
    { id: number; action: string; token: string }
  >({
    query: ({ id, action, token }) => ({
      url: `order-tour/${action}/`,
      method: 'POST',
      body: { token: token },
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Delivery', id }],
  }),
  notifyDeliveryCustomer: builder.mutation<void, number>({
    query: deliveryId => ({
      url: `order-tour/${deliveryId}/notify`,
      method: 'GET',
    }),
    invalidatesTags: (result, error, deliveryId) => [
      { type: 'Delivery', deliveryId },
    ],
  }),
  confirmDelivery: builder.mutation<void, number>({
    query: deliveryId => ({
      url: `order-tour/${deliveryId}/`,
      method: 'PUT',
      body: { confirmed: true },
    }),
    invalidatesTags: (result, error, deliveryId) => [
      { type: 'Delivery', id: 'LIST' },
      { type: 'Delivery', deliveryId },
    ],
  }),
  getDeliveriesForTours: builder.query<
    { deliveries: Delivery[]; partOrders: PartOrder[] },
    number[]
  >({
    query: tourIds => {
      const ids = tourIds.join(',');
      return `/order-tour/tours/?ids=${ids}`;
    },
    async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
      // We integrate the transformation logic within the RTK Query lifecycle methods to avoid using selectors directly in reducers.
      try {
        const { data } = await queryFulfilled;
        const state = getState();
        // @ts-ignore
        const destinations = selectAllDestinations(state);
        // @ts-ignore
        const carriers = selectAllCarriers(state);
        // @ts-ignore
        const colors = selectAllColors(state);

        if (data.partOrders !== undefined) {
          const transformedPartOrders = data.partOrders.map(e =>
            createPartOrder(e, destinations, carriers, colors),
          );
          dispatch(addPartOrders(transformedPartOrders));
        }
      } catch (error) {
        console.error('Error fetching part orders:', error);
      }
    },
    // providesTags: result => providesList(result.deliveries, 'Delivery'),
  }),
});
