import * as Sentry from '@sentry/browser';
import React, { ErrorInfo } from 'react';
import CrashMessage from './CrashMessage';

interface State {
  eventId: any;
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  state: State = {
    eventId: null,
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <div className="flex flex-col justify-center items-center w-screen h-screen">
          <CrashMessage />

          {/*<button
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report
          </button>*/}
        </div>
      );
    }
    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
