import { call, put, select, takeLatest } from 'redux-saga/effects';
import { actions, searchingOrder } from './slice';
import { setOrder } from '../SiteWrapper/slice';
import { requestError } from 'app/containers/ToursList/slice';
import { PartOrder } from 'app/models/PartOrder';
import { selectAllPartOrders } from 'store/slices/partOrderSlice';
import {
  setActiveSearch,
  setCurrentPartOrderId,
} from 'store/slices/activeContentSlice';
import { selectOrderAction } from 'app/containers/TourMap/slice';
import { kewloxApi } from 'services/kewloxApi';
import { store } from 'index';
import { selectDeliveryFromPartOrderId } from 'store/slices/deliverySlice';

export function* searchOrder(action) {
  if (action.payload === '') {
    yield put(setOrder(undefined));
    yield put(setCurrentPartOrderId(undefined));
    yield put(setActiveSearch(undefined));
    yield put(searchingOrder(false));
    return;
  }

  const searchIdOrder: number = parseInt(action.payload);

  yield put(searchingOrder(true));
  if (searchIdOrder > 99999) {
    const partOrders = yield select(selectAllPartOrders);
    const matchingPartOrders: PartOrder[] | undefined = partOrders.filter(
      entry => entry.order.id === searchIdOrder,
    );
    if (matchingPartOrders && matchingPartOrders.length === 1) {
      let tours: number[] | undefined = undefined;
      const delivery = yield select(
        selectDeliveryFromPartOrderId(matchingPartOrders[0].id),
      );

      if (delivery) tours = [delivery.tour];

      yield put(selectOrderAction(matchingPartOrders[0]));
      yield put(setCurrentPartOrderId(matchingPartOrders[0].id));
      yield put(
        setActiveSearch({
          tourIds: tours && tours.length > 0 ? tours : undefined,
          partOrderIds: [matchingPartOrders[0].id],
        }),
      );
      yield put(searchingOrder(false));
    } else {
      try {
        yield call(
          store.dispatch,
          kewloxApi.endpoints.searchOrder.initiate(searchIdOrder),
        );
      } catch (error) {
        if (error && error.status === 404) {
          yield put(setOrder(undefined));
          yield put(setCurrentPartOrderId(undefined));
          yield put(setActiveSearch(undefined));
        }
        yield put(requestError(error));
      }
    }
  } else {
    yield put(setOrder(undefined));
    yield put(setCurrentPartOrderId(undefined));
    yield put(setActiveSearch(undefined));
    yield put(searchingOrder(false));
  }
}

export function* searchOrderSaga() {
  yield takeLatest(actions.updateSearchField.type, searchOrder);
}
