import React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSelector } from 'react-redux';
import { AppRootState } from 'index';
import { selectWaypointById } from 'store/slices/waypointsSlice';
import { Waypoint } from 'app/pages/WaypointsPage/types';

interface Props {
  waypointId: number;
  waypointNote?: string;
  index: number;
}

const WaypointTourStopInfos = ({ waypointId, waypointNote, index }: Props) => {
  const waypoint: Waypoint | undefined = useSelector((state: AppRootState) =>
    selectWaypointById(state, waypointId),
  );

  if (!waypoint) return null;

  return (
    <div key={`${waypointId}`} className="page-section">
      <div className="flex items-baseline justify-between w-full">
        <div>
          <span className="page-section-order-n">{index + 1}. </span>
          <span className="page-section-order-id">
            {waypoint.name.toUpperCase()}
          </span>{' '}
          <span className="italic">(Point de passage)</span>
        </div>
      </div>
      <div>Adresse : {waypoint.address}</div>
      {waypoint.description && <div>Description: {waypoint.description}</div>}
      {waypoint.phoneNumber && waypoint.phoneNumber !== '' && (
        <div>Tél. : {`${waypoint.phoneNumber}`}</div>
      )}
      {waypointNote && <div>Message : {waypointNote}</div>}
    </div>
  );
};

export default WaypointTourStopInfos;
