import React from 'react';
import {
  Table,
  TableBody,
  Tab,
  TableContainer,
  TableRow,
  TableCell,
} from '@mui/material';
import { PartOrder } from 'app/models/PartOrder';
import { format, parseISO } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Paper } from '@mui/material';
import InfoLocation from 'app/pages/ToursPage/InfoLocation';

// import styled from 'styled-components';

interface Props {
  partOrder: PartOrder;
}

const InformationsOrder = ({ partOrder }: Props) => {
  const orderInfo = partOrder.orderInfo;

  let language = 'Anglais';
  if (orderInfo.idLang === 7) {
    language = 'Néerlandais';
  } else if (orderInfo.idLang === 5) {
    language = 'Français';
  } else if (orderInfo.idLang === 8) {
    language = 'Allemand';
  }

  return (
    <div>
      <div className="text-xl mb-2">Informations</div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Order informations">
          <TableBody>
            {orderInfo.weight !== undefined && (
              <TableRow>
                <TableCell align="left">Poids de la commande</TableCell>
                <TableCell align="left">
                  {orderInfo.weight.toFixed(2)} kg
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="left">Date de la facture</TableCell>
              <TableCell align="left">
                {format(parseISO(orderInfo.invoiceDate), 'dd/MM/yyyy', {
                  locale: frLocale,
                })}
              </TableCell>
            </TableRow>
            {partOrder.destination.home && (
              <TableRow>
                <TableCell align="left">Province / pays</TableCell>
                <TableCell align="left">
                  <div className="font-semibold">
                    <InfoLocation
                      province={orderInfo.province}
                      isoCode={orderInfo.isoCode}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="left">Langue de la commande</TableCell>
              <TableCell align="left">
                <span className="">{language}</span>
              </TableCell>
            </TableRow>
            {orderInfo.phoneMobile && (
              <TableRow>
                <TableCell align="left">Téléphone mobile</TableCell>
                <TableCell align="left">
                  <span className="font-semibold">
                    <a href={`tel:${orderInfo.phoneMobile}`}>
                      {orderInfo.phoneMobile}
                    </a>
                  </span>
                </TableCell>
              </TableRow>
            )}
            {orderInfo.phone && (
              <TableRow>
                <TableCell align="left">Téléphone</TableCell>
                <TableCell align="left">
                  <span className="font-semibold">
                    <a href={`tel:${orderInfo.phone}`}>{orderInfo.phone}</a>
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InformationsOrder;
