import { RootStateKeyType } from '../types/injector-typings';
import {
  createSlice as createSliceOriginal,
  SliceCaseReducers,
  CreateSliceOptions,
} from '@reduxjs/toolkit';

/* Wrap createClice with stricter Name options */

/* istanbul ignore next */
export const createSlice = function <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends RootStateKeyType
>(options: CreateSliceOptions<State, CaseReducers, Name>) {
  return createSliceOriginal(options);
};
