import { Carrier } from 'app/pages/ToursPage/types';
import { compareDesc, isToday, parseISO, subDays } from 'date-fns';
import { PartOrder } from 'app/models/PartOrder';
import { Delivery } from 'app/models/Delivery';
import { Driver } from 'app/pages/DriverPage/types';
import { createDestination, Destination } from 'app/models/Destination';

export interface TourJson {
  id: number;
  date: string;
  orders: any;
  distance?: number;
  manual?: boolean;
  returnDuration?: number;
  tourDuration?: number;
  finishTime?: string;
  carrier?: Carrier;
  destination?: any;
  workforce: number;
  workload: number;
  drivers: Driver[];
  totalWeight: number;
  note?: string;
  delivered: boolean;
}

export interface Tour extends TourJson {
  deliveries: number[];
  destination?: Destination;
  isOldTour: boolean;
  direct: boolean;
  isToday: boolean;
  color: string;
  textColor: string;
  isHomeTour: boolean;
  isKewlox: boolean;
  isBpost: boolean;
  isTourWithClientsPage: boolean;
  shouldPrintTourList: boolean;
  shouldPrintPackageSheetList: boolean;
  canManuallyUpdateDeliveriesStatus: boolean;
  isReseller: boolean;
  title: string;
  note?: string;
  // partOrdersList: PartOrder[];
}

export const createTour = (data: TourJson): Tour => {
  const deliveries = data.orders
    .map(delivery => {
      return delivery.id;
    })
    .sort((a, b) => a.position - b.position);

  const tourDestination = data.destination
    ? createDestination(data.destination)
    : undefined;

  return {
    ...data,
    orders: data.orders.sort((a, b) => a.position - b.position),
    deliveries: deliveries,
    destination: tourDestination,
    isOldTour: isOldTour(data),
    direct: direct(data),
    isToday: isToday(parseISO(data.date)),
    color: color(data),
    textColor: textColor(data),
    isHomeTour: isHomeTour(tourDestination),
    isKewlox: isKewlox(data),
    isBpost: isBpost(data),
    isTourWithClientsPage: isTourWithClientsPage(data),
    shouldPrintTourList: shouldPrintTourList(data),
    shouldPrintPackageSheetList: shouldPrintPackageSheetList(data),
    canManuallyUpdateDeliveriesStatus: canManuallyUpdateDeliveriesStatus(
      data,
      deliveries,
    ),
    isReseller: isReseller(data),
    title: title(data),
    note: data.note,
    // partOrdersList: partOrdersList(orders),
  };
};

const isOldTour = (data: TourJson): boolean => {
  const tourDate = parseISO(data.date);
  return compareDesc(subDays(new Date(), 1), tourDate) === -1;
};

const direct = (data: TourJson): boolean => {
  return data.destination?.name === 'Direct';
};

const color = (data: TourJson): string => {
  if (data.destination) {
    if (data.destination.shop) {
      return data.destination.color;
    }
    if (data.carrier) {
      return data.carrier.color;
    }
    return data.destination.color;
  }
  if (data.carrier) {
    return data.carrier.color;
  }
  return '#a99090';
};

const textColor = (data: TourJson): string => {
  if (data.destination) {
    if (data.destination.shop) {
      return data.destination.textColor;
    }
    if (data.carrier) {
      return data.carrier.textColor;
    }
    return data.destination.textColor;
  }
  if (data.carrier) {
    return data.carrier.textColor;
  }
  return '#a99090';
};

const isHomeTour = (tourDestination?: Destination): boolean => {
  return tourDestination === undefined || tourDestination.home;
};

const isKewlox = (data: TourJson): boolean => {
  return (
    data.carrier?.name === 'KEWLOX' || data.carrier?.name === 'ALAIN VAN EYKEN'
  );
};

const isBpost = (data: TourJson): boolean => {
  return data.carrier?.name === 'BPOST';
};

const isTourWithClientsPage = (data: TourJson): boolean => {
  return (
    data.carrier?.name === 'BRENGER' ||
    data.carrier?.name === 'GARSOU-ANGENOT' ||
    data.carrier?.name === 'ZIEGLER'
  );
};

const shouldPrintTourList = (data: TourJson): boolean => {
  return (
    !direct(data) &&
    (isKewlox(data) ||
      data.carrier?.name === 'ALAIN VAN EYKEN' ||
      data.carrier?.name === 'VDB')
  );
};

const shouldPrintPackageSheetList = (data: TourJson): boolean => {
  return (
    (!shouldPrintTourList(data) || data.carrier?.name === 'VDB') &&
    !isBpost(data) &&
    !direct(data)
  );
};

const canManuallyUpdateDeliveriesStatus = (
  data: TourJson,
  deliveries: Delivery[],
): boolean => {
  return (
    deliveries.length > 0 &&
    (isReseller(data) || !(data.destination?.shop === true)) &&
    data.carrier?.name !== 'BPOST' &&
    !direct(data)
  );
};

const isReseller = (data: TourJson): boolean => {
  return data.destination?.name === 'Köln';
};

const title = (data: TourJson): string => {
  if (data.destination?.shop) {
    return data.destination.name;
  }
  return data.carrier?.name ?? 'NO CARRIER';
};

const ordersCustomer = (deliveries: Delivery[]): Delivery[] => {
  return deliveries.filter(o => o.order !== undefined);
};

const waypointsDeliveries = (orders: Delivery[]): Delivery[] => {
  return orders.filter(o => o.waypoint !== undefined);
};

const partOrdersList = (orders: Delivery[]): PartOrder[] => {
  return ordersCustomer(orders).map(delivery => delivery.order!);
};

// export class Tour {
//   id: number;
//   date: string;
//   orders: Delivery[];
//   distance?: number;
//   manual?: boolean;
//   returnDuration?: number;
//   tourDuration?: number;
//   finishTime?: string;
//   carrier?: Carrier;
//   destination?: Destination;
//   workforce: number;
//   workload: number;
//   drivers: Driver[];
//   totalWeight: number;
//
//   constructor(data: TourJson, ordersList: PartOrder[]) {
//     this.id = data.id;
//     this.date = data.date;
//     this.workforce = data.workforce;
//     this.workload = data.workload;
//     this.drivers = data.drivers;
//     if (data.distance) this.distance = data.distance;
//     if (data.manual) this.manual = data.manual;
//     if (data.returnDuration) this.returnDuration = data.returnDuration;
//     if (data.tourDuration) this.tourDuration = data.tourDuration;
//     if (data.finishTime) this.finishTime = data.finishTime;
//     if (data.carrier) this.carrier = data.carrier;
//     if (data.destination)
//       this.destination = createDestination(data.destination);
//     this.totalWeight = data.totalWeight;
//
//     this.orders = [];
//     for (const orderTourJson of data.orders) {
//       const partOrder = ordersList.find(o => o.id === orderTourJson.partOrder);
//       if (partOrder) {
//         this.orders.push(new Delivery(orderTourJson, partOrder));
//       } else if (orderTourJson.waypoint) {
//         this.orders.push(new Delivery(orderTourJson, undefined));
//       } else {
//         // continue;
//         // throw new Error(
//         //   `PartOrder should exist ${orderTourJson.partOrder} for tour ${this.id}`,
//         // );
//       }
//     }
//     this.orders = this.orders.sort((a, b) => a.position - b.position);
//   }
//
//   get isOldTour() {
//     if (this.direct) return false;
//     const tourDate = parseISO(this.date);
//     return compareDesc(subDays(new Date(), 1), tourDate) === -1;
//   }
//
//   get direct() {
//     return !!this.destination?.direct;
//   }
//
//   get isToday() {
//     const tourDate = parseISO(this.date);
//     return isToday(tourDate);
//   }
//
//   get color() {
//     if (this.destination) {
//       if (this.destination.shop) {
//         return this.destination.color;
//       }
//       if (this.carrier) {
//         return this.carrier.color;
//       }
//       return this.destination.color;
//     }
//     if (this.carrier) {
//       return this.carrier.color;
//     }
//     return '#a99090';
//   }
//
//   get textColor() {
//     if (this.destination) {
//       if (this.destination.shop) {
//         return this.destination.textColor;
//       }
//       if (this.carrier) {
//         return this.carrier.textColor;
//       }
//       return this.destination.textColor;
//     }
//     if (this.carrier) {
//       return this.carrier.textColor;
//     }
//     return '#a99090';
//   }
//
//   get isHomeTour(): boolean {
//     return this.destination === undefined || this.destination.home;
//   }
//
//   get isKewlox(): boolean {
//     return (
//       this.carrier?.name === 'KEWLOX' ||
//       this.carrier?.name === 'ALAIN VAN EYKEN'
//     );
//   }
//
//   get isBpost(): boolean {
//     return this.carrier?.name === 'BPOST';
//   }
//
//   get isTourWithClientsPage(): boolean {
//     return (
//       this.carrier?.name === 'BRENGER' ||
//       this.carrier?.name === 'GARSOU-ANGENOT' ||
//       this.carrier?.name === 'ZIEGLER'
//     );
//   }
//
//   get shouldPrintTourList(): boolean {
//     return (
//       !this.direct &&
//       (this.isKewlox ||
//         this.carrier?.name === 'ALAIN VAN EYKEN' ||
//         this.carrier?.name === 'VDB')
//     );
//   }
//
//   get shouldPrintPackageSheetList(): boolean {
//     return (
//       (!this.shouldPrintTourList || this.carrier?.name === 'VDB') &&
//       !this.isBpost &&
//       !this.direct
//     );
//   }
//
//   get canManuallyUpdateDeliveriesStatus(): boolean {
//     return (
//       this.orders.length > 0 &&
//       (this.isReseller || !(this.destination?.shop === true)) &&
//       this.carrier?.name !== 'BPOST'
//     );
//   }
//
//   get isReseller(): boolean {
//     return this.destination?.name === 'Köln';
//   }
//
//   get title(): string {
//     if (this.destination?.shop) {
//       return this.destination.name;
//     }
//
//     // @ts-ignore
//     return this.carrier?.name;
//   }
//
//   clone(): Tour {
//     return cloneDeep(this);
//   }
//
//   get ordersCustomer(): Delivery[] {
//     return this.orders.filter(o => o.order !== undefined);
//   }
//
//   get waypointsDeliveries(): Delivery[] {
//     return this.orders.filter(o => o.waypoint !== undefined);
//   }
//
//   get partOrdersList(): PartOrder[] {
//     // @ts-ignore
//     return this.ordersCustomer.map(delivery => delivery.order);
//   }
// }
