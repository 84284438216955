import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import { useSelector } from 'react-redux';
import DestinationLabel from 'app/components/Kewlox/DestinationLabel';
import { Destination } from 'app/models/Destination';
import { selectAllDestinations } from 'store/slices/destinationsSlice';

interface Props {
  handleOnChange: any;
  selectedDestination?: Destination;
}

const DestinationsSelector = ({
  handleOnChange,
  selectedDestination,
}: Props) => {
  const classes = useStyles();

  const destinations = useSelector(selectAllDestinations);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="destination-select-label">Destination</InputLabel>
      <Select
        labelId="destination-select-label"
        label="Destination"
        id="destination-select"
        onChange={handleOnChange}
        value={selectedDestination ? selectedDestination.id : ''}
      >
        <MenuItem value="">Toutes</MenuItem>
        {destinations
          .filter(
            d =>
              !(
                (d.home && d.name !== '@Home') ||
                (d.name.startsWith('Köln') && d.name !== 'Köln')
              ),
          )
          .sort((a, b) => a.sorting - b.sorting)
          .map(destination => (
            <MenuItem value={destination.id} key={destination.id}>
              <div className="flex items-center mx-1">
                <DestinationLabel destination={destination} short />
                <div className="ml-2">{destination.name}</div>
              </div>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DestinationsSelector;
