import React from 'react';
import { FaHammer } from 'react-icons/fa';
import 'assets/css/print.css';
import { format, isValid, parseISO } from 'date-fns';
import { PartOrder } from 'app/models/PartOrder';
import { BoxWithPackages } from './BoxWithPackages';
import CarrierDestinationLabel from '../../../../components/Kewlox/CarrierDestinationLabel';
import _ from 'lodash';

interface Props {
  partOrder: PartOrder;
  firstPage?: boolean;
}

export function OrderPage({ partOrder, firstPage = false }: Props) {
  const nbPackagesToDisplay = 20;

  return (
    <div className={`${!firstPage && 'page-break'} page relative flex`}>
      <div className="absolute bottom-label order-page-bottom">
        <div className="relative">
          {partOrder.assembly && (
            <div className="flex absolute bottom-prod-assembly bg-yellow">
              <FaHammer className="bottom-hammer text-red" />
            </div>
          )}
          <div
            className={`flex absolute bottom-prod-date${
              partOrder.assembly ? ' bottom-prod-date-a' : ''
            }`}
          >
            {partOrder.productionDate ? (
              <div>
                {format(parseISO(partOrder.productionDate), 'dd/MM/yyyy')}
              </div>
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <div
            className={`flex absolute bottom-id-customer${
              partOrder.assembly ? ' bottom-id-customer-a' : ''
            }`}
          >
            <div className="font-bold">{partOrder.identification}</div>
            <div className="ml-4">
              {partOrder.orderInfo.firstname}{' '}
              {partOrder.orderInfo.lastname.toUpperCase()}
            </div>
          </div>
          <BoxWithPackages order={partOrder} />
          <div className="absolute bottom-pname"></div>
          <div className="absolute bottom-datamatrix"></div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex items-center label-header justify-between w-full">
          <CarrierDestinationLabel
            carrier={partOrder.carrier}
            destination={partOrder.destination}
          />
          {partOrder.productionDate ? (
            <div className="order-page-date-top">
              {format(parseISO(partOrder.productionDate), 'dd/MM/yyyy')}
            </div>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div className="flex flex-col justify-start items-start order-page-content">
          <div className="flex justify-between w-full">
            <div>{partOrder.identification}</div>
            {false && !partOrder.orderInfo.paid && (
              <div className="text-2xl">NP</div>
            )}
          </div>
          <div>
            {partOrder.orderInfo.firstname}{' '}
            <b>{partOrder.orderInfo.lastname.toUpperCase()}</b>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start order-page-packages italic">
          {_.orderBy(partOrder.packages, ['sorting', 'name'])
            .slice(0, nbPackagesToDisplay)
            .map(pkg => (
              <div key={pkg.id}>
                -{' '}
                {_.truncate(pkg.uniqueDescr ?? pkg.name, {
                  length: 65,
                  omission: '…',
                })}
              </div>
            ))}
          {partOrder.packages?.length !== undefined &&
            partOrder.packages?.length > nbPackagesToDisplay && (
              <div>
                + {partOrder.packages?.length - nbPackagesToDisplay} packages
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
