import { PackageType, Workstation } from 'app/containers/OrderDetails/types';

export const workstationDescription = (workstation: Workstation) => {
  switch (workstation) {
    case Workstation.Cabinet:
      return 'Préparation';
    case Workstation.Corner:
      return 'Cornières';
    case Workstation.Accessory:
      return 'Accessoires';
    case Workstation.Drawer:
      return 'Tiroirs';
    case Workstation.Woodwork:
      return 'Menuiserie';
    case Workstation.WoodworkSpecial:
      return 'Menuiserie spéciale';
    default:
      return 'Autres';
  }
};

export const packageWorkstation = (workstation: Workstation) => {
  switch (workstation) {
    case Workstation.Cabinet:
    case Workstation.WoodworkSpecial:
      return [PackageType.Cabinet];
    case Workstation.Corner:
      return [PackageType.Corner];
    case Workstation.Accessory:
      return [
        PackageType.Accessories,
        PackageType.WheelPane,
        PackageType.Other,
        PackageType.UpperShelf,
        PackageType.Intermediate_shelf,
        PackageType.Intermediate_shelf_accessories,
      ];
    case Workstation.Drawer:
      return [PackageType.Drawer];
    case Workstation.Woodwork:
    case Workstation.Marketing:
      return [PackageType.Accessories];
  }

  throw new Error('The workstation is not found');
};

export const workstationPackage = (packageType: PackageType) => {
  switch (packageType) {
    case PackageType.Cabinet:
      return Workstation.Cabinet;
    case PackageType.Corner:
      return Workstation.Corner;
    case PackageType.Glass:
    case PackageType.Wardrobe:
    case PackageType.Intermediate_shelf:
    case PackageType.Intermediate_shelf_accessories:
    case PackageType.Accessories:
    case PackageType.WheelPane:
    case PackageType.Other:
    case PackageType.UpperShelf:
      return Workstation.Accessory;
    case PackageType.Drawer:
      return Workstation.Drawer;
  }

  throw new Error('The PackageType is not found');
};
