import React from 'react';
import Img from 'app/components/Img';
import Logo from 'assets/images/logo.png';
import QRCode from 'qrcode.react';
import { TourFurnitures } from 'app/containers/TourPrinter/TourPrinterButton';

export class FurniturePage extends React.Component<{
  furnitureInfo: TourFurnitures;
}> {
  render() {
    const { furnitureInfo } = this.props;
    return (
      <div className={'page-break new-page page'}>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center">
            <Img src={Logo} alt="Logo" className="header-page-logo" />
          </div>
        </div>
        <div className="page-section">
          <div className="page-title">
            Commande <b>{furnitureInfo.partOrderObject?.identification}</b> de{' '}
            {furnitureInfo.partOrderObject?.orderInfo.firstname.charAt(0)}.{' '}
            {furnitureInfo.partOrderObject?.orderInfo.lastname}
          </div>
          <div className="flex justify-between">
            <div>
              <div className="page-product-price-container"></div>
              <div className="page-product-reference">
                Reference&nbsp;&nbsp;&nbsp;
                <b>f{furnitureInfo.id}</b>{' '}
                {furnitureInfo.name.replace('Furniture', '')}
              </div>
            </div>
            <div className="page-product-size"></div>
          </div>
        </div>
        {/* {t('Depth')} {cabinet.id} */}
        {furnitureInfo.image && (
          <div className="page-section">
            <div className="flex items-center justify-center">
              <Img
                src={furnitureInfo.image}
                alt="Image"
                className="page-main-image"
              />
            </div>
          </div>
        )}
        <div className="page-section flex justify-around">
          <div className="text-center flex flex-col justify-center items-center">
            <div className="flex page-qr">
              <QRCode
                value={`https://www.kewlox.com/q-f${furnitureInfo.id}`}
                level="H"
                size={100}
                renderAs="canvas"
              />
            </div>
            <div className="flex">
              <a
                href={`https://www.kewlox.com/k-f${furnitureInfo.id}`}
                target="_blank"
              >
                {`kewlox.com/k-f${furnitureInfo.id}`}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
