import React from 'react';
import Modal from 'react-responsive-modal';
import { Tour } from 'app/models/Tour';
import { useGetAllWaypointsQuery } from 'services/kewloxApi';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from 'index';
import { selectAllWaypoints } from 'store/slices/waypointsSlice';
import { addDelivery } from 'app/containers/ToursList/slice';
import { Link } from 'react-router-dom';

interface Props {
  show: any;
  tour: Tour;
}

const WaypointModal = ({ show, tour }: Props) => {
  const dispatch = useDispatch();
  const { isLoading: isLoadingWaypoints } = useGetAllWaypointsQuery();

  const waypoints: Waypoint[] = useSelector((state: AppRootState) =>
    selectAllWaypoints(state),
  );

  const handleAddWaypointTour = (wayPointId: number) => {
    dispatch(addDelivery(tour, 0, undefined, wayPointId));
    show(false);
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        show(false);
      }}
      center
    >
      <div className="flex flex-col items-center justify-center">
        <div className="flex justify-center text-xl my-1 mt-4">
          Sélectionner un point de passage pour votre tournée Kewlox {tour.id}
        </div>
        <div className="border-b border-gray-400 w-full mb-4" />
        <ul className="my-4">
          {waypoints
            ?.filter(waypoint => waypoint.active)
            .map(waypoint => (
              <li
                key={waypoint.id}
                className="flex my-2 items-center justify-between"
              >
                <div className="flex flex-col">
                  <div className="">{waypoint.name}</div>
                  <div className="text-sm italic">{waypoint.description}</div>
                </div>

                <button
                  className="py-0 px-2 bg-white text-gray-700 border border-gray-300 ml-4"
                  onClick={() => {
                    handleAddWaypointTour(waypoint.id);
                  }}
                >
                  Ajouter
                </button>
              </li>
            ))}
        </ul>

        <div className="mt-4">
          <Link to="/waypoint">
            <div className="underline">Gérer les points de passage</div>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default WaypointModal;
