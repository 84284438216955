import React from 'react';
import {
  ActionAnimations,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import { FiEdit2 } from 'react-icons/fi';
import { ListType } from 'app/pages/ProductionPage/constants';
import { ProductionPiece } from 'app/models/ProductionPiece';
// import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  leftAction: any;
  rightAction: any;
  listType?: ListType;
  piece: ProductionPiece;
}

const SwipeableItem = ({
  children,
  leftAction,
  rightAction,
  listType,
  piece,
}: Props) => {
  let swipeLeftTxt = `${piece.name} `;
  if (listType === ListType.picked) swipeLeftTxt += 'annulé';
  else if (listType === ListType.standby) swipeLeftTxt += 'prêt';
  else if (listType === ListType.picking) swipeLeftTxt += 'fait !';

  let styleItem = '';
  if (listType === ListType.picked) styleItem = ' bg-gray-200 italic';
  else if (
    listType === ListType.standby ||
    listType === ListType.standbyCutting
  )
    styleItem = ' bg-orange-lighter';

  return (
    <SwipeableListItem
      swipeLeft={
        listType === ListType.picked || listType === ListType.standbyCutting
          ? undefined
          : {
              content: (
                <div className="bg-green h-12 w-full flex justify-end items-center">
                  <div className="mx-4 text-lg text-white">{swipeLeftTxt}</div>
                  {/*<FiCheck className="h-8 w-8 text-white mr-5" />*/}
                </div>
              ),
              action: () => {
                leftAction();
              },
              actionAnimation: ActionAnimations.REMOVE,
            }
      }
      swipeRight={
        listType === ListType.standbyCutting
          ? undefined
          : {
              content: (
                <div className="bg-orange  h-12 w-full flex justify-start  items-center">
                  <FiEdit2 className="h-6 w-6 text-white ml-5" />
                </div>
              ),
              action: () => {
                rightAction();
              },
              actionAnimation: ActionAnimations.RETURN,
            }
      }
      // onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
      threshold={0.2}
    >
      <div
        className={`h-12 w-full border-b border-gray-400 flex items-center justify-between${styleItem}`}
      >
        <>{children}</>
      </div>
    </SwipeableListItem>
  );
};

export default SwipeableItem;
