import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { actions } from 'app/pages/ProductionPage/slice';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import { API_BASE_URL } from 'app/constants';
import { requestGet } from 'utils/requests';
import {
  createProductOrder,
  ProductOrder,
  ProductOrderJson,
} from 'app/models/ProductOrder';
import { CircleSpinner } from 'react-spinners-kit';
import ProductOrderListItem from 'app/pages/ProductionPage/DrawersPage/ProductOrderListItem';
import { ProductionStatus } from 'app/containers/OrderDetails/types';
import TabsProductionStatus from 'app/components/TabProductionStatus';
import { IconButton, InputBase, List } from '@mui/material';
import { selectAllColors } from 'store/slices/colorSlice';

const DrawersPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const colors = useSelector(selectAllColors);

  const [drawersList, setDrawersList] = useState<ProductOrder[] | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [productionStatusFilter, setProductionStatusFilter] = useState<
    ProductionStatus
  >(ProductionStatus.NotStarted);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setProductionStatusFilter(newValue);
  };

  useEffect(() => {
    if (drawersList === undefined) {
      setLoading(true);
      const url = `${API_BASE_URL}/drawers/`;
      requestGet(url)
        .then((res: ProductOrderJson[]) => {
          setDrawersList(
            res.map((data: ProductOrderJson) =>
              createProductOrder(data, colors),
            ),
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Tiroirs</title>
        <meta name="description" content="Tiroirs" />
      </Helmet>
      <SiteWrapper
        title={'Tiroirs'}
        leftTopBarButton={
          <Link to="/production">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(actions.setProductionMode(undefined))}
            >
              <MenuIcon />
            </IconButton>
          </Link>
        }
        rightTopBarButton={
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        }
      >
        <ProductionContainer>
          <TabsProductionStatus
            productionStatus={productionStatusFilter}
            handleTabChange={handleTabChange}
          />
          {drawersList !== undefined ? (
            <div className="flex justify-center ">
              <div className="list-order w-full">
                <List aria-label="secondary ">
                  {drawersList?.map((product: ProductOrder) => (
                    <ProductOrderListItem
                      key={product.id}
                      product={product}
                      to={`/production/drawers/picking/${product.id}`}
                    />
                  ))}
                </List>
              </div>
            </div>
          ) : (
            <div>
              <CircleSpinner size={30} color="#fff" />
            </div>
          )}
        </ProductionContainer>
      </SiteWrapper>
    </>
  );
};

export default DrawersPage;
