import { sortingDestinations } from 'app/constants';
import { Carrier } from 'app/pages/ToursPage/types';
import { parseISO } from 'date-fns';
import {
  createProductOrder,
  ProductOrder,
  ProductOrderJson,
} from 'app/models/ProductOrder';
import {
  createPackageOrder,
  PackageOrder,
  PackageOrderJson,
} from 'app/models/PackageOrder';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import { Destination } from 'app/models/Destination';
import { createTrolley, Trolley } from 'app/models/Trolley';
import { OrderJson } from 'app/models/Order';
import { Color } from 'app/models/Color';
import { OrderInfo } from 'types';
import cloneDeep from 'lodash/cloneDeep';

export enum OrderPriority {
  lowest = 0,
  normal = 1,
  fast = 2,
  urgent = 3,
}

export interface Progression {
  progress: number;
  standby: boolean;
}

export interface Sibling {
  id: number;
  index: number;
}

export interface PartOrderJson {
  id: number;
  order: OrderJson;
  index: number;
  total: number;
  productionDate?: any; //string;
  destination: any; // number;
  carrier?: any; // number;
  priority?: OrderPriority;
  products?: ProductOrderJson[];
  packages?: PackageOrderJson[];
  pickingListPrinted?: any; // string;
  outOfStockProducts: boolean;
  cutting: boolean;
  cuttingBatchPrint?: number;
  packageDelivery: boolean;
  progression: Progression;
  progressionCabinet?: Progression;
  progressionAccessories?: Progression;
  progressionCorners?: Progression;
  progressionDrawers?: Progression;
  siblings?: Sibling[];
}

export interface PartOrder extends PartOrderJson {
  productionDate?: string;
  pickingListPrinted?: string;
  priority: OrderPriority;
  products?: ProductOrder[];
  packages?: PackageOrder[];
  carrier?: Carrier;
  carrierSuggested?: Carrier;
  destination: Destination;
  destinationNotAggregated: Destination;
  trolleys: Trolley[];
  identification: string;
  orderInfo: OrderInfo;
  statusId: number;
  assembly: boolean;
  statusName: string;
  lat: number;
  lng: number;
  sorting: number;
  customerName: string;
  diffBusinessDays: number;
  direct: boolean;
  isHomeTour: boolean;
  isKewlox: boolean;
  cabinets?: ProductOrder[];
}

export const createPartOrder = (
  data: PartOrderJson,
  destinations: Destination[],
  carriers: Carrier[],
  colors: Color[],
): PartOrder => {
  const productionDate = data.productionDate;
  const priority = data.priority ?? OrderPriority.normal;

  const destinationNotAggregated = destinations.find(
    e => e.id === data.destination,
  );
  if (!destinationNotAggregated) {
    throw new Error(`Destination should exist id->${data.destination}`);
  }

  const destination = destinationNotAggregated.name.startsWith('Köln')
    ? destinations.find(e => e.name === 'Köln')
    : destinationNotAggregated.home
    ? destinations.find(e => e.name === '@Home')
    : destinationNotAggregated;

  if (!destination) {
    throw new Error(`Destination should exist id->${data.destination}`);
  }

  const carrier = data.carrier
    ? carriers.find(e => e.id === data.carrier)
    : undefined;
  let carrierSuggested;
  if (destinationNotAggregated.name === '@HomeParcel') {
    carrierSuggested = carriers.find(e => e.name === 'BPOST');
  } else if (destinationNotAggregated.name === '@HomeFR') {
    carrierSuggested = carriers.find(
      e =>
        e.name.toLowerCase() === 'kuehne+nagel' ||
        e.name.toLowerCase() === 'kuehne-nagel',
    );
  } else if (
    destinationNotAggregated.name === '@HomeGeneral' &&
    data.order.infos.suggestedCarrierCompanyId
  ) {
    carrierSuggested = carriers.find(
      e => e.id === data.order.infos.suggestedCarrierCompanyId,
    );
  } else if (destinationNotAggregated.name === '@Home') {
    carrierSuggested = carriers.find(e => e.name === 'KEWLOX');
  }

  const products = data.products?.map(productEntry =>
    createProductOrder(productEntry, colors),
  );
  const trolleys: Trolley[] = [];
  let packages;
  if (data.packages) {
    packages = cloneDeep(data.packages)
      ?.sort((a, b) => a.id - b.id)
      .map((packageEntry, index) =>
        createPackageOrder(packageEntry, products, index + 1, colors),
      );

    const trolleysMap = new Map<number, PackageOrder[]>();
    packages.forEach(pkg => {
      const trolleyPackages = trolleysMap.get(pkg.trolley) || [];
      trolleyPackages.push(pkg);
      trolleysMap.set(pkg.trolley, trolleyPackages);
    });
    trolleysMap.forEach((pkgList, trolleyId) => {
      trolleys.push(createTrolley(trolleyId, pkgList));
    });
  }
  const diffBusinessDays = productionDate
    ? differenceInBusinessDays(parseISO(productionDate), new Date())
    : 7;

  const orderInfos: OrderInfo = data.order.infos;

  return {
    ...data,
    priority,
    products,
    packages,
    carrier,
    carrierSuggested,
    destination,
    destinationNotAggregated,
    trolleys,
    identification:
      data.total > 1 ? `${data.order.id} (${data.index})` : `${data.order.id}`,
    orderInfo: orderInfos,
    statusId: orderInfos.statusId,
    assembly: orderInfos.assembly ?? false,
    statusName: orderInfos.status,
    lat: orderInfos.lat!,
    lng: orderInfos.lng!,
    sorting: sortingDestinations.indexOf(destination.id),
    customerName: `${orderInfos.firstname
      .charAt(0)
      .toUpperCase()}. ${orderInfos.lastname.toUpperCase()}`,
    diffBusinessDays,
    direct: destination.name === 'Direct',
    isHomeTour: !destination || destination.home,
    isKewlox: carrier?.name === 'KEWLOX' || carrier?.name === 'ALAIN VAN EYKEN',
    cabinets: products
      ?.filter(p => p.cabinet)
      .sort((a, b) => a.name!.localeCompare(b.name!)),
  };
};

// export class PartOrder {
//   id: number;
//   order: Order;
//   index: number;
//   total: number;
//   productionDate?: Date;
//   pickingListPrinted?: Date;
//   destination: Destination;
//   destinationNotAggregated: Destination;
//   cutting: boolean;
//   cuttingBatchPrint?: number;
//
//   priority: OrderPriority;
//
//   products?: ProductOrder[];
//   packages?: PackageOrder[];
//   carrier?: Carrier;
//   carrierSuggested?: Carrier;
//
//   progression: Progression;
//   progressionCabinet?: Progression;
//   progressionAccessories?: Progression;
//   progressionCorners?: Progression;
//   progressionDrawers?: Progression;
//   trolleys: Trolley[];
//
//   outOfStockProducts: boolean;
//   packageDelivery: boolean;
//
//   siblings?: Sibling[];
//
//   constructor(
//     data: PartOrderJson,
//     destinations: Destination[],
//     carriers: Carrier[],
//     colors: Color[],
//   ) {
//     this.id = data.id;
//     this.index = data.index;
//     this.total = data.total;
//     if (data.productionDate)
//       this.productionDate = parseISO(data.productionDate);
//     if (data.pickingListPrinted)
//       this.pickingListPrinted = parseISO(data.pickingListPrinted);
//     if (data.priority) this.priority = data.priority;
//     else this.priority = OrderPriority.normal;
//     this.outOfStockProducts = data.outOfStockProducts;
//     this.packageDelivery = data.packageDelivery;
//     if (data.siblings) {
//       this.siblings = data.siblings;
//     }
//
//     this.cutting = data.cutting;
//     this.cuttingBatchPrint = data.cuttingBatchPrint;
//
//     this.order = createOrder(data.order);
//
//     this.progression = data.progression;
//     if (data.progressionCabinet)
//       this.progressionCabinet = data.progressionCabinet;
//     if (data.progressionAccessories)
//       this.progressionAccessories = data.progressionAccessories;
//     if (data.progressionCorners)
//       this.progressionCorners = data.progressionCorners;
//     if (data.progressionDrawers)
//       this.progressionDrawers = data.progressionDrawers;
//
//     const destinationNotAggregated = destinations.find(
//       e => e.id === data.destination,
//     );
//     if (!destinationNotAggregated) {
//       console.log('destinationNotAggregated', destinations, data.destination);
//       throw new Error(`Destination should exist id->${data.destination}`);
//     }
//     this.destinationNotAggregated = destinationNotAggregated;
//     const destination = destinationNotAggregated.name.startsWith('Köln')
//       ? destinations.find(e => e.name === 'Köln')
//       : destinationNotAggregated.home
//       ? destinations.find(e => e.name === '@Home')
//       : destinationNotAggregated;
//     if (!destination) {
//       throw new Error(`Destination should exist id->${data.destination}`);
//     }
//     this.destination = destination;
//
//     if (data.carrier) {
//       const carrier = carriers.find(e => e.id === data.carrier);
//       if (!carrier) {
//         throw new Error(`Carrier should exist id->${data.destination}`);
//       }
//       this.carrier = carrier;
//     }
//
//     if (this.destinationNotAggregated.name === '@HomeParcel')
//       this.carrierSuggested = carriers.find(e => e.name === 'BPOST');
//     else if (this.destinationNotAggregated.name === '@HomeFR')
//       this.carrierSuggested = carriers.find(
//         e =>
//           e.name.toLowerCase() === 'kuehne+nagel' ||
//           e.name.toLowerCase() === 'kuehne-nagel',
//       );
//     else if (this.destinationNotAggregated.name === '@HomeGeneral') {
//       if (data.order.infos.suggestedCarrierCompanyId) {
//         this.carrierSuggested = carriers.find(
//           e => e.id === data.order.infos.suggestedCarrierCompanyId,
//         );
//       }
//     } else if (this.destinationNotAggregated.name === '@Home')
//       this.carrierSuggested = carriers.find(e => e.name === 'KEWLOX');
//
//     if (data.products && data.packages) {
//       this.products = [];
//       for (const productEntry of data.products) {
//         const product = createProductOrder(productEntry, colors);
//         this.products.push(product);
//       }
//
//       this.packages = [];
//       data.packages.sort(
//         (a: PackageOrderJson, b: PackageOrderJson) => a.id - b.id,
//       );
//       for (let index = 0; index < data.packages.length; index++) {
//         const packageEntry = data.packages[index];
//         const pkg = createPackageOrder(
//           packageEntry,
//           this.products,
//           index + 1,
//           colors,
//         );
//         this.packages.push(pkg);
//       }
//     } else if (data.packages) {
//       this.packages = [];
//       for (let index = 0; index < data.packages.length; index++) {
//         const packageEntry = data.packages[index];
//         const pkg = createPackageOrder(
//           packageEntry,
//           undefined,
//           index + 1,
//           colors,
//         );
//         this.packages.push(pkg);
//       }
//     }
//
//     this.trolleys = [];
//     if (
//       this.packages &&
//       this.packages.length > 0 &&
//       this.packages[0].composition &&
//       this.packages[0].composition.length > 0
//     )
//       this.loadTrolleys();
//   }
//
//   get identification() {
//     if (this.total > 1) {
//       return `${this.order.id} (${this.index})`;
//     }
//     return `${this.order.id}`;
//   }
//
//   get orderInfo() {
//     return this.order.orderInfo;
//   }
//
//   get statusId() {
//     return this.orderInfo.statusId;
//   }
//
//   get assembly() {
//     return this.orderInfo.assembly;
//   }
//
//   get statusName() {
//     return this.orderInfo.status;
//   }
//
//   get lat() {
//     return this.orderInfo.lat;
//   }
//
//   get lng() {
//     return this.orderInfo.lng;
//   }
//
//   get sorting() {
//     return sortingDestinations.indexOf(this.destination.id);
//   }
//
//   get customerName() {
//     return `${this.orderInfo.firstname
//       .charAt(0)
//       .toUpperCase()}. ${this.orderInfo.lastname.toUpperCase()}`;
//   }
//
//   clone() {
//     const orderClone = cloneDeep(this);
//     orderClone.products = this.products?.map(p => p.clone());
//     return orderClone;
//   }
//
//   get diffBusinessDays(): number {
//     if (this.productionDate)
//       return differenceInBusinessDays(this.productionDate, new Date());
//     else return 7;
//   }
//
//   get direct() {
//     return this.destination?.name === 'Direct';
//   }
//
//   get isHomeTour(): boolean {
//     return this.destination === undefined || this.destination.home;
//   }
//
//   get isKewlox(): boolean {
//     return (
//       this.carrier?.name === 'KEWLOX' ||
//       this.carrier?.name === 'ALAIN VAN EYKEN'
//     );
//   }
//
//   get cabinets(): ProductOrder[] | undefined {
//     return this.products
//       ?.filter((p: ProductOrder) => p.cabinet)
//       ?.sort((a: ProductOrder, b: ProductOrder) =>
//         a.name.localeCompare(b.name),
//       );
//   }
//
//   loadTrolleys() {
//     const trolleysMap = new Map<number, PackageOrder[]>();
//     if (this.packages) {
//       for (const pkg of this.packages) {
//         if (trolleysMap.get(pkg.trolley) !== undefined) {
//           // @ts-ignore
//           trolleysMap.get(pkg.trolley).push(pkg);
//         } else {
//           trolleysMap.set(pkg.trolley, [pkg]);
//         }
//       }
//     }
//
//     for (const [trolleyId, pkgList] of trolleysMap.entries()) {
//       const trolley = createTrolley(trolleyId, pkgList);
//       this.trolleys.push(trolley);
//     }
//   }
// }
