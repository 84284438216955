import { createSelector } from '@reduxjs/toolkit';
import { PartOrder } from 'app/models/PartOrder';
import { selectUnallocatedPartOrderByDestination } from 'app/containers/OrderList/selectors';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';
import { selectDeliveryEntities } from 'store/slices/deliverySlice';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { selectTourForCurrentPartOrder } from 'store/slices/toursSlice';

export const selectNavigationPartOrders = createSelector(
  [
    selectCurrentPartOrder(),
    selectTourForCurrentPartOrder,
    selectUnallocatedPartOrderByDestination,
    selectDeliveryEntities,
    selectPartOrderEntities,
  ],
  (
    partOrder,
    tour,
    unallocatedOrders,
    deliveries,
    partOrders,
  ): PartOrder[] | null | void => {
    if (partOrder) {
      if (tour) {
        const deliveriesOfTour = tour.deliveries.map(
          deliveryId => deliveries[deliveryId]!,
        );
        const partOrdersList = deliveriesOfTour
          .sort((a, b) => a.position - b.position)
          .map(d => partOrders[d.partOrder!])
          .filter(e => e !== undefined);
        console.log(
          'selectNavigationPartOrders : partOrdersList',
          partOrdersList,
        );
        return partOrdersList as PartOrder[];
      }
      if (unallocatedOrders) {
        return ([] as PartOrder[]).concat(...unallocatedOrders);
      }
    }
  },
);

export const selectPreviousPartOrder = createSelector(
  [selectCurrentPartOrder(), selectNavigationPartOrders],
  (selectedPartOrder, partOrders): PartOrder | void => {
    if (selectedPartOrder && partOrders) {
      // Get index of selectedPartOrder in partOrders and return the index+1 if it's exist
      const index = partOrders.map(e => e.id).indexOf(selectedPartOrder.id);
      if (index !== undefined && index > 0 && partOrders.length > 0) {
        return partOrders[index - 1];
      }
    }
  },
);

export const selectNextPartOrder = createSelector(
  [selectCurrentPartOrder(), selectNavigationPartOrders],
  (selectedPartOrder, partOrders): PartOrder | void => {
    if (selectedPartOrder && partOrders) {
      // Get index of selectedPartOrder in partOrders and return the index+1 if it's exist
      const index = partOrders.map(e => e.id).indexOf(selectedPartOrder.id);
      if (index !== undefined && index < partOrders.length - 1) {
        return partOrders[index + 1];
      }
    }
  },
);
