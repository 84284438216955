import React from 'react';
import { PlaceholderLine } from 'app/components/Placeholders/PlaceholderLine';

const TourItemPlaceholder = () => {
  return (
    <div className="bg-white mb-8 mx-4">
      <div
        className="tour-item-placeholder jsyuko flex items-center justify-around text-lg border border-gray-300 px-4 py-2 animate-pulse"
        style={{ backgroundColor: '#787878' }}
      >
        <div className="ml-0 flex items-baseline">
          <PlaceholderLine width="w-24" />
          <PlaceholderLine width="w-8" />
          <PlaceholderLine width="w-12" />
        </div>

        <div className="date-tour ml-1">
          <PlaceholderLine width="w-32" height="h-4" />
        </div>
        <div className="flex ml-10 items-center">
          <PlaceholderLine width="w-16" height="h-4" />
        </div>
        <div className="ml-10 flex items-center hidden xxl:flex">
          <PlaceholderLine width="w-12" />
        </div>
        <div className="hidden xl:flex ml-10 items-center">
          <PlaceholderLine width="w-24" height="h-4" />
        </div>
      </div>
      <div>
        <DeliveryRowPlaceholder />
      </div>
    </div>
  );
};

const DeliveryRowPlaceholder = () => {
  return (
    <div className="delivery-row-placeholder flex items-center overflow-auto animate-pulse py-2">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="delivery-placeholder border-2 p-2 mr-4 flex flex-col space-y-1 justify-center items-center"
        >
          <PlaceholderLine width="w-4" />
          <PlaceholderLine width="w-12" />
          <PlaceholderLine width="w-12" />
        </div>
      ))}
    </div>
  );
};

export { TourItemPlaceholder, DeliveryRowPlaceholder };

// <div
//     className="tour-item-placeholder flex items-center justify-around text-lg border border-gray-500 p-4 animate-pulse"
//     style="background-color: #363636;">
//     <div className="ml-0 flex items-baseline">
//         <div className="text-sm mx-1 bg-gray-300 h-4 w-24 rounded"></div>
//         <div className="text-sm mx-1 bg-gray-300 h-4 w-8 rounded"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="cursor-pointer">
//         <div className="flex items-center">
//             <div className="flex text-sm items-center mx-1 bg-gray-300 h-4 w-20 rounded"></div>
//         </div>
//     </div>
//     <div className="date-tour ml-1">
//         <div className="bg-gray-300 h-8 w-32 rounded"></div>
//     </div>
//     <div className="flex ml-10 items-center">
//         <div className="text-xl bg-gray-300 h-6 w-16 rounded"></div>
//     </div>
//     <div className="ml-10 flex items-center hidden xxl:flex">
//         <div className="text-base bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="hidden xl:flex ml-10 items-center">
//         <div className="text-xl flex items-baseline bg-gray-300 h-6 w-24 rounded"></div>
//     </div>
//     <
// </div>
//
// <div className="delivery-row-placeholder flex items-center overflow-auto animate-pulse mt-4">
//
//     <div className="delivery-placeholder border border-2 p-2 mr-4">
//         <div className="bg-gray-300 h-4 w-4 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="delivery-placeholder border border-2 p-2 mr-4">
//         <div className="bg-gray-300 h-4 w-4 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="delivery-placeholder border border-2 p-2 mr-4">
//         <div className="bg-gray-300 h-4 w-4 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="delivery-placeholder border border-2 p-2 mr-4">
//         <div className="bg-gray-300 h-4 w-4 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
//     <div className="delivery-placeholder border border-4 p-2 mr-4">
//         <div className="bg-gray-300 h-4 w-4 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded mb-2"></div>
//         <div className="bg-gray-300 h-4 w-12 rounded"></div>
//     </div>
// </div>
