import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { PackageOrder } from 'app/models/PackageOrder';
import Button from 'app/components/Button';
import { PACKAGE_URL } from 'app/containers/OrderDetails/constants';
import { requestPost } from 'utils/requests';
import { getPartOrder } from 'app/containers/SiteWrapper/slice';
import { useDispatch, useSelector } from 'react-redux';
import TitleModal from 'app/components/Modal/TitleModal';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { parsePackageEntryName } from 'app/containers/OrderDetails/PackagesTab/index';
import ReactTooltip from 'react-tooltip';
import { selectAllColors } from 'store/slices/colorSlice';
import { useSplitPackagesMutation } from 'services/kewloxApi';

interface Props {
  setPackageDetails: any;
  packageOrder: PackageOrder | undefined;
}

const PackageDetails = ({ setPackageDetails, packageOrder }: Props) => {
  const dispatch = useDispatch();
  const [selectedPiece, setSelectedPiece] = useState<number[]>([]);
  const [selectedPieceQuantity, setSelectedPieceQuantity] = useState<
    Map<number, number>
  >(new Map());
  const allColors = useSelector(selectAllColors);

  const [splitPackages] = useSplitPackagesMutation();

  useEffect(() => {
    setSelectedPiece([]);
    setSelectedPieceQuantity(new Map());
  }, [packageOrder]);

  if (!packageOrder) return null;

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = new Set<number>(selectedPiece);
    const id = parseInt(event.target.name);
    if (event.target.checked && !selected.has(id)) selected.add(id);
    if (!event.target.checked && selected.has(id)) selected.delete(id);

    setSelectedPiece(Array.from(selected));
  };

  function handleSliderChange(pieceId: number, value: number) {
    const newSelectPieceQuantity = new Map<number, number>(
      selectedPieceQuantity,
    );

    newSelectPieceQuantity.set(pieceId, value);

    setSelectedPieceQuantity(newSelectPieceQuantity);
  }

  let allSelected = true;
  if (packageOrder.composition) {
    for (let i = 0; i < packageOrder.composition.length; i++) {
      if (selectedPieceQuantity.has(packageOrder.composition[i].id)) {
        if (
          (selectedPieceQuantity.get(packageOrder.composition[i].id) ?? 0) <
          packageOrder.composition[i].quantity
        ) {
          allSelected = false;
          break;
        }
      }
    }
    if (
      selectedPiece.length > 0 &&
      selectedPiece.length < packageOrder.composition.length
    ) {
      allSelected = false;
    }
  }

  function handleSplit() {
    if (packageOrder) {
      // selectedPiece and selectedPieceQuantity
      const data = selectedPiece.map(pieceId => {
        const quantity = selectedPieceQuantity.get(pieceId);
        const changeList = {
          id: pieceId,
        };
        if (quantity) changeList['quantity'] = quantity;
        return changeList;
      });

      splitPackages({
        pkgId: packageOrder.id,
        partOrderId: packageOrder.order,
        data,
      })
        .unwrap()
        .then(() => {
          dispatch(getPartOrder(packageOrder.order));
          setPackageDetails(undefined);
        })
        .catch(error => {
          console.error(
            `Error splitting package with id ${packageOrder.id}:`,
            error,
          );
        });

      // const url = `${PACKAGE_URL}/${packageOrder.id}/split/`;
      // requestPost(url, data).then(() => {
      //   dispatch(getPartOrder(packageOrder.order));
      //   setPackageDetails(undefined);
      // });
    }
  }

  return (
    <Modal
      open={true}
      onClose={() => {
        setPackageDetails(undefined);
      }}
      center
    >
      <TitleModal title={`${packageOrder.id}`} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Pieces packages details">
          <FormControl component="fieldset">
            <FormGroup>
              <TableBody>
                {packageOrder.composition?.map(piece => (
                  <TableRow key={piece.id}>
                    <TableCell align="left">
                      <div className="flex items-center w-128">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedPiece.includes(piece.id)}
                              color="primary"
                              onChange={handleSelection}
                              name={`${piece.id}`}
                            />
                          }
                          label={parsePackageEntryName(
                            `${
                              selectedPieceQuantity.has(piece.id)
                                ? selectedPieceQuantity.get(piece.id)
                                : piece.quantity
                            }x ${piece.reference}`,
                            allColors,
                          )}
                        />

                        {selectedPiece.includes(piece.id) &&
                          piece.quantity > 1 && (
                            <div className="w-64">
                              <Slider
                                defaultValue={piece.quantity}
                                aria-labelledby="discrete-slider-small-steps"
                                step={1}
                                marks
                                min={1}
                                max={piece.quantity}
                                valueLabelDisplay="off"
                                onChange={(e, value) =>
                                  handleSliderChange(piece.id, value as number)
                                }
                              />
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </FormGroup>
          </FormControl>
        </Table>
      </TableContainer>
      {selectedPiece.length > 0 && !allSelected && (
        <div className="w-full">
          <div className="my-2  flex flex-end">
            <Button
              onClick={handleSplit}
              contentText="Créer un nouveau colis avec la sélection"
            />
          </div>
        </div>
      )}
      <ReactTooltip id="colorInfo" place="bottom" type="dark" />
    </Modal>
  );
};

export default PackageDetails;
