import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { PackageOrder } from 'app/models/PackageOrder';
import { LabelPage } from './LabelPage';
import { PartOrder } from 'app/models/PartOrder';
import { Delivery } from 'app/models/Delivery';
import { selectDeliveryFromPartOrderId } from 'store/slices/deliverySlice';

class ComponentToPrint extends React.Component<{
  packagesOrder: PackageOrder[];
  partOrder: PartOrder;
  delivery?: Delivery;
}> {
  render() {
    const { packagesOrder, partOrder } = this.props;

    return (
      <div>
        {packagesOrder.map((packageOrder: PackageOrder, index: number) => (
          <LabelPage
            partOrder={partOrder}
            packageOrder={packageOrder}
            firstPage={index === 0}
            key={packageOrder.id}
          />
        ))}
      </div>
    );
  }
}

interface Props {
  partOrder: PartOrder;
  packagesOrder: PackageOrder[];
}

export function LabelsToPrint({ partOrder, packagesOrder }: Props) {
  const componentRef = useRef<any>(null);
  const delivery = useSelector(selectDeliveryFromPartOrderId(partOrder.id));

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button data-tip="Imprimer les étiquettes sélectionnées">
            <FiPrinter className="h-5 w-5" />
          </button>
        )}
        content={() => componentRef.current}
        bodyClass="toprint"
      />
      <div className="will-print" style={{ display: 'none' }}>
        <ComponentToPrint
          ref={componentRef}
          packagesOrder={packagesOrder}
          partOrder={partOrder}
          delivery={delivery}
        />
      </div>
    </>
  );
}
