import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from 'app/containers/ToursList/slice';
import { Tour } from 'app/models/Tour';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.toursList || initialState;

export const selectDestinationSelected = createSelector(
  [selectDomain],
  substate => substate.destination,
);

export const selectDestinationFilteredList = createSelector(
  [selectDomain],
  substate => substate.filteredList,
);

export const selectIsPastToursList = createSelector(
  [selectDomain],
  substate => substate.pastTours,
);

export const selectError = createSelector(
  [selectDomain],
  substate => substate.error,
);

export const selectIsTourLoading = (tour: Tour) =>
  createSelector(
    [selectDomain],
    substate =>
      substate.loadingTours.find(entry => entry === tour.id) !== undefined,
  );

export const selectedDirectTour = createSelector([selectDomain], substate =>
  substate.tours.find(e => e.destination?.name === 'Direct'),
);
