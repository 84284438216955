import React, { useState } from 'react';
import { GiCircularSawblade } from 'react-icons/gi';
import CuttingModal from 'app/containers/Cutting/CuttingModal';
import { useSelector } from 'react-redux';
import { Badge, Button } from '@mui/material';
import { selectNumberOfCuttings } from 'app/containers/SiteWrapper/selectors';

const CuttingButton = () => {
  const [show, showModal] = useState<boolean>(false);
  const numberOfCuttings = useSelector(selectNumberOfCuttings);

  return (
    <div className="mr-2">
      <Badge badgeContent={numberOfCuttings} color="secondary">
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            showModal(true);
          }}
          className="flex items-center px-2 py-1 border border-white rounded"
        >
          <div className="mr-2">Découpes</div>
          <GiCircularSawblade className="h-5 w-5" />
        </Button>
      </Badge>
      {show && <CuttingModal showModal={showModal} />}
    </div>
  );
};

export default CuttingButton;
