import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.productionPage || initialState;

export const selectProductionMode = createSelector(
  [selectDomain],
  productionPageState => productionPageState.productionMode,
);
