import React from 'react';
import { Carrier } from 'app/pages/ToursPage/types';
import { Destination } from 'app/models/Destination';
import styled from 'styled-components';
// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';

interface Props {
  carrier?: Carrier;
  destination: Destination;
}

const CarrierDestinationLabel = ({ carrier, destination }: Props) => {
  const showCarrier = carrier && !destination.shop;

  if (destination.direct)
    return (
      <div>
        <DirectLabelStyled
          height={2.5}
          className="flex justify-center items-center w-40"
        >
          <div className="text-xl font-bold uppercase">{destination.name}</div>
        </DirectLabelStyled>
      </div>
    );

  return (
    <div>
      {showCarrier && (
        <LabelStyled
          color={destination.color}
          textColor={destination.textColor}
          height={2.5}
          className="flex justify-center items-center w-40"
        >
          <div className="text-xl font-bold">{destination.name}</div>
        </LabelStyled>
      )}
      <LabelStyled
        color={carrier && showCarrier ? carrier.color : destination.color}
        textColor={
          carrier && showCarrier ? carrier.textColor : destination.textColor
        }
        height={showCarrier ? 3 : 4}
        className="flex justify-center items-center w-40"
      >
        <div className="text-xl font-bold">
          {showCarrier && carrier ? carrier.name : destination.name}
        </div>
      </LabelStyled>
    </div>
  );
};

export default CarrierDestinationLabel;

const LabelStyled = styled.div<{
  color: string;
  textColor: string;
  height: number;
}>`
  color: white;
  font-size: 1.125rem;
  padding: 0.25rem 1.25rem;
  background-color: ${p => p.color};
  height: ${p => `${p.height}rem`};

  ${p =>
    p.textColor &&
    css`
      color: ${p.textColor};
    `}
`;

const DirectLabelStyled = styled.div<{
  height: number;
}>`
  color: black;
  font-size: 1.125rem;
  padding: 0.25rem 1.25rem;
  background-color: white;
  border: 6px solid red;
  height: ${p => `${p.height}rem`};
`;
