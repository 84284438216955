import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { actions } from './slice';
import { requestGet } from 'utils/requests';
import { FEED_URL, POLLING_DELAY } from 'app/containers/PollingAgent/constants';
import { FeedParams } from 'app/containers/PollingAgent/types';
import { createPartOrder, PartOrderJson } from 'app/models/PartOrder';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { Carrier } from 'app/pages/ToursPage/types';
import { actions as actionsSiteWrapper } from 'app/containers/SiteWrapper/slice';
import { Destination } from 'app/models/Destination';
import { Feed } from 'app/models/Feed';
import { CUTTING_PRINTING_INFO_URL } from 'app/containers/Cutting/constants';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { Color } from 'app/models/Color';
import { selectAllColors } from 'store/slices/colorSlice';
import { selectCurrentPartOrderId } from 'store/slices/activeContentSlice';
import { kewloxApi } from 'services/kewloxApi';

function* pollingSaga() {
  const params: FeedParams = {};

  const min = Date.now();
  params.min = `${min}-0`;

  while (true) {
    try {
      // @ts-ignore
      const feedData = yield call(requestGet, FEED_URL, null, params);
      if (feedData.length > 0) {
        const feed = new Feed(feedData);

        params.min = feed.idNextEvent;

        // Refresh
        const toursUpdated = feed.isToursUpdatedInFeed();
        if (toursUpdated) {
          // yield put(actionsTours.loadTours(true));
          yield put(
            kewloxApi.util.invalidateTags([{ type: 'Tour', id: 'LIST' }]),
          );

          const printingInfo = yield call(
            requestGet,
            CUTTING_PRINTING_INFO_URL,
          );
          const toPrint = printingInfo?.find(
            pI =>
              pI.datetime === undefined &&
              pI.ordersHighPriority &&
              pI.ordersHighPriority.length > 0,
          );
          yield put(
            actionsSiteWrapper.setNumberOfCuttings(
              toPrint ? toPrint.ordersHighPriority.length : 0,
            ),
          );
        }

        const partOrdersUpdated = feed.partOrdersUpdatedInFeed();
        if (partOrdersUpdated && partOrdersUpdated.length > 0) {
          const carriers: Carrier[] = yield select(selectAllCarriers);
          const destinations: Destination[] = yield select(
            selectAllDestinations,
          );
          const colors: Color[] = yield select(selectAllColors);
          const partOrdersJson: PartOrderJson[] = yield call(
            requestGet,
            PARTORDER_URL,
          );
          const orders = partOrdersJson.map(entry =>
            createPartOrder(entry, destinations, carriers, colors),
          );
          // yield put(setOrders(orders));
          yield put(
            kewloxApi.util.invalidateTags([{ type: 'PartOrder', id: 'LIST' }]),
          );

          const currentdPartOrderId = yield select(selectCurrentPartOrderId());
          if (
            currentdPartOrderId &&
            partOrdersUpdated.includes(currentdPartOrderId)
          ) {
            yield put(
              kewloxApi.util.invalidateTags([
                { type: 'PartOrder', id: currentdPartOrderId },
              ]),
            );
            // const url = `${PARTORDER_URL}${currentdPartOrderId}/`;
            // const partOrdersJson: PartOrderJson = yield call(requestGet, url);
            // const newPartOrder = createPartOrder(
            //   partOrdersJson,
            //   destinations,
            //   carriers,
            //   colors,
            // );
            // yield put(refreshPartOrder(newPartOrder));
          }
        }
      }
    } catch (errors) {
      yield put(actions.pollingFailure(errors));
    }
    yield delay(POLLING_DELAY);
  }
}

export function* watchPollingSaga() {
  while (true) {
    yield take(actions.startPollingAgent.type);
    yield delay(3000);

    const pollingTask = yield fork(pollingSaga);
    yield take(actions.stopPollingAgent.type);
    if (pollingTask) yield cancel(pollingTask);
  }
}
