import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import { Tour } from 'app/models/Tour';
import { Delivery } from 'app/models/Delivery';
import { OrderPageForExternalCarrier } from 'app/containers/OrderDetails/PackagesTab/LabelsToPrint/OrderPageForExternalCarrier';

class ComponentToPrint extends React.Component<{
  t: any;
  deliveries: Delivery[];
}> {
  render() {
    const { deliveries } = this.props;
    return (
      <div>
        {deliveries
          .filter(delivery => delivery && delivery.partOrder)
          .map((delivery: Delivery) => (
            <OrderPageForExternalCarrier
              partOrderId={delivery.partOrder!}
              key={delivery.id}
            />
          ))}
      </div>
    );
  }
}

interface Props {
  deliveries: Delivery[];
}

export function ExternalCarrierOrderPages({ deliveries }: Props) {
  const { t } = useTranslation();
  const componentRef = useRef<any>(null);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button data-tip="Imprimer les feuilles clients" data-for="infos">
            <FiFileText className="h-5 w-5" />
          </button>
        )}
        content={() => componentRef.current}
        bodyClass="toprint"
      />
      <div className="will-print" style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} t={t} deliveries={deliveries} />
      </div>
    </>
  );
}
