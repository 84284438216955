import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import PrintContent from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PrintContent';
import { Workstation } from 'app/containers/OrderDetails/types';
import {
  createPartOrder,
  PartOrder,
  PartOrderJson,
} from 'app/models/PartOrder';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { requestGet } from 'utils/requests';
import { refreshPartOrder } from 'app/containers/SiteWrapper/slice';
import { OrderPage } from '../../PackagesTab/LabelsToPrint/OrderPage';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllColors } from 'store/slices/colorSlice';

class ComponentToPrint extends React.Component<{
  order: PartOrder;
}> {
  render() {
    const { order } = this.props;

    return (
      <div>
        {order.carrier?.name !== 'BPOST' && (
          <OrderPage partOrder={order} firstPage={true} />
        )}
        <PrintContent order={order} workstation={Workstation.Cabinet} />
        <PrintContent order={order} workstation={Workstation.Corner} />
        <PrintContent order={order} workstation={Workstation.Drawer} />
        <PrintContent order={order} workstation={Workstation.Accessory} />
      </div>
    );
  }
}

interface Props {
  order: PartOrder;
}

function PrintOrder({ order }: Props) {
  const dispatch = useDispatch();
  const componentRef = useRef<any>(null);
  const destinations = useSelector(selectAllDestinations);
  const carriers = useSelector(selectAllCarriers);
  const colors = useSelector(selectAllColors);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button
            className="mt-2 ml-2"
            data-tip="Imprimer le picking et les étiquettes"
            data-for="infos"
          >
            <FiPrinter className="h-6 w-6 ml-2" />
          </button>
        )}
        content={() => componentRef.current}
        bodyClass="toprint"
        onAfterPrint={() => {
          const url = `${PARTORDER_URL}${order.id}/picking_list_printed/`;
          requestGet(url).then((partOrdersJson: PartOrderJson) => {
            const newPartOrder = createPartOrder(
              partOrdersJson,
              destinations,
              carriers,
              colors,
            );
            dispatch(refreshPartOrder(newPartOrder));
          });
        }}
      />
      <div className="will-print" style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} order={order} />
      </div>
    </>
  );
}

export default PrintOrder;
