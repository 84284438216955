export const BASE_URL = `${import.meta.env.VITE_API_URL}`;
export const API_BASE_URL = `${BASE_URL}/fr/api`;
export const ASSEMBLY_TIME = 6000; // Time in seconds for the worker to do the assembly

// export enum Transport {
//   Kewlox = 'Kewlox'
// }

type Transport = 'Kewlox' | 'TNPS' | '...';

export enum KewnetStatus {
  inProgress = 3, // Prépration en cours
  inProductionQueue = 18, // Commandé à l'usine
  cancel = 6,
  standby = 51,
  waitingPaymentBankwire = 10,
  paymentAccepted = 2,
  delivered = 33,
  giveToTransportStatus = 33,
  managed = 47,
  addressToCorrect = 58,
  closed = 35,
  partialProcessing = 52,
  partialDelivered = 53,
  partialShopDelivered = 54,
  finalProcessing = 38,
  shippingNotification = 13,
}

export const sortingDestinations = [
  31, 19, 10, 24, 23, 46, 36, 48, 50, 7, 54, 51,
];

export enum DoorHole {
  nothing = 0,
  button = 4,
  cup = 30,
  cupLarge = 38,
}

export const BIG_SHELF_MINIMUM_HEIGHT = 800;
export const MINIMUM_WIDTH_FOR_TWO_DRAWER_HANDLES = 800;

export const MINIMUM_LARGE_DOORS_HEIGHT = 600;

export const ALUMINIUM_COLOR = 'DIBOND';
export const NATURAL_COLOR = 'BRUT';
export const WHITE_COLOR = 'W4006';
export const BLACK_COLOR = 'U4020';
export const BLUE_COLOR = 'U4017';
export const ECO_COLOR = 'BRUT-ECO';
export const ECO_PLUS_COLOR = 'ECO-PLUS';
export const WHITE_OPAQUE_COLOR = '1T41';
export const BLACK_OPAQUE_COLOR = '9221';

export const AQUA_COLOR = '7T1D';
export const POLAR_COLOR = '030';
export const INOX_COLOR = 'INOX';
export const GALVA_COLOR = 'GALVA';
export const GLASS_COLOR = 'V3';
export const MIRROR_COLOR = 'M3';

export const CREAM_COLOR = 'U4018';
export const BROWN_COLOR = 'BROWN';
export const GREEN_COLOR = '379044';

export const DESTINATION_LEUZE_ID = 19;

export enum DeliveryStatus {
  waitingDelivering = 0,
  loaded = 1,
  partiallyDelivered = 2,
  delivered = 3,
  notDelivered = 4,
}
