import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { API_BASE_URL } from 'app/constants';
import { requestGet } from 'utils/requests';
import {
  createProductionPiece,
  ProductionPiece,
  ProductionPieceJson,
} from 'app/models/ProductionPiece';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import SwipeableCell from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/SwipeableCell';
import { ListType } from 'app/pages/ProductionPage/constants';
import { ProductOrderJson } from 'app/models/ProductOrder';
import { Link } from 'react-router-dom';
import { actions } from 'app/pages/ProductionPage/slice';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import { CircleSpinner } from 'react-spinners-kit';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputBase } from '@mui/material';
import { selectAllColors } from 'store/slices/colorSlice';

const DrawerPickingPage = props => {
  const classes = useStyles();
  const productId = props.match.params.productId;
  const dispatch = useDispatch();
  const colors = useSelector(selectAllColors);

  const [drawerPickingList, setDrawerPickingList] = useState<
    ProductionPiece[] | undefined
  >(undefined);

  useEffect(() => {
    if (drawerPickingList === undefined) {
      const url = `${API_BASE_URL}/product/${productId}/`;
      requestGet(url).then((res: ProductOrderJson) => {
        if (res.production)
          setDrawerPickingList(
            res.production.map((data: ProductionPieceJson) =>
              createProductionPiece(data, undefined, undefined, colors),
            ),
          );
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Picking - Tiroirs</title>
        <meta name="description" content="Picking des tiroirs" />
      </Helmet>
      <SiteWrapper
        title={'Picking des tiroirs'}
        leftTopBarButton={
          <Link to="/production">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(actions.setProductionMode(undefined))}
            >
              <MenuIcon />
            </IconButton>
          </Link>
        }
        rightTopBarButton={
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        }
      >
        <ProductionContainer>
          {drawerPickingList !== undefined ? (
            <div>
              <SwipeableList>
                {drawerPickingList?.map(piece => (
                  <SwipeableCell
                    key={piece.id}
                    quantity={piece.quantity}
                    piece={piece}
                    cabinetName={''}
                    listType={ListType.picking}
                  />
                ))}
              </SwipeableList>
            </div>
          ) : (
            <div>
              <CircleSpinner size={30} color="#fff" />
            </div>
          )}
        </ProductionContainer>
      </SiteWrapper>
    </>
  );
};

export default DrawerPickingPage;
