import React from 'react';
import Img from 'app/components/Img';
import { AppBar, Theme, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import logo from 'assets/images/Kewlox_logo_light.svg';
import { Link } from 'react-router-dom';
import { ElevationScroll } from './ElevationScroll';
import { FiMenu } from 'react-icons/fi';
import Menu from 'app/containers/Header/Menu';

// import styled from 'styled-components';

interface Props {
  title: string;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 999,
    },
  }),
);

const Header = ({ title, leftButton, rightButton }: Props) => {
  const classes = useStyles();

  return (
    <ElevationScroll>
      <AppBar className={classes.root}>
        <Toolbar>
          <div className="flex justify-between items-center w-full">
            {/*{leftButton}*/}
            <div className="flex flex-row items-center">
              <div className="flex">{leftButton}</div>
              <div className="hidden md:flex">
                <Link to={import.meta.env.NODE_ENV !== 'production' ? '/' : ''}>
                  <Img src={logo} alt="logo" className="text-white w-32" />
                </Link>
              </div>
            </div>
            <div
              className={`title  md:text-2xl ${
                rightButton ? 'text-base' : 'text-2xl'
              }`}
            >
              {title}
            </div>
            <div className="flex items-center">
              <div>{rightButton}</div>
              <div className="ml-2">
                <Menu />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};
export default Header;
