import React from 'react';
import CarrierDestinationLabel from 'app/components/Kewlox/CarrierDestinationLabel';
import { format, parseISO } from 'date-fns';
import { Workstation } from 'app/containers/OrderDetails/types';
import { PartOrder } from 'app/models/PartOrder';
import { workstationDescription } from 'app/containers/OrderDetails/helpers';
import OrderIdentification from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingPageHeader/OrderIdentification';

// import styled from 'styled-components';

interface Props {
  order: PartOrder;
  workstation: Workstation;
}

const CuttingPageHeader = ({ order, workstation }: Props) => {
  const { orderInfo } = order;

  return (
    <div>
      <div className="flex justify-around mb-4">
        <div className="flex justify-center items-center text-xl font-bold uppercase flex-col">
          <div>Découpe</div>
          <div>{workstationDescription(workstation)}</div>
        </div>

        <div className="flex flex-col ml-4">
          <div>
            <div className="text-lg flex">
              {orderInfo.firstname}
              <span className="uppercase ml-1">{orderInfo.lastname}</span>
            </div>
            <OrderIdentification partOrder={order} />
          </div>
        </div>

        <div className="flex flex-col items-center">
          {order.productionDate && (
            <div className="ml-2 text-2xl">
              {format(parseISO(order.productionDate), 'dd/MM/yyyy')}
            </div>
          )}
          <CarrierDestinationLabel
            carrier={order.carrier}
            destination={order.destination}
          />
        </div>
      </div>
    </div>
  );
};

export default CuttingPageHeader;
