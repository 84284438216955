import React from 'react';
import { FaRegEnvelope } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Props {
  messageContent: string;
  expedition: boolean;
}

const MessageOrder = ({ messageContent, expedition }: Props) => {
  const data = messageContent.split('%%%');
  const message = data[0];
  const date = new Date(data[1]);
  const employee = data[2];

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between w-full">
        <div
          className={`flex justify-center items-center ml-2 text-white w-32 py-1 z-10 ${
            !expedition ? `bg-orange` : `bg-blue`
          }`}
        >
          <FaRegEnvelope className="h-4 w-4 mx-2" />
          {!expedition ? 'Fabrication' : 'Expedition'}
        </div>
        {date && employee && (
          <div className="flex text-sm">
            <div className="-mt-1 mr-2 font-semibold">{employee}</div>
            <div className="-mt-1 mr-2 italic text-sm">
              Il y a {formatDistanceToNow(date, { locale: fr })}
            </div>
          </div>
        )}
      </div>

      <div
        className={`whitespace-pre-line bg-gray-100 p-2 mb-4 mx-2 border ${
          !expedition ? `border-orange-light` : `border-blue-light`
        }`}
      >
        {message}
      </div>
    </div>
  );
};

export default MessageOrder;
