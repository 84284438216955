import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AppRootState } from 'index';
import { kewloxApi } from 'services/kewloxApi';
import { Carrier } from 'types';

export const carrierAdapter = createEntityAdapter<Carrier>();

const carriersSlice = createSlice({
  name: 'carriersSlice',
  initialState: carrierAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getCarriers.matchFulfilled, // replace with your actual API endpoint
      (state, { payload }) => {
        carrierAdapter.setAll(state, payload);
      },
    );
  },
});

export const {} = carriersSlice.actions;

export const {
  selectById: selectCarrierById,
  selectIds: selectCarrierIds,
  selectEntities: selectCarrierEntities,
  selectAll: selectAllCarriers,
} = carrierAdapter.getSelectors((state: AppRootState) => state.carrier);

export default carriersSlice.reducer;
