/**
 *
 * CarrierSelector
 *
 */

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carrier } from 'app/pages/ToursPage/types';
import useOnClickOutside from 'use-onclickoutside';
import { FaShippingFast } from 'react-icons/fa';
import { PartOrder } from 'app/models/PartOrder';
import { FiChevronDown } from 'react-icons/fi';
import { selectAllCarriers } from 'store/slices/carriersSlice';

interface Props {
  onClick: any;
  selected?: Carrier;
  order?: PartOrder;
}

export function CarrierSelector({ onClick, selected, order }: Props) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const carriers = useSelector(selectAllCarriers);

  useOnClickOutside(ref, () => {
    hide();
  });

  const hide = () => {
    setOpen(false);
  };

  if (!carriers) return <div>Pas de transporteurs trouvés</div>;

  return (
    <div className="relative">
      <button
        className="flex items-center"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <FaShippingFast className="h-6 w-6" />
        <div className="text-lg ml-2">{selected?.name}</div>
        <FiChevronDown />
      </button>

      {isOpen && (
        <div
          ref={ref}
          className="absolute z-50 w-64 bg-white border border-gray-200 -ml-20 shadow-xl"
        >
          {carriers.map((carrier: Carrier) => (
            <div
              key={carrier.id}
              className="border-b border-gray-300 py-2 px-4 hover:bg-gray-200 cursor-pointer flex justify-between"
              onClick={() => {
                onClick(carrier);
                hide();
              }}
            >
              <span>{carrier.name}</span>
              {order && carrier.name === order.carrierSuggested?.name && (
                <span className="font-semibold italic ml-2">Recommandé</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
