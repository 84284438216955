import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import jwtDecode from 'jwt-decode';

function getTokenHelper() {
  // Need to get the token from localStorage if it's exist
  const token = localStorage.getItem('token');
  if (token) return { ...jwtDecode(token), data: token };
  return null;
}

export const initialState: ContainerState = {
  token: getTokenHelper(),
};

const authProviderSlice = createSlice({
  name: 'authProvider',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = jwtDecode(action.payload);
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', action.payload);
      }
    },
    removeToken(state) {
      state.token = undefined;
      localStorage.removeItem('token');
    },
  },
});

export const { setToken, removeToken } = authProviderSlice.actions;

export const { actions, reducer, name: sliceKey } = authProviderSlice;
