import { Tab, Tabs } from '@mui/material';
import React from 'react';
export enum OrderDetailsTabs {
  General = 0,
  Packages = 1,
  Pieces = 2,
  History = 3,
}

interface Props {
  tabSelected: OrderDetailsTabs;
  handleTabChange: any;
  hidePieces: boolean;
}

const TabsOrderDetails = ({
  tabSelected,
  handleTabChange,
  hidePieces,
}: Props) => (
  <div>
    <Tabs
      value={tabSelected}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Général" disableRipple />
      <Tab label="Colis" disableRipple />
      {!hidePieces && <Tab label="Pièces" disableRipple />}
      <Tab label="Historique" disableRipple />
    </Tabs>
  </div>
);

export default TabsOrderDetails;
