import { ProductionPiece } from 'app/models/ProductionPiece';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectProduct } from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/selectors';
import _, { cloneDeep } from 'lodash';
import { pieceRefWithProductionPiece } from 'app/pages/ToursPage/tourHelpers';

interface Props {
  pieces: ProductionPiece[];
  title?: string;
  productId?: number;
}

export function PickingSection({ pieces, title, productId }: Props) {
  const product = useSelector(selectProduct(productId));

  if (pieces.length === 0) return null;

  const piecesGroups = _.groupBy(pieces, piece => piece.reference);
  const piecesAggregated: ProductionPiece[] = [];
  _.forEach(piecesGroups, group => {
    const pieceAggregated = cloneDeep(group[0]);
    const initialValue = 0;
    pieceAggregated.quantity = group.reduce(
      (previousValue, currentValue) => previousValue + currentValue.quantity,
      initialValue,
    );
    piecesAggregated.push(pieceAggregated);
  });
  const piecesAggregatedSorted = _.orderBy(piecesAggregated, [
    'sorting',
    'sortingInType',
    'sortingThird',
  ]);

  return (
    <div>
      {product && (
        <div className="flex ml-4 mt-3 mb-1">
          <div className="text-xl">{product.name}</div>
        </div>
      )}
      {title && !product && (
        <div className="flex ml-4 mt-2 mb-1">
          <div className="text-xl">{title}</div>
        </div>
      )}
      <div>
        {piecesAggregatedSorted.map(piece => (
          <div key={piece.id} className="flex text-lg border-gray-800 my-1">
            <div className="border h-6 w-6 border-gray-800 mr-2" />
            <div className="w-1/12 border-b border-gray-800 font-bold">
              {piece.quantity}
            </div>
            <div className="w-1/6 border-b border-gray-800 text-base">
              {pieceRefWithProductionPiece(piece)}
            </div>
            <div className="border h-6 w-6 border-gray-800 mx-2" />
            <div
              className="w-4/6 border-b border-gray-800"
              dangerouslySetInnerHTML={{
                __html:
                  piece.product && piece.product.uniqueProduct
                    ? piece.product.name
                    : piece.descriptionHTML ?? '',
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
