/**
 *
 * OrderDetails
 *
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDestinationSelected,
  selectIsPastToursList,
} from 'app/containers/ToursList/selectors';
import OrderDetailsContent from 'app/containers/OrderDetails/OrderDetailsContent';
import {
  loadTours,
  selectDestinationFilter,
} from 'app/containers/ToursList/slice';
import {
  selectCurrentPartOrder,
  setCurrentPartOrderId,
} from 'store/slices/activeContentSlice';
import { PartOrder } from 'app/models/PartOrder';
import { useGetPartOrderByIdQuery } from 'services/kewloxApi';

const OrderDetails = () => {
  const dispatch = useDispatch();

  const partOrder: PartOrder | undefined = useSelector(
    selectCurrentPartOrder(),
  );
  // const loading = useSelector(selectOrderLoading);
  const isHistoryTourList = useSelector(selectIsPastToursList);

  const destinationSelected = useSelector(selectDestinationSelected);

  const {
    data: partOrderData,
    isLoading: partOrderLoading,
    isFetching: partOrderFetching,
    refetch: getPartOrderDetails,
  } = useGetPartOrderByIdQuery(partOrder!.id, { skip: !partOrder });

  useEffect(() => {
    if (partOrder) {
      if (partOrder.packages === undefined) {
        getPartOrderDetails();
      }
      if (
        partOrder.destination &&
        destinationSelected?.id !== partOrder.destination.id
      ) {
        dispatch(
          selectDestinationFilter({
            destination: partOrder.destination,
            list: false,
          }),
        );
      }
    }
  }, [partOrder]);

  const close = () => {
    dispatch(setCurrentPartOrderId(undefined));
    dispatch(selectDestinationFilter({ destination: null, list: false }));
    if (isHistoryTourList) dispatch(loadTours());
  };

  return (
    <OrderDetailsContent
      partOrder={partOrder}
      loading={partOrderLoading || partOrderFetching}
      displayOnly={false}
      close={close}
    />
  );
};

export default OrderDetails;
