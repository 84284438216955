// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif; 
  }
  
  body {
    background-color: #f7fafc;
  }
  
  *  {
    outline: none !important;
    &:focus {
        outline: none !important;
      }
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .date-tour .cursor-pointer input{
    cursor: pointer;
  }
  
  textarea {
    color: black !important;
  }

`;
