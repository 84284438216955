import React from 'react';
import 'assets/css/print.css';
// eslint-disable-next-line no-restricted-imports
import { Tour } from 'app/models/Tour';
import { VscChecklist } from 'react-icons/all';
import { useSelector } from 'react-redux';
import { selectPartOrderFromTour } from 'store/slices/toursSlice';

interface Props {
  tour: Tour;
}

export function ShopPackagesSheets({ tour }: Props) {
  const printPickupTable = tour.destination?.shop && !tour.isReseller;

  const data = {
    date: tour.date,
    tourId: tour.id,
    printPickupTable: printPickupTable,
    shopLg: tour.destination?.shopLg ?? 'fr',
    title: tour.title,
  };

  return (
    <form
      action={`${
        import.meta.env.VITE_KEWNET_URL
      }/modules/kewlox/controllers/export_shop.php`}
      method="post"
      target="_blank"
      className="leading-3 text-xs"
    >
      <input
        type="hidden"
        name="pkg_data"
        value={encodeURIComponent(JSON.stringify(data))}
      />
      <button
        data-tip="Imprimer la feuille de colisage"
        data-for="infos"
        type="submit"
      >
        <VscChecklist className="h-5 w-5" />
      </button>
    </form>
  );
}
