import React from 'react';
import Modal from 'react-responsive-modal';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import FormInputErrorStyled from 'app/components/styles/FormInputErrorStyled';
import * as Yup from 'yup';
import { Delivery } from 'app/models/Delivery';
import { updateDelivery } from 'app/containers/ToursList/slice';
import { makeStyles } from '@mui/styles';
import { createStyles, TextField, Theme } from '@mui/material';

interface Props {
  delivery: Delivery;
  show: any;
}

const AddNoteWaypointForm = ({ show, delivery }: Props) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      waypointNote: delivery?.waypointNote ?? '',
      duration: delivery?.duration ?? '1200',
    },
    validationSchema: Yup.object({
      waypointNote: Yup.string().required('Champ requis'),
      duration: Yup.number(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      dispatch(updateDelivery(delivery, { waypointNote: values.waypointNote }));
    },
  });

  return (
    <Modal
      open={true}
      onClose={() => {
        show(undefined);
      }}
      center
    >
      <div className="flex flex-col items-center justify-center">
        <div className="flex justify-center text-xl my-1 mt-4 w-128">
          Ajouter un message pour le livreur
        </div>
        <div className="py-5 px-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="my-3 text-blue">
              <TextField
                id="waypointNote"
                label="Message au livreur"
                variant="outlined"
                placeholder="Ajouter ici un message qui sera imprimer sur la feuille de livraison"
                multiline
                rows={5}
                rowsMax={10}
                {...formik.getFieldProps('waypointNote')}
                error={
                  formik.touched.waypointNote !== undefined &&
                  formik.errors.waypointNote !== undefined
                }
              />
              {formik.touched.waypointNote && formik.errors.waypointNote ? (
                <FormInputErrorStyled>
                  {formik.errors.waypointNote}
                </FormInputErrorStyled>
              ) : null}
            </div>

            <div className="border-b border-gray-400 w-full mb-4" />
            <button type="submit" className="bg-main px-2 py-1 text-white">
              Sauver
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteWaypointForm;
