import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelsToPrint } from './LabelsToPrint';
import { PackageOrder } from 'app/models/PackageOrder';
import { CgAddR, CgArrowsMergeAltH } from 'react-icons/cg';
import { PACKAGE_URL } from 'app/containers/OrderDetails/constants';
import { requestDelete } from 'utils/requests';
import { getPartOrder } from 'app/containers/SiteWrapper/slice';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import PackageDetails from 'app/containers/OrderDetails/PackagesTab/PackageDetails';
import { BiReset } from 'react-icons/bi';
import AddPackageModal from 'app/containers/OrderDetails/PackagesTab/AddPackageModal';
import ReactTooltip from 'react-tooltip';
import Img from 'app/components/Img';
import _ from 'lodash';
import { PackageType } from 'app/containers/OrderDetails/types';
import LoadingSpinner from 'app/containers/OrderDetails/LoadingSpinner';
import SubExpeditionConfirmationModal from 'app/containers/OrderDetails/PackagesTab/SubExpeditionConfirmationModal';
import { FaCubes } from 'react-icons/fa';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Color, findColor } from 'app/models/Color';
import styled from 'styled-components';
import { selectAllColors } from 'store/slices/colorSlice';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';
import {
  useMergePackagesMutation,
  useResetPartOrderMutation,
} from 'services/kewloxApi';

interface Props {
  loading: boolean;
}

const PackagesTab = ({ loading }: Props) => {
  const partOrder = useSelector(selectCurrentPartOrder());
  const orderPackages = partOrder?.packages;
  const [isSubExpeditionModalOpen, setSubExpeditionModalOpen] =
    useState<boolean>(false);
  const [selectedPackages, setSelectedPackages] = useState<Set<number>>(
    new Set<number>(),
  );
  const [showAddingPackageModal, setShowAddingPackageModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const [packageDetails, setPackageDetails] = useState<
    PackageOrder | undefined
  >(undefined);

  const allColors = useSelector(selectAllColors);

  const [resetPartOrder] = useResetPartOrderMutation();
  const [mergePackages] = useMergePackagesMutation();

  const selectAllPackages = () => {
    if (partOrder && orderPackages) {
      const selected = new Set<number>();
      orderPackages.forEach((packageEntry: PackageOrder) => {
        selected.add(packageEntry.id);
      });
      setSelectedPackages(selected);
    }
  };

  // useEffect(() => {
  //   selectAllPackages();
  // }, [partOrder, orderPackages]);

  const isSelected = (id: number) => selectedPackages.has(id);

  const handleSelectedPackage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selected = new Set<number>(selectedPackages);
    const id = parseInt(event.target.name);
    if (event.target.checked && !selected.has(id)) selected.add(id);
    if (!event.target.checked && selected.has(id)) selected.delete(id);

    setSelectedPackages(selected);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) selectAllPackages();
    else setSelectedPackages(new Set<number>());
  };

  const getSelectedPackagesOrder = (): PackageOrder[] => {
    const selectedPackagesOrder: PackageOrder[] = [];
    selectedPackages.forEach(packageOrderId => {
      partOrder?.packages?.forEach(pkg => {
        if (pkg.id === packageOrderId) selectedPackagesOrder.push(pkg);
      });
    });
    return selectedPackagesOrder;
  };

  const selectedPackagesOrder = getSelectedPackagesOrder();

  const handleMerge = () => {
    if (selectedPackages.size > 1) {
      const selectedPkg = Array.from(selectedPackages);
      if (window.confirm(`Fusionner les paquets sélectionnés ?`)) {
        let toPackageId: number | undefined;
        for (const pkgId of selectedPackages) {
          const pkg: PackageOrder | undefined = partOrder?.packages?.find(
            pkg => pkg.id === pkgId,
          );
          if (pkg && pkg.type === PackageType.Cabinet && pkg.productCabinet) {
            toPackageId = pkg.id;
            break;
          }
        }
        if (!toPackageId) toPackageId = selectedPkg[0];

        const data = {
          packages: selectedPkg.filter(entry => entry !== toPackageId),
        };
        mergePackages({ pkgId: toPackageId, partOrderId: partOrder!.id, data })
          .unwrap()
          .catch(error => {
            console.error('Error merging packages:', error);
          });
      }
    }
  };

  const handleReset = () => {
    if (partOrder) {
      if (window.confirm(`Voulez-vous réinitialiser les colis`)) {
        resetPartOrder(partOrder.id)
          .unwrap()
          .catch(error => {
            console.error('Error resetting part order:', error);
          });
      }
    }
  };

  const handleAdd = () => {
    if (partOrder) {
      setShowAddingPackageModal(true);
    }
  };

  const handleRemove = () => {
    if (selectedPackages.size === 1) {
      const selectedPkg = Array.from(selectedPackages);
      if (window.confirm(`Supprimer le paquet sélectionné ?`)) {
        const url = `${PACKAGE_URL}/${selectedPkg[0]}/`;
        requestDelete(url).then(() => {
          if (partOrder) dispatch(getPartOrder(partOrder.id));
        });
      }
    }
  };

  if (loading) return <LoadingSpinner />;

  if (partOrder && partOrder.packages) {
    return (
      <>
        <TableContainer
          className="overflow-y-auto"
          style={{ height: 'calc(100% - 170px)' }}
        >
          <Table
            stickyHeader
            size="small"
            aria-label="Pieces packages informations"
          >
            <TableHead className="border-b-2 border-black">
              <TableRow>
                <TableCell align="left" colSpan={3}>
                  <div className="flex items-center">
                    <Checkbox
                      checked={selectedPackages.size > 0}
                      indeterminate={
                        selectedPackages.size > 0 &&
                        selectedPackages.size < partOrder.packages.length
                      }
                      onChange={handleSelectAll}
                      color="default"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <div className="flex justify-between w-3/4 items-center">
                      <div className="text-lg">Liste des colis</div>
                      <div className="flex justify-center items-center">
                        <div className="mx-2 px-3 py-1 flex justify-center items-center">
                          {selectedPackages.size > 0 && (
                            <LabelsToPrint
                              partOrder={partOrder}
                              packagesOrder={selectedPackagesOrder}
                            />
                          )}
                        </div>
                        {selectedPackages.size > 1 && (
                          <button
                            onClick={handleMerge}
                            className="px-3 py-1 mx-2 "
                            data-tip="Fusionner les colis sélectionnés"
                          >
                            <CgArrowsMergeAltH className="h-6 w-6 " />
                          </button>
                        )}
                        <button
                          onClick={handleReset}
                          className="px-3 py-1 mx-2 "
                          data-tip="Réinitialiser tous les colis"
                        >
                          <BiReset className="h-6 w-6 " />
                        </button>
                        <ReactTooltip place="bottom" type="dark" />
                        <button
                          onClick={handleAdd}
                          className="px-3 py-1 mx-2 "
                          data-tip="Ajouter un colis"
                        >
                          <CgAddR className="h-6 w-6 " />
                        </button>
                        {selectedPackages.size === 1 &&
                          Array.from(selectedPackages)[0] &&
                          partOrder?.packages?.find(
                            pkg => pkg.id === Array.from(selectedPackages)[0],
                          )?.isGenericPiecesOnly && (
                            <button
                              onClick={handleRemove}
                              className="px-3 py-1 mx-2 "
                              data-tip="Supprimer le colis sélectionné"
                            >
                              <FiTrash2 className="h-6 w-6 " />
                            </button>
                          )}
                        {selectedPackages.size > 0 && (
                          <button
                            onClick={() => {
                              setSubExpeditionModalOpen(true);
                            }}
                            className="px-3 py-1 mx-2 "
                            data-tip="Créer une livraison partielle"
                          >
                            <FaCubes className="h-6 w-6 " />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <FormControl component="fieldset" className="w-full">
              <FormGroup>
                <div className="table">
                  <TableBody>
                    {_.orderBy(partOrder.packages, ['type', 'name']).map(
                      (packageEntry: PackageOrder, index: number) => {
                        const uniqueDescr = packageEntry.uniqueDescr;
                        return (
                          <TableRow key={packageEntry.id}>
                            <TableCell align="left">
                              <div className="flex items-center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isSelected(packageEntry.id)}
                                      color="primary"
                                      onChange={handleSelectedPackage}
                                      name={`${packageEntry.id}`}
                                    />
                                  }
                                  label={
                                    uniqueDescr
                                      ? packageEntry.uniqueDescr
                                      : packageEntry.name
                                      ? parsePackageEntryName(
                                          packageEntry.name,
                                          allColors,
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="w-16">
                                {packageEntry.averageWeight
                                  ? (packageEntry.averageWeight / 1000).toFixed(
                                      2,
                                    )
                                  : '-'}
                                <span className="text-sm ml-1">kg</span>
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              {packageEntry.productCabinet &&
                                packageEntry.productCabinet.image && (
                                  <div className="w-12 h-12">
                                    <Img
                                      src={packageEntry.productCabinet.image}
                                      alt={`preview `}
                                    />
                                  </div>
                                )}
                            </TableCell>
                            <TableCell align="right">
                              <div className="flex items-center justify-end w-full">
                                <button
                                  onClick={() => {
                                    setPackageDetails(packageEntry);
                                  }}
                                  className="flex items-center border border-gray-400 py-1 px-2 w-24"
                                >
                                  <FiEdit3 className="h-4 w-4 mr-2" />
                                  Modifier
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableBody>
                </div>
              </FormGroup>
            </FormControl>
          </Table>
        </TableContainer>
        <PackageDetails
          packageOrder={packageDetails}
          setPackageDetails={setPackageDetails}
        />
        {showAddingPackageModal && partOrder && (
          <AddPackageModal
            show={setShowAddingPackageModal}
            partOrder={partOrder}
          />
        )}
        <SubExpeditionConfirmationModal
          partOrder={partOrder}
          isModalOpen={isSubExpeditionModalOpen}
          setModalOpen={setSubExpeditionModalOpen}
          selectedPackages={selectedPackages}
        />
        <ReactTooltip id="colorInfo" place="bottom" type="dark" />
      </>
    );
  } else {
    return null;
  }
};

export function parsePackageEntryName(
  name: string,
  colors: Color[],
): JSX.Element {
  const parts = name.split(/(\[.*?\])/); // Split by color codes within brackets
  return (
    <div className="flex flex-row flex-wrap">
      {parts.map((part, index) => {
        if (part.startsWith('[') && part.endsWith(']')) {
          const colorCode = part.substring(1, part.length - 1);
          const colorInfo = findColor(colorCode, colors);
          if (!colorInfo) return part;
          return (
            <div key={index} className="flex">
              {part}
              <ColorIndicator
                color={colorInfo.hexaHtml}
                className="w-6 h-5 mx-1 border border-gray-700 text-indigo-dark"
                data-tip={colorInfo.name}
                data-for="colorInfo"
              />
            </div>
          );
        }
        return part; // Return the text part directly
      })}
    </div>
  );
}

export const ColorIndicator = styled.div<{ color: string }>`
  background-color: ${props => props.color};
`;

export default PackagesTab;
