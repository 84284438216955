import React from 'react';
import Modal from 'react-responsive-modal';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CgAddR } from 'react-icons/cg';
import { HiOutlineMinusCircle } from 'react-icons/all';
import { useDispatch } from 'react-redux';
import { PackageType } from 'app/containers/OrderDetails/types';
import { PartOrder } from 'app/models/PartOrder';
import { useAddPackagesMutation } from 'services/kewloxApi';

interface Props {
  show: any;
  partOrder: PartOrder;
}

const AddPackageModal = ({ show, partOrder }: Props) => {
  const dispatch = useDispatch();

  const [addPackages] = useAddPackagesMutation();

  const initialValues = {
    pieces: [
      { name: '', quantity: 1 },
      { name: '', quantity: 1 },
      { name: '', quantity: 1 },
      { name: '', quantity: 1 },
      { name: '', quantity: 1 },
    ],
  };

  const handleSubmit = async values => {
    const data = {
      order: partOrder.id,
      type: PackageType.Other,
      pieces: values.pieces.filter(entry => entry.name !== ''),
    };

    try {
      await addPackages({ partOrderId: partOrder.id, data }).unwrap();
      show(false);
    } catch (e) {
      console.error('Error with addPackages mutation', e);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        show(false);
      }}
      center
    >
      <div className="flex flex-col items-center justify-center w-128 ">
        <div className="flex justify-center text-xl my-1">
          Ajouter un nouveau paquet
        </div>
        <div className="border-b border-gray-400 w-full mb-4" />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ values }) => (
            <Form>
              <FieldArray
                name="pieces"
                render={arrayHelpers => (
                  <div>
                    {values.pieces && values.pieces.length > 0 ? (
                      values.pieces.map((piece, index) => (
                        <div key={index} className="flex items-center my-2">
                          <Field
                            name={`pieces.${index}.quantity`}
                            className="border border-gray-200 p-1 mr-2 w-12"
                          />
                          <Field
                            name={`pieces.${index}.name`}
                            placeholder="Référence de la pièce"
                            className="border border-gray-300 p-1 w-80"
                          />
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a piece from the list
                            className="ml-2"
                          >
                            <HiOutlineMinusCircle className="h-5 w-5" />
                          </button>
                        </div>
                      ))
                    ) : (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push('')}
                      >
                        Ajouter une piece
                      </button>
                    )}
                    <div className="flex justify-center my-5">
                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.insert(0, {
                            name: '',
                            quantity: 1,
                          });
                          arrayHelpers.insert(0, {
                            name: '',
                            quantity: 1,
                          });
                          arrayHelpers.insert(0, {
                            name: '',
                            quantity: 1,
                          });
                        }}
                      >
                        <CgAddR className="h-6 w-6" />
                      </button>
                    </div>
                    <div className="mt-3 flex justify-end">
                      <button
                        type="submit"
                        className="bg-main text-white text-lg px-2 py-1"
                      >
                        Créer le paquet
                      </button>
                    </div>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export default AddPackageModal;
