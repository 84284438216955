import React from 'react';
// import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
}

const ProductionContainer = ({ children }: Props) => (
  <div className="flex justify-center  ">
    <div className="w-full md:w-2/3 lg:w-3/5 my-4">{children}</div>
  </div>
);

export default ProductionContainer;
