import { Workstation } from 'app/containers/OrderDetails/types';
import { ProductOrder } from 'app/models/ProductOrder';
import { DoorHole, GLASS_COLOR, MIRROR_COLOR } from 'app/constants';
import { Color, extractColor } from 'app/models/Color';
import { parseISO } from 'date-fns';
import { PartOrder } from 'app/models/PartOrder';
import { pieceRef } from '../pages/ToursPage/tourHelpers';

export interface ProductionPieceJson {
  id: number;
  order: number;
  cutting: boolean;
  station: Workstation;
  type: string;
  reference: string;
  description?: any; // string[];
  quantity: number;
  quantityCutted: number;
  quantityPicked: number;
  quantityWrapped: number;
  quantityStandby: number;
  quantityDamaged: number;
  height: number;
  width: number;
  depth: number;
  doorHole?: DoorHole;
  pickedAt?: any; //string;
  cuttedAt?: any; //string;
  product?: any; //number;
  trolley: number;
  trolleyName: string;
  drawer?: number;
  sorting?: number;
  cabinet?: number;
  slatMaterial?: string;
  largeDoorPosition?: string;
  isOutOfStock: boolean;
}

export interface ProductionPiece extends ProductionPieceJson {
  description?: string[];
  pickedAt?: Date;
  cuttedAt?: Date;
  color?: Color;
  sortingThird: number;
  sortingInType: number;
  sorting: number;
  typeName: string;
  name: string;
  shortName: string;
  product?: ProductOrder;
  cabinetName: string;
  isSparePart: boolean;
  isWidth62: boolean;
  hasDRL: boolean;
  isOutOfStock: boolean;
  descriptionFr: string;
  descriptionNl: string;
  descriptionEn: string;
  descriptionHTML?: string;
  isFragile: boolean;
  isFragileCustom: boolean;
  basePieceRef: string;
  isSparePartInsideCabinetPackage: boolean;
  calibrated: boolean;
}

export const createProductionPiece = (
  data: ProductionPieceJson,
  product?: ProductOrder,
  orderMap?: Map<number, PartOrder>,
  colors: Color[] = [],
): ProductionPiece => {
  const color = extractColor(data.reference, colors);

  return {
    ...data,
    doorHole: data.doorHole ? data.doorHole : undefined,
    type: getTypeName(data),
    description: data.description,
    pickedAt: data.pickedAt ? parseISO(data.pickedAt) : undefined,
    cuttedAt: data.cuttedAt ? parseISO(data.cuttedAt) : undefined,
    color: color,
    sortingThird: getSortingThird(data),
    sortingInType: getSortingInType(data),
    sorting: getSorting(data),
    typeName: getTypeName(data),
    name: getName(data),
    shortName: getShortName(data),
    product,
    cabinetName: getCabinetName(product),
    isSparePart: getIsSparePart(product),
    isWidth62: false,
    hasDRL: false,
    isOutOfStock: data.isOutOfStock,
    descriptionFr: getDescriptionForLang(data, 'fr'),
    descriptionNl: getDescriptionForLang(data, 'nl'),
    descriptionEn: getDescriptionForLang(data, 'en'),
    descriptionHTML: getDescriptionHTML(data),
    isFragile: getIsFragile(color),
    isFragileCustom: getIsFragileCustom(color, data.cutting),
    basePieceRef: getBasePieceRef(data),
    isSparePartInsideCabinetPackage: getIsSparePartInsideCabinetPackage(data),
    calibrated: data.reference.includes('-CAL'),
  };
};

const getSortingThird = (data: ProductionPieceJson): number => {
  switch (getTypeName(data)) {
    case 'door':
    case 'pane':
    case 'plate':
      return data.width;
    case 'cross piece':
      if (data.reference.startsWith('F1')) {
        return data.reference.endsWith('S') ? 9 : 8;
      }
      if (data.reference.startsWith('F2')) {
        return data.reference.endsWith('S') ? 11 : 10;
      }
      if (data.reference.startsWith('G1')) return 12;
      if (data.reference.startsWith('G2')) return 13;
      return 15;
    default:
      return 1;
  }
};

const getSortingInType = (data: ProductionPieceJson): number => {
  switch (getTypeName(data)) {
    case 'rod':
      return data.width;
    case 'cross piece':
      return -data.width;
    case 'feet cabinet':
    case 'batten':
    case 'door':
    case 'pane':
    case 'plate':
      return data.height;
    case 'door handle':
      return 1;
    default:
      return 1;
  }
};

const getSorting = (data: ProductionPieceJson): number => {
  if (data.reference === 'PA-CN') return 21;
  switch (getTypeName(data)) {
    case 'cross piece':
      if (data.reference.endsWith('P')) return 15;
      if (data.reference.startsWith('G1') || data.reference.startsWith('G2'))
        return 10;
      if (data.reference.startsWith('F1') || data.reference.startsWith('F2'))
        return 12;
      return 17;
    case 'rod':
      return 18;
    case 'feet cabinet':
      return 20;
    case 'batten':
      return 25;
    case 'door handle':
      return 30;
    case 'door':
      return 40;
    case 'pane':
      if (!data.reference.startsWith('F')) return 50;
      return 51;
    case 'plate':
      return 60;
    case 'intermediate plank':
    case 'intermediate  plank':
      return 100;
    case 'support is intermediate':
      return 110;
    case 'reinforcement is intermediate':
      return 120;
    default:
      return 1000;
  }
};

const getTypeName = (data: ProductionPieceJson): string => {
  return data.type
    .replace('rack', '')
    .replace('shelf', '')
    .replace('  ', ' ')
    .replace('top cabinet', '')
    .trim();
};

const getName = (data: ProductionPieceJson): string => {
  return pieceRef(data.reference);
};

const getShortName = (data: ProductionPieceJson): string => {
  return getName(data).replace(/ *\[[^\]]*]/, '');
};

const getCabinetName = (product?: ProductOrder): string => {
  return product ? product.cabinetName : '';
};

const getIsSparePart = (product?: ProductOrder): boolean => {
  return product ? product.cabinet === undefined : true;
};

const getDescriptionForLang = (
  data: ProductionPieceJson,
  lang: string,
): string => {
  if (data.description && data.description.length > 0) {
    switch (lang) {
      case 'fr':
        return cleanText(data.description[0]);
      case 'nl':
        return cleanText(data.description[1]);
      case 'en':
        return cleanText(data.description[2]);
    }
  }
  return '';
};

const getDescriptionHTML = (data: ProductionPieceJson): string | undefined => {
  if (data.description && data.description.length > 0)
    return data.description[0];
  return undefined;
};

export function productionPieceGetDescription(
  piece: ProductionPiece,
  lang: string,
) {
  return getDescriptionForLang(piece, lang);
}

const cleanText = (text: string): string => {
  return text ? text.replace(/<\/?[^>]+(>|$)/g, '') : '';
};

const getIsFragile = (color?: Color): boolean => {
  return color ? [GLASS_COLOR, MIRROR_COLOR].includes(color.reference) : false;
};

const getIsFragileCustom = (color?: Color, cutting?: boolean): boolean => {
  return color &&
    (color.reference === GLASS_COLOR || color.reference === MIRROR_COLOR)
    ? !!cutting
    : false;
};

const getBasePieceRef = (data: ProductionPieceJson): string => {
  const regex = /\*(.+)-CAL.+/g;
  const result = [...data.reference.matchAll(regex)];

  return result && result.length === 1 && result[0].length === 2
    ? result[0][1].toString()
    : getShortName(data);
};

const getIsSparePartInsideCabinetPackage = (
  data: ProductionPieceJson,
): boolean => {
  if (!data.trolley) return false;
  if (!data.trolleyName) return false;
  return parseInt(data.trolleyName) !== data.trolley && !data.cabinet;
};

// export class ProductionPiece {
//   id: number;
//   order?: PartOrder;
//   cutting: boolean;
//   station: Workstation;
//   type: string;
//   reference: string;
//   _description?: string[];
//   cabinet?: number;
//   furniture?: number;
//   quantity: number;
//   quantityCutted: number;
//   quantityPicked: number;
//   quantityWrapped: number;
//   quantityStandby: number;
//   quantityDamaged: number;
//   height: number;
//   width: number;
//   depth: number;
//   doorHole?: DoorHole;
//   pickedAt?: Date;
//   cuttedAt?: Date;
//   color?: Color;
//   private _product?: ProductOrder;
//   // sparePart?: boolean;
//   // product: number;
//   trolley: number;
//   trolleyName: string;
//   drawer?: number;
//   slatMaterial?: string;
//   largeDoorPosition?: string;
//   isWidth62 = false;
//   hasDRL = false;
//   isOutOfStock = false;
//
//   constructor(
//     data: ProductionPieceJson,
//     product?: ProductOrder,
//     orderMap?: Map<number, PartOrder>,
//   ) {
//     this.id = data.id;
//     this.type = data.type
//       .replace('rack', '')
//       .replace('shelf', '')
//       .replace('top cabinet', '')
//       .trim();
//     this.cutting = data.cutting;
//     this.station = data.station;
//     this.reference = data.reference;
//     this._description = data.description;
//     this.quantity = data.quantity;
//     this.quantityCutted = data.quantityCutted;
//     this.quantityPicked = data.quantityPicked;
//     this.quantityWrapped = data.quantityWrapped;
//     this.quantityStandby = data.quantityStandby;
//     this.quantityDamaged = data.quantityDamaged;
//     this.trolley = data.trolley;
//     this.trolleyName = data.trolleyName;
//     this.slatMaterial = data.slatMaterial;
//     this.largeDoorPosition = data.largeDoorPosition;
//     this.isOutOfStock = data.isOutOfStock;
//     if (data.drawer) this.drawer = data.drawer;
//
//     if (product) {
//       this._product = product;
//       this.cabinet = product.cabinet;
//       this.furniture = product.furniture;
//     } else if (data.cabinet) this.cabinet = data.cabinet;
//
//     this.height = data.height;
//     this.width = data.width;
//     this.depth = data.depth;
//     if (data.doorHole) this.doorHole = data.doorHole;
//     if (data.pickedAt) this.pickedAt = parseISO(data.pickedAt);
//     if (data.cuttedAt) this.cuttedAt = parseISO(data.cuttedAt);
//
//     this.color = this.getColor();
//
//     if (orderMap) {
//       const orderEntry = orderMap.get(data.order);
//       if (orderEntry) {
//         this.order = orderEntry;
//       }
//     }
//   }
//
//   get sortingThird(): number {
//     switch (this.type) {
//       case 'door':
//         return this.width;
//       case 'pane':
//         return this.width;
//       case 'plate':
//         return this.width;
//       case 'cross piece':
//         if (this.reference.startsWith('F1')) {
//           if (!this.reference.endsWith('S')) return 8;
//           return 9;
//         }
//         if (this.reference.startsWith('F2')) {
//           if (!this.reference.endsWith('S')) return 10;
//           return 11;
//         }
//         if (this.reference.startsWith('G1')) return 12;
//         if (this.reference.startsWith('G2')) return 13;
//         return 15;
//       default:
//         return 1;
//     }
//   }
//
//   get sortingInType(): number {
//     switch (this.type) {
//       case 'rod':
//         return this.width;
//       case 'cross piece':
//         return -this.width;
//       case 'feet cabinet':
//         return this.height;
//       case 'batten':
//         return this.height;
//       case 'door handle':
//         return 1;
//       case 'door':
//         return this.height;
//       case 'pane':
//         return this.height;
//       case 'plate':
//         return this.height;
//       default:
//         return 1;
//     }
//   }
//
//   get sorting(): number {
//     if (this.reference === 'PA-CN') return 21;
//     switch (this.type) {
//       case 'cross piece':
//         if (this.reference.endsWith('P')) return 15;
//         if (this.reference.startsWith('G1') || this.reference.startsWith('G2'))
//           return 10;
//         if (this.reference.startsWith('F1') || this.reference.startsWith('F2'))
//           return 12;
//         return 17;
//       case 'rod':
//         return 18;
//       case 'feet cabinet':
//         return 20;
//       case 'batten':
//         return 25;
//       case 'door handle':
//         return 30;
//       case 'door':
//         return 40;
//       case 'pane':
//         if (!this.reference.startsWith('F')) return 50;
//         return 51;
//       case 'plate':
//         return 60;
//       case 'intermediate plank':
//       case 'intermediate  plank':
//         return 100;
//       case 'support is intermediate':
//         return 110;
//       case 'reinforcement is intermediate':
//         return 120;
//       default:
//         return 1000;
//     }
//   }
//
//   get typeName(): string {
//     return this.type
//       .replace('rack', '')
//       .replace('shelf', '')
//       .replace('  ', ' ');
//   }
//
//   get name(): string {
//     return pieceRef(this.reference);
//   }
//
//   get shortName(): string {
//     // The reference without the color
//     return this.name.replace(/ *\[[^\]]*]/, '');
//   }
//
//   get product(): ProductOrder | undefined {
//     return this._product;
//   }
//
//   set product(product: ProductOrder | undefined) {
//     this._product = product;
//   }
//
//   get cabinetName(): string {
//     if (this._product) {
//       return this._product.cabinetName;
//     } else {
//       return '';
//     }
//   }
//
//   get isSparePart(): boolean {
//     if (this._product) {
//       return this._product.cabinet === undefined;
//     }
//     return true;
//   }
//
//   clone(): ProductionPiece {
//     return cloneDeep(this);
//   }
//
//   getColor(): Color | undefined {
//     return Color.extractColor(this.reference);
//   }
//
//   get calibrated(): boolean {
//     const results = this.reference.match(/(.)+-CAL(.)+/);
//     return !!(results && results.length > 0);
//   }
//
//   get basePieceRef(): string {
//     const regex = /\*(.+)-CAL.+/g;
//     const result = [...this.reference.matchAll(regex)];
//
//     if (result && result.length === 1 && result[0].length === 2)
//       return result[0][1].toString();
//     return this.shortName;
//   }
//
//   get isSparePartInsideCabinetPackage(): boolean {
//     if (!this.trolley) return false;
//     return parseInt(this.trolleyName) !== this.trolley && !this.cabinet;
//   }
//
//   get description(): string | undefined {
//     if (this._description) return this.descriptionFr;
//     return undefined;
//   }
//
//   get descriptionHTML(): string | undefined {
//     if (this._description && this._description.length > 0)
//       return this._description[0];
//     return undefined;
//   }
//
//   cleanText(text: string): string {
//     if (text) return text.replace(/<\/?[^>]+(>|$)/g, '');
//     return text;
//   }
//
//   get descriptionFr(): string {
//     if (this._description && this._description.length > 0)
//       return this.cleanText(this._description[0]);
//     return '';
//   }
//
//   get descriptionNl(): string {
//     if (this._description && this._description.length > 0)
//       return this.cleanText(this._description[1]);
//     return '';
//   }
//
//   get descriptionEn(): string {
//     if (this._description && this._description.length > 0)
//       return this.cleanText(this._description[2]);
//     return '';
//   }
//
//   descriptionForLang(lang: string) {
//     switch (lang) {
//       case 'fr':
//         return this.descriptionFr;
//       case 'nl':
//         return this.descriptionNl;
//       case 'en':
//         return this.descriptionEn;
//     }
//     return '';
//   }
//
//   get isFragile(): boolean {
//     if (this.color) {
//       return (
//         this.color.reference === GLASS_COLOR ||
//         this.color.reference === MIRROR_COLOR
//       );
//     }
//     return false;
//   }
//
//   get isFragileCustom(): boolean {
//     if (this.color) {
//       if (
//         this.color.reference === GLASS_COLOR ||
//         this.color.reference === MIRROR_COLOR
//       ) {
//         return this.cutting;
//       }
//     }
//     return false;
//   }
// }
