import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TourListState } from 'app/containers/ToursList/types';
import { AppThunk } from 'store/reducers';
import { DELIVERY_URL, TOUR_URL } from 'app/pages/ToursPage/constants';
import { formatISO } from 'date-fns';
import { requestGet, requestPut } from 'utils/requests';
import { ASSEMBLY_TIME } from 'app/constants';
import { createTour, Tour, TourJson } from 'app/models/Tour';
import { Destination } from 'app/models/Destination';
import { PartOrder } from 'app/models/PartOrder';
import { Delivery } from 'app/models/Delivery';
import { kewloxApi } from 'services/kewloxApi';
import { setCurrentTourId } from 'store/slices/activeContentSlice';

export const initialState: TourListState = {
  tours: [],
  loading: false,
  error: null,
  filteredList: false,
  loadingTours: [],
  destination: null,
  isDragging: false,
  pastTours: false,
};

export const toursListSlice = createSlice({
  name: 'toursList',
  initialState,
  reducers: {
    tourLoading: (state, action: PayloadAction<number>) => {
      state.loadingTours.push(action.payload);
      state.error = null;
    },
    loadTour: (state, action: PayloadAction<number>) => {
      state.loadingTours.push(action.payload);
      state.error = null;
    },
    tourLoaded: (state, action: PayloadAction<Tour>) => {
      state.tours = state.tours.map(e => {
        if (e.id === action.payload.id) {
          return action.payload;
        }
        return e;
      });
      state.loadingTours = [...state.loadingTours].filter(
        entry => entry !== action.payload.id,
      );
    },
    loadTours: (state, action: PayloadAction<boolean | undefined>) => {
      if (!action.payload) state.loading = true;
      state.error = null;
    },
    toursLoaded: (
      state,
      action: PayloadAction<{ tours: Tour[]; pastTours: boolean }>,
    ) => {
      state.tours = action.payload.tours;
      state.pastTours = action.payload.pastTours;
      state.loading = false;
    },
    loadingToursError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addTour: (state, action: PayloadAction<Tour>) => {
      state.tours.push(action.payload);
    },
    removeTour: (state, action: PayloadAction<number>) => {
      state.tours = state.tours.filter(tour => tour.id !== action.payload);
    },
    removeOrder: (
      state,
      action: PayloadAction<{ tourId: number; orderId: number }>,
    ) => {
      const tours = state.tours.filter(
        tour => tour.id !== action.payload.tourId,
      );
      if (tours && tours.length > 0) {
        const tour = tours[0];
        tour.orders.filter(e => e.order?.id !== action.payload.orderId);
      }
    },
    selectDestinationFilter: (
      state,
      action: PayloadAction<{ destination: Destination | null; list: boolean }>,
    ) => {
      state.destination = action.payload.destination;
      state.filteredList = action.payload.list;
    },
    requestError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload.statusText;
      state.loadingTours = [];
    },
    setOrderTourDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    assignOrderTour: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getActiveTours.matchFulfilled,
      (state, { payload }) => {
        state.loadingTours = [];
      },
    );
  },
});

export const addNewTour =
  (
    date: string,
    carrierId: number,
    partOrder?: PartOrder,
    delivery?: Delivery,
  ): AppThunk =>
  async dispatch => {
    const url = `${TOUR_URL}`;
    const data = { date: date, carrierId: carrierId };
    if (partOrder && partOrder.destination) {
      data['destination_id'] = partOrder.destination.id;
    }

    try {
      const result = await dispatch(kewloxApi.endpoints.addTour.initiate(data));

      if ('data' in result) {
        const tour = createTour(result.data);
        dispatch(actions.addTour(tour));
        if (delivery) {
          dispatch(updateDelivery(delivery, { tour: tour.id }));
        } else if (partOrder) {
          dispatch(addDelivery(tour, 0, partOrder));
        }
      } else if ('error' in result) {
        dispatch(actions.requestError(result.error));
      }
    } catch (error) {
      dispatch(actions.requestError(error));
    }

    // requestPost(url, data)
    //   .then((res: TourJson) => {
    //     const tour = createTour(res);
    //     dispatch(actions.addTour(tour));
    //     if (delivery) {
    //       dispatch(updateDelivery(delivery, { tour: tour.id }));
    //     } else if (partOrder) {
    //       dispatch(addDelivery(tour, 0, partOrder));
    //     }
    //   })
    //   .catch((error: any) => {
    //     dispatch(actions.requestError(error));
    //   });
  };

export const deleteTour =
  (tour: Tour): AppThunk =>
  dispatch => {
    dispatch(actions.tourLoading(tour.id));

    try {
      dispatch(kewloxApi.endpoints.deleteTour.initiate(tour.id));
    } catch (error) {
      console.error('An error occurred while deleting tour', error);
    }

    // const url = `${TOUR_URL}${tour.id}/`;
    // dispatch(actions.tourLoading(tour.id));
    // requestDelete(url)
    //   .then(() => {
    //     dispatch(actions.removeTour(tour.id));
    //   })
    //   .catch((error: any) => {
    //     dispatch(actions.requestError(error));
    //   });
  };

export const updateTour =
  (
    tour: Tour,
    date?: Date,
    manual?: boolean,
    delivered?: boolean,
    workforce?: number,
  ): AppThunk =>
  async dispatch => {
    const url = `${TOUR_URL}${tour.id}/`;
    const data = {};
    if (date) {
      data['date'] = formatISO(date);
    }
    if (manual !== undefined) {
      data['manual'] = manual;
    }
    if (delivered !== undefined) {
      data['delivered'] = delivered;
    }
    if (workforce !== undefined) {
      data['workforce'] = workforce;
    }
    dispatch(actions.tourLoading(tour.id));
    try {
      const result = await dispatch(
        kewloxApi.endpoints.updateTour.initiate({ id: tour.id, ...data }),
      );
    } catch (e) {
      console.error('An error occurred while updating tour', e);
    }

    // requestPut(url, data)
    //   .then((res: OrderInfo) => {
    //     if (delivered) dispatch(actions.removeTour(tour.id));
    //     else dispatch(actions.loadTour(tour.id));
    //   })
    //   .catch((error: any) => {
    //     dispatch(actions.requestError(error));
    //   });
  };

// export const setDriversTour = (
//   tour: Tour,
//   drivers: Driver[],
// ): AppThunk => dispatch => {
//   const url = `${TOUR_URL}${tour.id}/set_drivers/`;
//   const data = {
//     drivers: drivers,
//   };
//   dispatch(actions.tourLoading(tour.id));
//   requestPost(url, data)
//     .then((res: OrderInfo) => {
//       dispatch(actions.loadTour(tour.id));
//     })
//     .catch((error: any) => {
//       dispatch(actions.requestError(error));
//     });
// };

export const addDelivery =
  (
    tour: Tour,
    index: number,
    partOrder?: PartOrder,
    waypointId?: number,
  ): AppThunk =>
  async (dispatch, getState) => {
    const data = {
      partOrder: partOrder?.id,
      tour: tour.id,
      index: index,
      waypoint: waypointId,
    };

    if (partOrder && partOrder.orderInfo.assembly) {
      // Time for the worker to do the assembly
      data['unloadingTime'] = ASSEMBLY_TIME;
    }

    dispatch(actions.tourLoading(tour.id));

    try {
      const result = await dispatch(
        kewloxApi.endpoints.addDelivery.initiate(data),
      );

      if ('data' in result) {
        dispatch(setCurrentTourId(tour.id));
      } else if ('error' in result) {
        // Error response
        console.error('Failed to add delivery');
      }
    } catch (error) {
      console.error('An error occurred while adding delivery', error);
    }

    // const url = `${DELIVERY_URL}`;
    // requestPost(url, data)
    //   .then((res: OrderInfo) => {
    //     dispatch(actions.loadTour(tour.id));
    //     dispatch(actions.selectTour(tour.id));
    //   })
    //   .catch((error: any) => {
    //     dispatch(actions.requestError(error));
    //   });
  };

export const updateDelivery =
  (delivery: Delivery, data: Partial<Delivery>): AppThunk =>
  async dispatch => {
    const oldTour: number = delivery.tour as number;
    let newTourId: number = oldTour;
    if (data.tour) newTourId = data.tour as number;

    if (newTourId) dispatch(actions.tourLoading(newTourId));
    if (oldTour !== newTourId) {
      dispatch(actions.tourLoading(oldTour as number));
    }

    const partOrderId = delivery.partOrder as number;

    try {
      const result = await dispatch(
        kewloxApi.endpoints.updateDelivery.initiate({
          id: delivery.id,
          partOrder: partOrderId, // Use to refresh the part order
          ...data,
        }),
      );
    } catch (error) {
      console.error('An error occurred while updating delivery', error);
    }
  };

// export const updateDeliveryPosition = (
//   delivery: Delivery,
//   tour: Tour,
//   position: number,
// ): AppThunk => dispatch => {
//   const url = `${DELIVERY_URL}${delivery.id}/`;
//   const data = {
//     tour: tour.id,
//     position,
//   };
//   // dispatch(actions.tourLoading(tour.id));
//
//   try {
//     const result = dispatch(
//       kewloxApi.endpoints.updateDelivery.initiate({ id: delivery.id, ...data }),
//     );
//
//     if ('data' in result) {
//       dispatch(kewloxApi.endpoints.getTour.initiate(tour.id));
//       dispatch(actions.selectTour(tour.id));
//     } else if ('error' in result) {
//       console.error('Failed to add delivery');
//     }
//   } catch (error) {
//     console.error('An error occurred while updating delivery', error);
//   }
//
//   requestPut(url, data)
//     .then((res: OrderInfo) => {
//       dispatch(actions.loadTour(tour.id));
//       dispatch(actions.selectTour(tour.id));
//     })
//     .catch((error: any) => {
//       dispatch(actions.requestError(error));
//     });
// };

export const deleteDeliveryAction =
  (delivery: Delivery): AppThunk =>
  dispatch => {
    try {
      dispatch(kewloxApi.endpoints.deleteDelivery.initiate(delivery.id));
    } catch (error) {
      console.error('An error occurred while updating delivery', error);
    }
  };

export const searchOrderTour =
  (orderId: number): AppThunk =>
  dispatch => {
    const url = `${TOUR_URL}search?order=${orderId}`;
    requestGet(url)
      .then((res: TourJson[]) => {
        // dispatch(
        //   toursLoaded(res.map((tourJson: TourJson) => new Tour(tourJson, []))),
        // );
      })
      .catch((error: any) => {
        dispatch(actions.requestError(error));
      });
  };

export const {
  loadTours,
  selectDestinationFilter,
  setOrderTourDragging,
  requestError,
} = toursListSlice.actions;

export const { actions, reducer, name: sliceKey } = toursListSlice;
