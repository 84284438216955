import React, { useRef, useState } from 'react';
import DestinationLabel from '../../../../components/Kewlox/DestinationLabel';
import { FiChevronDown } from 'react-icons/fi';
import { PartOrder } from 'app/models/PartOrder';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import { Destination } from 'app/models/Destination';
import Modal from 'react-responsive-modal';
import { updatePartOrder } from '../../../SiteWrapper/slice';
import { selectAllDestinations } from 'store/slices/destinationsSlice';

interface Props {
  partOrder: PartOrder;
}

const DestinationSelector = ({ partOrder }: Props) => {
  const destinations = useSelector(selectAllDestinations);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [newDestination, setNewDestination] = useState<Destination | undefined>(
    undefined,
  );

  useOnClickOutside(ref, () => {
    hide();
  });

  const hide = () => {
    setOpen(false);
  };

  const checkVATChange = () => {
    // Check if the destination change impact the VAT of the order, if so, refuse to change
    // The VAT change when it's a foreign customer and it's switch from @Home to store or store to @Home
    if (newDestination) {
      if (partOrder.order.infos.isoCode !== 'BE') {
        console.log('The customer is foreign');
        if (partOrder.destination.shop && newDestination.home) {
          return true;
        } else if (partOrder.destination.home && newDestination.shop) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const changeHome = () => {
    if (newDestination) {
      return (
        (partOrder.destination.home && newDestination.shop) ||
        (partOrder.destination.shop && newDestination.home)
      );
    }
    return false;
  };

  return (
    <div className="relative">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DestinationLabel destination={partOrder.destination} />
        <FiChevronDown />
      </div>
      {isOpen && (
        <div
          ref={ref}
          className="absolute z-50 w-64 bg-white border border-gray-200 -ml-20 shadow-xl"
        >
          {destinations
            .filter(destination => destination.shop)
            .map((destination: Destination) => (
              <div
                key={destination.id}
                className="border-b border-gray-300 py-2 px-4 hover:bg-gray-200 cursor-pointer flex justify-between"
                onClick={() => {
                  setNewDestination(destination);
                  hide();
                }}
              >
                <span>{destination.name}</span>
              </div>
            ))}
          <div
            className="border-b border-gray-300 py-2 px-4 hover:bg-gray-200 cursor-pointer flex justify-between"
            onClick={() => {
              setNewDestination(destinations.find(e => e.name === '@Home'));
              hide();
            }}
          >
            <span>@Home</span>
          </div>
        </div>
      )}

      {newDestination && (
        <Modal
          open={true}
          onClose={() => {
            setNewDestination(undefined);
          }}
        >
          <div className="w-128 py-3 px-4">
            <div className="my-3">
              <div className="text-3xl flex justify-center">
                Changement de destination
              </div>
            </div>
            {checkVATChange() ? (
              <div>
                Le changement de destination va entrainer un changement de TVA
                pour cette commande, l'opération peut uniquement être effectuée
                via le <b>Kewnet</b>.
              </div>
            ) : (
              <>
                <div>
                  Voulez vous changer la destination de la commande{' '}
                  <b>{partOrder.id}</b> de{' '}
                  <div className="flex items-center gap-2">
                    <div className="w-40">
                      <DestinationLabel destination={partOrder.destination} />
                    </div>{' '}
                    vers{' '}
                    <div className="w-40">
                      <DestinationLabel destination={newDestination} />
                    </div>
                    ?
                  </div>
                </div>
                {changeHome() && (
                  <div className="mt-3">
                    Ce changement de destination implique{' '}
                    <b>un changement de facturation</b>!
                    <div>
                      L'opération peut uniquement être effectuée via le{' '}
                      <b>Kewnet</b>
                    </div>
                    {newDestination.home && (
                      <div className="mt-4">
                        <div>A faire:</div>
                        <div className="ml-4">
                          - Il faut rajouter des frais de livraison en créant
                          une nouvelle commande dans le Kewnet.
                        </div>
                        <div className="ml-4">
                          - Vérifier l'adresse de livraison.
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            <div className="flex justify-end mt-5">
              <button
                type="submit"
                className="border-2 text-lg px-2 py-1 rounded-sm mx-3"
                onClick={() => {
                  setNewDestination(undefined);
                }}
              >
                Annuler
              </button>
              {!checkVATChange() && !changeHome() && (
                <button
                  type="submit"
                  className="bg-main text-white text-lg px-2 py-1 rounded-sm"
                  onClick={() => {
                    dispatch(
                      updatePartOrder(
                        partOrder,
                        undefined,
                        undefined,
                        newDestination,
                      ),
                    );
                    setNewDestination(undefined);
                  }}
                >
                  Confirmer
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DestinationSelector;
