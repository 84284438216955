import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { ProductionMode } from 'app/pages/ProductionPage/constants';

export const initialState: ContainerState = {
  productionMode: undefined,
};

const productionSlice = createSlice({
  name: 'productionPage',
  initialState,
  reducers: {
    setProductionMode(
      state,
      action: PayloadAction<ProductionMode | undefined>,
    ) {
      state.productionMode = action.payload;
    },
  },
});

export const { setProductionMode } = productionSlice.actions;

export const { actions, reducer, name: sliceKey } = productionSlice;
