import React from 'react';

export const PlaceholderLine = ({
  width,
  height = 'h-4',
}: {
  width: string;
  height?: string;
}) => {
  return (
    <div
      className={`text-sm mx-1 bg-gray-300 ${height} ${width} rounded`}
    ></div>
  );
};
