import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { AppRootState } from 'index';
import { kewloxApi } from 'services/kewloxApi';
import {
  createDestination,
  Destination,
  DestinationJson,
} from 'app/models/Destination';

const destinationAdapter = createEntityAdapter<Destination>({
  selectId: destination => destination.id,
  sortComparer: (a, b) => a.sorting - b.sorting,
});

const destinationsSlice = createSlice({
  name: 'destinationsSlice',
  initialState: destinationAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getDestinations.matchFulfilled,
      (state, { payload }) => {
        const transformedPayload = payload.map((item: DestinationJson) =>
          createDestination(item),
        );
        destinationAdapter.setAll(state, transformedPayload);
      },
    );
  },
});

export const {} = destinationsSlice.actions;

export const {
  selectById: selectDestinationById,
  selectIds: selectDestinationIds,
  selectEntities: selectDestinationEntities,
  selectAll: selectAllDestinations,
} = destinationAdapter.getSelectors((state: AppRootState) => state.destination);

export const selectSortedDestinations = createSelector(
  [selectAllDestinations],
  destinations => destinations.sort((a, b) => a.sorting - b.sorting),
);

export default destinationsSlice.reducer;
