import React from 'react';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey } from './slice';
import Header from 'app/containers/Header';
import { productionSaga } from 'app/containers/SiteWrapper/saga';
import {
  useGetCarriersQuery,
  useGetColorsQuery,
  useGetDestinationsQuery,
} from 'services/kewloxApi';
import { CircleSpinner } from 'react-spinners-kit';

interface Props {
  title?: string;
  children?: React.ReactNode;
  leftTopBarButton?: React.ReactNode;
  rightTopBarButton?: React.ReactNode;
}

export function SiteWrapper({
  title,
  children,
  leftTopBarButton,
  rightTopBarButton,
}: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: productionSaga });

  const { isLoading: isLoadingCarrier } = useGetCarriersQuery();

  const { isLoading: isLoadingDestination } = useGetDestinationsQuery();

  const { isLoading: isLoadingColor } = useGetColorsQuery();

  const isLoading = isLoadingCarrier || isLoadingDestination;

  return (
    <>
      <Header
        title={title ?? 'Expedinet'}
        leftButton={leftTopBarButton}
        rightButton={rightTopBarButton}
      />
      <div className="container mx-auto flex flex-col pt-16 notranslate">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="flex flex-col items-center">
              <CircleSpinner size={50} color="#4a5568" />
              <div className="italic mt-2">Chargement des données...</div>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
}

export default SiteWrapper;
