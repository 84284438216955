import React from 'react';
import { FiCheck, FiClock, MdCallSplit } from 'react-icons/all';
import { ActionStream, Event } from 'app/models/Feed';
import { format } from 'date-fns';
import { FiPlus, FiPrinter, FiSmartphone } from 'react-icons/fi';
import { GiCircularSawblade } from 'react-icons/gi';
import EventEntry from 'app/components/Timeline/EventEntry';
import { CgArrowsMergeAltH } from 'react-icons/cg';
import { BiReset } from 'react-icons/bi';

interface Props {
  event: Event;
}

const TimelineItem = ({ event }: Props) => {
  const date = new Date(event.timeMillisec);

  return (
    <div className="feed-timeline-item">
      <div className="feed-timeline-item-content">
        <div className="flex flex-row">
          <div className="">
            <div className="datetime flex items-center">
              <FiClock className="h-4 w-4 mx-2" />
              {format(date, 'dd/MM/yyyy HH:mm')}
            </div>
            <div className="mt-1">
              {event.action === ActionStream.CreatePartOrder && (
                <EventEntry event={event}>
                  <FiPlus className="h-6 w-6 mx-2 text-gray-700" /> La commande
                  a été créée
                </EventEntry>
              )}
              {event.action === ActionStream.TourUpdated && (
                <EventEntry event={event}>
                  La commande a été placée dans la tournée{' '}
                  {event.tour === '1' ? 'directe' : `${event.tour}`}
                </EventEntry>
              )}
              {event.action === ActionStream.Reset && (
                <EventEntry event={event}>
                  <BiReset className="h-6 w-6 text-gray-700 mx-2" />
                  La commande a été réinitialisée
                </EventEntry>
              )}
              {event.action === ActionStream.ProductionDate &&
                event.date !== 'None' && (
                  <EventEntry event={event}>
                    La date de production du{' '}
                    {event.date && format(new Date(event.date), 'dd/MM/yyyy')} a
                    été définie
                  </EventEntry>
                )}
              {event.action === ActionStream.PrintPickingList && (
                <EventEntry event={event}>
                  <FiPrinter className="h-6 w-6 text-gray-700 mx-2" />
                  La liste de décomposition a été imprimée
                </EventEntry>
              )}
              {event.action === ActionStream.CancelPrintPickingList && (
                <EventEntry event={event}>
                  <FiPrinter className="h-6 w-6 text-gray-700 mx-2" />
                  L'impression de la liste de décomposition a été annulé
                </EventEntry>
              )}
              {event.action === ActionStream.PrintCutting && (
                <EventEntry event={event}>
                  <GiCircularSawblade className="h-6 w-6 mr-2 text-gray-700 mx-2" />
                  La liste de découpe a été imprimée
                </EventEntry>
              )}
              {event.action === ActionStream.CarrierUpdated && (
                <EventEntry event={event}>
                  Le transporteur a été mis à jours
                </EventEntry>
              )}
              {event.action === ActionStream.NotifyDelivery && (
                <EventEntry event={event}>
                  <FiSmartphone className="h-6 w-6 mr-2 text-gray-700 mx-2" />
                  Le client a été averti de la date de livraison
                </EventEntry>
              )}
              {event.action === ActionStream.ConfirmedDelivery && (
                <EventEntry event={event}>
                  <FiCheck className="h-6 w-6 mr-2 text-green mx-2" />
                  La date de livraison est confirmée
                </EventEntry>
              )}
              {event.action === ActionStream.SubdivisePartOrder && (
                <EventEntry event={event}>
                  <MdCallSplit className="h-6 w-6 text-gray-700 mx-2" />
                  La commande a été subdivisée
                </EventEntry>
              )}
              {event.action === ActionStream.DeleteDeliveryTour && (
                <EventEntry event={event}>
                  <MdCallSplit className="h-6 w-6 text-gray-700 mx-2" />
                  La commande a été retirée de la tournée
                </EventEntry>
              )}
              {event.action === ActionStream.MergePackages && (
                <EventEntry event={event}>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <CgArrowsMergeAltH className="h-6 w-6 text-gray-700 mx-2" />
                      Paquets fusionnés
                    </div>
                    <div className="italic">{event.change}</div>
                  </div>
                </EventEntry>
              )}
              {event.action === ActionStream.SplitPackages && (
                <EventEntry event={event}>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <CgArrowsMergeAltH className="h-6 w-6 text-gray-700 mx-2" />
                      Paquets subdivisés
                    </div>
                    <div className="italic">{event.change}</div>
                  </div>
                </EventEntry>
              )}
              {event.action === ActionStream.CreatePackages && (
                <EventEntry event={event}>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <CgArrowsMergeAltH className="h-6 w-6 text-gray-700 mx-2" />
                      Un nouveau paquet a été crée
                    </div>
                    <div className="italic">{event.change}</div>
                  </div>
                </EventEntry>
              )}
              {event.action === ActionStream.AddDeliveryTour && (
                <EventEntry event={event}>
                  <MdCallSplit className="h-6 w-6 text-gray-700 mx-2" />
                  {event.from ? (
                    <div>
                      La commande a été déplacée depuis la tournée {event.from}{' '}
                      vers la tournée {event.tour}
                    </div>
                  ) : (
                    <div>
                      La commande a été ajoutée dans la tournée {event.tour}
                    </div>
                  )}
                </EventEntry>
              )}
            </div>
          </div>
          <div className="ml-4" />
        </div>

        <span className="circle" />
      </div>
    </div>
  );
};

export default TimelineItem;
