import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, CuttingPrintingInfo } from './types';
import { AppThunk } from 'store/reducers';
import { requestGet } from 'utils/requests';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { Carrier, OrderInfo } from 'app/pages/ToursPage/types';
import {
  createProductionPiece,
  ProductionPiece,
  ProductionPieceJson,
} from 'app/models/ProductionPiece';
import { Destination } from 'app/models/Destination';
import { Workstation } from 'app/containers/OrderDetails/types';
import { API_BASE_URL } from 'app/constants';
import {
  createPartOrder,
  PartOrder,
  PartOrderJson,
  Sibling,
} from 'app/models/PartOrder';
import cloneDeep from 'lodash/cloneDeep';
import { PackageOrder } from 'app/models/PackageOrder';
import { ProductOrder } from 'app/models/ProductOrder';
import { Trolley } from 'app/models/Trolley';
import { CUTTING_PRINTING_INFO_URL } from 'app/containers/Cutting/constants';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import { RootState } from 'types';
import { kewloxApi } from 'services/kewloxApi';

// The initial state of the SiteWrapper container
export const initialState: ContainerState = {
  carriers: [],
  destinations: [],
  orders: undefined,
  order: undefined,
  orderInfos: [],
  error: undefined,
  loading: false,
  loadingOrder: false,
  cuttingPieces: [],
  numberOfCuttings: 0,
};

const siteWrapperSlice = createSlice({
  name: 'siteWrapper',
  initialState,
  reducers: {
    setDestinations: (state, action: PayloadAction<Destination[]>) => {
      state.destinations = action.payload;
    },
    setOrders: (state, action: PayloadAction<PartOrder[]>) => {
      state.orders = action.payload;
      state.loading = false;
    },
    setNumberOfCuttings: (state, action: PayloadAction<number>) => {
      state.numberOfCuttings = action.payload;
    },
    requestCuttingPieces: state => {
      state.loading = true;
    },
    requestOrderInfos: state => {
      state.loading = true;
    },
    setOrdersInfos: (state, action: PayloadAction<OrderInfo[]>) => {
      state.orderInfos = action.payload;
    },
    requestError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
      state.loadingOrder = false;
    },
    loadOrder: (state, action: PayloadAction<number>) => {
      state.loadingOrder = true;
    },
    setOrderRequest: state => {
      state.loadingOrder = true;
    },
    setOrder: (state, action: PayloadAction<PartOrder | undefined>) => {
      if (action.payload) state.order = cloneDeep(action.payload);
      else state.order = undefined;
      state.loadingOrder = false;
    },
    setOrderDetails: (
      state,
      action: PayloadAction<{
        partOrderId: number;
        packages: PackageOrder[] | undefined;
        products: ProductOrder[] | undefined;
        trolleys: Trolley[];
        siblings: Sibling[] | undefined;
      }>,
    ) => {
      if (state.order && state.order.id === action.payload.partOrderId) {
        state.order.packages = action.payload.packages;
        state.order.products = action.payload.products;
        state.order.trolleys = action.payload.trolleys;
        state.order.siblings = action.payload.siblings;
      }
      state.loadingOrder = false;
    },
    updateProductionPiece: (
      state,
      action: PayloadAction<ProductionPieceJson>,
    ) => {
      const pieceJson = action.payload;
      if (state.order) {
        const clonedOrder = cloneDeep(state.order);
        clonedOrder.products = clonedOrder.products?.map(product => {
          product.production = product.production.map(p => {
            if (p.id === pieceJson.id) {
              // @ts-ignore
              const allColors = selectAllColors({ color: state } as RootState);
              console.log('allColors from siteWrapperSlice', allColors);
              return createProductionPiece(
                pieceJson,
                product,
                undefined,
                allColors,
              );
            } else return p;
          });
          return product;
        });
        state.order = clonedOrder;
      }
    },
    setCuttingPieces(state, action: PayloadAction<ProductionPiece[]>) {
      state.cuttingPieces = action.payload;
      state.loading = false;
    },
    updateCuttingPiece: (state, action: PayloadAction<ProductionPieceJson>) => {
      const pieceJson = action.payload;
      const cuttingPieces: ProductionPiece[] = [];
      // @ts-ignore
      const allColors = selectAllColors({ color: state } as RootState);
      state.cuttingPieces.forEach(cuttingPiece => {
        if (cuttingPiece.id === pieceJson.id) {
          cuttingPieces.push(
            createProductionPiece(pieceJson, undefined, undefined, allColors),
          );
        } else cuttingPieces.push(cuttingPiece);
      });
      state.cuttingPieces = cuttingPieces;
    },
    refreshPartOrder: (state, action: PayloadAction<PartOrder>) => {
      state.loadingOrder = false;
      if (action.payload.packages && action.payload.products) {
        state.order = action.payload;
        if (state.orders) {
          state.orders = [...state.orders].map(entry => {
            if (entry.id === action.payload.id) {
              return action.payload;
            } else return entry;
          });
        }
      } else {
        // We only update the orders list
        if (state.order) {
          if (action.payload.productionDate)
            state.order.productionDate = action.payload.productionDate;
          if (action.payload.pickingListPrinted)
            state.order.pickingListPrinted = action.payload.pickingListPrinted;
          if (action.payload.carrier)
            state.order.carrier = action.payload.carrier;
        }
        if (state.orders) {
          state.orders = [...state.orders].map(entry => {
            if (entry.id === action.payload.id) {
              return action.payload;
            } else return entry;
          });
        }
      }
    },
  },
});

export const getNumberOfCuttings = (): AppThunk => dispatch => {
  requestGet(CUTTING_PRINTING_INFO_URL)
    .then((res: CuttingPrintingInfo[]) => {
      const toPrint = res?.find(pI => pI.datetime === undefined);
      dispatch(
        actions.setNumberOfCuttings(
          toPrint && toPrint.ordersHighPriority
            ? toPrint.ordersHighPriority.length
            : 0,
        ),
      );
    })
    .catch((error: any) => {
      dispatch(actions.requestError(error));
    });
};

export const getPartOrder = (partOrderId: number): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    await dispatch(kewloxApi.endpoints.getPartOrderById.initiate(partOrderId));
    //TODO: Set this part order as current active part order??
  } catch (e) {
    console.error(' Error while fetching part order', e);
  }
};

export const getAllPartOrders = (): AppThunk => (dispatch, getState) => {
  dispatch(actions.setOrderRequest());
  const url = `${PARTORDER_URL}`;
  // @ts-ignore
  const destinations = selectAllDestinations(getState());
  // @ts-ignore
  const carriers = selectAllCarriers(getState());
  // @ts-ignore
  const colors = selectAllColors(getState());
  requestGet(url)
    .then((partOrdersJson: PartOrderJson[]) => {
      const orders = partOrdersJson.map(entry =>
        createPartOrder(entry, destinations, carriers, colors),
      );
      dispatch(setOrders(orders));
    })
    .catch((error: any) => {
      dispatch(actions.requestError(error));
    });
};

export const updatePartOrder = (
  partOrder: PartOrder,
  carrier?: Carrier,
  productionDate?: Date,
  destination?: Destination,
): AppThunk => async (dispatch, getState) => {
  dispatch(actions.setOrderRequest());

  const data = {};
  if (productionDate) data['productionDate'] = productionDate;
  if (carrier) data['carrier'] = carrier.id;
  if (destination) data['destination'] = destination.id;

  try {
    const result = await dispatch(
      kewloxApi.endpoints.updatePartOrder.initiate({
        id: partOrder.id,
        ...data,
      }),
    );
  } catch (e) {
    console.log(' Error while updating part order', e);
  }
};

export const getProductionPiece = (
  workstation: Workstation,
  ordersMap?: Map<number, PartOrder>,
): AppThunk => (dispatch, getState) => {
  const url = `${API_BASE_URL}/cutting/${workstation}/`;
  dispatch(actions.requestCuttingPieces());
  // @ts-ignore
  const allColors = selectAllColors(getState());
  console.log('allColors from getProductionPiece', allColors);

  requestGet(url).then((res: ProductionPieceJson[]) => {
    const pieces = res.map((pieceData: ProductionPieceJson) =>
      createProductionPiece(pieceData, undefined, ordersMap, allColors),
    );
    dispatch(setCuttingPieces(pieces));
  });
};

export const {
  setOrders,
  setOrder,
  requestOrderInfos,
  updateProductionPiece,
  setCuttingPieces,
  updateCuttingPiece,
  setNumberOfCuttings,
  loadOrder,
  refreshPartOrder,
} = siteWrapperSlice.actions;

export const { actions, reducer, name: sliceKey } = siteWrapperSlice;
