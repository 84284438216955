import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';
import { getTime } from 'date-fns';

const selectDomain = (state: RootState) => state.authProvider || initialState;

export const selectAuthProvider = createSelector(
  [selectDomain],
  authProviderState => authProviderState,
);

export const selectTokenInfo = createSelector(
  [selectDomain],
  substate => substate.token,
);

export const selectToken = createSelector(
  [selectDomain],
  substate => substate.token?.data,
);

export const selectIsTokenExpired = () =>
  createSelector(selectTokenInfo, token => {
    if (token && token.exp) {
      return 1000 * token.exp - getTime(new Date()) < 5000;
    }
    return true;
  });

export const selectIsAuthenticated = createSelector(
  [selectTokenInfo, selectIsTokenExpired()],
  (token, isTokenExpired) => {
    if (isTokenExpired && token?.exp) {
      // The token had expired we delete it
      if (localStorage.getItem('token')) localStorage.removeItem('token');
    }
    return !isTokenExpired;
  },
);
