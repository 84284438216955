import { PackageType } from 'app/containers/OrderDetails/types';
import { ProductOrder } from 'app/models/ProductOrder';
import {
  createProductionPiece,
  ProductionPiece,
} from 'app/models/ProductionPiece';
import { pieceRef } from '../pages/ToursPage/tourHelpers';
import { Color } from 'app/models/Color';

export interface PackageOrderJson {
  id: number;
  type: PackageType;
  order: number;
  composition?: any;
  name?: string;
  index?: number;
  total?: number;
  weight?: number;
  trolley: number;
  trolleyName: string;
}

export interface PackageOrder extends PackageOrderJson {
  packageNumber: number;
  averageWeight?: number;
  composition?: ProductionPiece[];
  description: string;
  isGenericPiecesOnly: boolean;
  cabinets: number[];
  cabinet?: number;
  compositionExceptedCabinet?: ProductionPiece[];
  productCabinet?: ProductOrder;
  furnitures: number[];
  images: string[];
  sorting: number;
  uniqueDescr: string | null;
}

export const createPackageOrder = (
  data: PackageOrderJson,
  products: ProductOrder[] | undefined,
  packageNumber: number,
  colors: Color[],
): PackageOrder => {
  const composition: ProductionPiece[] = [];

  if (products) {
    for (const packagePieceEntry of data.composition) {
      const product = products.find(e => e.id === packagePieceEntry.product);
      const piece = createProductionPiece(
        packagePieceEntry,
        product,
        undefined,
        colors,
      );
      composition.push(piece);
    }
  }

  const isGenericPiecesOnly = composition.every(
    piece => piece.type === 'piece',
  );

  const description = composition
    .map(
      (entry: ProductionPiece) =>
        `${entry.quantity} x ${pieceRef(entry.reference)}`,
    )
    .join(' + ');

  const cabinetsSet = new Set<number>();
  const furnituresSet = new Set<number>();
  const imageSet = new Set<string>();

  for (const piece of composition) {
    if (piece.product?.cabinet) {
      cabinetsSet.add(piece.product.cabinet);
    }
    if (piece.product?.furniture) {
      furnituresSet.add(piece.product.furniture);
    }
    if (piece.product?.image) {
      imageSet.add(piece.product.image);
    }
  }

  const cabinets = Array.from(cabinetsSet);
  const furnitures = Array.from(furnituresSet);
  const images = Array.from(imageSet);

  const compositionExceptedCabinet =
    data.type !== PackageType.Cabinet
      ? composition
      : composition.filter(piece => piece.product?.cabinet === undefined);

  const productCabinet = composition.find(
    piece => piece.product?.cabinet && data.type === PackageType.Cabinet,
  )?.product;

  const sorting = data.type === PackageType.Corner ? 0 : data.type;

  const uniqueDescr =
    composition.length === 1 && composition[0].product?.uniqueProduct
      ? `${composition[0].quantity}x ${composition[0].product.name}`
      : null;

  return {
    ...data,
    packageNumber,
    composition,
    description,
    isGenericPiecesOnly,
    cabinets,
    cabinet: cabinets[0],
    compositionExceptedCabinet,
    productCabinet,
    furnitures,
    images,
    sorting,
    uniqueDescr,
  };
};

// export class PackageOrder {
//   id: number;
//   type: PackageType;
//   order: number;
//   name?: string;
//   composition?: ProductionPiece[];
//   index?: number;
//   packageNumber: number;
//   total?: number;
//   weight?: number;
//   averageWeight?: number;
//   trolley: number;
//   trolleyName: string;
//
//   constructor(
//     data: PackageOrderJson,
//     products: ProductOrder[] | undefined,
//     packageNumber: number,
//   ) {
//     this.id = data.id;
//     this.type = data.type;
//     this.order = data.order;
//     this.packageNumber = packageNumber;
//     if (data.index) this.index = data.index;
//     if (data.total) this.total = data.total;
//     if (data.weight) this.weight = data.weight;
//     this.trolley = data.trolley;
//     this.trolleyName = data.trolleyName;
//
//     this.composition = [];
//     if (products) {
//       for (const packagePieceEntry of data.composition) {
//         const product = products.find(e => e.id === packagePieceEntry.product);
//         const piece = new ProductionPiece(
//           packagePieceEntry,
//           product,
//           undefined,
//         );
//         this.composition.push(piece);
//       }
//     }
//
//     if (data.name) this.name = data.name;
//     if (!this.name) {
//       this.name = this.description;
//     }
//   }
//
//   get isGenericPiecesOnly() {
//     if (!this.composition) return true;
//
//     for (const piece of this.composition) {
//       // TODO test GENERIC type when info will be provided by Kewapp
//       if (piece.type !== 'piece') return false;
//     }
//
//     return true;
//   }
//
//   get description() {
//     return this.composition
//       ?.map(
//         (entry: ProductionPiece) =>
//           `${entry.quantity} x ${pieceRef(entry.reference)}`,
//       )
//       .join(' + ');
//   }
//
//   get cabinets(): number[] {
//     if (!this.composition) return [];
//     const cabinetsSet = new Set<number>();
//
//     for (const piece of this.composition) {
//       if (piece.product && piece.product.cabinet) {
//         cabinetsSet.add(piece.product.cabinet);
//       }
//     }
//
//     return Array.from(cabinetsSet);
//   }
//
//   get cabinet(): number | undefined {
//     const cabinets = this.cabinets;
//     if (cabinets.length > 0) return cabinets[0];
//     return undefined;
//   }
//
//   get compositionExceptedCabinet(): ProductionPiece[] | undefined {
//     if (this.type !== PackageType.Cabinet) return this.composition;
//     return this.composition?.filter(
//       piece => piece.product?.cabinet === undefined,
//     );
//   }
//
//   get productCabinet(): ProductOrder | undefined {
//     if (!this.composition) return undefined;
//     for (const piece of this.composition) {
//       if (piece.product?.cabinet && this.type === PackageType.Cabinet) {
//         return piece.product;
//       }
//     }
//     return undefined;
//   }
//
//   get furnitures(): number[] {
//     if (!this.composition) return [];
//     const furnituresSet = new Set<number>();
//
//     for (const piece of this.composition) {
//       if (piece.product?.furniture) {
//         furnituresSet.add(piece.product.furniture);
//       }
//     }
//
//     return Array.from(furnituresSet);
//   }
//
//   get images(): string[] {
//     if (!this.composition) return [];
//     const imageSet = new Set<string>();
//
//     for (const piece of this.composition) {
//       if (piece.product?.image) imageSet.add(piece.product.image);
//     }
//
//     return Array.from(imageSet);
//   }
//
//   get sorting(): number {
//     switch (this.type) {
//       case PackageType.Corner:
//         return 0;
//
//       default:
//         return this.type;
//     }
//   }
//
//   get uniqueDescr(): string | null {
//     if (this.composition && this.composition.length === 1) {
//       if (this.composition[0].product?.uniqueProduct) {
//         return `${this.composition[0].quantity}x ${this.composition[0].product.name}`;
//       }
//     }
//     return null;
//   }
// }
