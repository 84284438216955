import React from 'react';
import { PlaceholderLine } from 'app/components/Placeholders/PlaceholderLine';

const PartOrdersSectionPlaceholder = ({ rows }: { rows: number }) => {
  return (
    <div>
      <div className=" mt-4">
        <div className="px-1 text-md w-full pt-6 pb-2 italic bg-gray-lightest mb-2">
          <PlaceholderLine width="w-24" />
        </div>
        <table className="w-full">
          <tbody className="bg-white py-3">
            {[...Array(rows)].map((_, index) => (
              <tr key={index} className="my-10">
                <td className="py-3 px-4">
                  <div className="font-semibold flex items-center">
                    <PlaceholderLine width="w-12" />
                    <div className="mx-2">
                      {' '}
                      <PlaceholderLine width="w-20" />
                    </div>
                  </div>
                </td>
                <td className="py-3 px-4 text-right">
                  <div className="italic">
                    <PlaceholderLine width="w-16" />
                  </div>
                </td>
                <td className="py-3 px-4 text-center">
                  <div className="flex justify-center">
                    <PlaceholderLine width="w-12" />
                  </div>
                </td>
                <td className="py-3 px-4 text-right">
                  <div className="flex justify-center items-center">
                    <PlaceholderLine width="w-12" />
                  </div>
                </td>
                <td className="py-3 px-4 text-right">
                  <PlaceholderLine width="w-16" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const PartOrdersPlaceholder = () => {
  return (
    <div>
      <PartOrdersSectionPlaceholder rows={5} />
      <PartOrdersSectionPlaceholder rows={2} />
      <PartOrdersSectionPlaceholder rows={3} />
    </div>
  );
};

export default PartOrdersPlaceholder;
