import React, { useState } from 'react';
import { PartOrder } from 'app/models/PartOrder';
import { useDispatch, useSelector } from 'react-redux';
import { Destination } from 'app/models/Destination';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { requestPost } from 'utils/requests';
import {
  getAllPartOrders,
  getPartOrder,
} from 'app/containers/SiteWrapper/slice';
import Modal from 'react-responsive-modal';
import { ModalContentStyled } from 'app/containers/OrderDetails/PartOrderSelection/PrintDirectPartOrderModal';
import TitleModal from 'app/components/Modal/TitleModal';
import DestinationsSelector from 'app/components/Filters/DestinationsSelector';
import Button from 'app/components/Button';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { useSubdividePartOrderMutation } from 'services/kewloxApi';

// import styled from 'styled-components';

interface Props {
  partOrder: PartOrder;
  isModalOpen: boolean;
  setModalOpen: any;
  selectedPackages: Set<number>;
}

const SubExpeditionConfirmationModal = ({
  partOrder,
  isModalOpen,
  setModalOpen,
  selectedPackages,
}: Props) => {
  const dispatch = useDispatch();
  const [destination, setDestination] = useState<Destination | undefined>(
    partOrder.destination,
  );
  const destinations = useSelector(selectAllDestinations);

  const [subdivide] = useSubdividePartOrderMutation();

  const handleDestination = (event: React.ChangeEvent<{ value: unknown }>) => {
    let targetId = event.target.value as number;
    if (targetId === 51) targetId = 54;
    const destination = destinations.find(e => e.id === targetId);
    if (destination) {
      setDestination(destination);
    }
  };

  const handleCreateNewPartOrder = () => {
    if (destination) {
      const data = {};
      const packagesData = Array.from(selectedPackages).map(packageId => ({
        id: packageId,
      }));

      if (packagesData.length > 0) {
        data['packages'] = packagesData;
      }

      data['destination'] = destination.id;

      subdivide({ id: partOrder.id, data })
        .unwrap()
        .then(() => {
          setModalOpen(false);
        })
        .catch(error => {
          console.error('Error fetching part orders:', error);
        });
    } else {
      alert(
        'Vous devez sélectionner une destination pour la nouvelle commande',
      );
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      center
    >
      <ModalContentStyled>
        <TitleModal
          title={`Voulez-vous créer une livraison partielle pour ${partOrder.identification}?`}
        />
      </ModalContentStyled>
      <div className="text-base my-2 text-gray-900">
        Les colis sélectionnés seront placés dans une livraison complémentaire
        cela permettra de les attribuer à une autre tournée
      </div>
      <div className="flex justify-center items-center py-4 border-b border-t border-gray-200">
        <div className="flex justify-center item-center ">
          <div className="mx-5">Destination:</div>
          <div className="ml-10 -mt-5">
            <DestinationsSelector
              handleOnChange={handleDestination}
              selectedDestination={destination}
            />
          </div>
        </div>
      </div>

      {/*<CarrierSelector*/}
      {/*  onClick={(carrier: Carrier) => {*/}
      {/*    console.log(`Carrier selected ${carrier.name}`);*/}
      {/*  }}*/}
      {/*/>*/}

      {selectedPackages.size > 0 && (
        <div className="my-2 flex justify-end">
          <Button
            onClick={handleCreateNewPartOrder}
            contentText="Créer une livraison partielle"
          />
        </div>
      )}
    </Modal>
  );
};

export default SubExpeditionConfirmationModal;
