import React from 'react';
import {
  ProductionStatus,
  Workstation,
} from 'app/containers/OrderDetails/types';
import { useSelector } from 'react-redux';
import { selectOrdersProductionWorkstation } from 'app/containers/SiteWrapper/selectors';
import { ListItemLink } from 'app/pages/ProductionPage/BasePickingListPage/ListItemLink';
import { PartOrder } from 'app/models/PartOrder';
import { List } from '@mui/material';

interface Props {
  workstation: Workstation;
  productionStatus: ProductionStatus;
}

const ListOrders = ({ workstation, productionStatus }: Props) => {
  const orders = useSelector(
    selectOrdersProductionWorkstation(workstation, productionStatus),
  );

  return (
    <div>
      {orders && orders.length > 0 ? (
        <div className="flex justify-center ">
          <div className="list-order w-full">
            <List aria-label="secondary ">
              {orders.map((partOrder: PartOrder) => (
                <ListItemLink
                  to={`/production/picking/${workstation}/${partOrder.id}`}
                  key={partOrder.id}
                  order={partOrder}
                />
              ))}
            </List>
          </div>
        </div>
      ) : (
        <div className="text-xl text-gray-800 mx-2 my-12 italic flex justify-center w-full">
          Il n'y a pas de commandes dans cette liste
        </div>
      )}
    </div>
  );
};

export default ListOrders;
