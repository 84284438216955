import React from 'react';
import SwipeableItem from 'app/components/SwipeableItem';
import { ProductionPiece } from 'app/models/ProductionPiece';
import DiffBusinessDay from '../../../components/DiffBusinessDay';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCTION_PIECE_URL } from '../constants';
import { updateCuttingPiece } from 'app/containers/SiteWrapper/slice';
import { requestPut } from 'utils/requests';
import { selectPartOrderById } from 'store/slices/partOrderSlice';
import { AppRootState } from 'index';

interface Props {
  quantity: number;
  piece: ProductionPiece;
}

const SwipeableCuttingCell = ({ quantity, piece }: Props) => {
  const dispatch = useDispatch();

  const partOrder = useSelector((state: AppRootState) =>
    selectPartOrderById(state, piece.order),
  );

  return (
    <SwipeableItem
      piece={piece}
      leftAction={() => {
        console.log(`Cutting Swipe Left ${piece.id} (${piece.reference})`);

        const url = `${PRODUCTION_PIECE_URL}/${piece.id}/`;
        const data = {
          quantityCutted: quantity,
        };

        requestPut(url, data)
          .then(data => {
            dispatch(updateCuttingPiece(data));
          })
          .catch(error => {
            // TODO Set toast with error msg
          });
      }}
      rightAction={() => {
        console.log(`Cutting Swipe Right  ${piece.id} (${piece.reference})`);
      }}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <div className="text-xl font-semibold mr-4 ml-2">{quantity}</div>
          <div className="flex text-xl">
            {piece.height} x {piece.width} x {piece.depth}
          </div>
        </div>

        {piece.doorHole && (
          <div className="text-xl flex items-center">
            <span className="mr-2 text-sm font-hairline">trou:</span>
            {piece.doorHole}
          </div>
        )}
        <div className="flex items-center">
          <div className="mx-5">
            <div>{piece.order}</div>
            <div className="text-sm">{partOrder?.customerName}</div>
          </div>
          <div className="flex items-center">
            <div className="mx-1">
              {partOrder && (
                <DiffBusinessDay
                  days={partOrder.diffBusinessDays}
                  alert={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SwipeableItem>
  );
};

export default SwipeableCuttingCell;
