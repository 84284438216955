import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTour } from 'app/containers/ToursList/slice';
import { formatISO, setHours, setMinutes } from 'date-fns';
import addBusinessDays from 'date-fns/addBusinessDays';
import { Button } from '@mui/material';
import { selectLastTourDate } from 'store/slices/toursSlice';

const AddTour = () => {
  const dispatch = useDispatch();
  const lastDate = useSelector(selectLastTourDate);

  const handleAddTour = () => {
    if (lastDate) {
      const date = addBusinessDays(lastDate, 1);
      dispatch(addNewTour(formatISO(setMinutes(setHours(date, 8), 15)), 1));
    } else {
      dispatch(
        addNewTour(formatISO(setMinutes(setHours(new Date(), 8), 15)), 1),
      );
    }
  };

  return (
    <Button variant="outlined" onClick={handleAddTour} color="secondary">
      <FiPlus className="h-6 w-6 mr-2" />
      <div className="mr-2">Ajouter une tournée</div>
    </Button>
  );
};

export default AddTour;
