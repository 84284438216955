import {
  createEntityAdapter,
  createSelector,
  createSlice,
  Update,
} from '@reduxjs/toolkit';
import { kewloxApi } from 'services/kewloxApi';
import { AppRootState } from 'index';
import { selectDestinationSelected } from 'app/containers/ToursList/selectors';
import { createTour, Tour, TourJson } from 'app/models/Tour';
import { compareAsc, parseISO } from 'date-fns';
import { selectDeliveryEntities } from 'store/slices/deliverySlice';
import { selectPartOrderEntities } from 'store/slices/partOrderSlice';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';

const toursAdapter = createEntityAdapter<Tour>();

const initialState = toursAdapter.getInitialState({});

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    addTours: toursAdapter.addMany,
  },
  extraReducers: builder => {
    builder.addMatcher(
      kewloxApi.endpoints.getActiveTours.matchFulfilled,
      (state, { payload }) => {
        const tours = payload.map((item: TourJson) => createTour(item));
        toursAdapter.upsertMany(state, tours);
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.getTour.matchFulfilled,
      (state, { payload }) => {
        toursAdapter.setOne(state, createTour(payload));
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.addTour.matchFulfilled,
      (state, { payload }) => {
        toursAdapter.addOne(state, createTour(payload));
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.updateTour.matchFulfilled,
      (state, { meta, payload }) => {
        if (meta.arg.originalArgs) {
          const tourId = meta.arg.originalArgs.id!;
          const update: Update<Tour> = {
            id: tourId,
            changes: payload,
          };
          toursAdapter.updateOne(state, update);
        }
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints?.getTourHistory.matchFulfilled,
      (state, { payload }) => {
        const tours = payload.map((item: TourJson) => createTour(item));
        toursAdapter.upsertMany(state, tours);
      },
    );
    builder.addMatcher(
      kewloxApi.endpoints.deleteTour.matchFulfilled,
      (state, { payload, meta }) => {
        const tourId = meta.arg.originalArgs;
        toursAdapter.removeOne(state, tourId as number);
      },
    );
    // builder.addMatcher(
    //   kewloxApi.endpoints.deleteDelivery.matchFulfilled,
    //   (state, { meta }) => {
    //     const deliveryId = meta.arg.originalArgs;
    //     const tourId = state.ids.find(id =>
    //       state.entities[id]?.deliveries.includes(deliveryId),
    //     );
    //     if (tourId) {
    //       const tour = state.entities[tourId];
    //       const updatedTour = {
    //         ...tour,
    //         deliveries: tour!.deliveries.filter(id => id !== deliveryId),
    //       };
    //       const update: Update<Tour> = {
    //         id: tourId,
    //         changes: updatedTour,
    //       };
    //       toursAdapter.updateOne(state, update);
    //     }
    //   },
    // );
  },
});

export const { addTours } = toursSlice.actions;

export const {
  selectById: selectTourById,
  selectIds: selectTourIds,
  selectEntities: selectTourEntities,
  selectAll: selectAllTours,
} = toursAdapter.getSelectors((state: AppRootState) => state.tour);

export const selectToursFiltered = createSelector(
  [selectAllTours, selectDestinationSelected],
  (tours: Tour[], destination) => {
    if (destination) {
      if (destination.home) {
        return tours.filter(e => e.isHomeTour);
      }
      return tours.filter(e => e.destination?.id === destination.id);
    }
    return tours;
  },
);

export const selectLastTourDate = createSelector(
  [selectToursFiltered],
  tours => {
    if (tours.length > 0) {
      const dateTourList = tours.map((e: Tour) => parseISO(e.date));
      dateTourList.sort((a: Date, b: Date) => compareAsc(a, b));
      return dateTourList.pop();
    }
  },
);

export const selectDeliveriesFromTour = (tourId: number) =>
  createSelector(
    [selectTourEntities, selectDeliveryEntities],
    (tours, deliveries) => {
      const tour = tours[tourId];
      if (tour) {
        return tour.deliveries.map(id => deliveries[id]!);
      }
    },
  );

export const selectPartOrderFromTour = (tourId: number) =>
  createSelector(
    [selectDeliveriesFromTour(tourId), selectPartOrderEntities],
    (deliveries, partOrders) => {
      if (!deliveries || deliveries.length === 0) return [];

      // Sort deliveries by `position` before mapping to `partOrders`
      const sortedDeliveries = [...deliveries].sort(
        (a, b) => a.position - b.position,
      );

      return sortedDeliveries
        .filter(delivery => delivery !== undefined)
        .map(delivery => partOrders[delivery.partOrder!]);
    },
  );

export const selectTourForCurrentPartOrder = createSelector(
  [selectCurrentPartOrder(), selectAllTours, selectDeliveryEntities],
  (partOrder, tours, deliveries): Tour | undefined => {
    if (partOrder) {
      if (partOrder.destination && partOrder.productionDate) {
        const toursForCurrentPartOrder = tours.filter(t =>
          t.deliveries
            .map(d => deliveries[d]?.partOrder)
            .includes(partOrder.id),
        );
        if (toursForCurrentPartOrder && toursForCurrentPartOrder.length === 1)
          return toursForCurrentPartOrder[0];
      }
    }
    return undefined;
  },
);

export default toursSlice.reducer;
