import React from 'react';
import { PartOrder } from 'app/models/PartOrder';
import { FaCubes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPartOrders,
  getPartOrder,
  setOrder,
} from 'app/containers/SiteWrapper/slice';
import {
  selectBasePartOrder,
  selectSiblingPartOrders,
} from 'app/containers/SiteWrapper/selectors';
import { BiReset } from 'react-icons/bi';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { requestGet } from 'utils/requests';

import { CgArrowsShrinkH, MdCallSplit } from 'react-icons/all';
import {
  loadTours,
  selectDestinationFilter,
} from 'app/containers/ToursList/slice';
import { setCurrentPartOrderId } from 'store/slices/activeContentSlice';
import { useResetPartOrderMutation } from 'services/kewloxApi';

interface Props {
  partOrder: PartOrder;
}

const PartOrderBanner = ({ partOrder }: Props) => {
  const dispatch = useDispatch();
  const basePartOrder = useSelector(selectBasePartOrder);
  const siblingPartOrders = useSelector(selectSiblingPartOrders);

  const [resetPartOrderMutation] = useResetPartOrderMutation();

  if (partOrder.total === 1) return null;

  return (
    <div className="bg-blue-light w-full flex justify-center text-white py-2 text-lg">
      {basePartOrder && basePartOrder.id !== partOrder.id ? (
        <div className="flex items-center">
          {partOrder.packageDelivery ? (
            <FaCubes />
          ) : (
            <MdCallSplit className="h-6 w-6" />
          )}

          <div className="ml-4">Sous-commande de</div>
          <div
            onClick={() => {
              dispatch(setCurrentPartOrderId(basePartOrder.id));
            }}
            className="ml-2 underline cursor-pointer"
          >
            {basePartOrder?.order.id}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <div className="ml-4">
            Cette commande est décomposée en {partOrder.total} sous-commandes
          </div>
          <div className="flex">
            {partOrder.siblings &&
              partOrder.siblings.map(entry => (
                <div
                  key={entry.id}
                  onClick={() => {
                    dispatch(setCurrentPartOrderId(entry.id));
                  }}
                  className="mx-2 underline cursor-pointer"
                >
                  {`${partOrder.order.id} (${entry.index})`}
                </div>
              ))}
          </div>
          {/*<div className="flex">*/}
          {/*  {siblingPartOrders &&*/}
          {/*    siblingPartOrders.map(siblingPartOrder => (*/}
          {/*      <div*/}
          {/*        key={siblingPartOrder.id}*/}
          {/*        onClick={() => {*/}
          {/*          dispatch(setOrder(siblingPartOrder));*/}
          {/*        }}*/}
          {/*        className="mx-2 underline cursor-pointer"*/}
          {/*      >*/}
          {/*        {siblingPartOrder.identification}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*</div>*/}
        </div>
      )}
      <button
        onClick={() => {
          if (
            window.confirm(
              `Voulez-vous vraiment supprimer toutes les sous-commandes et réinitialiser les colis ?`,
            )
          ) {
            resetPartOrderMutation(partOrder.id)
              .unwrap()
              .then(() => {
                if (partOrder) {
                  if (partOrder.index > 1) {
                    dispatch(setCurrentPartOrderId(undefined));
                    dispatch(
                      selectDestinationFilter({
                        destination: null,
                        list: false,
                      }),
                    );
                  }
                }
              })
              .catch(error => {
                console.error('Error resetting part order:', error);
              });
          }
        }}
        className="text-sm ml-20 border border-white py-1 px-2 flex items-center"
      >
        <BiReset className="h-6 w-6 mr-2" /> Réinitialiser
      </button>
    </div>
  );
};

export default PartOrderBanner;
