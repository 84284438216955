import { ProductOrderType } from 'app/containers/OrderDetails/types';
import {
  createProductionPiece,
  ProductionPiece,
} from 'app/models/ProductionPiece';
import { parseISO } from 'date-fns';
import { Color } from 'app/models/Color';

export interface ProductOrderJson {
  id: number;
  prestashopId: number;
  order: number;
  cabinet?: number;
  furniture?: number;
  furniturePosition?: number;
  type: ProductOrderType;
  production: any;
  reference: string;
  quantity: number;
  image?: string;
  name?: string;
  drawer?: number;
  productionDate?: string;
  uniqueProduct?: boolean;
}

export interface ProductOrder extends ProductOrderJson {
  production: ProductionPiece[];
  cabinetName: string;
  outOfStock: boolean;
}

export const createProductOrder = (
  data: ProductOrderJson,
  colors: Color[],
): ProductOrder => {
  const production = data.production.map(pj =>
    createProductionPiece(pj, undefined, undefined, colors),
  );

  const cabinetName = data.cabinet ? data.name ?? `${data.cabinet}` : '/';

  const outOfStock = production.some(p => p.isOutOfStock);

  return {
    ...data,
    production,
    cabinetName,
    outOfStock,
    name: data.name ?? '',
    uniqueProduct: data.uniqueProduct ?? false,
  };
};

// export class ProductOrder {
//   id: number;
//   prestashopId: number;
//   order: number;
//   cabinet?: number;
//   furniture?: number;
//   furniturePosition?: number;
//   type: ProductOrderType;
//   production: ProductionPiece[];
//   reference: string;
//   quantity: number;
//   image?: string;
//   name: string;
//   drawer?: number;
//   productionDate?: Date;
//   uniqueProduct: boolean;
//
//   constructor(data: ProductOrderJson) {
//     this.id = data.id;
//     this.prestashopId = data.prestashopId;
//     this.order = data.order;
//     if (data.cabinet) this.cabinet = data.cabinet;
//     if (data.furniture) this.furniture = data.furniture;
//     if (data.furniturePosition) this.furniturePosition = data.furniturePosition;
//     this.type = data.type;
//     this.reference = data.reference;
//     this.quantity = data.quantity;
//     if (data.image) this.image = data.image;
//     this.name = data.name ?? '';
//     this.uniqueProduct = data.uniqueProduct ?? false;
//     if (data.drawer) this.drawer = data.drawer;
//     if (data.productionDate)
//       this.productionDate = parseISO(data.productionDate);
//
//     this.production = [];
//     if (data.production)
//       this.production = data.production.map(pj => new ProductionPiece(pj));
//   }
//
//   get cabinetName() {
//     if (this.cabinet) {
//       if (this.name) return `${this.name}`;
//       return `${this.cabinet}`;
//     }
//     return '/';
//   }
//
//   clone(): ProductOrder {
//     const clonedProductOrder: ProductOrder = cloneDeep(this);
//     for (const production of clonedProductOrder.production) {
//       production.product = clonedProductOrder;
//     }
//
//     return clonedProductOrder;
//   }
//
//   get outOfStock() {
//     if (this.production) {
//       const outOfStockPieces = this.production.filter(p => p.isOutOfStock);
//       if (outOfStockPieces.length > 0) return true;
//     }
//     return false;
//   }
// }
