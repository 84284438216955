import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { Tour } from 'app/models/Tour';
import {
  useGetAllDriversQuery,
  useSetDriversTourMutation,
} from 'services/kewloxApi';
import { Driver } from 'app/pages/DriverPage/types';

import Button from 'app/components/Button';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { actions } from 'app/containers/ToursList/slice';

interface Props {
  tour: Tour;
  displayDriverSelector: boolean;
  setDisplayDriverSelector: (boolean) => void;
}

const DriverSelector = ({
  tour,
  displayDriverSelector,
  setDisplayDriverSelector,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedDrivers, setSelectedDriver] = useState<Driver[]>([]);
  const { data: drivers } = useGetAllDriversQuery();

  const [saveDriversTour] = useSetDriversTourMutation();

  useEffect(() => {
    setSelectedDriver(tour.drivers);
  }, [tour]);

  const isSelected = (id: number) =>
    selectedDrivers.map(e => e.id).includes(id);

  const handleSelectedDriver = (driver: Driver) => {
    if (isSelected(driver.id)) {
      setSelectedDriver(selectedDrivers.filter(e => e.id !== driver.id));
    } else {
      setSelectedDriver([driver, ...selectedDrivers]);
    }
  };

  const save = () => {
    dispatch(actions.tourLoading(tour.id));
    saveDriversTour({
      tour: tour,
      drivers: selectedDrivers,
    })
      .unwrap()
      .catch(e => {
        console.error('Error when saving the drivers for the tour: ', e);
      });
  };

  return (
    <Modal
      open={displayDriverSelector}
      onClose={() => {
        setDisplayDriverSelector(false);
      }}
      center
    >
      <div>
        <div className="text-lg">
          Livreurs pour la tournée{' '}
          <span className="font-semibold">{tour.id}</span>
        </div>
        <TableContainer className="overflow-y-auto w-128 mt-4">
          <Table
            stickyHeader
            size="small"
            aria-label="List des livreurs Kewlox"
          >
            <TableHead className="border-b-2 border-black">
              <TableRow>
                <TableCell align="right" colSpan={1}>
                  Sélectionner
                </TableCell>
                <TableCell align="right" colSpan={4}>
                  Nom
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  Initiales
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers?.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell align="left">
                    <Checkbox
                      checked={isSelected(driver.id)}
                      color="primary"
                      onChange={() => {
                        handleSelectedDriver(driver);
                      }}
                      name={`${driver.id}`}
                    />
                  </TableCell>
                  <TableCell align="right" colSpan={4}>
                    {driver.name}
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    {driver.shortName ?? '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="my-4 flex justify-center">
          <Button
            contentText="Sauver"
            onClick={() => {
              save();
              setDisplayDriverSelector(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DriverSelector;
