/* eslint-disable import/named */
/* eslint-disable import/namespace */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the LoginPage container
export const initialState: ContainerState = {
  polling: false,
  loading: false,
  errors: false,
};

const pollingAgentSlice = createSlice({
  name: 'pollingAgent',
  initialState,
  reducers: {
    startPollingAgent(state) {
      state.polling = true;
    },
    pollingFailure(state, action: PayloadAction<any>) {
      state.errors = action.payload;
    },
    stopPollingAgent(state) {
      state.polling = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = pollingAgentSlice;
