import { Helmet } from 'react-helmet-async';
import React, { useMemo, useState } from 'react';
import SiteWrapper from 'app/containers/SiteWrapper';
import RangeDateSelectionHistory from 'app/pages/HistoryPage/datesRangeSelector';
import { addDays, compareAsc, format, parseISO, subDays } from 'date-fns';
import {
  useGetAllWaypointsQuery,
  useGetDeliveriesForToursQuery,
  useGetTourHistoryQuery,
} from 'services/kewloxApi';
import { CircleSpinner } from 'react-spinners-kit';
import { theme } from 'styles/theme';
import { fr } from 'date-fns/locale';
import TourItem from 'app/containers/ToursList/TourItem';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { selectTourEntities } from 'store/slices/toursSlice';

const HistoryPage = () => {
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const {
    data: toursHistory,
    error,
    isLoading,
    isFetching,
  } = useGetTourHistoryQuery({
    start: format(range[0].startDate, 'yyyy-MM-dd'),
    end: format(addDays(range[0].endDate, 1), 'yyyy-MM-dd'),
  });

  const {
    data: historyData,
    error: historyError,
    isLoading: historyLoading,
    isFetching: historyFetching,
  } = useGetDeliveriesForToursQuery(
    toursHistory ? toursHistory.map(tour => tour.id) : [],
    {
      skip: !toursHistory || toursHistory.length === 0,
    },
  );
  useGetAllWaypointsQuery();

  const sortedToursHistory = useMemo(() => {
    if (!toursHistory) return [];

    return [...toursHistory].sort((a, b) => {
      // If direct, it should be first
      if (a.direct) return -1;
      if (b.direct) return 1;

      // Sort by date
      const dateComp = compareAsc(parseISO(a.date), parseISO(b.date));
      if (dateComp === 0) {
        return a.id - b.id;
      } else {
        return dateComp;
      }
    });
  }, [toursHistory]); // Only recompute if toursHistory changes

  const toursEntities = useSelector(selectTourEntities);

  const renderNoToursMessage = () => {
    if (toursHistory && toursHistory.length === 0) {
      const start = format(range[0].startDate, 'dd/MM/yyyy', {
        locale: fr,
      });
      const end = format(range[0].endDate, 'dd/MM/yyyy', {
        locale: fr,
      });

      if (start === end) {
        return <div>Il n'y a pas de tournée pour la date du {start}.</div>;
      }
      return (
        <div>
          Il n'y a pas de tournée entre les dates du {start} et {end}.
        </div>
      );
    }
    return null;
  };

  const oneDayRange = range[0].startDate === range[0].endDate;

  return (
    <>
      <Helmet>
        <title>Historique</title>
        <meta
          name="description"
          content="This page show the past tour with a date selector"
        />
      </Helmet>

      <SiteWrapper title="Historique des tournées">
        <div className="flex justify-center">
          <div className="flex flex-col">
            <div className="mt-4">
              <RangeDateSelectionHistory range={range} setRange={setRange} />
            </div>
            <div className="flex w-full mt-10">
              <div className="flex-1">
                {isLoading || isFetching ? (
                  <div className="flex flex-col justify-center items-center">
                    <CircleSpinner
                      size={50}
                      color={theme.palette.primary.main}
                    />
                    <div className="my-4">Chargement des données...</div>
                  </div>
                ) : error ? (
                  <div>Error fetching tour history</div>
                ) : (
                  <>{/*{renderNoToursMessage()}*/}</>
                )}
              </div>
            </div>

            {!isLoading && !isFetching && (
              <>
                {toursHistory && toursHistory.length > 0 ? (
                  <div className="text-xl mb-4">
                    Voici les tournées {`${oneDayRange ? 'du' : 'entre le'}`}{' '}
                    {format(range[0].startDate, 'EEEE d MMMM yyyy', {
                      locale: fr,
                    })}
                    {!oneDayRange && (
                      <span>
                        {' '}
                        et le{' '}
                        {format(range[0].endDate, 'EEEE d MMMM yyyy', {
                          locale: fr,
                        })}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="text-xl mb-4">{renderNoToursMessage()}</div>
                )}
                <div className="flex flex-col max-w-5xl">
                  <DragDropContext>
                    {sortedToursHistory &&
                      sortedToursHistory.map(tour => (
                        <TourItem
                          key={tour.id}
                          droppableId={`${tour.id}`}
                          tour={toursEntities[tour.id]}
                          history={true}
                        />
                      ))}
                  </DragDropContext>
                </div>
              </>
            )}
          </div>
        </div>
      </SiteWrapper>
    </>
  );
};

export default HistoryPage;
