import React from 'react';
import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
  defaultInputRanges,
} from 'react-date-range';
import fr from 'date-fns/locale/fr'; // Import French locale

import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS file

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface Props {
  range: DateRange[];
  setRange: (value: DateRange[]) => void;
}

// Custom static range labels in French
const customStaticRanges = createStaticRanges([
  {
    label: "Aujourd'hui", // Today
    range: () => ({
      startDate: new Date(),
      endDate: new Date(),
    }),
  },
  {
    label: 'Hier', // Yesterday
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    }),
  },
  {
    label: '7 derniers jours', // Last 7 days
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
      endDate: new Date(),
    }),
  },
  {
    label: '30 derniers jours', // Last 30 days
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
      endDate: new Date(),
    }),
  },
  {
    label: 'Ce mois-ci', // This month
    range: () => ({
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
    }),
  },
  {
    label: 'Mois dernier', // Last month
    range: () => ({
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1,
      ),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    }),
  },
]);

export default function RangeDateSelectionHistory({ range, setRange }: Props) {
  return (
    <DateRangePicker
      onChange={item => setRange([item.selection])}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={range}
      direction="horizontal"
      preventSnapRefocus={true}
      calendarFocus="backwards"
      locale={fr}
      staticRanges={customStaticRanges}
      inputRanges={[
        {
          ...defaultInputRanges[0],
          label: "Jours jusqu'à aujourd'hui",
        },
        {
          ...defaultInputRanges[1],
          label: "Jours à partir d'aujourd'hui",
        },
      ]}
    />
  );
}
