/**
 *
 * AuthProvider
 *
 */

import React from 'react';

import { useInjectReducer } from 'utils/redux-injectors';
import { reducer, sliceKey } from './slice';

export function AuthProvider(props: { children: React.ReactChild }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  return <>{React.Children.only(props.children)}</>;
}
