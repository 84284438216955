import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
// import styled from 'styled-components';

interface Props {
  onClick?: any;
  contentText?: string;
  loading?: boolean;
  className?: string;
}

const Button = ({ onClick, contentText, loading, className }: Props) => (
  <button
    onClick={onClick}
    className={`border bg-main text-white px-3 py-2 rounded ${className}`}
  >
    {loading ? <CircleSpinner size={18} color="#fff" /> : contentText}
  </button>
);

export default Button;
