import React from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

interface Props {
  province: string | undefined;
  isoCode: string;
  size?: number;
}

const InfoLocation = ({ province, isoCode, size }: Props) =>
  province ? (
    <>{province}</>
  ) : (
    <span title={isoCode}>
      <FlagIcon code={isoCode as FlagIconCode} size={size ? size : 32} />
    </span>
  );

export default InfoLocation;
