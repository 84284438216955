import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import {
  addDelivery,
  addNewTour,
  updateDelivery,
} from 'app/containers/ToursList/slice';
import { FaRegCalendarCheck, FaRegCalendarMinus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { findTour } from 'app/pages/ToursPage/tourHelpers';
import { Tour } from 'app/models/Tour';
import { updatePartOrder } from 'app/containers/SiteWrapper/slice';
import { PartOrder } from 'app/models/PartOrder';
import { Delivery } from 'app/models/Delivery';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import ButtonDatePicker from 'app/components/ButtonDatePicker';
import { selectAllTours } from 'store/slices/toursSlice';

interface Props {
  partOrder: PartOrder;
  delivery?: Delivery;
}

const ProductionDate = ({ partOrder, delivery }: Props) => {
  const dispatch = useDispatch();
  const tours = useSelector(selectAllTours);
  const productionDateOrder = partOrder.productionDate
    ? parseISO(partOrder.productionDate)
    : null;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDateChange = (date: Date | null) => {
    console.log('handleDateChange');
    if (date) {
      if (partOrder.carrier) {
        const tour: Tour | undefined = findTour(
          date,
          partOrder.carrier,
          partOrder.destination,
          tours,
        );
        if (tour) {
          // A tour already exists for this date
          if (delivery) {
            // We add the delivery to the existing tour
            dispatch(updateDelivery(delivery, { tour: tour.id }));
          } else {
            // Create a new delivery
            dispatch(addDelivery(tour, 0, partOrder));
          }
        } else {
          if (delivery) {
            dispatch(
              addNewTour(
                date.toISOString(),
                partOrder.carrier.id,
                partOrder,
                delivery,
              ),
            );
          } else {
            dispatch(
              addNewTour(date.toISOString(), partOrder.carrier.id, partOrder),
            );
          }
        }
      } else {
        dispatch(updatePartOrder(partOrder, undefined, date));
      }
    }
  };

  return (
    <div className="cursor-pointer flex items-center">
      <span className="mt-0">
        {productionDateOrder ? (
          <FaRegCalendarCheck className="h-4 w-4 text-green-dark" />
        ) : (
          <FaRegCalendarMinus className="h-4 w-4" />
        )}
      </span>
      <div className="ml-2 lg:w-42">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <ButtonDatePicker
            label={
              productionDateOrder
                ? format(productionDateOrder, 'eee d MMM', {
                    locale: fr,
                  })
                : null
            }
            value={productionDateOrder}
            onChange={handleDateChange}
            disablePast={false}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default ProductionDate;
