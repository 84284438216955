import React from 'react';
import { DestinationOrderId } from 'app/containers/OrderDetails/constants';
import styled from 'styled-components';

// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import { Destination } from 'app/models/Destination';

interface Props {
  destination: Destination;
  short?: boolean;
}

const DestinationLabel = ({ destination, short }: Props) => {
  let name = destination.name;
  if (short) {
    name = destination.name.charAt(0);
    if (name === '@') name = 'H';
  }

  return (
    <DestinationLabelStyled
      color={destination.color}
      light={[DestinationOrderId.Leuze, DestinationOrderId.Home].includes(
        destination.id,
      )}
      short={short}
      className="border border-gray-400 flex justify-center"
    >
      {name}
    </DestinationLabelStyled>
  );
};

export default DestinationLabel;

export const DestinationLabelStyled = styled.div<{
  color: string;
  light: boolean;
  short?: boolean;
}>`
  color: white;
  font-size: 1.125rem;
  padding: 0.25rem 1.25rem;
  background-color: ${p => p.color};

  ${p =>
    p.short &&
    css`
      max-width: 2rem;
      font-size: 0.75rem;
    `}

  ${p =>
    p.light &&
    css`
      color: #000000;
    `}
`;
