import React from 'react';
import { ProductionPiece } from 'app/models/ProductionPiece';
import {
  pieceRef,
  pieceRefWithProductionPiece,
} from 'app/pages/ToursPage/tourHelpers';

interface Props {
  piece: ProductionPiece;
}

function getDimensionsText(dimensions: number[]) {
  return dimensions.sort((a, b) => b - a).join(' x ');
}

export function CuttingLine({ piece }: Props) {
  return (
    <div className="flex text-lg border-gray-800 my-1">
      <div className="border h-6 w-6 border-gray-800 mr-2" />
      <div className="w-1/12 border-b border-gray-800 font-bold">
        {piece.quantity}
      </div>
      <div className="w-5/6 border-b border-gray-800 flex justify-start flex-wrap">
        <div>{getDimensionsText([piece.height, piece.width, piece.depth])}</div>
        <div className="ml-2">—</div>
        <div className="ml-2">{pieceRefWithProductionPiece(piece, true)}</div>
        {piece.doorHole && (
          <>
            <div className="ml-2">—</div>
            <div className="ml-2">
              Trou <b>{piece.doorHole}</b>
            </div>
          </>
        )}
        {piece.hasDRL && (
          <>
            <div className="ml-2">—</div>
            <div className="ml-2">
              Poignée <b>latte en bois</b>
            </div>
          </>
        )}
        {piece.slatMaterial === 'W' && (
          <>
            <div className="ml-2">—</div>
            <div className="ml-2">
              Poignées <b>bois</b>
            </div>
          </>
        )}
        {piece.slatMaterial === 'A' && (
          <>
            <div className="ml-2">—</div>
            <div className="ml-2">
              Poignées <b>alu</b>
            </div>
          </>
        )}
        {piece.largeDoorPosition === 'I' && (
          <>
            <div className="ml-2">—</div>
            <div className="font-bold ml-2">Intérieure</div>
          </>
        )}
        {piece.largeDoorPosition === 'E' && (
          <>
            <div className="ml-2">—</div>
            <div className="font-bold ml-2">Extérieure</div>
          </>
        )}
      </div>
    </div>
  );
}
