import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { requestGet, requestPost } from 'utils/requests';
import { CUTTING_PRINTING_INFO_URL } from 'app/containers/Cutting/constants';
import {
  createProductionPiece,
  ProductionPiece,
  ProductionPieceJson,
} from 'app/models/ProductionPiece';
import { FaInfoCircle } from 'react-icons/fa';
import { CircleSpinner } from 'react-spinners-kit';
import { format, parseISO } from 'date-fns';
import { FiPrinter } from 'react-icons/fi';
import { CuttingToPrint } from 'app/containers/Cutting/CuttingToPrint';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CuttingModalToPrintLine from 'app/containers/Cutting/CuttingModalToPrintLine';
import FormInputErrorStyled from 'app/components/styles/FormInputErrorStyled';
import { Workstation } from 'app/containers/OrderDetails/types';
import { CuttingPrintingInfo } from 'app/containers/SiteWrapper/types';
import {
  selectAllColors,
  selectColorByReference,
} from 'store/slices/colorSlice';
import {
  selectAllPartOrders,
  selectPartOrderEntities,
} from 'store/slices/partOrderSlice';

interface Props {
  showModal: any;
}

const CuttingModal = ({ showModal }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [printingInfo, setPrintingInfo] = useState<
    CuttingPrintingInfo[] | undefined
  >(undefined);
  const [pieces, setPieces] = useState<ProductionPiece[] | undefined>(
    undefined,
  );
  // const ordersMap = useSelector(selectOrdersMap);
  const partOrdersEntities = useSelector(selectPartOrderEntities);
  const partOrders = useSelector(selectAllPartOrders);

  const colorDibond = useSelector(selectColorByReference('DIBOND'));
  const colorWood = useSelector(selectColorByReference('WOOD'));
  const colors = useSelector(selectAllColors);

  useEffect(() => {
    if (!loading && !printingInfo) {
      setLoading(true);
      // Request
      requestGet(CUTTING_PRINTING_INFO_URL).then((data: any) => {
        setLoading(false);
        const newData = data.map(e => ({
          datetime: e.datetime,
          ordersHighPriority: e.ordersHighPriority?.map(
            i => partOrdersEntities[i],
          ),
          ordersMediumPriority: e.ordersMediumPriority?.map(
            i => partOrdersEntities[i],
          ),
          ordersLowPriority: e.ordersLowPriority?.map(
            i => partOrdersEntities[i],
          ),
          partsOrders: e.partsOrders?.map(i => {
            if (partOrdersEntities.hasOwnProperty(i))
              return partOrdersEntities[i];
            else return undefined;
            // throw new Error(
            //   `Could not found the partOrder ${i} in CuttingPrintingInfo`,
            // );
          }),
        }));
        setPrintingInfo(newData);
      });
    }
  }, []);

  const getInfo = (partsOrdersId: number[]) => {
    setLoading(true);
    requestPost(CUTTING_PRINTING_INFO_URL, {
      printPartsOrders: partsOrdersId,
    }).then((data: ProductionPieceJson[]) => {
      const allPieces = data.map((pieceData: ProductionPieceJson) => {
        const piece = createProductionPiece(
          pieceData,
          undefined,
          undefined,
          colors,
        );
        if (
          (piece.station === Workstation.Woodwork ||
            piece.station === Workstation.WoodworkSpecial ||
            piece.station === Workstation.Drawer) &&
          piece.color === undefined
        )
          if (
            piece.reference.includes('ALU') ||
            piece.reference.includes('DIBOND')
          ) {
            piece.color = colorDibond;
          } else {
            piece.color = colorWood;
          }
        return piece;
      });
      const pieces = allPieces.filter((piece: ProductionPiece) => {
        if (
          piece.station === Workstation.Drawer &&
          piece.reference.includes('DRL')
        ) {
          const frontDrawer = allPieces.find((p: ProductionPiece) => {
            if (
              p.trolleyName === piece.trolleyName &&
              !p.reference.includes('DRL') &&
              !p.hasDRL
            )
              return true;
          });
          if (frontDrawer) frontDrawer.hasDRL = true;
          return false;
        }
        return true;
      });
      setLoading(false);
      setPieces(pieces);
    });
  };

  const toPrint = printingInfo?.find(
    pI =>
      pI.datetime === undefined &&
      ((pI.ordersHighPriority && pI.ordersHighPriority.length > 0) ||
        (pI.ordersMediumPriority && pI.ordersMediumPriority.length > 0) ||
        (pI.ordersLowPriority && pI.ordersLowPriority.length > 0)),
  );
  const history = printingInfo
    ?.filter(
      pI =>
        pI.datetime !== undefined &&
        pI.partsOrders &&
        pI.partsOrders.length > 0,
    )
    .sort((a, b) =>
      a.datetime && b.datetime ? b.datetime?.localeCompare(a.datetime) : 0,
    )
    .slice(0, 5);

  const form = useFormik({
    initialValues: {
      orderToPrint: '',
    },
    validationSchema: Yup.object({
      orderToPrint: Yup.number('Numéro de commande').required('Champ requis'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const orderToPrint = partOrders?.find(
        e => e.order.id === parseInt(values.orderToPrint),
      );
      if (orderToPrint) getInfo([orderToPrint.id]);
      setSubmitting(false);
    },
  });

  if (pieces) {
    showModal(false);
    return <CuttingToPrint pieces={pieces} />;
  }

  return (
    <Modal
      open={true}
      onClose={() => {
        showModal(false);
      }}
      center
    >
      <div className="w-128 py-3 px-4">
        <div className="my-3">
          <div className="text-3xl flex justify-center">
            Impressions des découpes
          </div>
        </div>
        {loading && (
          <div className="text-center w-full flex items-center justify-center">
            <CircleSpinner size={40} color="#4a5568" />
          </div>
        )}
        {!loading && (
          <div>
            <div className="text-lg">À imprimer</div>
            {toPrint ? (
              <>
                <CuttingModalToPrintLine
                  orders={toPrint.ordersHighPriority}
                  text="(≤ 7 jours)"
                  getInfo={getInfo}
                  highPriority={true}
                />
                <CuttingModalToPrintLine
                  orders={toPrint.ordersMediumPriority}
                  text="(8 à 14 jours)"
                  getInfo={getInfo}
                  highPriority={false}
                />
                <CuttingModalToPrintLine
                  orders={toPrint.ordersLowPriority}
                  text="(> 14 jours)"
                  getInfo={getInfo}
                  highPriority={false}
                />
              </>
            ) : (
              <div className="italic">Aucune nouvelle découpe à imprimer</div>
            )}
            {history?.length !== undefined && history?.length > 0 && (
              <div className="text-lg mt-6">Historique</div>
            )}
            {history?.map(pI =>
              pI?.partsOrders ? (
                <div className="flex justify-center items-center">
                  <div className="w-40 text-left italic">
                    {pI.datetime &&
                      format(parseISO(pI.datetime), 'dd/MM/yyyy HH:mm')}
                  </div>
                  <div className="w-56 text-left flex">
                    <div>
                      {pI?.partsOrders.length} commande
                      {pI?.partsOrders.length > 1 && 's'}
                    </div>
                    <div
                      data-tip={pI?.partsOrders
                        .filter(partOrder => partOrder)
                        .sort((a, b) => a.id - b.id)
                        .map(e => e.identification)
                        .join(' - ')}
                    >
                      <FaInfoCircle className="w-4 h-4 ml-2 mt-1" />
                    </div>
                    <ReactTooltip place="bottom" type="dark" />
                  </div>
                  <div className="w-32 text-right flex justify-end mb-1">
                    <button
                      onClick={() =>
                        pI?.partsOrders
                          ? getInfo(pI?.partsOrders.map(e => e.id))
                          : undefined
                      }
                      className="flex items-center border border-gray-400 py-1 px-0 w-28 text-sm"
                    >
                      <FiPrinter className="h-4 w-4 mr-2 ml-1" />
                      Ré-imprimer
                    </button>
                  </div>
                </div>
              ) : null,
            )}
            <div className="flex mt-6">
              <div className="flex w-1/3 text-lg">Par commande</div>
              <div className="w-2/3">
                <form onSubmit={form.handleSubmit} className="flex-col">
                  <div className="flex w-full justify-between">
                    <input
                      id="orderToPrint"
                      type="number"
                      placeholder="N° de commande"
                      className={`border px-3 text-sm w-40 mr-2`}
                      {...form.getFieldProps('orderToPrint')}
                    />
                    <button
                      type="submit"
                      className="flex items-center border border-gray-400 py-1 px-2 w-28 text-sm"
                      disabled={form.isSubmitting}
                    >
                      {!form.isSubmitting ? (
                        <>
                          <FiPrinter className="h-4 w-4 mr-2 ml-1" /> Imprimer
                        </>
                      ) : (
                        <div className="flex justify-center items-center">
                          <CircleSpinner size={30} color="#fff" />
                        </div>
                      )}
                    </button>
                  </div>

                  {form.touched.orderToPrint ? (
                    <div className="mt-1">
                      <FormInputErrorStyled>
                        {form.errors.orderToPrint}
                      </FormInputErrorStyled>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CuttingModal;
