import React, { useState } from 'react';
import SwipeableCuttingCell from 'app/pages/ProductionPage/CuttingPanePage/SwipeableCuttingCell';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';

interface Props {
  title: string;
  color?: string;
  cuttingList: ProductionPiece[];
  cuttingCompleted?: ProductionPiece[];
}

const CuttingGroup = ({
  title,
  color = '#000',
  cuttingList,
  cuttingCompleted,
}: Props) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  if (cuttingList.length === 0) return null;

  const showMore = () => {
    if (!cuttingCompleted || cuttingCompleted.length === 0) return null;
    return (
      <button
        onClick={() => {
          setShowAll(!showAll);
        }}
        className="py-2 px-3 flex items-center bg-gray-200"
      >
        <span>
          + {cuttingCompleted.length}{' '}
          {cuttingCompleted.length === 1 ? 'autre' : 'autres'}
        </span>
        <FiChevronDown className="h-4 w-4 ml-1" />
      </button>
    );
  };

  return (
    <div className="mt-3 mb-8">
      <div className="flex items-center">
        {color && (
          <ColorPreviewStyled
            color={color}
            className="border border-gray-400 w-12 h-6 mx-2"
          />
        )}
        <div className="italic text-lg">{title}</div>
      </div>
      <SwipeableList>
        {cuttingList.map((piece, i) => (
          <SwipeableCuttingCell
            key={`${i}`}
            quantity={piece.quantity}
            piece={piece}
          />
        ))}
      </SwipeableList>
      {cuttingCompleted && !showAll && (
        <div className="flex w-full justify-center my-2">{showMore()}</div>
      )}
      {cuttingCompleted && showAll && (
        <SwipeableList>
          {cuttingCompleted.map((piece, i) => (
            <SwipeableCuttingCell
              key={`${i}`}
              quantity={piece.quantity}
              piece={piece}
            />
          ))}
        </SwipeableList>
      )}
    </div>
  );
};

export default CuttingGroup;

const ColorPreviewStyled = styled.div<{ color: string }>`
  background-color: ${p => p.color};
`;
