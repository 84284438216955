import React, { useState } from 'react';
import {
  useDeleteDriverMutation,
  useGetAllDriversQuery,
} from 'services/kewloxApi';
import { CircleSpinner } from 'react-spinners-kit';
import SiteWrapper from 'app/containers/SiteWrapper';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

import Button from 'app/components/Button';
import DriverForm from 'app/pages/DriverPage/DriverForm';
import { Driver } from 'app/pages/DriverPage/types';
import {
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';

const DriverPage = () => {
  const {
    data: drivers,
    isLoading: isLoadingDrivers,
  } = useGetAllDriversQuery();

  const [deleteDriver] = useDeleteDriverMutation();

  const [displayAddDriverForm, setDisplayAddDriverForm] = useState<boolean>(
    false,
  );
  const [driverEdition, setDriverEdition] = useState<Driver | undefined>(
    undefined,
  );

  function handleDeleteDriver(id: number) {
    deleteDriver(id)
      .unwrap()
      .then(() => {
        toast.success('Le livreurs a été supprimé');
      })
      .catch(() => {
        toast.error(`Une erreur est survenue`);
      });
  }

  const onAfterSave = () => {
    setDriverEdition(undefined);
  };

  return (
    <>
      <Helmet>
        <title>Livreurs Kewlox</title>
        <meta name="description" content="Livreurs Kewlox" />
      </Helmet>
      <SiteWrapper title="Livreurs Kewlox">
        <div className="mx-auto w-2/3 lg:w-1/2 bg-white px-8">
          <div>
            {isLoadingDrivers && <CircleSpinner size={35} color="#4a5568" />}
          </div>

          <TableContainer className="overflow-y-auto w-128 mt-12">
            <Table
              stickyHeader
              size="small"
              aria-label="List des livreurss Kewlox"
            >
              <TableHead className="border-b-2 border-black">
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    Nom
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    Initiales
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drivers &&
                  [...drivers]
                    .sort((a, b) => a.id - b.id)
                    .map(driver => (
                      <TableRow key={driver.id}>
                        <TableCell align="right" colSpan={4}>
                          {driver.name}
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          {driver.shortName ?? '-'}
                        </TableCell>
                        <TableCell align="right" colSpan={2}>
                          <button
                            className="py-0 px-2 mx-2 bg-white text-gray-700 border border-gray-300 mx2"
                            onClick={() => {
                              setDriverEdition(driver);
                              setDisplayAddDriverForm(false);
                            }}
                          >
                            Modifier
                          </button>
                          <button
                            className="py-0 px-2 bg-white text-gray-700 border border-gray-300 mx2 text-red-dark"
                            onClick={() => {
                              handleDeleteDriver(driver.id);
                            }}
                          >
                            Retirer
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          {driverEdition !== undefined && (
            <div className="flex justify-center">
              <DriverForm
                editedDriver={driverEdition}
                onAfterSave={onAfterSave}
              />
            </div>
          )}

          {displayAddDriverForm ? (
            <div className="flex justify-center">
              <DriverForm />
            </div>
          ) : (
            <div className="my-4 flex justify-center">
              <Button
                contentText="Ajouter un livreur"
                onClick={() => {
                  setDriverEdition(undefined);
                  setDisplayAddDriverForm(true);
                }}
              />
            </div>
          )}
        </div>
      </SiteWrapper>
    </>
  );
};

export default DriverPage;
