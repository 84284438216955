import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { actions } from 'app/pages/ProductionPage/slice';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SiteWrapper from 'app/containers/SiteWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from 'app/pages/ProductionPage/BasePickingListPage/style';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';
import {
  ProductionStatus,
  Workstation,
} from 'app/containers/OrderDetails/types';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import SwipeableCell from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/SwipeableCell';
import { ListType } from 'app/pages/ProductionPage/constants';
import {
  getProductionPiece,
  setCuttingPieces,
} from 'app/containers/SiteWrapper/slice';
import {
  selectCornersProductionPiecesPicked,
  selectCornersProductionPiecesToPick,
} from 'app/pages/ProductionPage/CornersPickingPage/selectors';
import PickedList from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage/PickedList';
import { selectOrdersMap } from 'app/containers/SiteWrapper/selectors';
import TabsProductionStatus from 'app/components/TabProductionStatus';
import { IconButton, InputBase } from '@mui/material';

const CornersPickingPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const productionToPick = useSelector(selectCornersProductionPiecesToPick);
  const productionPicked = useSelector(selectCornersProductionPiecesPicked);
  const ordersMap = useSelector(selectOrdersMap);

  const [productionStatusFilter, setProductionStatusFilter] = useState<
    ProductionStatus
  >(ProductionStatus.NotStarted);

  useEffect(() => {
    if (ordersMap) dispatch(getProductionPiece(Workstation.Corner, ordersMap));
    return () => {
      dispatch(setCuttingPieces([]));
    };
  }, [ordersMap]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setProductionStatusFilter(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Production</title>
        <meta name="description" content="Cornières" />
      </Helmet>
      <SiteWrapper
        title={'Cornières'}
        leftTopBarButton={
          <Link to="/production">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(actions.setProductionMode(undefined))}
            >
              <MenuIcon />
            </IconButton>
          </Link>
        }
        rightTopBarButton={
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        }
      >
        <ProductionContainer>
          <TabsProductionStatus
            productionStatus={productionStatusFilter}
            handleTabChange={handleTabChange}
          />
          <SwipeableList>
            {productionToPick?.map(piece => (
              <>
                <SwipeableCell
                  key={piece.id}
                  quantity={piece.quantityStandby}
                  piece={piece}
                  cabinetName={piece.cabinetName}
                  listType={ListType.standby}
                  hideDescription
                />

                <SwipeableCell
                  key={piece.id}
                  quantity={
                    piece.quantity -
                    piece.quantityStandby -
                    piece.quantityPicked
                  }
                  piece={piece}
                  cabinetName={piece.cabinetName}
                  listType={ListType.picking}
                  hideDescription
                />
              </>
            ))}
          </SwipeableList>
          {productionPicked && productionPicked.length > 0 && (
            <PickedList production={productionPicked} />
          )}
        </ProductionContainer>
      </SiteWrapper>
    </>
  );
};

export default CornersPickingPage;
