import { createSelector } from '@reduxjs/toolkit';
import { selectCuttingPiecesSorted } from 'app/containers/SiteWrapper/selectors';

export const selectCornersProductionPiecesToPick = createSelector(
  selectCuttingPiecesSorted,
  production => {
    if (production) {
      return production.filter(entry => entry.quantityPicked < entry.quantity);
    }
  },
);

export const selectCornersProductionPiecesPicked = createSelector(
  selectCuttingPiecesSorted,
  production => {
    if (production) {
      return production.filter(entry => entry.quantityPicked > 0);
    }
  },
);
