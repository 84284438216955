import { createSelector } from '@reduxjs/toolkit';
import { Workstation } from 'app/containers/OrderDetails/types';
import { Trolley } from 'app/models/Trolley';
import { ProductOrder } from 'app/models/ProductOrder';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';

export const selectedTrolley = (workstation: Workstation) =>
  createSelector([selectCurrentPartOrder()], partOrder => {
    if (!partOrder) return undefined;
    return partOrder.trolleys.filter(
      (trolley: Trolley) => trolley.workstation === workstation,
    );
  });

export const selectDrawerComposition = (drawerId: number | undefined) =>
  createSelector([selectCurrentPartOrder()], partOrder => {
    if (!drawerId || !partOrder || !partOrder.products) return undefined;

    const drawerProduct = partOrder.products.find(
      (product: ProductOrder) => product.id === drawerId,
    );

    return drawerProduct?.production;
  });

export const selectProduct = (productId: number | undefined) =>
  createSelector([selectCurrentPartOrder()], partOrder => {
    if (!productId || !partOrder || !partOrder.products) return undefined;

    const product = partOrder.products.find(
      (product: ProductOrder) => product.id === productId,
    );

    return product;
  });
