import React, { useEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FaCubes, FaHammer, FaRegCalendarTimes } from 'react-icons/fa';
import {
  STATUS_FINAL_PROCESSING,
  STATUS_PARTIAL_PROCESSING,
} from 'app/pages/ToursPage/constants';
import DateDelay from 'app/components/DateDelay';
import { FiMessageSquare } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { selectOrderAction } from 'app/containers/TourMap/slice';
import { useDispatch } from 'react-redux';
import DestinationLabel from 'app/components/Kewlox/DestinationLabel';
import { selectDestinationFilter } from 'app/containers/ToursList/slice';
import { Destination } from 'app/models/Destination';
import InfoLocation from 'app/pages/ToursPage/InfoLocation';
import { PartOrder } from 'app/models/PartOrder';
import { MdCallSplit, RiAlarmWarningFill } from 'react-icons/all';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { setCurrentPartOrderId } from 'store/slices/activeContentSlice';

interface Props {
  destination: Destination;
  orders: PartOrder[];
}

const DestinationTable = ({ destination, orders }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [orders]);

  const delivery = destination.name.toUpperCase().includes('HOME');

  const handleClickRow = (id: number) => {
    const orderSelected = orders.find(e => e.id === id);
    if (orderSelected) {
      dispatch(selectOrderAction(orderSelected));
      dispatch(
        selectDestinationFilter({
          destination: orderSelected.destination,
          list: false,
        }),
      );
    }
  };

  return (
    <div className="bg-white">
      <div className="px-1 text-md w-full pt-6 pb-2 italic bg-grey-lightest">
        {destination.name}
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Order picking">
          <Droppable
            droppableId={`order-tables-${destination.id}`}
            renderClone={(provided, snapshot, rubric) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {
                  <div className="flex flex-col bg-white text-gray-800 text-xl my-2 mx-3 border-2 border-blue rounded w-24 flex justify-center items-center">
                    {`${orders[rubric.source.index].order.id}`}
                    <div className="text-sm">
                      {orders[rubric.source.index].orderInfo.lastname}
                    </div>
                  </div>
                }
              </div>
            )}
          >
            {(provided, snapshot) => (
              <TableBody ref={provided.innerRef}>
                {orders.map((partOrder, index) => (
                  <TableRow
                    key={partOrder.id}
                    className={` ${
                      partOrder.orderInfo.standby
                        ? 'bg-orange-lighter'
                        : 'hover:bg-gray-200'
                    }`}
                    onMouseDown={() => {
                      console.log('onMouseDown');
                      handleClickRow(partOrder.id);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Draggable
                        key={partOrder.id}
                        draggableId={`${partOrder.id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="font-semibold flex items-center"
                          >
                            {partOrder.identification}{' '}
                            {partOrder.orderInfo.assembly && (
                              <FaHammer className="h4 w-4 ml-2" />
                            )}
                            {partOrder.outOfStockProducts && (
                              <RiAlarmWarningFill
                                className="h-5 w-5 mx-1 bg-red text-white"
                                data-tip={'Rupture de stock'}
                                data-for="infos"
                              />
                            )}
                            {!partOrder.packageDelivery &&
                              partOrder.index > 1 &&
                              (partOrder.orderInfo.statusId ===
                                STATUS_PARTIAL_PROCESSING ||
                                partOrder.orderInfo.statusId ===
                                  STATUS_FINAL_PROCESSING ||
                                partOrder.total > 1) && (
                                <MdCallSplit
                                  className="h-5 w-5 ml-2 text-gray-800"
                                  data-tip={`Livraison partielle`}
                                  data-for="rowInfos"
                                />
                              )}
                            {partOrder.packageDelivery && (
                              <FaCubes
                                className="h-5 w-5 ml-2 text-gray-800"
                                data-tip={`Livraison partielle`}
                                data-for="rowInfos"
                              />
                            )}
                            {/*{partOrder.orderInfo.statusId ===*/}
                            {/*  STATUS_PARTIAL_PROCESSING && (*/}
                            {/*  <FaCube*/}
                            {/*    className="h4 w-4 ml-2"*/}
                            {/*    data-tip={`Livraison partielle, il restera après : ${partOrder.orderInfo.remainsDelivery}`}*/}
                            {/*    data-for="rowInfos"*/}
                            {/*  />*/}
                            {/*)}*/}
                            {/*{partOrder.orderInfo.statusId ===*/}
                            {/*  STATUS_FINAL_PROCESSING && (*/}
                            {/*  <FaCubes*/}
                            {/*    className="h4 w-4 ml-2"*/}
                            {/*    data-tip={`Livraison finale : ${partOrder.orderInfo.remainsDelivery}`}*/}
                            {/*    data-for="rowInfos"*/}
                            {/*  />*/}
                            {/*)}*/}
                            <div className="capitalize ml-1 font-normal">
                              {' - '}{' '}
                              {partOrder.orderInfo.firstname
                                .charAt(0)
                                .toUpperCase()}
                              {'. '}
                              {partOrder.orderInfo.lastname.toLocaleLowerCase()}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </TableCell>
                    {delivery && (
                      <TableCell align="right">
                        <div className="font-semibold">
                          <InfoLocation
                            province={partOrder.orderInfo.province}
                            isoCode={partOrder.orderInfo.isoCode}
                          />
                        </div>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <div className="italic text-sm">
                        <DateDelay
                          date={partOrder.orderInfo.invoiceDate}
                          priority={partOrder.orderInfo.priority}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex justify-center">
                        <DestinationLabel
                          destination={partOrder.destination}
                          short
                        />

                        {partOrder.destination.home && partOrder.carrier ? (
                          <span
                            className="ml-2 font-semibold"
                            title={partOrder.carrier.name}
                          >
                            {partOrder.carrier.name.substr(0, 3)}
                          </span>
                        ) : (
                          partOrder.carrierSuggested && (
                            <span
                              className="italic text-grey ml-2"
                              title={`Recommandé : ${partOrder.carrierSuggested.name}`}
                            >
                              {partOrder.carrierSuggested.name.substr(0, 3)}
                            </span>
                          )
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div className="flex justify-center items-center">
                        {partOrder.orderInfo.messages ? (
                          <div className="flex items-center">
                            {partOrder.orderInfo.messages}
                            {partOrder.orderInfo.messages > 0 && (
                              <FiMessageSquare className="h-3 w-3" />
                            )}
                          </div>
                        ) : null}
                        {partOrder.orderInfo.standby && (
                          <FaRegCalendarTimes
                            className="h4 w-4 ml-2"
                            data-tip={`Affrètemenent en standby : ${partOrder.orderInfo.standby}`}
                            data-for="rowWarning"
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <button
                        className="border py-0 px-2 text-gray-700"
                        onClick={() => {
                          dispatch(setCurrentPartOrderId(partOrder.id));
                        }}
                      >
                        Détails
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Droppable>
        </Table>
        <ReactTooltip id="rowInfos" place="bottom" type="dark" />
        <ReactTooltip id="rowWarning" place="bottom" type="warning" />
      </TableContainer>
    </div>
  );
};

export default DestinationTable;
