/**
 *
 * ProductionPage
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { selectProductionMode } from './selectors';
import styled from 'styled-components';
import { ProductionMode } from 'app/pages/ProductionPage/constants';
import { Navigate } from 'react-router-dom';
import SiteWrapper from 'app/containers/SiteWrapper';
import ProductionContainer from 'app/pages/ProductionPage/ProductionContainer';

export function ProductionPage() {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const productionModeSelected = useSelector(selectProductionMode);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  const onClickProductionMode = (mode: ProductionMode) => {
    dispatch(actions.setProductionMode(mode));
  };

  if (productionModeSelected !== undefined) {
    return (
      <Navigate
        to={`/production/${productionModeSelected}`}
        state={{ from: location }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Production</title>
        <meta
          name="description"
          content="Production infos about Kewlox order"
        />
      </Helmet>

      <SiteWrapper title="Production">
        <ProductionContainer>
          <div className="flex flex-col my-4 w-full lg:1/3">
            <ProductionButton
              onClick={() => onClickProductionMode(ProductionMode.basePicking)}
            >
              Picking de base
            </ProductionButton>
            <ProductionButton
              onClick={() => {
                onClickProductionMode(ProductionMode.corners);
              }}
            >
              Cornières
            </ProductionButton>
            <ProductionButton
              onClick={() =>
                onClickProductionMode(ProductionMode.accessoriesPicking)
              }
            >
              Picking accessoires
            </ProductionButton>
            <ProductionButton
              onClick={() =>
                onClickProductionMode(ProductionMode.drawerPicking)
              }
            >
              Picking tiroirs
            </ProductionButton>
          </div>

          <div className="flex flex-col mt-20 w-full lg:1/3">
            <ProductionButton
              onClick={() => onClickProductionMode(ProductionMode.paneCutting)}
            >
              Découpe panneaux
            </ProductionButton>
            <ProductionButton
              onClick={() =>
                onClickProductionMode(ProductionMode.drawerCuttinh)
              }
            >
              Découpe tiroirs
            </ProductionButton>
            <ProductionButton
              onClick={() =>
                onClickProductionMode(ProductionMode.woodworkCutting)
              }
            >
              Découpe menuiserie
            </ProductionButton>
          </div>
        </ProductionContainer>
      </SiteWrapper>
    </>
  );
}

const ProductionButton = styled.button`
  margin: 0.5rem 0.5rem;
  border-width: 1px;
  background-color: white;

  --border-opacity: 1;
  border-color: #718096;
  border-color: rgba(113, 128, 150, var(--border-opacity));

  font-size: 1.5rem;
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));

  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;
