/**
 * Create the store with dynamic reducers
 */

import { configureStore, Middleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { kewloxApi } from 'services/kewloxApi';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware] as Middleware[];

  middlewares.push(kewloxApi.middleware);

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(sagaMiddleware, kewloxApi.middleware),
    devTools: import.meta.env.NODE_ENV !== 'production',
    enhancers,
  });

  return store;
}
