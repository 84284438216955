// Define endpoints related to carriers

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Carrier } from 'types';
import { CarrierConfiguration } from 'app/pages/CarrierConfigurationPage/types';

export const carrierEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getCarriers: builder.query<Carrier[], void>({
    query: () => `/carrier/`,
    providesTags: ['Carrier'],
  }),
  getCarrierConfiguration: builder.query<CarrierConfiguration, number>({
    query: id => `/carrier-conf/${id}/`,
    providesTags: ['CarrierConfiguration'],
  }),
  updateCarrierConfiguration: builder.mutation<
    CarrierConfiguration,
    Partial<CarrierConfiguration>
  >({
    query: ({ id, ...body }) => ({
      url: `carrier-conf/${id}/`,
      method: 'PATCH',
      body: body,
    }),
    invalidatesTags: ['CarrierConfiguration'],
  }),
});
