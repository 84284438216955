import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import { providesList } from 'services/utils';

export const waypointEndpoints = (builder: EndpointBuilder<any, any, any>) => ({
  getAllWaypoints: builder.query<Waypoint[], void>({
    query: () => `/waypoint/`,
    providesTags: results => providesList(results, 'Waypoint'),
  }),
  getWaypoint: builder.query<Waypoint, number>({
    query: id => `/waypoint/${id}/`,
    providesTags: ['Waypoint'],
  }),
  addWaypoint: builder.mutation<Waypoint, Partial<Waypoint>>({
    query: newWaypoint => ({
      url: `/waypoint/`,
      method: 'POST',
      body: newWaypoint,
    }),
    invalidatesTags: ['Waypoint'],
  }),
  updateWaypoint: builder.mutation<Waypoint, Partial<Waypoint>>({
    query: ({ id, ...body }) => ({
      url: `waypoint/${id}/`,
      method: 'PATCH',
      body: body,
    }),
    invalidatesTags: ['Waypoint'],
  }),
  deleteWaypoint: builder.mutation<Waypoint, number>({
    query: id => ({
      url: `waypoint/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Waypoint'],
  }),
});
