import { backgroundColors, ListType } from './../../constants';
import { createSelector } from '@reduxjs/toolkit';
import { selectOrderPackages } from 'app/containers/SiteWrapper/selectors';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { Workstation } from 'app/containers/OrderDetails/types';

export const selectProductionPiecesFactory = (
  workstation: Workstation,
  listType?: ListType,
) =>
  createSelector(selectOrderPackages, packages => {
    if (packages) {
      const pieces: ProductionPiece[] = [];

      for (const pkg of packages) {
        if (pkg.composition) {
          for (const piece of pkg.composition.filter(
            e => e.station === workstation,
          )) {
            if (
              listType === undefined ||
              (listType === ListType.picked && piece.quantityPicked > 0) ||
              (listType === ListType.standby && piece.quantityStandby > 0) ||
              (listType === ListType.picking &&
                (piece.quantityStandby > 0 ||
                  piece.quantity >
                    piece.quantityPicked + piece.quantityStandby))
            )
              pieces.push(piece);
          }
        }
      }

      if (listType === ListType.picked)
        pieces.sort((a, b) => {
          if (a.pickedAt === undefined || b.pickedAt === undefined) return 0;
          return b.pickedAt.getTime() - a.pickedAt.getTime();
        });
      return pieces;
    }
  });

/**
 * Group spare part that are in the same package an cabinet
 * For example: if 6x G1-9 of spare part and 1 package of standard pieces for cabinet (reference: REFC1) but
 * Daniel choose to regroup 2 of the 6 G1-9 inside another package of type cabinet with others standard pieces of REFC1
 * we need to have 4x G1-9 (labeled to "/") and 2x G1-9 (labeled to cabinet "REFC1") in the picking list instead of 6x G1-9
 * So the picker user can put the 2x G1-9 on the same chariot than standard piece for the cabinet "REFC1"
 */
// export const selectProductionPiecesGroupedSparePartFactory = (
//   workstation: Workstation,
//   listType?: ListType,
// ) =>
//   createSelector(
//     selectProductionPiecesFactory(workstation, listType),
//     selectOrderPackages,
//     (pieces, packages) => {
//       if (pieces && packages) {
//         const groupPieces: ProductionPiece[] = [];
//         for (const piece of pieces) {
//           if (!piece.cabinet) {
//             for (const pkg of packages) {
//               if (pkg.composition) {
//                 for (const pkgPiece of pkg.composition) {
//                   if (pkgPiece.product.id === piece.product?.id) {
//                     const cabinet = pkg.cabinet;
//                     const newPiece = piece.clone();
//                     newPiece.quantity = pkgPiece.quantity;
//                     if (cabinet) {
//                       newPiece.cabinet = cabinet;
//                       newPiece.sparePart = true;
//                       if (pkg.productCabinet)
//                         newPiece.product = pkg.productCabinet;
//                     } else {
//                       newPiece.cabinet = undefined;
//                       newPiece.sparePart = true;
//                       newPiece.product = undefined;
//                     }
//                     groupPieces.push(newPiece);
//                   }
//                 }
//               }
//             }
//           } else {
//             groupPieces.push(piece);
//           }
//         }
//         return groupPieces;
//       } else {
//         return pieces;
//       }
//     },
//   );

export const selectMapCabinetsColor = (workstation: Workstation) =>
  createSelector(
    selectProductionPiecesFactory(workstation),
    (pieces: ProductionPiece[] | undefined): Map<string, string> => {
      const resultMap = new Map<string, string>();
      if (pieces) {
        const cabinets = new Set(pieces.map(entry => entry.cabinetName));

        Array.from(cabinets).forEach((cabinetName, i) => {
          resultMap.set(
            cabinetName,
            backgroundColors[i % backgroundColors.length],
          );
        });
      }
      return resultMap;
    },
  );
