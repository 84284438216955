import { createSelector } from '@reduxjs/toolkit';
import {
  selectDestinationFilteredList,
  selectDestinationSelected,
} from 'app/containers/ToursList/selectors';
import _ from 'lodash';
import { selectSearchFieldValue } from 'app/containers/SearchField/selectors';
import { Destination } from 'app/models/Destination';
import { PartOrder } from 'app/models/PartOrder';
import { selectAllPartOrders } from '../../../store/slices/partOrderSlice';
import { selectPartOrderInDelivery } from '../../../store/slices/deliverySlice';

// export const selectUnAllocatedOrders = createSelector(
//   [selectOrderInfos, selectAllOrderToursId],
//   (orders, ordersInTours) =>
//     orders.filter(e => !ordersInTours.includes(e.idOrder)),
// );

// export const selectOrderWithInfosInTour = createSelector(
//   [selectedTour, selectOrderInfos],
//   (tour, orders) => {
//     if (tour)
//       return orders.filter(o =>
//         tour.orders.map(e => e.order?.id).includes(o.idOrder),
//       );
//     return null;
//   },
// );

export const selectUnallocatedPartOrders = createSelector(
  [selectAllPartOrders, selectPartOrderInDelivery()],
  (partOrders, partOrderIdInsideDelivery): PartOrder[] | undefined => {
    const partOrderIdsSet = new Set(partOrderIdInsideDelivery);
    return partOrders.filter(entry => !partOrderIdsSet.has(entry.id));
  },
);

export const selectUnallocatedPartOrderByDestination = createSelector(
  [
    selectUnallocatedPartOrders,
    selectSearchFieldValue,
    selectDestinationFilteredList,
    selectDestinationSelected,
  ],
  (partOrders, searchValue, destinationFilteredList, selectedDestination) => {
    if (destinationFilteredList && selectedDestination) {
      const results = partOrders?.filter(
        e =>
          e.destination.id === (selectedDestination as Destination | null)?.id,
      );
      if (results) return [results];
    }
    if (searchValue) {
      const searchOrder = partOrders?.find(
        e => e.id.toString() === searchValue,
      );
      if (searchOrder) return [[searchOrder]];
    }

    const ret = _.chain(partOrders)
      .orderBy(['sorting', 'orderInfo.isoCode', 'orderInfo.postcode'])
      .groupBy('destination.id')
      .value();
    const data = Object.values(ret);
    data.sort((a, b) => a[0].sorting - b[0].sorting);

    return data;
  },
);
