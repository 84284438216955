import React, { useMemo } from 'react';
import { ProductionPiece } from 'app/models/ProductionPiece';
import PickingPageHeader from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingPageHeader';
import { Workstation } from 'app/containers/OrderDetails/types';
import { PickingSection } from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingSection';
import { useSelector } from 'react-redux';
import { selectDrawerComposition } from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/selectors';
import { PartOrder } from 'app/models/PartOrder';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

interface Props {
  order: PartOrder;
  pieces: ProductionPiece[];
  workstation: Workstation;
  trolleyName?: string;
  drawerId?: number;
}

const PickingPage = ({
  order,
  pieces,
  workstation,
  trolleyName,
  drawerId,
}: Props) => {
  const linesByPage = 27;
  const drawerPieces = useSelector(selectDrawerComposition(drawerId));

  const numberOfTextLines = piece => {
    var nbCharsInLine = 50;
    var html =
      piece.product && piece.product.uniqueProduct
        ? piece.product.name
        : piece.descriptionHTML ?? '';
    var div = document.createElement('div');
    div.innerHTML = html;
    var text = div.textContent || div.innerText || '';

    return Math.floor(text.length / nbCharsInLine) + 1;
  };

  const pickingSubPages = useMemo(() => {
    let cabinetPiecesList: ProductionPiece[] = [];
    const cuttingPiecesList: ProductionPiece[] = [];
    let spartPartPiecesList: ProductionPiece[] = [];
    const cuttingSpartPartPiecesList: ProductionPiece[] = [];

    const isWidth62 = !!(
      pieces.find(p => p.type === 'cross piece' && p.width === 611) &&
      !pieces.find(p => p.type === 'cross piece' && p.width > 611)
    );

    // Clone pieces before modifying because pieces is immutable
    const clonedPieces = pieces.map(p => cloneDeep(p));
    clonedPieces.forEach(p => (p.isWidth62 = isWidth62));

    let allPieces = _.orderBy(clonedPieces, [
      'sorting',
      'sortingInType',
      'sortingThird',
    ]);

    if (drawerPieces) {
      allPieces = [...allPieces, ...drawerPieces];
    }

    for (const piece of allPieces) {
      if (!piece.drawer) {
        if (piece.isSparePartInsideCabinetPackage) {
          if (piece.cutting) cuttingSpartPartPiecesList.push(piece);
          else spartPartPiecesList.push(piece);
        } else {
          if (piece.cutting) cuttingPiecesList.push(piece);
          else cabinetPiecesList.push(piece);
        }
      }
    }

    // We dont remove the spare part section for if it's a drawer
    if (drawerPieces && drawerPieces.length > 0) {
      cabinetPiecesList = [...spartPartPiecesList, ...cabinetPiecesList];
      spartPartPiecesList = [];
    }

    const newPckingSubPages: any[] = [];
    let counter = 0;
    let nbSubPages = 0;
    const allListsData = [
      cabinetPiecesList,
      cuttingPiecesList,
      spartPartPiecesList,
      cuttingSpartPartPiecesList,
    ];
    newPckingSubPages.push([[], [], [], []]);
    for (let index = 0; index < allListsData.length; index++) {
      if (
        index === 3 &&
        allListsData[2].length === 0 &&
        allListsData[index].length > 0
      )
        counter++;
      if (index > 0 && allListsData[index].length > 0) counter++;
      for (const piece of allListsData[index]) {
        if (counter >= linesByPage) {
          counter = 0;
          nbSubPages++;
          newPckingSubPages.push([[], [], [], []]);

          if (
            index === 3 &&
            allListsData[2].length === 0 &&
            allListsData[index].length > 0 &&
            newPckingSubPages[nbSubPages - 1][index].length > 0
          )
            counter++;
          if (
            index > 0 &&
            allListsData[index].length > 0 &&
            newPckingSubPages[nbSubPages - 1][index].length > 0
          )
            counter++;
        }
        counter += numberOfTextLines(piece);

        newPckingSubPages[nbSubPages][index].push(piece);
      }
    }

    return newPckingSubPages;
  }, [order.id]);

  return (
    <>
      {pickingSubPages.map((subpage, indexPage) => (
        <div key={indexPage} className="page page-break" lang="fr">
          <PickingPageHeader
            order={order}
            workstation={workstation}
            indexPage={indexPage + 1}
            nbPages={pickingSubPages.length}
            trolley={trolleyName}
          />
          <div className="flex flex-col">
            <PickingSection pieces={subpage[0]} />
            <PickingSection
              pieces={subpage[1]}
              title={subpage[1].length > 0 ? 'Découpes' : undefined}
            />
            {(subpage[2].length > 0 || subpage[3].length > 0) && (
              <div className="flex mt-4 -mb-1">
                <div className="text-xl font-bold">
                  Pièces supplémentaires au meuble du chariot
                </div>
              </div>
            )}
            <PickingSection pieces={subpage[2]} />
            <PickingSection
              pieces={subpage[3]}
              title={subpage[3].length > 0 ? 'Découpes' : undefined}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default PickingPage;
