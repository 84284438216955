/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';
import 'react-toastify/dist/ReactToastify.css';

import { NotFoundPage } from './components/NotFoundPage';
import PrivateRoute from 'app/containers/PrivateRoute';
import { ToursPage } from './pages/ToursPage';
import { LoginPage } from './pages/LoginPage';
import { AuthProvider } from 'app/containers/AuthProvider';
import { ConfirmationPage } from 'app/pages/ConfirmationPage';
import { ToastContainer } from 'react-toastify';
import { ProductionPage } from 'app/pages/ProductionPage';
import { BasePickingListPage } from 'app/pages/ProductionPage/BasePickingListPage';
import BasePickingDetailsPage from 'app/pages/ProductionPage/BasePickingListPage/BasePickingDetailsPage';
import CuttingPanePage from 'app/pages/ProductionPage/CuttingPanePage';
import CornersPickingPage from 'app/pages/ProductionPage/CornersPickingPage';
import DrawersPage from 'app/pages/ProductionPage/DrawersPage';
import DrawerPickingPage from 'app/pages/ProductionPage/DrawersPage/DrawerPickingPage';
import DrawersCuttingPage from 'app/pages/ProductionPage/DrawersCuttingPage';
import WaypointsPage from 'app/pages/WaypointsPage';
import DriverPage from 'app/pages/DriverPage';
import AddWaypointPage from 'app/pages/WaypointsPage/AddWaypoint';
import PickupPage from 'app/containers/PickupPage';
import CarrierConfigurationPage from 'app/pages/CarrierConfigurationPage';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'styles/theme';
import HistoryPage from 'app/pages/HistoryPage';

export function App() {
  return (
    <>
      <Helmet titleTemplate="%s - Expedinet" defaultTitle="Expedinet">
        <meta name="description" content="Logistics tools for Kewlox" />
      </Helmet>

      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/c/:token" element={<ConfirmationPage />} />
            <Route path="/pi/:orderId/:pickupCode" element={<PickupPage />} />

            <Route element={<PrivateRoute />}>
              <Route path="/" element={<ToursPage />} />
              <Route path="/history" element={<HistoryPage />} />
              <Route path="/waypoint" element={<WaypointsPage />} />
              <Route path="/waypoint/add" element={<AddWaypointPage />} />
              <Route
                path="/waypoint/edit/:waypointId"
                element={<AddWaypointPage />}
              />
              <Route path="/driver" element={<DriverPage />} />
              <Route path="/driver" element={<DriverPage />} />
              <Route
                path="/carrier-configuration"
                element={<CarrierConfigurationPage />}
              />
              <Route path="/production" element={<ProductionPage />} />
              <Route
                path="/production/picking/1"
                element={<CornersPickingPage />}
              />
              <Route
                path="/production/picking/:workstation"
                element={<BasePickingListPage />}
              />
              <Route
                path="/production/picking/:workstation/:orderId"
                element={<BasePickingDetailsPage />}
              />
              <Route path="/production/cutting" element={<CuttingPanePage />} />
              <Route path="/production/drawers" element={<DrawersPage />} />
              <Route
                path="/production/drawers-cutting"
                element={<DrawersCuttingPage />}
              />
              <Route
                path="/production/drawers/picking/:productId"
                element={<DrawerPickingPage />}
              />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ThemeProvider>
      </AuthProvider>
      <GlobalStyle />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}
