import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { PartOrder } from 'app/models/PartOrder';
import { useTheme } from '@mui/styles';
// import styled from 'styled-components';

interface Props {
  size?: number;
}

const LoadingSpinner = ({ size = 35 }: Props) => {
  const theme = useTheme();

  return (
    <div className="flex w-full items-center justify-center my-4">
      <div className="flex flex-col justify-center items-center">
        <CircleSpinner size={size} color={theme.palette.primary.main} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
