export enum ActionStream {
  CreateOrder = 'create-order',
  CreatePartOrder = 'create-part-order',
  Delete = 'delete',
  Update = 'update',
  OrderStatus = 'order-status',
  SplitPackages = 'split-packages',
  MergePackages = 'merge-packages',
  CreatePackages = 'create-packages',
  DeletePackages = 'delete-packages',
  PrintPickingList = 'print-picking',
  CancelPrintPickingList = 'cancel-print-picking',
  PrintCutting = 'print-cutting',
  Reset = 'reset',
  CarrierUpdated = 'carrier',
  DestinationUpdated = 'destination',
  ProductionDate = 'production-date',
  NotifyDelivery = 'notify-delivery',
  ConfirmedDelivery = 'confirmed-delivery',
  SubdivisePartOrder = 'subdivise',
  TourUpdated = 'tour',
  ChangeTourDate = 'tour-date',
  AddDeliveryTour = 'add-tour-delivery',
  DeleteDeliveryTour = 'delete-tour-delivery',
  CreateTour = 'create-tour',
  DeleteTour = 'delete-tour',
}

export class Event {
  id: number;
  eventId: string;
  action: ActionStream;
  user?: string;
  timeMillisec: number;
  tour?: string;
  from?: string;
  date?: string;
  datetime?: string;
  change?: string;

  constructor(
    id: number,
    eventId: string,
    action: ActionStream,
    user: string,
    timeMillisec: number,
  ) {
    this.id = id;
    this.eventId = eventId;
    this.action = action;
    this.user = user;
    this.timeMillisec = timeMillisec;
  }
}

export class Stream {
  name: string;
  events: Event[];
  partOrder: boolean;
  action: ActionStream;

  constructor(data: Stream) {
    this.name = data.name;
    this.events = data.events;
    const result = data.name.replace('stream:', '').split('.');
    this.partOrder = result[0] === 'part-order';
    // @ts-ignore
    this.action = result[1];
  }
}

export class Feed {
  streams: Stream[];

  constructor(streams: Stream[]) {
    this.streams = streams.map(s => new Stream(s));
  }

  get countEvents(): number {
    let counter = 0;
    for (const stream of this.streams) {
      counter += stream.events.length;
    }
    return counter;
  }

  get idNextEvent(): string | undefined {
    // Get the id of the last event of the feed

    // Get the last event
    let lastEvent: Event | undefined = undefined;
    for (const stream of this.streams) {
      for (const event of stream.events) {
        if (!lastEvent || event.timeMillisec >= lastEvent.timeMillisec)
          lastEvent = event;
      }
    }

    if (lastEvent) {
      const lastEntry = lastEvent.eventId.split('-');
      return `${lastEntry[0]}-${Number(lastEntry[1]) + 1}`;
    }

    return undefined;
  }

  isToursUpdatedInFeed(): boolean {
    // Check if we have events with tours
    const toursStream = this.streams.filter(stream =>
      [
        ActionStream.TourUpdated,
        ActionStream.ChangeTourDate,
        ActionStream.AddDeliveryTour,
        ActionStream.DeleteTour,
        ActionStream.DeleteDeliveryTour,
        ActionStream.CreateTour,
      ].includes(stream.action),
    );
    return toursStream && toursStream.length > 0;
  }

  toursUpdatedInFeed(): number[] | undefined {
    // Get the ids of tours updated in this feed
    const updatedToursSet = new Set<number>();
    const toursStream = this.streams.filter(stream =>
      [ActionStream.TourUpdated].includes(stream.action),
    );
    if (toursStream) {
      for (const stream of toursStream) {
        for (const event of stream.events) {
          if (event.tour) updatedToursSet.add(parseInt(event.tour));
          if (event.from) updatedToursSet.add(parseInt(event.from));
        }
      }
    }
    if (updatedToursSet.size > 0) return Array.from(updatedToursSet);
    return undefined;
  }

  partOrdersUpdatedInFeed(): number[] | undefined {
    // Get the ids of part orders updated in this feed
    const updatedPartOrderList = new Set<number>();
    const streamsList = this.streams.filter(stream => stream.partOrder);
    if (streamsList) {
      for (const stream of streamsList) {
        for (const event of stream.events) {
          updatedPartOrderList.add(event.id);
        }
      }
    }
    if (updatedPartOrderList.size > 0) return Array.from(updatedPartOrderList);
    return undefined;
  }

  shouldUpdateCutting(): boolean {
    // Check if we need to update the cutting badge
    return !!this.toursUpdatedInFeed();
  }
}
