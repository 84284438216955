import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { TextField, Theme } from '@mui/material';
import FormInputErrorStyled from 'app/components/styles/FormInputErrorStyled';
import Button from 'app/components/Button';
import { Waypoint } from 'app/pages/WaypointsPage/types';
import {
  useAddWaypointMutation,
  useUpdateWaypointMutation,
} from 'services/kewloxApi';
import CoordinatesHelp from 'assets/images/helps/coordinates.png';
import Img from 'app/components/Img';
import { makeStyles, createStyles } from '@mui/styles';

interface Props {
  waypoint: Partial<Waypoint>;
  onAfterSave?: any;
}

const WaypointForm = ({ waypoint, onAfterSave }: Props) => {
  const [displayCoordinatesHelp, setDisplayCoordinatesHelp] = useState<boolean>(
    false,
  );
  const [
    addWaypoint,
    { isLoading: isLoadingAddWaypoint },
  ] = useAddWaypointMutation();
  const [
    updateWaypoint,
    { isLoading: isLoadingUpdateWaypoint },
  ] = useUpdateWaypointMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: waypoint.name ?? '',
      description: waypoint.description ?? '',
      coordinates:
        waypoint.latitude && waypoint.longitude
          ? `${waypoint.latitude}, ${waypoint.longitude}`
          : '',
      address: waypoint.address ?? '',
      duration: waypoint.duration ? `${waypoint.duration / 60}` : '20',
      phoneNumber: waypoint.phoneNumber ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Champ requis'),
      description: Yup.string(),
      duration: Yup.number(),
      coordinates: Yup.string().required('Champ requis'),
      address: Yup.string().required('Champ requis'),
      phoneNumber: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const coordinatesValues = values.coordinates.split(',');
      if (!waypoint.id) {
        addWaypoint({
          name: values.name,
          address: values.address,
          latitude: parseFloat(coordinatesValues[0]),
          longitude: parseFloat(coordinatesValues[1]),
          duration: parseFloat(values.duration) * 60,
          phoneNumber: values.phoneNumber,
        })
          .unwrap()
          .then(() => {
            toast.success('Votre point de passage a été rajouté');
            onAfterSave();
          })
          .catch(() => {
            toast.error(`Votre point de passage n'a pas pu être rajouté`);
          });
      } else {
        updateWaypoint({
          id: waypoint.id,
          name: values.name,
          description: values.description,
          address: values.address,
          latitude: parseFloat(coordinatesValues[0]),
          longitude: parseFloat(coordinatesValues[1]),
          duration: parseFloat(values.duration) * 60,
          phoneNumber: values.phoneNumber,
        })
          .unwrap()
          .then(() => {
            toast.success('Votre point de passage a été mis à jours');
          })
          .catch(() => {
            toast.error(`Votre point de passage n'a pas pu être mis à jours`);
          });
      }
    },
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: 300,
        },
      },
    }),
  );
  const classes = useStyles();

  return (
    <div className="my-5">
      {waypoint.id && <div>Editer {waypoint.name}</div>}
      <form onSubmit={formik.handleSubmit} className={classes.root}>
        <div className="my-3">
          <TextField
            id="name"
            label="Nom"
            variant="outlined"
            {...formik.getFieldProps('name')}
            error={
              formik.touched.name !== undefined &&
              formik.errors.name !== undefined
            }
          />
          {formik.touched.name && formik.errors.name ? (
            <FormInputErrorStyled>{formik.errors.name}</FormInputErrorStyled>
          ) : null}
        </div>

        <div className="my-3">
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            placeholder="Cette description sera ajoutée à la feuille de tournée du livreur"
            multiline
            rows={4}
            {...formik.getFieldProps('description')}
            error={
              formik.touched.description !== undefined &&
              formik.errors.description !== undefined
            }
          />
          {formik.touched.description && formik.errors.description ? (
            <FormInputErrorStyled>
              {formik.errors.description}
            </FormInputErrorStyled>
          ) : null}
        </div>

        <div className="my-3">
          <TextField
            id="coordinates"
            label="Coordonnées GPS"
            variant="outlined"
            placeholder="50.622426726565635, 5.591740727008212"
            {...formik.getFieldProps('coordinates')}
            error={
              formik.touched.coordinates !== undefined &&
              formik.errors.coordinates !== undefined
            }
          />
          {formik.touched.coordinates && formik.errors.coordinates ? (
            <FormInputErrorStyled>
              {formik.errors.coordinates}
            </FormInputErrorStyled>
          ) : null}
        </div>

        <div className="text-xs -mt-2">
          Vous pouvez obtenir les coordonnées GPS avec Google Maps.{' '}
          {!displayCoordinatesHelp && (
            <span
              className="underline text-xs cursor-pointer"
              onClick={() => {
                setDisplayCoordinatesHelp(true);
              }}
            >
              En savoir plus
            </span>
          )}
        </div>

        {displayCoordinatesHelp && (
          <ul className="my-2 text-sm ml-4">
            <li className="">
              Rendez vous sur{' '}
              <a
                href="https://www.google.com/maps/@50.8410719,5.2062775,9.25z"
                target="_blank"
                className="underline"
              >
                Google Maps
              </a>
            </li>
            <li>
              Faite un clic droit sur la destination et copier les coordonnées
            </li>
            <Img src={CoordinatesHelp} alt="CoordinateHelp" className="my-2" />
            <li>Coller dans le formulaire ci-dessus</li>
          </ul>
        )}

        <div className="my-3">
          <TextField
            id="duration"
            label="Durée (en minute)"
            variant="outlined"
            multiline={true}
            // minRows={2}
            // maxRows={5}
            {...formik.getFieldProps('duration')}
            error={
              formik.touched.duration !== undefined &&
              formik.errors.duration !== undefined
            }
          />
          {formik.touched.duration && formik.errors.duration ? (
            <FormInputErrorStyled>
              {formik.errors.duration}
            </FormInputErrorStyled>
          ) : null}
        </div>

        <div className="my-3">
          <TextField
            id="address"
            label="Adresse"
            variant="outlined"
            {...formik.getFieldProps('address')}
            error={
              formik.touched.address !== undefined &&
              formik.errors.address !== undefined
            }
          />
          {formik.touched.address && formik.errors.address ? (
            <FormInputErrorStyled>{formik.errors.address}</FormInputErrorStyled>
          ) : null}
        </div>

        <div className="my-3">
          <TextField
            id="phoneNumber"
            label="Téléphone"
            variant="outlined"
            {...formik.getFieldProps('phoneNumber')}
            error={
              formik.touched.phoneNumber !== undefined &&
              formik.errors.phoneNumber !== undefined
            }
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <FormInputErrorStyled>
              {formik.errors.phoneNumber}
            </FormInputErrorStyled>
          ) : null}
        </div>

        <Button
          contentText={`${
            waypoint.id ? 'Sauver' : 'Ajouter un nouveau point de passage'
          }`}
          loading={isLoadingAddWaypoint || isLoadingUpdateWaypoint}
        />
      </form>
    </div>
  );
};

export default WaypointForm;
