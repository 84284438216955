import React from 'react';
import { Workstation } from 'app/containers/OrderDetails/types';
import { PackageOrder } from 'app/models/PackageOrder';
import { LabelPage } from 'app/containers/OrderDetails/PackagesTab/LabelsToPrint/LabelPage';
import PickingPage from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage';
import { useSelector } from 'react-redux';
import { selectedTrolley } from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/selectors';
import { Trolley } from 'app/models/Trolley';
import PickingCornerPage from 'app/containers/OrderDetails/HeaderOrderDetails/PrintOrder/PickingPage/PickingCornerPage';
import { PartOrder } from 'app/models/PartOrder';

interface Props {
  order: PartOrder;
  workstation: Workstation;
}

const PrintContent = ({ order, workstation }: Props) => {
  const trolleys = useSelector(selectedTrolley(workstation));

  const renderTrolley = (trolley: Trolley) => (
    <div key={trolley.id}>
      {trolley.production.length > 0 && (
        <PickingPage
          order={order}
          pieces={trolley.production}
          workstation={workstation}
          trolleyName={trolley.name}
          drawerId={trolley.drawer}
        />
      )}
      {order.carrier?.name !== 'BPOST' &&
        trolley.packages.map((pkg: PackageOrder) => (
          <LabelPage partOrder={order} packageOrder={pkg} key={pkg.id} />
        ))}
    </div>
  );

  if (!trolleys || trolleys.length === 0) return null;

  const shouldRenderCornerLabel =
    order.carrier?.name !== 'BPOST' &&
    order.carrier?.name !== 'B-POST' &&
    !order.direct;

  if (workstation === Workstation.Corner) {
    return (
      <div>
        <PickingCornerPage
          order={order}
          trolleys={trolleys}
          workstation={workstation}
        />
        {shouldRenderCornerLabel &&
          trolleys.map(trolley =>
            trolley.packages.map((pkg: PackageOrder) => (
              <LabelPage partOrder={order} packageOrder={pkg} key={pkg.id} />
            )),
          )}
      </div>
    );
  } else {
    return <div>{trolleys.map(trolley => renderTrolley(trolley))}</div>;
  }
};

export default PrintContent;
