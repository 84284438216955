import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { requestGet } from 'utils/requests';
import { PARTORDER_URL } from 'app/containers/SiteWrapper/constants';
import { actions, setOrders } from './slice';
import { actions as actionsTours } from 'app/containers/ToursList/slice';
import { Tour } from 'app/models/Tour';
import { parseISO } from 'date-fns';
import { createPartOrder, PartOrderJson } from 'app/models/PartOrder';
import { selectAllCarriers } from 'store/slices/carriersSlice';
import { selectAllDestinations } from 'store/slices/destinationsSlice';
import { selectAllColors } from 'store/slices/colorSlice';
import { selectCurrentPartOrder } from 'store/slices/activeContentSlice';
import { selectAllPartOrders } from 'store/slices/partOrderSlice';
import { selectPartOrderFromTour } from 'store/slices/toursSlice';

function* getOrdersFromApi() {}

function* updateOrderInfo(action) {
  const partOrders = yield select(selectAllPartOrders);
  const selectedOrder = yield select(selectCurrentPartOrder());
  const tour: Tour = action.payload;
  const ordersToUpdate: number[] = [];
  const partOrdersList = yield select(selectPartOrderFromTour(tour.id));

  console.log('updateOrderInfo called from productionSaga');

  for (const partOrder of partOrdersList) {
    if (selectedOrder.id === partOrder.id) {
      // Update selectedOrder with tour date/carrier
      selectedOrder.productionDate = parseISO(tour.date);
      selectedOrder.carrier = tour.carrier;
      yield put(actions.setOrder(selectedOrder));
    }
    for (const po of partOrders) {
      if (po.id === partOrder.id) {
        ordersToUpdate.push(po.id);
      }
    }
  }
  // Update orders with tour date/carrier
  const updatedOrders = partOrders.map(o => {
    if (ordersToUpdate.includes(o.id)) {
      if (tour.date) o.productionDate = parseISO(tour.date);
      o.carrier = tour.carrier;
    }
    return o;
  });

  yield put(setOrders([...updatedOrders]));
}

function* getOrderFromApi(action) {
  const destination = yield select(selectAllDestinations);
  const carriers = yield select(selectAllCarriers);
  const colors = yield select(selectAllColors);

  const url = `${PARTORDER_URL}${action.payload}/`;
  try {
    const data: PartOrderJson = yield call(requestGet, url);
    const partOrder = createPartOrder(data, destination, carriers, colors);
    yield put(actions.setOrder(partOrder));
  } catch (error) {
    yield put(actions.requestError(error));
  }
}

function* getOrderSaga() {
  yield takeEvery(actions.loadOrder.type, getOrderFromApi);
}

function* updateOrdersInfoSaga() {
  yield takeEvery(actionsTours.tourLoaded.type, updateOrderInfo);
}

export function* getInfoSaga() {
  yield takeLatest(actions.requestOrderInfos.type, getOrdersFromApi);
}

export function* productionSaga() {
  yield all([
    call(getInfoSaga),
    call(getOrderSaga),
    call(updateOrdersInfoSaga),
  ]);
}
