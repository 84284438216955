import { API_BASE_URL } from 'app/constants';

export const ORDER_URL = `${API_BASE_URL}/order`;
export const PACKAGE_URL = `${API_BASE_URL}/package`;

export enum DestinationOrderId {
  Home = 46,
  HomeNl = 36,
  HomeEu = 48,
  Stockel = 10,
  Liege = 23,
  Antwerpen = 24,
  Leuze = 19,
  Direct = 31,
  Koeln = 50,
}
