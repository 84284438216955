import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.searchField || initialState;

export const selectSearchFieldValue = createSelector(
  [selectDomain],
  searchFieldState => searchFieldState.value,
);

export const selectIsSearching = createSelector(
  [selectDomain],
  searchFieldState => searchFieldState.searching,
);
