import { ProductOrder } from 'app/models/ProductOrder';
import { ProductionPiece } from 'app/models/ProductionPiece';
import { OrderDetails } from 'app/models/OrderDetails';

export interface ProductAggregated {
  product: ProductOrder;
  quantity: number;
}

export interface ProductProductionPieceData {
  product: ProductOrder;
  production: ProductionPieceAggregated[];
}

export interface ProductionPieceAggregated {
  piece: ProductionPiece;
  quantity: number;
}

export enum ProductionPieceStatus {
  Initial = 0,
  Picked = 1,
  Cut = 2,
  Wrapped = 3,
  Standby = 4,
  Damaged = 5,
}

export enum ProductOrderType {
  Cabinet = 'C',
  Piece = 'P',
  Other = 'O',
  Drawer = 'D',
}

export enum Workstation {
  Cabinet = 0,
  Corner = 1,
  Accessory = 2,
  Drawer = 3,
  Woodwork = 4,
  Marketing = 5,
  WoodworkSpecial = 6,
}

export enum ProductionStatus {
  NotStarted = 0,
  Started = 1,
  Standby = 2,
  Done = 3,
}

export enum PackageType {
  Cabinet = 1,
  Corner = 2,
  Glass = 3,
  Wardrobe = 4,
  Intermediate_shelf = 5,
  Intermediate_shelf_accessories = 6,
  Drawer = 7,
  Accessories = 8,
  WheelPane = 9,
  Other = 10,
  UpperShelf = 11,
}

/* --- STATE --- */
export interface OrderDetailsState {
  order?: OrderDetails;
  error?: string;
}

export type ContainerState = OrderDetailsState;
