import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaHammer } from 'react-icons/fa';
import { PackageOrder } from 'app/models/PackageOrder';
import 'assets/css/print.css';
import { PackageType } from '../../types';
// eslint-disable-next-line no-restricted-imports
import Logo from 'assets/images/Kewlox_logo.svg';
import Pefc from 'assets/images/Kewlox_pefc.svg';
import Guarantee from 'assets/images/Kewlox_guarantee.svg';
import Img from '../../../../components/Img';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import QRCode from 'qrcode.react';
import { PartOrder } from 'app/models/PartOrder';
// eslint-disable-next-line no-restricted-imports
import { css } from 'styled-components';
import { BoxWithPackages } from './BoxWithPackages';
import DataMatrix from 'react-datamatrix-svg';
import { productionPieceGetDescription } from 'app/models/ProductionPiece';

interface Props {
  partOrder: PartOrder;
  packageOrder: PackageOrder;
  firstPage?: boolean;
}

export function LabelPage({
  partOrder,
  packageOrder,
  firstPage = false,
}: Props) {
  const { t } = useTranslation();
  const destination = partOrder.destination;
  const info = partOrder.orderInfo;

  const config = { rectangular: false, bitSize: 1 };

  const lang = info.idLang === 5 ? 'fr' : info.idLang === 7 ? 'nl' : 'en';

  const numberOfPackages = partOrder.packages ? partOrder.packages.length : 0;

  return (
    <div
      key={packageOrder.id}
      className={`${!firstPage && 'page-break'} page relative flex`}
    >
      <div className="absolute bottom-label">
        <div className="relative">
          {partOrder.assembly && (
            <div className="flex absolute bottom-prod-assembly bg-yellow">
              <FaHammer className="bottom-hammer text-red" />
            </div>
          )}
          <div
            className={`flex absolute bottom-prod-date${
              partOrder.assembly ? ' bottom-prod-date-a' : ''
            }`}
          >
            {partOrder.productionDate ? (
              <div>
                {format(parseISO(partOrder.productionDate), 'dd/MM/yyyy')}
              </div>
            ) : (
              <>&nbsp;</>
            )}
          </div>
          <div
            className={`flex absolute item-center bottom-id-customer${
              partOrder.assembly ? ' bottom-id-customer-a' : ''
            }`}
          >
            <div className="font-bold">{partOrder.identification}</div>
            <div className="ml-4 bottom-id-customer-name -mt-1">
              {info.firstname} {info.lastname.toUpperCase()}
            </div>
          </div>
          <BoxWithPackages order={partOrder} packageOrder={packageOrder} />
          <div
            className={
              packageOrder.type === PackageType.Corner
                ? 'absolute bottom-pname-corner'
                : 'absolute bottom-pname'
            }
          >
            <LabelNameStyled
              size={
                packageOrder.uniqueDescr
                  ? packageOrder.uniqueDescr.length
                  : packageOrder.name?.length
              }
            >
              {packageOrder.uniqueDescr ?? packageOrder.name}
            </LabelNameStyled>

            {packageOrder.type === PackageType.Corner && (
              <div className="text-xl">
                {packageOrder.compositionExceptedCabinet &&
                  packageOrder.compositionExceptedCabinet?.length > 0 &&
                  packageOrder.compositionExceptedCabinet[0].product?.cabinet}
              </div>
            )}
          </div>
          <div className="absolute bottom-datamatrix">
            <DataMatrix
              msg={`${packageOrder.id}`}
              dim={100}
              pal={['#000', '#fff']}
            />
          </div>
          {packageOrder.type === PackageType.Corner && (
            <div className="absolute bottom-datamatrix-right">
              <DataMatrix
                msg={`${packageOrder.id}`}
                dim={80}
                pal={['#000', '#fff']}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-start justify-start label-header">
          <div>
            <Img src={Logo} alt="Logo" className="label-kewlox-logo" />

            <div className="label-header-slogan">
              {t('The storage furniture', { lng: lang })}
            </div>

            <div className="text-2xl  mt-1">
              {info.firstname} {info.lastname.toUpperCase()}
            </div>
          </div>
          <Img src={Guarantee} alt="Logo" className="img-guarantee" />
          <Img src={Pefc} alt="Logo" className="img-pefc" />
          <div className="head-datamatrix">
            <DataMatrix
              msg={`${packageOrder.id}`}
              dim={100}
              pal={['#000', '#fff']}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          {packageOrder.images.length === 1 && (
            <div className="flex items-start justify-start w-1/2">
              {packageOrder.images.length && (
                <Img src={packageOrder.images[0]} alt="Logo" />
              )}
            </div>
          )}
          <div
            className={
              packageOrder.images?.length === 1
                ? 'flex items-start justify-start w-1/2'
                : 'flex items-start justify-start flex-grow'
            }
          >
            <div className="w-full">
              {packageOrder.type === PackageType.Cabinet &&
                packageOrder.cabinet && (
                  <div>
                    {t('Package', { lng: lang })}{' '}
                    <b>{`${packageOrder.index}/${packageOrder.total}`}</b>{' '}
                    {t('of basic furniture parts', { lng: lang })}{' '}
                    <b>{packageOrder.cabinets && packageOrder.cabinets[0]}</b>
                    {packageOrder.furnitures && packageOrder.furnitures[0] && (
                      <>
                        {' '}
                        {t('of pack', { lng: lang })}{' '}
                        <b>f{packageOrder.furnitures[0]}</b>
                      </>
                    )}
                    <div className="label-note-pieces">
                      {packageOrder.compositionExceptedCabinet !== undefined &&
                      packageOrder.compositionExceptedCabinet.length > 0 ? (
                        <div>{t('Additional pieces', { lng: lang })}</div>
                      ) : (
                        <i>{t('TxtBasePieces', { lng: lang })}</i>
                      )}
                    </div>
                  </div>
                )}

              {packageOrder.compositionExceptedCabinet !== undefined &&
                packageOrder.compositionExceptedCabinet.length > 0 && (
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th align="left">{t('Piece', { lng: lang })}</th>
                        <th align="left" className="whitespace-no-wrap">
                          {t('Cabinet', { lng: lang })}
                        </th>
                        <th align="left" className="whitespace-no-wrap">
                          {t('Pack', { lng: lang })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageOrder.compositionExceptedCabinet?.map(
                        (piece, index) => (
                          <tr
                            key={`${piece.reference}-${index}`}
                            className="text-sm"
                          >
                            <td>
                              {packageOrder.uniqueDescr ??
                                `${piece.quantity} x ${
                                  piece.description
                                    ? productionPieceGetDescription(piece, lang)
                                    : piece.reference
                                }`}
                            </td>
                            <td className="whitespace-no-wrap">
                              {piece.product?.cabinet
                                ? piece.product.name
                                : '/'}
                            </td>
                            <td className="whitespace-no-wrap">
                              {piece.product?.furniture
                                ? `f${piece.product?.furniture}`
                                : '/'}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                )}
            </div>
          </div>
        </div>

        <div className="flex items-start justify-start label-signatures">
          <div className="flex w-1/3">{t('Prepared by', { lng: lang })}</div>
          <div className="flex w-1/3">{t('Checked by', { lng: lang })}</div>
          <div className="flex w-1/3">{t('Packed by', { lng: lang })}</div>
        </div>

        <div className="flex items-start justify-start label-text-qr">
          <div
            className={packageOrder.images?.length === 1 ? 'w-2/3' : 'w-full'}
          >
            <div>
              <b>{t('TxtKeepDocTitle', { lng: lang })}</b>
              <br />
              {t('TxtKeepDocText', { lng: lang })}
            </div>
          </div>
          {destination.name !== 'Köln' &&
            packageOrder.cabinets &&
            packageOrder.cabinets[0] && (
              <div className="flex items-center text-center w-1/3 flex-col">
                <QRCode
                  value={`https://www.kewlox.com/p-${packageOrder.cabinets[0]}`}
                  level="H"
                  size={100}
                  renderAs="canvas"
                />
                <div>{t('See my cabinet in 3D', { lng: lang })}</div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

const ColorBox = styled.div<{
  color?: string;
  textColor?: string;
  borderColor?: string;
}>`
  background-color: ${p => (p.color ? p.color : 'none')};
  color: ${p => (p.textColor ? p.textColor : 'default')};
  border: ${p => (p.borderColor ? `2px solid ${p.borderColor}` : 'none')};
`;

const LabelNameStyled = styled.div<{ size?: number }>`
  font-size: 1rem;

  ${p =>
    p.size &&
    p.size < 150 &&
    css`
      font-size: 1.125rem;
    `}

  ${p =>
    p.size &&
    p.size < 100 &&
    css`
      font-size: 1.25rem;
    `}

  ${p =>
    p.size &&
    p.size < 38 &&
    css`
      font-size: 1.7rem;
    `}
`;
